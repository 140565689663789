function initFreshChat() {
  const user = JSON.parse(window.localStorage.getItem('user'))

  window.fcWidget?.init({
    token: '2b970b96-2c1e-432a-b040-7aec5cb91f0e',
    host: 'https://wchat.freshchat.com',
    ...(user && {
      externalId: user.username,
      firstName: user.username,
      email: user.username
    })
  })

  if (user) {
    window.fcWidget?.user?.setFirstName(user.username)
  }
}

function initialize(i, t) {
  let e
  i.getElementById(t)
    ? initFreshChat()
    : (((e = i.createElement('script')).id = t),
      (e.async = !0),
      (e.src = 'https://wchat.freshchat.com/js/widget.js'),
      (e.onload = initFreshChat),
      i.head.appendChild(e))
}

function initiateCall() {
  window.addEventListener
    ? window.addEventListener('load', initiateCall, !1)
    : window.attachEvent('load', initiateCall, !1)

  initialize(document, 'freshchat-js-sdk')
}

const destroy = () => {
  window.fcWidget?.destroy()
}

export default { init: initiateCall, destroy }
