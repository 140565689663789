import React, { Component } from 'react'
import {
  Button,
  Checkbox,
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  HelpBlock,
  Modal,
  Panel,
  Row
} from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

import { getFormControlPropsFromReduxForm } from '../../../../util/form'
import Spinner from '../../../common/Spinner/Spinner'

function NotificationMessageModal({
  field,
  submitting,
  pristine,
  onSubmit,
  onHide
}) {
  return (
    <Modal animation={false} show onHide={onHide}>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage id='settings.merchant.messageModal.title' />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormGroup controlId={field.name}>
          <ControlLabel>
            <FormattedMessage id={`merchant.${field.name}`} />
          </ControlLabel>
          <FormControl
            componentClass='textarea'
            {...getFormControlPropsFromReduxForm(field)}
          >
            {field.value}
          </FormControl>
        </FormGroup>
        <HelpBlock>
          {/* <FormattedMessage id='settings.merchant.messageModal.help' /> */}
          You can include dynamic information in your message. These options
          are:
          <ul>
            <li>
              <code>&lt;MERCHANT&gt;</code> &ndash; The name of your company or
              brand.
            </li>
            <li>
              <code>&lt;URL&gt;</code> &ndash; The web address where your
              customer can track their order.
            </li>
            <li>
              <code>&lt;MINUTES&gt;</code> &ndash; Number of minutes left before
              the driver gets to the recipient's addess. (Only applies to nearby
              notifications.)
            </li>
          </ul>
        </HelpBlock>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide} disabled={submitting}>
          <i className='fa fa-times-circle spaceAfter' />
          <FormattedMessage id='settings.merchant.modal.cancel' />
        </Button>
        <Button
          type='submit'
          className='btn-brand-secondary'
          disabled={pristine || submitting}
          onClick={onSubmit}
        >
          {submitting ? (
            <Spinner showText={false} className='spaceAfter' />
          ) : (
            <i className='fa fa-check spaceAfter' />
          )}
          <FormattedMessage id='settings.merchant.modal.save' />
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

interface Props {
  handleSubmit: () => Promise<void>
  resetForm: () => void
  fields: any
  submitting: boolean
  pristine: boolean
}

interface State {
  modal: string
}

class NotificationSettings extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      modal: null
    }
  }

  handleCheckboxChange(event) {
    this.props.fields[event.target.name].onChange(event)
    // We need to submit the form asynchronously for the checkbox change event
    // to propagatee through the redux store.
    setTimeout(this.props.handleSubmit, 50)
  }

  handleCustomiseButtonClick(prop) {
    this.setState({ modal: prop })
  }

  handleModalHide() {
    this.setState({ modal: null })
    this.props.resetForm()
  }

  handleModalSubmit() {
    this.props.handleSubmit().then(this.handleModalHide.bind(this))
  }

  render() {
    const {
      sendMailWhenInTransit,
      sendMailWhenNearby,
      sendTextWhenInTransit,
      sendTextWhenNearby
    } = this.props.fields

    return (
      <Row className='NotificationSettings'>
        <Col sm={12}>
          <h3>
            <FormattedMessage id='settings.merchant.notifications.title' />
          </h3>
        </Col>

        {/* sms notification settings */}
        <Col sm={6}>
          <Panel>
            <Panel.Heading>
              <h3>
                <FormattedMessage id='settings.merchant.smsNotifications.title' />
              </h3>
            </Panel.Heading>
            <Panel.Body>
              <FormGroup controlId='sendTextWhenInTransit'>
                <Checkbox
                  disabled={this.props.submitting}
                  {...getFormControlPropsFromReduxForm(sendTextWhenInTransit)}
                  onChange={this.handleCheckboxChange.bind(this)}
                >
                  <FormattedMessage id='merchant.sendTextWhenInTransit' />
                </Checkbox>
                <Button
                  className='customiseMessage'
                  onClick={this.handleCustomiseButtonClick.bind(
                    this,
                    'inTransitTextMessage'
                  )}
                  disabled={this.props.submitting}
                >
                  <FormattedMessage id='settings.merchant.customiseText' />
                </Button>
              </FormGroup>

              <FormGroup controlId='sendTextWhenNearby'>
                <Checkbox
                  disabled={this.props.submitting}
                  {...getFormControlPropsFromReduxForm(sendTextWhenNearby)}
                  onChange={this.handleCheckboxChange.bind(this)}
                >
                  <FormattedMessage id='merchant.sendTextWhenNearby' />
                </Checkbox>
                <Button
                  className='customiseMessage'
                  onClick={this.handleCustomiseButtonClick.bind(
                    this,
                    'nearbyTextMessage'
                  )}
                  disabled={this.props.submitting}
                >
                  <FormattedMessage id='settings.merchant.customiseText' />
                </Button>
              </FormGroup>
            </Panel.Body>
          </Panel>
        </Col>

        {/* email notification settings */}
        <Col sm={6}>
          <Panel>
            <Panel.Heading>
              <h3>
                <FormattedMessage id='settings.merchant.emailNotifications.title' />
              </h3>
            </Panel.Heading>
            <Panel.Body>
              <FormGroup controlId='sendMailWhenInTransit'>
                <Checkbox
                  disabled={this.props.submitting}
                  {...getFormControlPropsFromReduxForm(sendMailWhenInTransit)}
                  onChange={this.handleCheckboxChange.bind(this)}
                >
                  <FormattedMessage id='merchant.sendMailWhenInTransit' />
                </Checkbox>
                <Button
                  className='customiseMessage'
                  onClick={this.handleCustomiseButtonClick.bind(
                    this,
                    'inTransitMailMessage'
                  )}
                  disabled={this.props.submitting}
                >
                  <FormattedMessage id='settings.merchant.customiseEmail' />
                </Button>
              </FormGroup>

              <FormGroup controlId='sendMailWhenNearby'>
                <Checkbox
                  disabled={this.props.submitting}
                  {...getFormControlPropsFromReduxForm(sendMailWhenNearby)}
                  onChange={this.handleCheckboxChange.bind(this)}
                >
                  <FormattedMessage id='merchant.sendMailWhenNearby' />
                </Checkbox>
                <Button
                  className='customiseMessage'
                  onClick={this.handleCustomiseButtonClick.bind(
                    this,
                    'nearbyMailMessage'
                  )}
                  disabled={this.props.submitting}
                >
                  <FormattedMessage id='settings.merchant.customiseEmail' />
                </Button>
              </FormGroup>
            </Panel.Body>
          </Panel>
        </Col>

        {this.state.modal != null ? (
          <NotificationMessageModal
            field={this.props.fields[this.state.modal]}
            submitting={this.props.submitting}
            pristine={this.props.pristine}
            onSubmit={this.handleModalSubmit.bind(this)}
            onHide={this.handleModalHide.bind(this)}
          />
        ) : null}
      </Row>
    )
  }
}

export { NotificationMessageModal }
export default NotificationSettings
