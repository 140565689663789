import { Map as ImmutableMap } from 'immutable'

import { REQUEST_SENT, RESPONSE } from '../../actions/services/ServiceActions'
import { RESPONSE_BATCH } from '../../actions/services/ServiceActions'

import AddressesReducer from './AddressesReducer'
import ArchivedTasksReducer from './ArchivedTasksReducer'
import DriversReducer from './DriversReducer'
import LocationsReducer from './LocationsReducer'
import MerchantsReducer from './MerchantsReducer'
import TasksReducer from './TasksReducer'

const initialState = ImmutableMap()

export default function services(state = initialState, action) {
  const { type, namespace } = action

  switch (type) {
    case RESPONSE_BATCH:
      return action.batch.reduce(services, state)
    case REQUEST_SENT:
    case RESPONSE:
      switch (namespace) {
        case 'merchants':
          return state.set(
            namespace,
            MerchantsReducer(state.get(namespace), action)
          )
        case 'drivers':
          return state.set(
            namespace,
            DriversReducer(state.get(namespace), action)
          )
        case 'tasks':
          return state.set(
            namespace,
            TasksReducer(state.get(namespace), action)
          )
        case 'archivedTasks':
          return state.set(
            namespace,
            ArchivedTasksReducer(state.get(namespace), action)
          )
        case 'locations':
          return state.set(
            namespace,
            LocationsReducer(
              state.get(namespace) as ImmutableMap<any, any>,
              action
            )
          )
        case 'addresses':
          return state.set(
            namespace,
            AddressesReducer(state.get(namespace), action)
          )
        default:
          // tslint:disable-next-line
          console.error('Unknown namespace', action)
          throw new Error('Unknown namespace')
      }

    default:
      return state
  }
}
