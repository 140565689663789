import moment from 'moment'
import React from 'react'
import { ListGroup, ListGroupItem } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

import { TASK_SECTION } from '../../../constants'

export function TaskAction({ action }) {
  const date = moment(action.get('timestamp')).format('L LT')
  return (
    <div className='TaskAction' data-testid='status-log-message'>
      <small>
        <b>
          {action.get('author')} &ndash; {date}
        </b>
      </small>
      <br />
      {action.get('type') === 'reassigned' &&
      action.get('driverId') === TASK_SECTION.WALKED ? (
        'Reassigned to Walked'
      ) : (
        <FormattedMessage id={`task.status.${action.get('type')}`} />
      )}
      {action.get('vendorReference') || action.get('vendor')
        ? ` (${action.get('vendor') || ''} ref.: ${action.get(
            'vendorReference'
          ) || '?'})`
        : null}
      {action.get('notes') ? ` - ${action.get('notes').join('; ')}` : ''}
      {action.get('driver')
        ? ` (${action.getIn(['driver', 'name'])}, ${action.getIn([
            'driver',
            'phone'
          ])})`
        : ''}
    </div>
  )
}

export default ({ actionLog }) => (
  <ListGroup className='selectable'>
    {actionLog.toArray().map((action, index) => (
      <ListGroupItem key={action.get('timestamp')}>
        <TaskAction action={action} />
      </ListGroupItem>
    ))}
  </ListGroup>
)
