import { fromJS, is, Map as ImmutableMap } from 'immutable'
import React from 'react'
import { connect } from 'react-redux'
import { generatePath, RouteComponentProps } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import MapUiActions from '../../../../actions/MapUiActions'
import QuotesUiActions from '../../../../actions/QuotesUiActions'
import ArchivedTaskActions from '../../../../actions/services/ArchivedTaskActions'
import MerchantActions from '../../../../actions/services/MerchantActions'
import TaskActions from '../../../../actions/services/TaskActions'
import { TASK_STATUS, VIEW_TYPE } from '../../../../constants'
import { Routes } from '../../../../navigation/Routes'
import LoadingModal from '../../../common/LoadingModal/LoadingModal'
import TaskForm from '../edit/TaskForm/TaskForm'
import TaskFormSelector from '../edit/TaskForm/TaskForm.selector'

interface Props
  extends RouteComponentProps<
    { taskId: string; viewType: VIEW_TYPE },
    {},
    { taskDataForRepeatBooking: ImmutableMap<any, any> }
  > {
  // injected by parent component
  className?: string
  // redux selector
  user: ImmutableMap<any, any>
  merchants: ImmutableMap<any, any>
  task?: ImmutableMap<any, any>
  isTaskArchived: boolean
  allowEditing: boolean
  // redux actions
  MerchantActions: typeof MerchantActions
  QuotesUiActions: typeof QuotesUiActions
  TaskActions: typeof TaskActions
  ArchivedTaskActions: typeof ArchivedTaskActions
  MapUiActions: typeof MapUiActions
}

interface State {
  archivingInProgress: boolean
  archiveResponse: any
  archiveError: any
  cloningInProgress: boolean
  cloneResponse: any
  cloneError: any
}

class TaskFormContainer extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      archivingInProgress: false,
      archiveResponse: null,
      archiveError: null,
      cloningInProgress: false,
      cloneResponse: null,
      cloneError: null
    }
  }

  private saveLastSource = (formData) => {
    const lastSourcesString = window.localStorage.lastSources
    const lastSources = lastSourcesString ? JSON.parse(lastSourcesString) : {}
    lastSources[formData.merchantId] = {
      name: formData.sourceName,
      address: formData.sourceAddress,
      city: formData.sourceCity,
      postcode: formData.sourcePostcode,
      country: formData.sourceCountry,
      phone: formData.sourcePhone,
      email: formData.sourceEmail
    }
    window.localStorage.setItem('lastMerchantId', formData.merchantId)
    window.localStorage.setItem('lastSources', JSON.stringify(lastSources))
  }

  hideModal = () => {
    this.props.QuotesUiActions.resetLastQuoteQueries()
    this.props.history.push(
      generatePath(Routes.Map.Tasks, {
        viewType: this.props.match.params.viewType
      })
    )
  }

  addStatus(id, status) {
    return this.props.TaskActions.addStatus({
      taskId: id,
      driverId: null,
      author: this.props.user.get('username'),
      data: status
    })
  }

  addNote(id, note) {
    return this.props.TaskActions.addNote({
      taskId: id,
      type: 'text',
      author: this.props.user.get('username'),
      data: note
    })
  }

  handleSubmit = async (formData) => {
    if (
      !this.isCreateNewTask() &&
      !confirm('Are you sure you want to make these changes?')
    ) {
      return
    }

    const { task, match } = this.props

    // This is the object that will eventually get sent in an `update` or
    // `insert` request.
    let data = fromJS(formData)

    if (task != null) {
      // If this is an existing task, we'll only send the data that was changed
      // on the form.
      data = data.filter((value, key) => {
        return !is(value, task.get(key))
      })
    }

    // Remove the `currentStatus` and `newNote` fields, these will need to be
    // passed to their own service methods (not to `insert` or `update`).
    const currentStatus = data.get('currentStatus')
    const newNote = data.get('newNote')
    data = data.delete('currentStatus')
    data = data.delete('newNote')

    return new Promise((resolve, reject) => {
      // If this is a new task...
      if (task == null) {
        // First insert the new task...
        this.props.TaskActions.geocodeAndInsert({ data })
          .catch((_error) => reject({ _error }))
          .then((response) => {
            // Then update the task with additional status changes and notes.
            const generatedId = response.get('generatedKeys').get(0)
            const actionPromises = []

            // Reject the promise if there was an error while inserting the task.
            if (generatedId == null || response.get('lastError') != null) {
              reject({ _error: response.get('lastError') })
            } else {
              // If the `newNote` field has been changed on the form, add the
              // first note to the task.
              if (newNote != null) {
                actionPromises.push(this.addNote(generatedId, newNote))
              }

              Promise.all(actionPromises)
                .catch((_error) => reject({ _error }))
                .then(() => {
                  this.saveLastSource(formData)
                  this.props.history.push(
                    generatePath(Routes.Map.TaskDetail, {
                      taskId: generatedId,
                      viewType: match.params.viewType
                    })
                  )
                  resolve()
                })
            }
          })
        // If this is an existing task...
      } else {
        // First update the task with the form field changes...
        this.props.TaskActions.geocodeAndUpdate({
          id: task.get('id'),
          data
        })
          .catch((_error) => reject({ _error }))
          .then((response) => {
            // Then also update the status and add any new note.
            const actionPromises = []

            // Reject the promise if there was an error while updating.
            if (
              response.get('updated') !== 1 ||
              response.get('lastError') != null
            ) {
              reject({ _error: response.get('lastError') })
            } else {
              // If the `currentStatus` field has been changed on the form, add
              // a new status to the task's `actionLog`.
              if (currentStatus != null) {
                actionPromises.push(
                  this.addStatus(task.get('id'), currentStatus)
                )
              }
              // If the `newNote` field has been changed on the form, add the
              // new note to the task.
              if (newNote) {
                actionPromises.push(this.addNote(task.get('id'), newNote))
              }

              Promise.all(actionPromises)
                .catch((_error) => reject({ _error }))
                .then(() => {
                  this.saveLastSource(formData)
                  resolve()
                })
            }
          })
      }
    })
  }

  private handleCloneClick = (values) => async () => {
    this.setState({ cloningInProgress: true })

    try {
      const response = await this.props.TaskActions.clone({
        taskId: this.props.task.get('id'),
        ...(values && { values })
      })
      this.setState({
        cloningInProgress: false,
        cloneResponse: response
      })
      const generatedId = response.get('generatedKeys').first()
      this.props.history.push(
        generatePath(Routes.Map.TaskDetail, {
          taskId: generatedId,
          viewType: this.props.match.params.viewType
        })
      )
    } catch (error) {
      this.setState({
        cloningInProgress: false,
        cloneError: error
      })
    }
  }

  private handleArchiveClick = async () => {
    this.setState({
      archivingInProgress: true
    })
    try {
      const taskId = this.props.task.get('id')
      const response = await this.props.TaskActions.archive({ taskId })
      this.setState({
        archivingInProgress: false,
        archiveResponse: response
      })
      if (response.get('deleted') === 1) {
        this.hideModal()
        this.props.MapUiActions.deselectTask(taskId)
      }
    } catch (error) {
      this.setState({
        archivingInProgress: false,
        archiveError: error
      })
    }
  }

  private findArchivedTask = () => {
    const { taskId } = this.props.match.params
    this.props.ArchivedTaskActions.findById({ keyword: taskId })
  }

  private handleRepeatBookingClick = () => {
    this.props.history.push({
      pathname: generatePath(Routes.Map.TaskDetail, {
        taskId: 'new',
        viewType: this.props.match.params.viewType
      }),
      state: {
        taskDataForRepeatBooking: this.props.task
      }
    })
  }

  private openChat = () => {
    const { taskId } = this.props.match.params

    this.props.history.push(
      generatePath(Routes.Map.TaskChat, {
        taskId,
        viewType: this.props.match.params.viewType
      })
    )
  }

  private getInitialValues = () => {
    const {
      task,
      merchants,
      user,
      location: { state }
    } = this.props
    if (state && state.taskDataForRepeatBooking) {
      return state.taskDataForRepeatBooking.toJS()
    }

    if (task != null) {
      return task.toJS()
    }

    const lastMerchantId = window.localStorage.getItem('lastMerchantId')
    const lastSourcesString = window.localStorage.getItem('lastSources')

    const defaultMerchantId =
      lastMerchantId && user.get('merchants').includes(lastMerchantId)
        ? lastMerchantId
        : user.get('merchants').first()
    const defaultMerchant = merchants.getIn(['data', defaultMerchantId])
    const lastSources = lastSourcesString ? JSON.parse(lastSourcesString) : {}
    const loadPreviousSource =
      defaultMerchant && defaultMerchant.get('loadPreviousSource', true)
    const lastSource = loadPreviousSource
      ? lastSources[defaultMerchantId]
      : null

    return {
      onDemand: false,
      merchantId: defaultMerchantId,
      schedule: 'urgent',
      parcelLength: defaultMerchant.get('parcelLength'),
      parcelWidth: defaultMerchant.get('parcelWidth'),
      parcelHeight: defaultMerchant.get('parcelHeight'),
      parcelWeight: defaultMerchant.get('parcelWeight'),
      parcelFragile: defaultMerchant.get('parcelFragile'),
      parcelDoNotRotate: defaultMerchant.get('parcelDoNotRotate'),
      parcelContainsLiquid: defaultMerchant.get('parcelContainsLiquid'),
      parcelContainsHotFood: defaultMerchant.get('parcelContainsHotFood'),
      currentStatus: TASK_STATUS.TASK_RECEIVED,
      sourceName: lastSource ? lastSource.name : '',
      sourceAddress: lastSource ? lastSource.address : '',
      sourceCity: lastSource ? lastSource.city : 'London',
      sourcePostcode: lastSource ? lastSource.postcode : '',
      sourceCountry: lastSource ? lastSource.country : 'United Kingdom',
      sourcePhone: lastSource ? lastSource.phone : '',
      sourceEmail: lastSource ? lastSource.email : '',
      destinationCity: 'London',
      destinationCountry: 'United Kingdom'
    }
  }

  private isCreateNewTask() {
    return this.props.match.params.taskId === 'new'
  }

  render() {
    // `task` will only be defined if this is an update dialog, otherwise this
    // dialog will be used to add a new task.
    const {
      task,
      merchants,
      className,
      match: {
        params: { taskId }
      }
    } = this.props
    if (merchants == null || !merchants.get('ready')) {
      return null
    }

    if (taskId && !this.isCreateNewTask() && !task) {
      setTimeout(this.findArchivedTask)
      return <LoadingModal onHide={this.hideModal} />
    }

    return (
      <TaskForm
        className={className}
        taskId={taskId}
        sourceError={task != null && task.get('sourceGeolocation') == null}
        destinationError={
          task != null && task.get('destinationGeolocation') == null
        }
        onModalHide={this.hideModal}
        onCloneClick={this.handleCloneClick({
          cloneReason: 'race_to_pickup'
        })}
        onMissingItemButtonClick={this.handleCloneClick({
          parcelValue: 0,
          cloneReason: 'missing_item'
        })}
        onRepeatBookingButtonClick={this.handleRepeatBookingClick}
        openChat={this.openChat}
        cloningInProgress={this.state.cloningInProgress}
        cloneError={this.state.cloneError}
        onArchiveClick={this.handleArchiveClick}
        archivingInProgress={this.state.archivingInProgress}
        archiveError={this.state.archiveError}
        initialValues={this.getInitialValues()}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  ArchivedTaskActions: bindActionCreators(ArchivedTaskActions, dispatch),
  TaskActions: bindActionCreators(TaskActions, dispatch),
  QuotesUiActions: bindActionCreators(QuotesUiActions, dispatch),
  MerchantActions: bindActionCreators(MerchantActions, dispatch),
  MapUiActions: bindActionCreators(MapUiActions, dispatch)
})

export { mapDispatchToProps }
export default connect(TaskFormSelector, mapDispatchToProps)(TaskFormContainer)
