import { Map as ImmutableMap } from 'immutable'

import {
  SOCKET_CONNECT,
  SOCKET_CONNECT_ATTEMPT,
  SOCKET_DISCONNECT,
  SOCKET_ERROR,
  SOCKET_RECONNECT,
  SOCKET_RECONNECT_ATTEMPT,
  SOCKET_RECONNECT_ERROR,
  SOCKET_RECONNECT_FAILED,
  SOCKET_RECONNECTING
} from '../actions/SocketConnectionActions'

import SocketConnectionReducer from './SocketConnectionReducer'

const initialState = ImmutableMap()

export default function SocketNamespacesReducer(state = initialState, action) {
  switch (action.type) {
    case SOCKET_CONNECT:
    case SOCKET_RECONNECT:
    case SOCKET_ERROR:
    case SOCKET_DISCONNECT:
    case SOCKET_RECONNECT_FAILED:
    case SOCKET_RECONNECT_ERROR:
    case SOCKET_CONNECT_ATTEMPT:
    case SOCKET_RECONNECT_ATTEMPT:
    case SOCKET_RECONNECTING:
      return state.set(
        action.namespace,
        SocketConnectionReducer(
          state.get(action.namespace) as ImmutableMap<any, any>,
          action
        )
      )
    default:
      return state
  }
}
