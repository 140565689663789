import { fromJS, Set as ImmutableSet } from 'immutable'
import React, { MouseEventHandler } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import DriverActions from '../../../../actions/services/DriverActions'
import { i18n } from '../../../../util/i18n'
import DriverSelect from '../DriverSelect/DriverSelect'

interface Props {
  close: () => void | MouseEventHandler<Button>
  selectedTasks: ImmutableSet<any>
  merchantIds: ImmutableSet<any>
  reassign: (value) => void
}

class ChangeStatusDialog extends React.PureComponent<Props> {
  private selectInputRef = React.createRef<HTMLSelectElement>()
  submit() {
    this.props.reassign(this.selectInputRef.current.value)
    this.props.close()
  }

  render() {
    return (
      <Modal
        animation={false}
        className='UploadDialog'
        show
        onHide={this.props.close}
      >
        <Modal.Header>
          <Modal.Title>{i18n`Change status`}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            <strong>{i18n`Selected ${this.props.selectedTasks.size} tasks.`}</strong>{' '}
            {i18n`Assign to driver:`}
          </p>
          <div className='inputContainer'>
            <DriverSelect
              inputRef={this.selectInputRef}
              merchantIds={this.props.merchantIds}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={this.props.close}>
            <i className='fa fa-times-circle spaceAfter' />
            {i18n`Cancel`}
          </Button>
          <Button onClick={this.submit.bind(this)}>{i18n`Save`}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

const mapStateToProps = (state: any) => {
  const allTasks = state.services.getIn(['tasks', 'all', fromJS({}), 'data'])
  const selectedTasks = state.MapUiReducer.get('selectedTasks')
  const merchantIds = selectedTasks.map((taskId) =>
    allTasks.getIn([taskId, 'merchantId'])
  )
  return {
    selectedTasks,
    merchantIds
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      reassign: DriverActions.reassign
    },
    dispatch
  )

export { mapStateToProps, mapDispatchToProps }
export default connect<{}, {}, Props>(
  mapStateToProps,
  mapDispatchToProps
)(ChangeStatusDialog)
