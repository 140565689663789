import React from 'react'
import { matchPath, RouteComponentProps, withRouter } from 'react-router-dom'

import { Routes } from '../../navigation/Routes'
import freshchat from '../../services/freshchat'

const pathsToShowFreshchat = [
  Routes.Login,
  Routes.Register,
  Routes.Confirm,
  Routes.Settings.Root
]

class FreshChat extends React.Component<RouteComponentProps> {
  componentDidMount() {
    this.startInitializing()
  }

  componentDidUpdate() {
    this.startInitializing()
  }

  startInitializing() {
    const {
      location: { pathname }
    } = this.props

    for (const path of pathsToShowFreshchat) {
      if (matchPath(pathname.replace(/#/, ''), { path })) {
        freshchat.init()

        return
      }
    }

    freshchat.destroy()
  }

  render() {
    return null
  }
}

export default withRouter(FreshChat)
