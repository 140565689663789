type SubscribeFunction = (data: any) => void
interface Subscriptions {
  [key: string]: {
    subscribers: SubscribeFunction[]
    lastGpsUpdateTimestamp: number
  }
}

class GpsPointsSubscriptionManager {
  private subscriptions: Subscriptions = {}

  subscribe = (key: string, subscriber: SubscribeFunction): void => {
    const subscription = this.subscriptions[key]
    if (subscription) {
      const subscribers = [...subscription.subscribers]
      if (subscribers.indexOf(subscriber) === -1) {
        subscribers.push(subscriber)
      }
      this.subscriptions[key] = {
        ...subscription,
        subscribers
      }
    } else {
      this.subscriptions[key] = {
        lastGpsUpdateTimestamp: 0,
        subscribers: [subscriber]
      }
    }
  }

  unsubscribe = (key: string, subscriber: SubscribeFunction): void => {
    const subscription = this.subscriptions[key]
    if (subscription) {
      const subscribers = [...subscription.subscribers]
      const index = subscribers.indexOf(subscriber)
      if (index > -1) {
        subscribers.splice(index, 1)
      }

      this.subscriptions[key] = {
        ...subscription,
        subscribers
      }
    }
  }

  notify(key: string, lastGpsUpdateTimestamp: any): void {
    const subscription = this.subscriptions[key]
    if (!subscription) {
      this.subscriptions[key] = {
        subscribers: [],
        lastGpsUpdateTimestamp
      }
      return
    }

    const subscribers = subscription.subscribers || []
    subscribers.forEach((subscribe) => subscribe(lastGpsUpdateTimestamp))

    this.subscriptions[key] = {
      ...subscription,
      lastGpsUpdateTimestamp
    }
  }

  getLastValue(taskId: string): number {
    const subscription = this.subscriptions[taskId]
    if (subscription) {
      return subscription.lastGpsUpdateTimestamp || 0
    }
    return 0
  }
}
const gpsPointsSubscriptionManager = new GpsPointsSubscriptionManager()
export { GpsPointsSubscriptionManager }
export default gpsPointsSubscriptionManager
