import React, { PureComponent } from 'react'
import { DateTimePicker } from 'react-widgets'

/**
 * Redux-Form compatible date & time picker. It uses the React-Widgets
 * DateTimePicker component. It handles the conversion between Date instances
 * and the ISO-8601 representation of dates.
 */
interface Props {
  id?: string
  value?: string
  defaultValue?: string
  onChange?: (value?) => void
  onBlur?: () => void
  calendar?: boolean
  time?: boolean
  readOnly?: boolean
  className?: string
  disabled?: boolean
  dropUp?: boolean
  format?: string
  placeholder?: string
}

class RFDateTimePicker extends PureComponent<Props> {
  static defaultProps = {
    calendar: true,
    time: true
  }

  handleChange(date) {
    if (!date && this.props.onChange != null) {
      this.props.onChange(null)
    } else if (date && this.props.onChange != null) {
      if (this.props.calendar === this.props.time) {
        this.props.onChange(date.toISOString())
      } else if (this.props.calendar && !this.props.time) {
        // only a day is selected
        date.setHours(0, 0, 0, 0)
        this.props.onChange(date.toISOString())
      } else if (!this.props.calendar && this.props.time) {
        // only a time is selected
        const hours = date.getHours()
        const minutes = date.getMinutes()
        const hoursPadded = '' + (hours < 10 ? '0' + hours : hours)
        const minutesPadded = '' + (minutes < 10 ? '0' + minutes : minutes)
        this.props.onChange(hoursPadded + ':' + minutesPadded)
      }
    }
  }

  convertValue(value) {
    let convertedValue
    if (value) {
      if (this.props.calendar === this.props.time) {
        convertedValue = new Date(value)
      } else if (this.props.calendar && !this.props.time) {
        convertedValue = new Date(value)
      } else if (!this.props.calendar && this.props.time) {
        // only a time is selected
        convertedValue = new Date(0)
        convertedValue.setHours(parseInt(value[0] + value[1], 10))
        convertedValue.setMinutes(parseInt(value[3] + value[4], 10))
      }
    }

    return convertedValue
  }

  render() {
    const defaultValue = this.convertValue(this.props.defaultValue)
    const value = this.convertValue(this.props.value)

    return (
      <DateTimePicker
        {...this.props}
        className={
          'form-control' +
          (this.props.className ? ' ' + this.props.className : '')
        }
        // override redux-form field props
        onChange={this.handleChange.bind(this)}
        defaultValue={defaultValue}
        value={value}
      />
    )
  }
}

export default RFDateTimePicker
