import { DELIVERY_STATUS, TASK_STATUS } from '../constants'

const PENDING_STATUSES = [
  TASK_STATUS.TASK_RECEIVED,
  TASK_STATUS.QUOTE_APPROVED,
  TASK_STATUS.FAILED
]

const isTaskPending = (task) =>
  PENDING_STATUSES.includes(task.get('currentStatus'))

const isPickingOrDelivering = (currentStatus) => {
  return (
    currentStatus === DELIVERY_STATUS.ArrivedAtPickup ||
    currentStatus === DELIVERY_STATUS.InTransit ||
    currentStatus === DELIVERY_STATUS.ArrivedAtDropoff
  )
}

const getIsMissingGpsTracking = ({ currentStatus, lastGpsUpdateTimestamp }) => {
  if (isPickingOrDelivering(currentStatus)) {
    const timeDiff =
      Date.now() - new Date(lastGpsUpdateTimestamp || 0).getTime()
    if (timeDiff > 20 * 60e3) {
      return true
    }
  }
  return false
}

export { isTaskPending, isPickingOrDelivering, getIsMissingGpsTracking }
