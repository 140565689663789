import { fromJS } from 'immutable'
import * as React from 'react'
import socketio from 'socket.io-client'

import config from '../config'
import gpsPointsSubscriptionManager from '../util/gpsPointsSubscriptionManager'

import AuthActions from './AuthActions'
import {
  connected,
  connectError,
  disconnected,
  reconnectAttempt,
  reconnectError,
  reconnectFail,
  reconnecting
} from './SocketConnectionActions'
import { RESPONSE, RESPONSE_BATCH } from './services/ServiceActions'

const windowKey = '__changfeedProviderSockets'

const TWO_MINUTES_IN_MILISECONDS = 2 * 60 * 1000

export class ChangfeedProvider extends React.Component<{
  dispatch: any
  token: string
}> {
  sockets: SocketIOClient.Socket[] = []
  interval: any = null
  batch: any[] = []
  connectionToken = null

  componentDidUpdate() {
    if (this.connectionToken !== this.props.token) {
      this.connectionToken = this.props.token
      this.closeAll()
      this.connect(this.props.token)
    }
  }

  private onInterval = () => {
    if (this.batch.length > 0) {
      this.props.dispatch({
        type: RESPONSE_BATCH,
        batch: this.batch.splice(0),
        meta: { async: true }
      })
    }
  }

  componentDidMount() {
    if (this.props.token) {
      this.connectionToken = this.props.token
      this.connect(this.props.token)
    }

    this.interval = setInterval(this.onInterval, 1000)
  }

  connect(token) {
    this.closeAll()
    this.sockets.push(
      ...[
        this.setupSocket('tasks', 'all', {}, token),
        this.setupSocket('drivers', 'all', {}, token),
        this.setupSocket('merchants', 'all', {}, token)
      ]
    )
  }

  setupSocket(namespace: string, method: string, params: any, token) {
    const socket = socketio(`${config.socketioServer}/${namespace}`, {
      transports: ['websocket']
    })

    socket.on('connect', () => {
      this.props.dispatch(connected(namespace, socket))
      this.onConnect(socket, namespace, method, params, token)
    })
    socket.on('error', (error) => {
      this.props.dispatch(connectError(namespace, error))
    })
    socket.on('disconnect', () => {
      this.props.dispatch(disconnected(namespace))
    })
    socket.on('reconnect', () => {
      this.props.dispatch(connected(namespace, socket))
      this.onConnect(socket, namespace, method, params, token)
    })
    socket.on('reconnect_attempt', () => {
      this.props.dispatch(reconnectAttempt(namespace))
    })
    socket.on('reconnecting', (tries) => {
      this.props.dispatch(reconnecting(namespace, tries))
      // tslint:disable-next-line
      console.error('reconnecting', tries)
    })
    socket.on('reconnect_error', (error) => {
      this.props.dispatch(reconnectError(namespace, error))
      // tslint:disable-next-line
      console.error('socket reconnect_error', error)
    })
    socket.on('reconnect_failed', () => {
      this.props.dispatch(reconnectFail(namespace))
      // tslint:disable-next-line
      console.error('socket reconnect_failed')
    })
    socket.on('response', (data) => {
      if (data && data.type === 'error' && data.data === 'invalidToken') {
        this.props.dispatch(AuthActions.logout('invalidToken'))
        this.closeAll()
      } else {
        this.onResponse(namespace, method, params, data)
      }
    })

    window[windowKey] = window[windowKey] || []
    window[windowKey].push(socket)

    return socket
  }

  componentWillUnmount() {
    this.closeAll()
    clearInterval(this.interval)
  }

  closeAll() {
    for (const socket of this.sockets.splice(0, this.sockets.length)) {
      this.closeSocket(socket)
    }
  }

  closeSocket(socket: SocketIOClient.Socket) {
    socket.close()
    if (window[windowKey] && window[windowKey].indexOf(socket) !== -1) {
      window[windowKey].splice(window[windowKey].indexOf(socket), 1)
    }
  }

  render() {
    return this.props.children
  }

  private onConnect(
    socket: SocketIOClient.Socket,
    namespace: string,
    method: string,
    params: any,
    token
  ) {
    socket.emit('request', {
      method,
      token,
      params
    })
  }

  private onResponse(
    namespace: string,
    method: string,
    params: any,
    response: any
  ) {
    if (response && response.data && response.data.lastKnownLocation) {
      // reduce performance hit on frontend by frequent gps updates by separating location
      // to separate event stream:
      const { id: taskId, lastKnownLocation } = response.data

      const newValue = new Date(lastKnownLocation.timestamp).getTime()
      const previousValue = gpsPointsSubscriptionManager.getLastValue(taskId)

      if (newValue - TWO_MINUTES_IN_MILISECONDS > previousValue) {
        // don't update gps more often than every 2 mins
        gpsPointsSubscriptionManager.notify(taskId, newValue)
      }

      delete response.data.lastKnownLocation
    }

    this.batch.push({
      type: RESPONSE,
      namespace,
      method,
      params: fromJS(params),
      responseType: response.type,
      responseData: response.data
    })
  }
}
