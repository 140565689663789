import { PureComponent } from 'react'
import React from 'react'
import { Table } from 'react-bootstrap'

class GlossaryCommonDefinitions extends PureComponent {
  render() {
    return (
      <Table striped className='glossaryTable'>
        <thead>
          <tr>
            <td>
              <h5>Definitions</h5>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>TT: Travel time</td>
            <td>DO: Dropoff</td>
            <td>PU: Pickup</td>
            <td>AT: Arrival time</td>
          </tr>
        </tbody>
      </Table>
    )
  }
}

export default GlossaryCommonDefinitions
