import classnames from 'classnames'
import React from 'react'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'

import './NavItemLink.scss'

interface Props extends RouteComponentProps {
  children: React.ReactNode
  to: string
  eventKey?: string
}

interface State {
  isActive: boolean
}

class NavItemLink extends React.Component<Props, State> {
  static getDerivedStateFromProps(props) {
    return {
      isActive: props.location.pathname === props.to
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      isActive: props.location.pathname === props.to
    }
  }

  shouldComponentUpdate(
    nextProps: Readonly<Props>,
    nextState: Readonly<State>
  ): boolean {
    return this.state.isActive !== nextState.isActive
  }

  render() {
    return (
      <li
        className={classnames([
          'NavItemLink pointer',
          { active: this.state.isActive }
        ])}
      >
        <Link to={this.props.to}>{this.props.children}</Link>
      </li>
    )
  }
}

export default withRouter(NavItemLink)
