import { ResponseChatInfo } from '../../components/common/ChatWindow/types'

interface ChatBehaviorSubject {
  isAdmin: boolean
  username?: string
  activeTaskId?: string
  chats: {
    [taskId: string]: ResponseChatInfo
  }
  taskIds: {
    [sectionId: string]: string[]
  }
}

interface CannedBehaviorSubject {
  items: CannedResponse[]
  loaded: boolean
}

interface CannedResponse {
  id: string
  content: string
  shortcut: string
}

interface CannedResponses {
  items: CannedResponse[]
}

enum MessageType {
  TEXT = 'text',
  RESOLVED = 'resolved',
  IMAGE = 'image'
}

export {
  ChatBehaviorSubject,
  MessageType,
  CannedBehaviorSubject,
  CannedResponse,
  CannedResponses
}
