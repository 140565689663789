import classnames from 'classnames'
import { Map as ImmutableMap } from 'immutable'
import { isNil } from 'lodash'
import moment from 'moment'
import React from 'react'
import { Alert, Col, Panel } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import QuotesUiActions from '../../../../../actions/QuotesUiActions'
import { QUOTE_STATUS, TASK_STATUS } from '../../../../../constants'
import Spinner from '../../../../common/Spinner/Spinner'
import QuoteCountdown from '../QuoteCountdown/QuoteCountdown'
import QuotesTableContainer from '../QuotesTableContainer/QuotesTableContainer'
import SelectedQuoteTable from '../SelectedQuoteTable/SelectedQuoteTable'

import './QuotesContainer.scss'

interface DispatchProps {
  QuotesUiActions: typeof QuotesUiActions
}

interface Props {
  // injected by parent component
  task?: ImmutableMap<any, any>
  className?: string
  // redux state selector
  user: ImmutableMap<any, any>
  chosenQuote: ImmutableMap<any, any>
  approvalInProgress?: boolean
  approvalError?: any
  cancellationInProgress?: boolean
  cancellationError?: any
  // redux actions
  QuotesUiActions: typeof QuotesUiActions
  isTaskArchived: boolean
}

class QuotesContainer extends React.PureComponent<DispatchProps & Props> {
  render() {
    const { task } = this.props
    if (task == null || !task.get('onDemand')) {
      return null
    }

    const className = classnames(
      'QuotesContainer',
      'customPanel',
      this.props.className
    )

    // expiry is always 5 minutes (even if not all quotes have been received)
    const expiry = moment(task.getIn(['quotes', 'timestamp'])).add(
      15,
      'minutes'
    )
    const expired = expiry.isBefore(moment())

    const panelHeader = (
      <div className='row-space-between'>
        <FormattedMessage id='task.quotes' />
        {!isNil(expiry) &&
          task.getIn(['quotes', 'status']) === QUOTE_STATUS.QUOTED && (
            <QuoteCountdown className='pull-right' expiry={expiry.toDate()} />
          )}
      </div>
    )

    let approvalErrorMessage
    if (this.props.approvalError != null) {
      approvalErrorMessage = (
        <Alert
          bsStyle='danger'
          className='spaceBelow'
          title={JSON.stringify(this.props.approvalError, null, 2)}
        >
          <FormattedMessage id='map.tasks.quote.approvalError' />
          <br />
          <strong>
            {typeof this.props.approvalError === 'string'
              ? this.props.approvalError
              : this.props.approvalError.errorMessage
              ? this.props.approvalError.errorMessage
              : null}
          </strong>
        </Alert>
      )
    }

    let cancellationErrorMessage
    if (this.props.cancellationError != null) {
      cancellationErrorMessage = (
        <Alert
          bsStyle='danger'
          className='spaceBelow'
          title={JSON.stringify(this.props.cancellationError, null, 2)}
        >
          <FormattedMessage id='map.tasks.quote.cancellationError' />
          <br />
          <strong className='selectable'>
            {this.props.cancellationError.errorMessage}
          </strong>
        </Alert>
      )
    }

    let statusMessage
    switch (true) {
      case !expired && task.getIn(['quotes', 'status']) === QUOTE_STATUS.QUOTED:
        statusMessage = (
          <Alert bsStyle='info' className='spaceBelow'>
            <FormattedMessage id='map.tasks.quote.help' />
          </Alert>
        )
        break
      case expired && task.getIn(['quotes', 'status']) === QUOTE_STATUS.QUOTED:
        statusMessage = (
          <Alert bsStyle='warning' className='spaceBelow'>
            <FormattedMessage id='map.tasks.quote.expiredQuotes' />
          </Alert>
        )
        break
      case task.getIn(['quotes', 'status']) === QUOTE_STATUS.APPROVED &&
        task.get('currentStatus') === TASK_STATUS.TASK_RECEIVED:
        statusMessage = (
          <Alert bsStyle='info' className='spaceBelow'>
            <Spinner className='spaceAfter' />
            <FormattedMessage id='map.tasks.quote.approvedAndUnassigned' />
          </Alert>
        )
        break
      case task.getIn(['quotes', 'status']) === QUOTE_STATUS.APPROVED:
        statusMessage = (
          <Alert bsStyle='success' className='spaceBelow'>
            <i className='fa fa-check spaceAfter' />
            <FormattedMessage id='map.tasks.quote.approved' />
          </Alert>
        )
        break
      case task.getIn(['quotes', 'status']) === QUOTE_STATUS.CANCELLED:
        statusMessage = (
          <Alert bsStyle='warning' className='spaceBelow'>
            <FormattedMessage id='map.tasks.quote.cancelled' />
          </Alert>
        )
        break
    }

    return (
      <Col>
        <Panel className={className}>
          <Panel.Heading>
            <span className='iconContainer'>
              <i className={'fa fa-sm fa-pound-sign icon'} />
            </span>

            {panelHeader}
          </Panel.Heading>
          <Panel.Body>
            {approvalErrorMessage}
            {cancellationErrorMessage}
            <LastFailedNotes task={task} />
            {statusMessage}
            {task.getIn(['quotes', 'status']) === QUOTE_STATUS.QUOTED &&
              !this.props.isTaskArchived && (
                <QuotesTableContainer
                  taskId={task.get('id')}
                  quotes={task.getIn(['quotes', 'results'])}
                  expiry={expiry.toDate()}
                  showPickupTime={task.get('schedule') === 'urgent'}
                />
              )}
            {task.getIn(['quotes', 'status']) === QUOTE_STATUS.APPROVED && (
              <SelectedQuoteTable
                quote={task.getIn(['quotes', 'chosenQuote'])}
                confirmation={task.getIn(['quotes', 'confirmation'])}
                driverName={task.getIn(['driver', 'name'])}
                driverPhone={task.getIn(['driver', 'phone'])}
                showPickupTime={task.get('schedule') === 'urgent'}
              />
            )}
          </Panel.Body>
        </Panel>
      </Col>
    )
  }
}

function LastFailedNotes({ task }) {
  const action = task.getIn(['actionLog', -1])
  if (action && action.get('level') === 'warn' && action.get('notes')) {
    return (
      <Alert bsStyle='danger' className='spaceBelow'>
        <strong>
          <FormattedMessage id={`task.status.${action.get('type')}`} />
        </strong>
        &nbsp;
        {action.get('notes').join('; ')}
      </Alert>
    )
  }

  return <></>
}

const mapDispatchToProps = (dispatch) => ({
  QuotesUiActions: bindActionCreators(QuotesUiActions, dispatch)
})

export { mapDispatchToProps }
export default connect<{}, DispatchProps, any>(
  null,
  mapDispatchToProps
)(QuotesContainer)
