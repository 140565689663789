import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import MapUiActions from '../../../../actions/MapUiActions'
import RFDateTimePicker from '../../../common/RFDateTimePicker/RFDateTimePicker'

import './FilterTasksByDay.scss'

interface Props {
  taskFilterDay: string
  MapUiActions: typeof MapUiActions
}

class FilterTasksByDay extends PureComponent<Props> {
  handleDateChange = (date) => {
    this.props.MapUiActions.changeTaskFilterDay(date)
  }

  render() {
    return (
      <span className='FilterTasksByDay'>
        <RFDateTimePicker
          time={false}
          value={this.props.taskFilterDay}
          onChange={this.handleDateChange}
        />
      </span>
    )
  }
}

const mapStateToProps = (state: any) => ({
  taskFilterDay: state.MapUiReducer.get('taskFilterDay')
})

const mapDispatchToProps = (dispatch) => ({
  MapUiActions: bindActionCreators(MapUiActions, dispatch)
})

export { mapStateToProps, mapDispatchToProps }
export default connect(mapStateToProps, mapDispatchToProps)(FilterTasksByDay)
