import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { generatePath, RouteComponentProps, withRouter } from 'react-router-dom'

import { VIEW_TYPE } from '../../../../constants'
import { Routes } from '../../../../navigation/Routes'

interface Props extends RouteComponentProps<{ viewType: VIEW_TYPE }> {
  response: any
}

class UploadResponse extends React.PureComponent<Props> {
  handleModalHide() {
    this.props.history.push(
      generatePath(Routes.Map.Tasks, {
        viewType: this.props.match.params.viewType
      })
    )
  }

  render() {
    return (
      <Modal
        animation={false}
        className='UploadResponse'
        show
        onHide={this.handleModalHide.bind(this)}
      >
        <Modal.Header>
          <Modal.Title>
            <i className='fa fa-upload' />{' '}
            <FormattedMessage id='map.tasks.upload.title' />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <pre style={{ maxHeight: '300px', overflow: 'scroll' }}>
            {this.props.response}
          </pre>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className='btn-brand-secondary'
            onClick={this.handleModalHide.bind(this)}
          >
            <i className='fa fa-check spaceAfter' />
            <FormattedMessage id='map.tasks.uploadResponse.ok' />
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default withRouter(UploadResponse)
