import { isEmpty } from 'lodash'
import React, { ChangeEvent } from 'react'
import { Button } from 'react-bootstrap'

import chat from '../../../../services/chat/chat'
import { MessageType } from '../../../../services/chat/types'
import { convertImageFileToBase64 } from '../../../../util/image'
import CannedResponses from '../CannedResponses/CannedResponses'
import { CannedResponse } from '../CannedResponses/types'

import './Composer.scss'
import { Props, State } from './types'

class Composer extends React.Component<Props, State> {
  textAreaRef = React.createRef<HTMLTextAreaElement>()
  cannedResponsesRef = React.createRef<CannedResponses>()

  constructor(props) {
    super(props)

    this.state = { message: '' }
  }

  componentDidMount(): void {
    this.textAreaRef?.current?.focus()
  }

  onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const {
      currentTarget: { value }
    } = event
    if (value === '\n') {
      return
    }

    this.setState({ message: value })
  }

  onKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const keyCode = e.keyCode || e.which

    // 13: enter key
    if (keyCode === 13) {
      if (this.showingCannedResponses()) {
        e.preventDefault()

        this.cannedResponsesRef?.current?.select()

        return
      }

      this.onSend()
    }
  }

  onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (!this.showingCannedResponses()) {
      return
    }

    const keyCode = e.keyCode || e.which

    // 38: arrow up
    if (keyCode === 38) {
      e.preventDefault()
      this.cannedResponsesRef?.current?.selectUp()
    }

    // 40: arrow down
    if (keyCode === 40) {
      e.preventDefault()
      this.cannedResponsesRef?.current?.selectDown()
    }
  }

  onSend = () => {
    const { message } = this.state

    if (isEmpty(message.trim())) {
      return
    }

    this.setState({ message: '' })
    this.props.onSend({ message })
  }

  private onFileSelect = async (e: ChangeEvent<HTMLInputElement>) => {
    const imageFile = e.currentTarget.files[0]

    if (!imageFile) {
      return
    }

    try {
      const imageBase64 = await convertImageFileToBase64(imageFile)

      this.props.onSend({
        type: MessageType.IMAGE,
        messageData: imageBase64,
        message: 'Sent an image'
      })
    } catch (error) {
      alert(error.message)
    }
  }

  onSelectCannedResponse = (cannedResponse: CannedResponse) => {
    if (!cannedResponse) {
      return
    }

    this.setState(
      {
        message: cannedResponse.content
      },
      () => {
        this.textAreaRef?.current?.focus()
      }
    )
  }

  private showingCannedResponses = () => {
    const { message } = this.state

    return chat.isAdmin() && message.startsWith('/')
  }

  render() {
    const { disabled = false } = this.props
    const { message } = this.state

    if (disabled) {
      return (
        <div className='chatComposerArchived'>
          This order is archived. You can not send new messages.
        </div>
      )
    }

    return (
      <div className='chatComposer'>
        <div className='chatInput spaceAfterLarge'>
          <textarea
            ref={this.textAreaRef}
            rows={2}
            onChange={this.onChange}
            value={message}
            onKeyPress={this.onKeyPress}
            onKeyDown={this.onKeyDown}
          />
          {this.showingCannedResponses() && (
            <CannedResponses
              ref={this.cannedResponsesRef}
              message={message}
              onClick={this.onSelectCannedResponse}
            />
          )}
          <label className='sendFileIcon pointer' htmlFor='chatFileUpload'>
            <i className='fa fa-paperclip' />
            <input
              id='chatFileUpload'
              accept='image/*'
              type='file'
              onChange={this.onFileSelect}
            />
          </label>
        </div>
        <Button
          onClick={this.onSend}
          disabled={isEmpty(message.trim())}
          bsStyle='success'
        >
          Send
        </Button>
      </div>
    )
  }
}

export default Composer
