type FetchFunction = (
  requestInfo: RequestInfo,
  requestInit?: RequestInit
) => Promise<Response>
function parallelLimited(func: FetchFunction, n: number): FetchFunction {
  const queue = []
  let running = 0
  return (...args) => {
    return new Promise((resolve: (value) => void, reject) => {
      queue.push(() =>
        func(...args)
          .then(resolve)
          .catch(reject)
      )
      processQueue()
    })
  }

  async function processQueue() {
    while (running < n && queue.length > 0) {
      running += 1
      queue
        .pop()()
        .then(() => {
          running -= 1
          processQueue()
        })
    }
  }
}

export { parallelLimited }
