import React from 'react'
import { SelectList } from 'react-widgets'

/**
 * Redux-Form compatible SelectList from React-Widgets.
 */

interface Props {
  onBlur: (event) => void
  onFocus: (event) => void
  data: any
  multiple: boolean
}

class RFSelectList extends React.PureComponent<Props> {
  // These are no-op callbacks to avoid redux-form populating the store with
  // boolean values.
  handleBlur(event) {
    if (this.props.onBlur) {
      this.props.onBlur(event)
    }
  }
  handleFocus(event) {
    if (this.props.onFocus) {
      this.props.onFocus(event)
    }
  }

  render() {
    return (
      <SelectList
        {...this.props}
        // override redux-form field props
        onBlur={this.handleBlur.bind(this)}
        onFocus={this.handleFocus.bind(this)}
      />
    )
  }
}

export default RFSelectList
