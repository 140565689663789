import { flow } from 'lodash'
import React from 'react'
import {
  Button,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  InputGroup
} from 'react-bootstrap'
import { FormattedMessage, injectIntl } from 'react-intl'
import { reduxForm } from 'redux-form'

import Spinner from '../../common/Spinner/Spinner'

import './ReportsSearchForm.scss'

interface Props {
  // injected by parent component
  showClearButton: boolean
  onClearButtonClick: (event) => void
  // injected by react-intl
  intl: any
  // injected by redux-form
  fields: any
  resetForm: () => void
  handleSubmit: React.FormEventHandler<any>
  pristine: boolean
  submitting: boolean
  error: any
}

class ReportsSearchForm extends React.PureComponent<Props> {
  handleClearButtonClick(event) {
    this.props.resetForm()
    if (this.props.onClearButtonClick != null) {
      this.props.onClearButtonClick(event)
    }
  }

  render() {
    const { showClearButton = false } = this.props
    const { keyword } = this.props.fields

    return (
      <Form
        inline
        className='ReportsSearchForm'
        onSubmit={this.props.handleSubmit}
      >
        <FormGroup controlId='keyword'>
          <ControlLabel className='sr-only'>
            <FormattedMessage id='reports.searchForm.keyword' />
          </ControlLabel>{' '}
          <InputGroup>
            <FormControl
              type='text'
              size={12}
              placeholder={this.props.intl.formatMessage({
                id: 'reports.searchForm.keyword'
              })}
              value={keyword.value}
              onChange={keyword.onChange}
            />
            <InputGroup.Button className={showClearButton ? '' : 'hidden'}>
              <Button
                data-testid='clear-keyword-search'
                disabled={keyword.value.length === 0}
                onClick={this.handleClearButtonClick.bind(this)}
                title={this.props.intl.formatMessage({
                  id: 'reports.searchForm.reset'
                })}
              >
                <i className='fa fa-times-circle' />
              </Button>
            </InputGroup.Button>
          </InputGroup>
        </FormGroup>{' '}
        <Button
          data-testid='keyword-search-sumbit'
          bsStyle='primary'
          disabled={this.props.submitting || keyword.value.length < 3}
          onClick={this.props.handleSubmit}
        >
          {this.props.submitting ? (
            <Spinner showText={false} className='spaceAfter' />
          ) : (
            <i className='fa fa-search spaceAfter' />
          )}
          <FormattedMessage id='reports.searchForm.apply' />
        </Button>{' '}
      </Form>
    )
  }
}

const reduxFormConfig = {
  form: 'reportsSearch',
  fields: ['keyword'],
  validate() {
    return {}
  }
}

export { reduxFormConfig }
export default flow(reduxForm(reduxFormConfig))(injectIntl(ReportsSearchForm))
