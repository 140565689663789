import classnames from 'classnames'
import { is } from 'immutable'
import { isEmpty } from 'lodash'
import React from 'react'

import {
  getTaskIconClassName,
  getTaskIconContainerClassName
} from '../../TaskIcon/TaskIcon'

import { Props } from './types'

class VehicleIcon extends React.Component<Props, {}> {
  shouldComponentUpdate(
    nextProps: Readonly<Props>,
    nextState: Readonly<{}>,
    nextContext: any
  ): boolean {
    return (
      !is(this.props.task, nextProps.task) ||
      this.props.isChildTask !== nextProps.isChildTask ||
      this.props.isMultidrop !== nextProps.isMultidrop ||
      this.props.openDetail !== nextProps.openDetail
    )
  }

  render() {
    const { task, isChildTask, isMultidrop, openDetail } = this.props
    const vehicleIcon = getTaskIconClassName(task)

    if (isEmpty(vehicleIcon)) {
      return null
    }

    return (
      <span
        className={classnames(getTaskIconContainerClassName(task), 'pointer', {
          childrenTasks: isChildTask && !isMultidrop
        })}
        onClick={openDetail}
      >
        <i className={vehicleIcon} />
      </span>
    )
  }
}

export default VehicleIcon
