import classnames from 'classnames'
import { Map as ImmutableMap } from 'immutable'
import React from 'react'
import { Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

interface Props {
  task: ImmutableMap<string, any>
  className?: string
}

class TrackerLink extends React.PureComponent<Props> {
  render() {
    const url = `/track.html?ref=${this.props.task.get(
      'salt'
    )}${this.props.task.get('id')}`
    const externalUrl = this.props.task.getIn([
      'quotes',
      'confirmation',
      'trackingUrl'
    ])

    const classNames = ['TrackerLink', 'spaceAfterLarge']
    if (this.props.className != null) {
      classNames.push(this.props.className)
    }

    return (
      <span className={classnames(classNames)}>
        <a
          data-testid='tracking-url-link'
          href={url}
          target='_blank'
          rel='noopener noreferrer'
        >
          <Button>
            <i className='fa fa-share spaceAfter' />
            <FormattedMessage id='task.trackingUrl' />
          </Button>
        </a>{' '}
        {!externalUrl ? null : (
          <a
            data-testid='tracking-url-link'
            href={externalUrl}
            target='_blank'
            rel='noopener noreferrer'
          >
            <Button>
              <i className='fa fa-share spaceAfter' />
              <FormattedMessage id='task.quotes.confirmation.trackingUrl' />
            </Button>
          </a>
        )}
      </span>
    )
  }
}

export default TrackerLink
