import React from 'react'
import { Modal } from 'react-bootstrap'

import './LoadingModal.scss'

interface Props {
  onHide: () => void
}

class LoadingModal extends React.PureComponent<Props> {
  render() {
    return (
      <Modal animation={false} show onHide={this.props.onHide}>
        <Modal.Body>
          <div className='sk-fading-circle'>
            <div className='sk-circle1 sk-circle' />
            <div className='sk-circle2 sk-circle' />
            <div className='sk-circle3 sk-circle' />
            <div className='sk-circle4 sk-circle' />
            <div className='sk-circle5 sk-circle' />
            <div className='sk-circle6 sk-circle' />
            <div className='sk-circle7 sk-circle' />
            <div className='sk-circle8 sk-circle' />
            <div className='sk-circle9 sk-circle' />
            <div className='sk-circle10 sk-circle' />
            <div className='sk-circle11 sk-circle' />
            <div className='sk-circle12 sk-circle' />
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

export default LoadingModal
