import classnames from 'classnames'
import { Map as ImmutableMap } from 'immutable'
import React, { MouseEventHandler } from 'react'
import { Alert, Button, Modal } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'

import * as TaskFormActions from '../../../../../actions/TaskFormActions'
import AddressActions from '../../../../../actions/services/AddressActions'
import TaskActions from '../../../../../actions/services/TaskActions'
import { CHAT_ICON_TYPE } from '../../../../../constants'
import ChatIcon from '../../../../common/ChatIcon/ChatIcon'
import TrackerLink from '../../../../common/TrackerLink/TrackerLink'
import TaskCloseButton from '../TaskFormButtons/TaskCloseButton/TaskCloseButton'
import TaskFormButtons from '../TaskFormButtons/TaskFormButtons'
import TaskFormFields from '../TaskFormFields/TaskFormFields'

import './TaskForm.scss'
import TaskFormSelector from './TaskForm.selector'

interface InnerProps {
  // selector state
  user: ImmutableMap<any, any>
  task: ImmutableMap<any, any>
  isTaskArchived: boolean
  allowEditing: boolean
  allowRepeating: boolean
  // actions
  AddressActions: any
  TaskActions: any
  TaskFormActions: any
  // injected by redux-form
  fields: any
  handleSubmit: () => Promise<void>
}

interface OutterProps {
  taskId: string
  sourceError: boolean
  destinationError: boolean
  className?: string
  onModalHide: MouseEventHandler<Button>
  onCloneClick: MouseEventHandler<Button>
  onMissingItemButtonClick?: MouseEventHandler<Button>
  onRepeatBookingButtonClick?: MouseEventHandler<Button>
  openChat?(): void
  cloningInProgress: boolean
  cloneError: any
  onArchiveClick: MouseEventHandler<Button>
  archivingInProgress: boolean
  archiveError: any
  initialValues: any
  onSubmit: (formData: any) => void
}

type Props = InnerProps & OutterProps

class TaskForm extends React.PureComponent<Props> {
  private renderTitle = () => {
    const { allowEditing, task } = this.props
    if (task == null) {
      return <FormattedMessage id='map.tasks.modal.addTitle' />
    }

    if (task.get('onDemand')) {
      return (
        <span>
          <FormattedMessage id='map.tasks.quote.title' />{' '}
          <small className='selectable'>{task.get('externalRef')}</small>
        </span>
      )
    }

    if (allowEditing) {
      return (
        <span>
          <FormattedMessage id='map.tasks.modal.editTitle' />{' '}
          <small className='selectable' data-testid='title-edit-task'>
            {task.get('externalRef')}
          </small>
        </span>
      )
    }

    return (
      <span>
        <FormattedMessage id='map.tasks.modal.viewTitle' />{' '}
        <small className='selectable'>{task.get('externalRef')}</small>
      </span>
    )
  }

  render() {
    const { task, isTaskArchived, openChat } = this.props
    const className = classnames('TaskForm', this.props.className)

    return (
      <Modal
        animation={false}
        className={className}
        show
        onHide={this.props.onModalHide}
        backdrop='static'
      >
        <form onSubmit={this.props.handleSubmit} autoComplete='off'>
          <Modal.Header>
            <div className='row-align-center pull-right'>
              {task && (
                <>
                  <ChatIcon
                    taskId={task.get('id')}
                    iconType={CHAT_ICON_TYPE.ORDER_DETAILS}
                    onPress={openChat}
                  />
                  <TrackerLink task={task} />
                </>
              )}
              <TaskCloseButton onClick={this.props.onModalHide} />
            </div>
            <Modal.Title>
              <i className='fa fa-briefcase spaceAfter' />
              {this.renderTitle()}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {isTaskArchived && (
              <Alert bsStyle='info' className='spaceBelow'>
                <FormattedMessage id='map.tasks.modal.taskArchived' />
              </Alert>
            )}
            <TaskFormFields {...this.props} />
          </Modal.Body>

          <Modal.Footer>
            <TaskFormButtons {...this.props} />
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

const reduxFormConfig = {
  form: 'task',
  fields: [
    'type',
    'merchantId',
    'externalRef',
    'parcelLength',
    'parcelWidth',
    'parcelHeight',
    'parcelWeight',
    'parcelValue',
    'parcelFragile',
    'parcelDoNotRotate',
    'parcelContainsLiquid',
    'parcelContainsHotFood',
    'currentStatus',
    'schedule',
    'pickupDateFrom',
    'pickupDateTo',
    'deliveryDateFrom',
    'deliveryDateTo',
    'sourceName',
    'sourceAddress',
    'sourceCity',
    'sourcePostcode',
    'sourceCountry',
    'sourcePhone',
    'sourceEmail',
    'pickupInstructions',
    'destinationName',
    'destinationAddress',
    'destinationCity',
    'destinationPostcode',
    'destinationCountry',
    'destinationPhone',
    'destinationEmail',
    'deliveryInstructions',
    'newNote',
    'containedTasks',
    'parentTask'
  ],
  validate(values) {
    const errors: any = {}

    if (!values.externalRef || !values.externalRef.trim()) {
      errors.externalRef = 'You must specify an order number.'
    }

    if (values.schedule === 'pickupAndDelivery' && !values.pickupDateFrom) {
      errors.pickupDateFrom = 'You must specify a pickup time.'
    }
    if (values.schedule === 'pickupAndDelivery' && !values.deliveryDateTo) {
      errors.deliveryDateTo = 'You must specify a delivery deadline.'
    }
    if (
      values.schedule === 'pickupAndDelivery' &&
      new Date(values.pickupDateFrom) > new Date(values.deliveryDateTo)
    ) {
      errors.pickupDateFrom =
        'Pickup time must be before the latest delivery time.'
      errors.deliveryDateTo =
        'Pickup time must be before the latest delivery time.'
    }

    if (values.schedule === 'delivery' && !values.deliveryDateFrom) {
      errors.deliveryDateFrom = 'You must specify the earliest delivery time.'
    }
    if (values.schedule === 'delivery' && !values.deliveryDateTo) {
      errors.deliveryDateTo = 'You must specify the latest delivery time.'
    }
    if (
      values.schedule === 'delivery' &&
      new Date(values.deliveryDateFrom) > new Date(values.deliveryDateTo)
    ) {
      errors.deliveryDateFrom =
        'Earliest delivery time must be before the latest delivery time.'
      errors.deliveryDateTo =
        'Earliest delivery time must be before the latest delivery time.'
    }

    if (values.schedule === 'custom' && !values.pickupDateFrom) {
      errors.pickupDateFrom = 'You must specify the earliest pickup time.'
    }
    if (values.schedule === 'custom' && !values.pickupDateTo) {
      errors.pickupDateTo = 'You must specify the latest pickup time.'
    }
    if (values.schedule === 'custom' && !values.deliveryDateFrom) {
      errors.deliveryDateFrom = 'You must specify the earliest delivery time.'
    }
    if (values.schedule === 'custom' && !values.deliveryDateTo) {
      errors.deliveryDateTo = 'You must specify the latest delivery time.'
    }
    if (
      values.schedule === 'custom' &&
      new Date(values.pickupDateFrom) > new Date(values.deliveryDateTo)
    ) {
      errors.pickupDateFrom =
        'Earliest pickup time must be before the latest delivery time.'
      errors.deliveryDateTo =
        'Earliest pickup time must be before the latest delivery time.'
    }
    if (
      values.schedule === 'custom' &&
      new Date(values.pickupDateFrom) > new Date(values.pickupDateTo)
    ) {
      errors.pickupDateFrom =
        'Earliest pickup time must be before the latest pickup time.'
      errors.deliveryDateTo =
        'Earliest pickup time must be before the latest pickup time.'
    }
    if (
      values.schedule === 'custom' &&
      new Date(values.deliveryDateFrom) > new Date(values.deliveryDateTo)
    ) {
      errors.deliveryDateFrom =
        'Earliest delivery time must be before the latest delivery time.'
      errors.deliveryDateTo =
        'Earliest delivery time must be before the latest delivery time.'
    }
    if (!values.sourceName || !values.sourceName.trim()) {
      errors.sourceName = 'You must specify a pickup address.'
    }
    if (!values.sourceAddress || !values.sourceAddress.trim()) {
      errors.sourceAddress = 'You must specify a pickup address.'
    }
    if (!values.sourceCity || !values.sourceCity.trim()) {
      errors.sourceCity = 'You must specify a pickup address.'
    }
    if (!values.sourcePostcode || !values.sourcePostcode.trim()) {
      errors.sourcePostcode = 'You must specify a pickup address.'
    }
    if (!values.sourceCountry || !values.sourceCountry.trim()) {
      errors.sourceCountry = 'You must specify a pickup address.'
    }
    if (!values.sourcePhone || !values.sourcePhone.trim()) {
      errors.sourcePhone = 'You must specify a pickup contact information.'
    }
    if (!values.sourceEmail || !values.sourceEmail.trim()) {
      errors.sourceEmail = 'You must specify a pickup contact information.'
    }
    if (!values.destinationName || !values.destinationName.trim()) {
      errors.destinationName = 'You must specify contact person/company name.'
    }
    if (!values.destinationAddress || !values.destinationAddress.trim()) {
      errors.destinationAddress = 'You must specify a dropoff address.'
    }
    if (!values.destinationCity || !values.destinationCity.trim()) {
      errors.destinationCity = 'You must specify a dropoff city.'
    }
    if (!values.destinationPostcode || !values.destinationPostcode.trim()) {
      errors.destinationPostcode = 'You must specify a dropoff postcode.'
    }
    if (!values.destinationCountry || !values.destinationCountry.trim()) {
      errors.destinationCountry = 'You must specify a dropoff country.'
    }
    if (!values.destinationPhone || !values.destinationPhone.trim()) {
      errors.destinationPhone =
        'You must specify destination contact information.'
    }
    if (!values.destinationEmail || !values.destinationEmail.trim()) {
      errors.destinationEmail =
        'You must specify destination contact information.'
    }

    return errors
  }
}

const mapDispatchToProps = (dispatch) => ({
  AddressActions: bindActionCreators(AddressActions, dispatch),
  TaskActions: bindActionCreators(TaskActions, dispatch),
  TaskFormActions: bindActionCreators(TaskFormActions, dispatch)
})

export { mapDispatchToProps, reduxFormConfig }
export default compose<InnerProps, OutterProps>(
  reduxForm(reduxFormConfig),
  connect(TaskFormSelector, mapDispatchToProps)
)(TaskForm)
