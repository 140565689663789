import { chunk } from 'lodash'
import { PureComponent } from 'react'
import React from 'react'
import { Table } from 'react-bootstrap'

import { renderRow } from './utils'

class GlossaryIcons extends PureComponent {
  iconsAndColors = [
    {
      text: 'Quote requested',
      paths: [require('./glossaryImages/icons/iconArrowRedBgYellow.png')]
    },
    {
      text: 'Quote expired',
      paths: [require('./glossaryImages/icons/iconArrowWhiteBgRed.png')]
    },
    {
      text: 'All quotes fetched',
      paths: [require('./glossaryImages/icons/iconArrowBlackBgPurple.png')]
    },
    {
      text: 'Pending',
      paths: [require('./glossaryImages/icons/iconArrowRedBgWhite.png')]
    },
    {
      text: 'Default icon (click to view order details)',
      paths: [require('./glossaryImages/icons/iconArrowBlackBgWhite.png')]
    },
    {
      text: 'All quotes fetched but expired',
      paths: [require('./glossaryImages/icons/iconArrowRedBgPurple.png')]
    },
    {
      text: 'Quote approved',
      paths: [require('./glossaryImages/icons/vehicleGreenBg.png')]
    },
    {
      text: 'Quote cancelled',
      paths: [require('./glossaryImages/icons/vehicleGreyBg.png')]
    },
    {
      text: 'Quote approved',
      paths: [require('./glossaryImages/icons/vehicleWhiteBg1.png')]
    },
    {
      text: 'Vehicle unknown',
      paths: [require('./glossaryImages/icons/iconThunderboltBlackBg.png')]
    },
    {
      text: 'Missing geolocation',
      paths: [require('./glossaryImages/icons/iconWarning.png')]
    },
    {
      text: 'Delay time',
      paths: [
        require('./glossaryImages/icons/delayTime.png'),
        require('./glossaryImages/icons/delayTimeExpanded.png')
      ]
    },
    {
      text: 'Waiting time',
      paths: [
        require('./glossaryImages/icons/waitingTime.png'),
        require('./glossaryImages/icons/waitingTimeExpanded.png')
      ]
    },
    {
      text: 'Parcel value',
      paths: [
        require('./glossaryImages/icons/parcelValue.png'),
        require('./glossaryImages/icons/parcelValueExpanded.png')
      ]
    },
    {
      text: 'Vehicles',
      paths: [
        require('./glossaryImages/icons/vehicleCar.png'),
        require('./glossaryImages/icons/vehicleMotorbike.png'),
        require('./glossaryImages/icons/vehicleBike.png'),
        require('./glossaryImages/icons/vehiclePerson.png')
      ]
    },
    {
      text: '',
      paths: []
    }
  ]

  render() {
    return (
      <Table striped responsive className='glossaryTable'>
        <thead>
          <tr>
            <th>Icon</th>
            <th>Definition</th>
            <th>Icon</th>
            <th>Definition</th>
          </tr>
        </thead>
        <tbody>
          {chunk(this.iconsAndColors, 2).map(([item1, item2], index) => {
            return (
              <tr key={`${item1.text}_${item2.text}_${index}`}>
                {renderRow(item1.text, 30, item1.paths)}
                {renderRow(item2.text, 30, item2.paths)}
              </tr>
            )
          })}
        </tbody>
      </Table>
    )
  }
}

export default GlossaryIcons
