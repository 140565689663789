import React from 'react'
import { Col, Grid, Panel, Row } from 'react-bootstrap'
import {
  generatePath,
  Link,
  RouteComponentProps,
  withRouter
} from 'react-router-dom'

import { VIEW_TYPE } from '../../../../../constants'
import { Routes } from '../../../../../navigation/Routes'

interface Props extends RouteComponentProps<{ viewType: VIEW_TYPE }> {
  containedTasks: any[]
  moveUp(taskId: string): void
  moveDown(taskId: string): void
}

class ContainedTasks extends React.PureComponent<Props> {
  private moveUp = (taskId) => () => {
    this.props.moveUp(taskId)
  }

  private moveDown = (taskId) => () => {
    this.props.moveDown(taskId)
  }

  render() {
    const { containedTasks, match } = this.props

    return (
      <Row>
        <Col sm={12}>
          <Panel className='customPanel'>
            <Panel.Heading>
              <span className='iconContainer'>
                <i className='fa fa-tasks fa-sm icon' />
              </span>
              Contained tasks
            </Panel.Heading>
            <Panel.Body>
              <Grid fluid>
                {containedTasks.map((t) => (
                  <Row className='' key={t.id}>
                    <Col sm={10}>
                      <Link
                        to={generatePath(Routes.Map.TaskDetail, {
                          taskId: t.id,
                          viewType: match.params.viewType
                        })}
                      >
                        {t.externalRef}
                      </Link>
                      <br />
                      <small>{t.destinationName}</small>
                    </Col>
                    <Col sm={2}>
                      <span
                        role='button'
                        className='btn btn-default spaceAfter'
                        onClick={this.moveDown(t.id)}
                      >
                        <i className='fa fa-arrow-down' />
                      </span>
                      <span
                        role='button'
                        className='btn btn-default'
                        onClick={this.moveUp(t.id)}
                      >
                        <i className='fa fa-arrow-up' />
                      </span>
                    </Col>
                  </Row>
                ))}
              </Grid>
            </Panel.Body>
          </Panel>
        </Col>
      </Row>
    )
  }
}

export default withRouter(ContainedTasks)
