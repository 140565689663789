import React, { FormEventHandler, PureComponent } from 'react'
import {
  Alert,
  Button,
  Checkbox,
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Grid,
  HelpBlock,
  Panel,
  Row
} from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { reduxForm } from 'redux-form'

import config from '../../../config'
import { getFormControlPropsFromReduxForm } from '../../../util/form'
import Spinner from '../../common/Spinner/Spinner'

interface TextInputGroupProps {
  label: React.ReactNode
  form: {
    submitting: boolean
  }
  field: any
  disabled?: boolean
}

function TextInputGroup(props: TextInputGroupProps) {
  const { form, field, disabled } = props

  return (
    <FormGroup
      controlId={field.name}
      validationState={field.touched && field.error ? 'error' : null}
    >
      <Col sm={4} componentClass={ControlLabel}>
        {props.label}
      </Col>
      <Col sm={8}>
        <FormControl
          {...getFormControlPropsFromReduxForm(field)}
          disabled={form.submitting || disabled}
        />
        {field.touched && field.error ? (
          <HelpBlock>{field.error}</HelpBlock>
        ) : null}
      </Col>
    </FormGroup>
  )
}

interface CheckboxInputGroupProps {
  // injected by parent component
  label: React.ReactNode
  // redux-form
  form: any
  field: any
}

function CheckboxInputGroup(props: CheckboxInputGroupProps) {
  const { form, field } = props
  return (
    <FormGroup controlId={field.name}>
      <Col sm={8} smOffset={4}>
        <Checkbox
          {...getFormControlPropsFromReduxForm(field)}
          className='spaceAfter'
          disabled={form.submitting}
          validationState={field.touched && field.error ? 'error' : null}
        >
          {field.touched && field.error ? (
            <HelpBlock>{field.error}</HelpBlock>
          ) : (
            <span>
              I have read and accept the{' '}
              <a target='_blank' href='https://d.orkestro.com/docs/tandc.doc'>
                Terms & Conditions
              </a>
              .
            </span>
          )}
        </Checkbox>
      </Col>
    </FormGroup>
  )
}

interface Props {
  // injected by parent component
  serverError: any
  // injected by redux-form
  handleSubmit: FormEventHandler<any>
  fields: any
  submitting: boolean
  invalid: boolean
  error: string
  merchantNameReadOnly: boolean
}
class RegistrationForm extends PureComponent<Props> {
  componentDidMount() {
    window.Stripe.setPublishableKey(config.stripePublishableKey)
  }

  render() {
    const { fields, submitting, merchantNameReadOnly } = this.props
    const formProps = { submitting }
    const cardType = window.Stripe.card.cardType(fields.cardNumber.value)

    return (
      <Form
        onSubmit={this.props.handleSubmit}
        horizontal
        className='RegistrationForm spaceBelowLarge'
      >
        <Grid>
          <Row>
            <Col sm={10} smOffset={1} md={6} mdOffset={3}>
              <Grid fluid>
                <Row>
                  <Col sm={8} smOffset={4}>
                    <h1>
                      <FormattedMessage id='register.title' />
                    </h1>
                  </Col>
                </Row>
              </Grid>

              <Panel>
                <Panel.Heading>
                  <FormattedMessage id='register.userDetails' />
                </Panel.Heading>

                <Panel.Body>
                  <Grid fluid>
                    <TextInputGroup
                      label={<FormattedMessage id='merchant.name' />}
                      field={fields.companyName}
                      form={formProps}
                      disabled={merchantNameReadOnly}
                    />
                    <TextInputGroup
                      label={<FormattedMessage id='user.firstName' />}
                      field={fields.firstName}
                      form={formProps}
                    />
                    <TextInputGroup
                      label={<FormattedMessage id='user.lastName' />}
                      field={fields.lastName}
                      form={formProps}
                    />
                    <TextInputGroup
                      label={<FormattedMessage id='user.username' />}
                      field={Object.assign({}, fields.email, {
                        type: 'email'
                      })}
                      form={formProps}
                    />
                    <TextInputGroup
                      label={<FormattedMessage id='register.password1' />}
                      field={Object.assign({}, fields.password1, {
                        type: 'password',
                        minLength: 8
                      })}
                      form={formProps}
                    />
                    <TextInputGroup
                      label={<FormattedMessage id='register.password2' />}
                      field={Object.assign({}, fields.password2, {
                        type: 'password',
                        minLength: 8
                      })}
                      form={formProps}
                    />
                  </Grid>
                </Panel.Body>
              </Panel>

              <Panel>
                <Panel.Heading>
                  <FormattedMessage id='register.billingAddress' />
                </Panel.Heading>

                <Panel.Body>
                  <Grid fluid>
                    <TextInputGroup
                      label={<FormattedMessage id='merchant.cardholderName' />}
                      field={fields.cardholderName}
                      form={formProps}
                    />
                    <TextInputGroup
                      label={<FormattedMessage id='merchant.billingAddress1' />}
                      field={fields.billingAddress1}
                      form={formProps}
                    />
                    <TextInputGroup
                      label={<FormattedMessage id='merchant.billingAddress2' />}
                      field={fields.billingAddress2}
                      form={formProps}
                    />
                    <TextInputGroup
                      label={<FormattedMessage id='merchant.billingCity' />}
                      field={fields.billingCity}
                      form={formProps}
                    />
                    <TextInputGroup
                      label={<FormattedMessage id='merchant.billingPostcode' />}
                      field={fields.billingPostcode}
                      form={formProps}
                    />
                    <TextInputGroup
                      label={<FormattedMessage id='merchant.billingCountry' />}
                      field={fields.billingCountry}
                      form={formProps}
                    />
                  </Grid>
                </Panel.Body>
              </Panel>

              <Panel>
                <Panel.Heading>
                  <FormattedMessage id='register.cardDetails' />
                </Panel.Heading>

                <Panel.Body>
                  <Grid fluid>
                    <TextInputGroup
                      label={<FormattedMessage id='register.cardNumber' />}
                      field={Object.assign({}, fields.cardNumber, {
                        minLength: 13,
                        maxLength: 19
                      })}
                      form={formProps}
                    />
                    <Row>
                      <Col sm={8} smOffset={4}>
                        <p>
                          <i className='fa fa-credit-card spaceAfter' />
                          {cardType === 'Unknown' ? '\u00A0' : cardType}
                        </p>
                      </Col>
                    </Row>
                    <TextInputGroup
                      label={<FormattedMessage id='merchant.cardExpiryMonth' />}
                      field={fields.expiryMonth}
                      form={formProps}
                    />
                    <TextInputGroup
                      label={<FormattedMessage id='merchant.cardExpiryYear' />}
                      field={fields.expiryYear}
                      form={formProps}
                    />
                    <TextInputGroup
                      label={<FormattedMessage id='register.cvc' />}
                      field={fields.cvc}
                      form={formProps}
                    />
                  </Grid>
                </Panel.Body>
              </Panel>

              <CheckboxInputGroup
                label={<FormattedMessage id='register.tnc' />}
                field={fields.tnc}
                form={formProps}
              />

              <Grid fluid>
                <Row>
                  <Col sm={8} smOffset={4}>
                    {this.props.serverError ? (
                      <Alert
                        bsStyle='danger'
                        className='spaceBelowLarge'
                        title={JSON.stringify(this.props.serverError, null, 2)}
                      >
                        {this.props.serverError.status &&
                        this.props.serverError.error &&
                        this.props.serverError.error.message ? (
                          <p>
                            <strong>
                              {this.props.serverError.error.message}
                            </strong>
                          </p>
                        ) : null}
                        <p>
                          <FormattedMessage id='register.error' />
                        </p>
                      </Alert>
                    ) : null}

                    <Button
                      bsStyle='primary'
                      type='submit'
                      disabled={submitting || this.props.invalid}
                    >
                      {submitting ? (
                        <Spinner showText={false} className='spaceAfter' />
                      ) : (
                        <i className='fa fa-check spaceAfter' />
                      )}
                      <FormattedMessage id='register.submitButton' />
                    </Button>
                  </Col>
                </Row>
              </Grid>
            </Col>
          </Row>
        </Grid>
      </Form>
    )
  }
}

const reduxFormConfig = {
  form: 'register',
  fields: [
    'companyName',
    'firstName',
    'lastName',
    'email',
    'password1',
    'password2',
    'cardholderName',
    'billingAddress1',
    'billingAddress2',
    'billingPostcode',
    'billingCity',
    'billingCountry',
    'cardNumber',
    'cvc',
    'expiryMonth',
    'expiryYear',
    'tnc'
  ],
  validate(values) {
    /*eslint curly: 0*/
    // TODO: localise validation error messages
    const errors: any = {}

    if (!values.companyName || values.companyName.length < 6) {
      errors.companyName =
        "Enter the registered name of the company you're working for."
    }
    if (!values.firstName || values.firstName.length < 2) {
      errors.firstName = 'Enter your first name.'
    }
    if (!values.lastName || values.lastName.length < 2) {
      errors.lastName = 'Enter your last name.'
    }
    if (!values.email || values.email.indexOf('@') === -1) {
      errors.email = 'Email address is invalid.'
    }
    if (!values.password1 || values.password1.length < 8) {
      errors.password1 = 'Password is too short. Enter at least 8 characters.'
    }
    if (values.password1 !== values.password2) {
      errors.password2 = 'Passwords do not match. Re-type your password.'
    }

    if (!values.cardholderName || values.cardholderName.length < 2) {
      errors.cardholderName =
        'Enter the name of the company and person associated with the bank card.'
    }
    if (!values.billingAddress1 || values.billingAddress1.length < 2) {
      errors.billingAddress1 =
        'Enter the first address line of the address associated with the bank card.'
    }
    if (!values.billingCity || values.billingCity.length < 2) {
      errors.billingCity =
        'Enter the name of the city associated with the bank card.'
    }
    if (!values.billingPostcode || values.billingPostcode.length < 2) {
      errors.billingPostcode =
        'Enter the post code associated with the bank card.'
    }
    if (!values.billingCountry || values.billingCountry.length < 2) {
      errors.billingCountry = 'Enter the country associated with the bank card.'
    }

    if (!window.Stripe.card.validateCardNumber(values.cardNumber)) {
      errors.cardNumber = 'Card number is invalid.'
    }
    if (
      !window.Stripe.card.validateExpiry(values.expiryMonth, values.expiryYear)
    ) {
      errors.expiryMonth = 'Expiry date is invalid.'
      errors.expiryYear = 'Expiry date is invalid.'
    }
    if (!window.Stripe.card.validateCVC(values.cvc)) {
      errors.cvc = 'CVC number is invalid.'
    }

    if (values.tnc !== true) {
      // tslint:disable-next-line
      errors.tnc = (
        <span>
          You must accept the{' '}
          <a target='_blank' href='https://d.orkestro.com/docs/tandc.doc'>
            Terms & Conditions
          </a>{' '}
          to continue.
        </span>
      )
    }

    return errors
  }
}

export { reduxFormConfig, CheckboxInputGroup, TextInputGroup }
export default reduxForm(reduxFormConfig)(RegistrationForm)
