import React from 'react'
import { generatePath, HashRouter, Redirect, Route } from 'react-router-dom'

import App from '../components/App'
import ConfirmForm from '../components/ConfirmForm/ConfirmForm'
import FreshChat from '../components/FreshChat/FreshChat'
import Login from '../components/Login/Login'
import RegistrationFormContainer from '../components/Register/RegistrationFormContainer/RegistrationFormContainer'
import CannedResponses from '../components/Settings/CannedResponses/CannedResponses'
import DriverDialog from '../components/Settings/Drivers/DriverDialog/DriverDialog'
import Drivers from '../components/Settings/Drivers/Drivers/Drivers'
import GlossaryDocument from '../components/Settings/Glossary/GlossaryDocument'
import LanguageSettings from '../components/Settings/Language/LanguageSettings'
import MerchantSettings from '../components/Settings/Merchant/MerchantSettings/MerchantSettings'
import Settings from '../components/Settings/Settings'
import UserSettings from '../components/Settings/User/UserSettings'
import MapContainer from '../components/map/MapContainer/MapContainer'
import TaskChat from '../components/map/TaskChat/TaskChat'
import TaskFormContainer from '../components/map/task/TaskFormContainer/TaskFormContainer'
import PrintTasks from '../components/map/tasks/PrintTasks/PrintTasks'
import TaskList from '../components/map/tasks/TaskList/TaskList'
import UploadDialog from '../components/map/upload/UploadDialog/UploadDialog'
import Reports from '../components/reports/Reports/Reports'
import { VIEW_TYPE } from '../constants'
import { store } from '../index'

import { Routes } from './Routes'

const isAdmin = () =>
  store.getState().AuthReducer.getIn(['user', 'administrator']) === true

const renderRoute = (Component: any) => (props: any) => {
  if (store.getState().AuthReducer.get('user') === null) {
    return (
      <Redirect
        to={{
          pathname: Routes.Login,
          state: { nextPathname: props.location.pathname }
        }}
      />
    )
  }

  return <Component {...props} />
}

const renderAdminRoute = (Component: any) => (props: any) => {
  if (!isAdmin()) {
    return redirect(Routes.Settings.User)()
  }

  return <Component {...props} />
}

const PrivateRoute = ({ component, ...rest }) => (
  <Route {...rest} render={renderRoute(component)} />
)

const AdminRoute = ({ component, ...rest }) => (
  <Route {...rest} render={renderAdminRoute(component)} />
)

const TaskRoute = (props) => {
  return (
    <MapContainer {...props}>
      <TaskList>
        <Route
          path={Routes.Map.TaskDetail}
          exact
          component={TaskFormContainer}
        />
        <Route path={Routes.Map.TaskUpload} exact component={UploadDialog} />
        <Route path={Routes.Map.TaskChat} exact component={TaskChat} />
      </TaskList>
    </MapContainer>
  )
}

const SettingsRoute = () => (
  <Settings>
    <PrivateRoute path={Routes.Settings.User} component={UserSettings} />
    <PrivateRoute path={Routes.Settings.Driver.Root} component={DriveRoute} />
    <PrivateRoute
      path={Routes.Settings.Merchant}
      component={MerchantSettings}
    />
    <PrivateRoute
      path={Routes.Settings.Language}
      component={LanguageSettings}
    />
    <PrivateRoute
      path={Routes.Settings.Glossary}
      component={GlossaryDocument}
    />
    <AdminRoute
      path={Routes.Settings.CannedResponses}
      component={CannedResponses}
    />
  </Settings>
)

const DriveRoute = () => (
  <Drivers>
    <PrivateRoute path={Routes.Settings.Driver.New} component={DriverDialog} />
    <PrivateRoute path={Routes.Settings.Driver.Edit} component={DriverDialog} />
  </Drivers>
)

const redirect = (to) => () => <Redirect to={to} />
const defaultTasksView = generatePath(Routes.Map.Tasks, {
  viewType: VIEW_TYPE.TASKS
})

const Router = (
  <HashRouter>
    <App>
      <FreshChat />
      <PrivateRoute
        exact
        path={Routes.Root}
        component={redirect(defaultTasksView)}
      />

      <Route path={Routes.Login} component={Login} />
      <Route path={Routes.Register} component={RegistrationFormContainer} />
      <Route path={Routes.Confirm} component={ConfirmForm} />

      <PrivateRoute path={Routes.Reports} component={Reports} />

      <PrivateRoute
        exact
        path={Routes.Map.Root}
        component={redirect(defaultTasksView)}
      />

      <PrivateRoute path={Routes.PrintTasks} component={PrintTasks} exact />
      <PrivateRoute path={Routes.Map.Tasks} component={TaskRoute} />

      <PrivateRoute
        exact
        path={Routes.Settings.Root}
        component={redirect(Routes.Settings.Merchant)}
      />
      <PrivateRoute path={Routes.Settings.Root} component={SettingsRoute} />
    </App>
  </HashRouter>
)

export {
  Router,
  redirect,
  DriveRoute,
  SettingsRoute,
  TaskRoute,
  renderRoute,
  PrivateRoute
}
