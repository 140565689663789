import moment from 'moment'

import { COURIER, TASK_SECTION, TASK_STATUS } from '../../../constants'
import { OrkTask } from '../../../types'

const CsvColumns: { [column: string]: (task: OrkTask) => any } = {
  ID: (task) => {
    return task.id
  },
  merchantId: (task) => {
    return task.merchantId
  },
  externalRef: (task) => {
    return task.externalRef
  },
  created: (task) => {
    return formatTime(task.actionLog[0].timestamp)
  },
  schedule: (task) => {
    return task.schedule || ''
  },
  pickupDateFrom: (task) => {
    return formatTime(task.pickupDateFrom)
  },
  pickupDateTo: (task) => {
    return formatTime(task.pickupDateTo)
  },
  deliveryDateFrom: (task) => {
    return formatTime(task.deliveryDateFrom)
  },
  deliveryDateTo: (task) => {
    return formatTime(task.deliveryDateTo)
  },
  parcelSize: (task) => {
    return `${task.parcelLength || '?'}x${task.parcelWidth ||
      '?'}x${task.parcelHeight || '?'}`
  },
  parcelWeight: (task) => {
    return task.parcelWeight ? task.parcelWeight.toString() : ''
  },
  parcelValue: (task) => {
    return (
      (task.parcelValue && parseFloat(String(task.parcelValue)).toFixed(2)) ||
      ''
    )
  },
  parcelHandling: (task) => {
    return [
      task.parcelDoNotRotate ? 'doNotRotate' : '',
      task.parcelFragile ? 'fragile' : '',
      task.parcelContainsLiquid ? 'containsLiquid' : '',
      task.parcelContainsHotFood ? 'containsFood' : ''
    ].join(', ')
  },
  sourceName: (task) => {
    return task.sourceName
  },
  sourceAddress: (task) => {
    return task.sourceAddress
  },
  sourceCity: (task) => {
    return task.sourceCity
  },
  sourcePostcode: (task) => {
    return task.sourcePostcode
  },
  sourcePhone: (task) => {
    return task.sourcePhone
  },
  sourceEmail: (task) => {
    return task.sourceEmail
  },
  // ${task.sourceName} (${task.sourceAddress},
  // ${task.sourceCity},
  // ${task.sourcePostcode},
  // ${task.sourceCountry}) (${task.sourcePhone}) (${task.sourceEmail})
  pickupInstructions: (task) => {
    return cleanText(task.pickupInstructions) || ''
  },
  destinationName: (task) => {
    return task.destinationName
  },
  destinationAddress: (task) => {
    return task.destinationAddress
  },
  destinationCity: (task) => {
    return task.destinationCity
  },
  destinationPostcode: (task) => {
    return task.destinationPostcode
  },
  destinationCountry: (task) => {
    return task.destinationCountry
  },
  destinationPhone: (task) => {
    return task.destinationPhone
  },
  destinationEmail: (task) => {
    return task.destinationEmail
  },
  deliveryInstructions: (task) => {
    return cleanText(task.deliveryInstructions) || ''
  },
  distanceMeters: (task) => {
    return (
      (task.geomatrix &&
        task.geomatrix.driving &&
        task.geomatrix.driving.distanceMeters) ||
      ''
    )
  },
  courier: (task) => {
    return task.isWalked
      ? 'WALKED'
      : getConfirmation(task)
      ? exportVendorName(getConfirmation(task).vendor)
      : task.actionLog.find(
          (log) => log.type === 'reassigned' && !!log.driverId
        )
      ? 'INTERNAL'
      : ''
  },
  courierPrice: (task) => {
    return (
      (task.quotes &&
        task.quotes.chosenQuote &&
        task.quotes.chosenQuote.price &&
        parseFloat(String(task.quotes.chosenQuote.price)).toFixed(2)) ||
      ''
    )
  },
  courierReference: (task) => {
    return (getConfirmation(task) || {}).vendorReference || ''
  },
  vehicleType: (task) => {
    return (
      (task.quotes &&
        task.quotes.chosenQuote &&
        task.quotes.chosenQuote.vehicleType) ||
      ''
    )
  },
  timeQuoteApproved: (task) => {
    return lastActionTimestamp(task, TASK_STATUS.QUOTE_APPROVED)
  },
  timeDriverAssigned: (task) => {
    return lastActionTimestamp(task, TASK_STATUS.DRIVER_ASSIGNED)
  },
  timeArrivedAtPickup: (task) => {
    return lastActionTimestamp(task, TASK_STATUS.ARRIVED_AT_PICKUP)
  },
  timeInTransit: (task) => {
    return lastActionTimestamp(task, TASK_STATUS.IN_TRANSIT)
  },
  timeArrivedAtDropoff: (task) => {
    return lastActionTimestamp(task, TASK_STATUS.ARRIVED_AT_DROPOFF)
  },
  timeCompleted: (task) => {
    return (task.timeCompleted && formatTime(task.timeCompleted)) || ''
  },
  actionLog: (task) => {
    return task.actionLog
      .map((action) => {
        if (action.author === COURIER.AddisonLee && action.driverId) {
          return `[${action.author} (${action.driverId})]: ${action.type} `
        }
        return (
          `[${action.author}]: ` +
          action.type +
          (action.driverId === TASK_SECTION.WALKED ? ' (Walked)' : '') +
          (action.vendorReference || action.vendor
            ? ` (${action.vendor} ${action.vendorReference})`
            : '') +
          (action.driver
            ? ` (${action.driver.name}, ${action.driver.phone})`
            : '')
        )
      })
      .join(' >>> ')
  },
  driver: (task) => {
    return (
      (task.driver && task.driver.name) ||
      (task.quotes && task.quotes.driverName) ||
      ''
    )
  },
  notes: (task) => {
    return task.notes && typeof task.notes.map === 'function'
      ? task.notes
          .map((note) => {
            let data = note.data
            if (note.type === 'image') {
              data = 'IMAGE'
            }
            if (note.data && String(note.data).startsWith('data:image/png')) {
              data = 'IMAGE/PNG'
            }
            return `[${note.author}]: ${cleanText(data)} `
          })
          .join(' >>> ')
      : ''
  },
  smsLog: (task) => {
    return (task.smsLog || [])
      .map((sms) => `[${sms.to}]: ${cleanText(sms.message)} `)
      .join(' >>> ')
  },
  smsTotal: (task) => {
    return (task.smsLog || []).length + ''
  },
  emailTotal: (task) => {
    return typeof task.emailLog === 'undefined' ? 'N/A' : task.emailLog.length
  },
  gpsTotal: (task) => {
    const gps = (task.quotes && task.quotes.gps) || []
    return gps.length > 0 ? gps.length : ''
  },
  gpsStart: (task) => {
    const gps = (task.quotes && task.quotes.gps) || []
    const timestamp = gps[0] && gps[0].timestamp
    if (timestamp) {
      return moment(timestamp).format('HH:mm')
    }
    return ''
  },
  gpsEnd: (task) => {
    const gps = (task.quotes && task.quotes.gps) || []
    if (gps.length > 0) {
      const timestamp = gps[gps.length - 1].timestamp
      if (timestamp) {
        return moment(timestamp).format('HH:mm')
      }
    }
    return ''
  },
  gps5minTotals: (task) => {
    // total number of gps points in
    // 5 minute windows since first gps point
    //
    // Can be useful to understand if there was a tracking downtime
    //
    // For example:
    //   5 0 3
    // means:
    //   total 6 minutes of tracking time
    //   5 points during first 5 mins of tracking
    //   0 points from 5min to 10min tracking window
    //   3 points at 10min~15min window
    const gps = (task.quotes && task.quotes.gps) || []
    if (gps.length > 0) {
      let windowTimestamp = new Date(gps[0].timestamp).getTime()
      let windowSize = 0
      const windows = []
      for (const p of gps) {
        const newTimestamp = new Date(p.timestamp).getTime()
        if (newTimestamp - windowTimestamp > 5 * 60 * 1000) {
          windowTimestamp = newTimestamp
          windows.push(windowSize)
          windowSize = 1
        } else {
          windowSize += 1
        }
      }
      windows.push(windowSize)

      return windows.join(' ')
    } else {
      return ''
    }
  },
  isDirect: (task) => {
    return task.quotes &&
      task.quotes.chosenQuote &&
      task.quotes.chosenQuote.isDirect
      ? 'yes'
      : 'no'
  },
  callbackUrl: (task) => {
    return task.callbackUrl || ''
  }
}

function lastActionTimestamp(task, actionType) {
  const { actionLog } = task
  const actions = actionLog.filter((log) => log.type === actionType)
  return actions.length ? formatTime(actions[actions.length - 1].timestamp) : ''
}

function formatTime(value) {
  return value ? moment(value).format('YYYY-MM-DD HH:mm') : ''
}

function taskToStringArray(task) {
  return Object.keys(CsvColumns).map((key) => {
    try {
      return CsvColumns[key](task)
    } catch (e) {
      // tslint:disable-next-line
      console.error(e)
      return e.message
    }
  })
}

function stringifyRow(row) {
  return (
    '"' +
    row
      .map((value) => {
        return `${typeof value === 'undefined' ? '' : value}`
          .replace(/"/g, '""')
          .replace(/\n/g, ' ')
      })
      .join('","') +
    '"\n'
  )
}

function exportTasksToCsvString(tasks: any[]) {
  return (
    stringifyRow(Object.keys(CsvColumns)) +
    tasks.map((task) => stringifyRow(taskToStringArray(task))).join('')
  )
}

function getConfirmation(task) {
  const { confirmation = null, previousConfirmations = null } =
    task.quotes || {}
  if (confirmation) {
    return confirmation
  } else if (previousConfirmations && previousConfirmations.length > 0) {
    return previousConfirmations[previousConfirmations.length - 1]
  }
}

function cleanText(text) {
  return String(text)
    .replace(/"/g, '')
    .replace(/\n/g, ' ')
    .replace(/\t/g, ' ')
    .replace(/\//g, ' ')
    .replace(/\\/g, '')
    .replace(/\s+/g, ' ')
    .trim()
}

/**
 * Keep exported vendor names compatable with preexisting spreadhseet scripts
 */
function exportVendorName(vendor: string): string {
  switch (vendor) {
    case COURIER.Stuart:
      return 'Stuart'
    default:
      return vendor
  }
}

export { CsvColumns, exportTasksToCsvString }
