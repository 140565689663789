import firebase from 'firebase/app'
import moment = require('moment')

import './firebaseConfig'

const chatsQuery = firebase.firestore().collection('chat')
const cannedResponsesDoc = firebase
  .firestore()
  .collection('cannedResponses')
  .doc('messages')

const chatsQueryForLast = (amount: number, unit) => {
  return chatsQuery.where(
    'lastMessage.timestamp',
    '>=',
    firebase.firestore.Timestamp.fromDate(
      moment()
        .subtract(amount, unit)
        .toDate()
    )
  )
}

const messagesQuery = (taskId: string) => {
  return chatsQuery
    .doc(taskId)
    .collection('messages')
    .orderBy('timestamp', 'asc')
}

export { chatsQuery, messagesQuery, chatsQueryForLast, cannedResponsesDoc }
