import React, { PureComponent } from 'react'

import QuotesTable from '../QuotesTable/QuotesTable'

interface Props {
  expiry: Date
  className?: string
  taskId: string
  quotes: any
  showPickupTime?: boolean
}

class QuotesTableContainer extends PureComponent<Props> {
  private interval = null
  componentWillMount() {
    this.interval = setInterval(this.forceUpdate.bind(this), 1e3)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    return (
      <QuotesTable
        disabled={this.props.expiry.getTime() - Date.now() < 0}
        {...this.props}
      />
    )
  }
}

export default QuotesTableContainer
