import { PureComponent } from 'react'
import React from 'react'
import { Table } from 'react-bootstrap'

import { renderRow } from './utils'

class GlossaryRecipients extends PureComponent {
  statusAndColors = [
    {
      text: 'Task received, Quote requested',
      paths: [
        require('./glossaryImages/recipients/statusAddedBlackBgYellow.png')
      ]
    },
    {
      text: 'Quote approved',
      paths: [
        require('./glossaryImages/recipients/statusBookedBlackBgYellow.png')
      ]
    },
    ,
    {
      text: 'Driver assigned',
      paths: [
        require('./glossaryImages/recipients/statusDropAtWhiteBgGrey.png')
      ]
    },
    {
      text: 'Driver assigned',
      paths: [
        require('./glossaryImages/recipients/statusDropInWhiteBgGrey.png')
      ]
    },
    {
      text: 'Arrived at pickup',
      paths: [
        require('./glossaryImages/recipients/statusArrivedYellowBgBlue.png')
      ]
    },
    {
      text: 'Arrived at pickup',
      paths: [
        require('./glossaryImages/recipients/statusDropInYellowBgBlue.png')
      ]
    },
    {
      text: 'In transit',
      paths: [
        require('./glossaryImages/recipients/statusDropAtGreenBgBlue.png')
      ]
    },
    {
      text: 'In transit',
      paths: [
        require('./glossaryImages/recipients/statusDropInGreenBgBlue.png')
      ]
    },
    {
      text: 'Completed',
      paths: [
        require('./glossaryImages/recipients/statusArrivedBlackBgGreen.png')
      ]
    },
    {
      text: 'Cancelled',
      paths: [require('./glossaryImages/recipients/statusCancelled.png')]
    }
  ]

  render() {
    return (
      <Table striped responsive className='glossaryTable'>
        <thead>
          <tr>
            <th>Status</th>
            <th>Legend</th>
          </tr>
        </thead>
        <tbody>
          {this.statusAndColors.map(({ text, paths }, index) => (
            <tr key={`${text}_${index}`}>{renderRow(text, 30, paths)}</tr>
          ))}
        </tbody>
      </Table>
    )
  }
}

export default GlossaryRecipients
