import { flow } from 'lodash'
import React, { Component } from 'react'
import {
  Button,
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Grid,
  Modal
} from 'react-bootstrap'
import { reduxForm } from 'redux-form'

import { getFormControlPropsFromReduxForm } from '../../../util/form'
import Spinner from '../../common/Spinner/Spinner'

import { CannedResponseSubmit, FormAction } from './types'

interface OutterProps {
  editingId?: string
  formAction: FormAction
  onSubmitData(submitData: CannedResponseSubmit): void
  onHide(): void
}

interface OwnProps {
  submitting: boolean
  fields: any
  pristine: boolean
  error: any
  values: {
    shortcut: string
    content: string
  }
  resetForm(): void
}

class CannedResponseFormDialog extends Component<OutterProps & OwnProps, {}> {
  _handleSubmit = (event) => {
    event.preventDefault()

    const { values, editingId, onSubmitData, formAction } = this.props
    const { shortcut, content } = values

    onSubmitData({ formAction, shortcut, content, editingId })
  }

  _onHide = () => {
    const { onHide, resetForm } = this.props

    onHide()
    resetForm()
  }

  render() {
    const { formAction, fields } = this.props

    return (
      <Modal
        animation={false}
        className='DriverForm'
        show={[FormAction.ADD, FormAction.EDIT].includes(formAction)}
        onHide={this._onHide}
      >
        <form onSubmit={this._handleSubmit}>
          <Modal.Header>
            <Modal.Title>
              <i className='fa fa-user spaceAfter' />
              {formAction === FormAction.ADD
                ? 'Add new canned response'
                : 'Edit canned response'}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Grid fluid>
              <div className='form-horizontal'>
                <FormGroup controlId='companyName'>
                  <Col sm={4} componentClass={ControlLabel}>
                    Shortcut
                  </Col>
                  <Col sm={8}>
                    <div className='row-align-center'>
                      <span className='spaceAfter'>/</span>
                      <FormControl
                        type='text'
                        {...getFormControlPropsFromReduxForm(fields.shortcut)}
                      />
                    </div>
                  </Col>
                </FormGroup>

                <FormGroup controlId='firstName'>
                  <Col sm={4} componentClass={ControlLabel}>
                    Content
                  </Col>
                  <Col sm={8}>
                    <FormControl
                      componentClass='textarea'
                      rows={6}
                      {...getFormControlPropsFromReduxForm(fields.content)}
                    />
                  </Col>
                </FormGroup>
              </div>
            </Grid>
          </Modal.Body>

          <Modal.Footer>
            <Button onClick={this._onHide} disabled={this.props.submitting}>
              <i className='fa fa-minus spaceAfter' />
              Cancel
            </Button>
            <Button
              type='submit'
              className='btn-brand-secondary'
              disabled={this.props.pristine || this.props.submitting}
            >
              {this.props.submitting ? (
                <Spinner showText={false} className='spaceAfter' />
              ) : (
                <i className='fa fa-check spaceAfter' />
              )}
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

const reduxFormConfig = {
  form: 'cannedResponses',
  fields: ['shortcut', 'content'],
  validate() {
    return {}
  }
}

export default flow(reduxForm(reduxFormConfig))(CannedResponseFormDialog)
