import classnames from 'classnames'
import { is } from 'immutable'
import React from 'react'

import { TASK_STATUS, VIEW_TYPE } from '../../../../../constants'
import {
  getArrivedAtPickupText,
  getFailText,
  getTaskAcceptedQuoteText,
  getTaskAddedText,
  getTaskArrivedAtDropoffText,
  getTaskCancelledText,
  getTaskDriverAssignedText,
  getTaskInTransitText,
  getTaskSuccessText
} from '../statusUtils'

import { Props } from './types'

class StatusAndTime extends React.Component<Props, {}> {
  shouldComponentUpdate(
    nextProps: Readonly<Props>,
    nextState: Readonly<{}>,
    nextContext: any
  ): boolean {
    return (
      !is(this.props.task, nextProps.task) ||
      this.props.viewType !== nextProps.viewType ||
      this.props.taskFilterDay !== nextProps.taskFilterDay
    )
  }

  private getChildrenTasksInfo(task) {
    const containedTasks = task.get('containedTasks')
    const existed =
      task.get('type') === 'multidrop' &&
      !!containedTasks &&
      containedTasks.size > 0

    return {
      existed,
      numberOfTasks: existed ? containedTasks.size : 0
    }
  }

  getTimeText = () => {
    const { task, viewType, taskFilterDay } = this.props

    if (this.getChildrenTasksInfo(task).existed) {
      return 'Multidrop'
    }

    switch (task.get('currentStatus')) {
      case TASK_STATUS.FAILED:
        return getFailText(task, taskFilterDay, viewType)

      case TASK_STATUS.TASK_RECEIVED:
        return getTaskAddedText(task, taskFilterDay, viewType)

      case TASK_STATUS.QUOTE_APPROVED:
        return getTaskAcceptedQuoteText(task, taskFilterDay, viewType)

      case TASK_STATUS.DRIVER_ASSIGNED:
        return getTaskDriverAssignedText(task, taskFilterDay, viewType)

      case TASK_STATUS.ARRIVED_AT_PICKUP:
        return getArrivedAtPickupText(task, taskFilterDay, viewType)

      case TASK_STATUS.IN_TRANSIT:
        return getTaskInTransitText(task, taskFilterDay, viewType)

      case TASK_STATUS.ARRIVED_AT_DROPOFF:
        return getTaskArrivedAtDropoffText(task, taskFilterDay, viewType)

      case TASK_STATUS.SUCCESS:
        return getTaskSuccessText(task, taskFilterDay, viewType)

      case TASK_STATUS.CANCELLED:
        return getTaskCancelledText(task, taskFilterDay, viewType)
    }
  }

  render() {
    const { task, viewType } = this.props
    const currentStatus = task.get('currentStatus')
    const isDriverView = viewType === VIEW_TYPE.TASKS
    const { existed } = this.getChildrenTasksInfo(task)

    return (
      <span
        className={classnames('statusAndTime', currentStatus, {
          statusAndTimeDriver: isDriverView,
          multidrop: existed
        })}
        title={currentStatus}
      >
        {this.getTimeText()}
      </span>
    )
  }
}

export default StatusAndTime
