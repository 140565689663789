import { fromJS, Map } from 'immutable'
import moment from 'moment'
import { Component } from 'react'
import { connect } from 'react-redux'
import socketio from 'socket.io-client'

import config from '../../../config'
import { TASK_STATUS } from '../../../constants'
import { getTaskIdAndSalt } from '../../../util/task'

const carIcon = require('../../../images/map/car-top.png')

interface Props {
  map: any
}

interface StateProps {
  selectedTask: Map<string, any>
}

type EnhancedProps = Props & StateProps

const CAR_MARK_ICON = {
  SIZE: 128,
  SCALE_SIZE: 40
}

const centerX = CAR_MARK_ICON.SIZE / 2
const centerY = CAR_MARK_ICON.SIZE / 2

const LINE_COLOR = '#01A2F9'
const PICKED_LINE_COLOR = '#FF9400'
const POLYLINE_OPTIONS = {
  geodesic: true,
  clickable: false,
  strokeColor: LINE_COLOR,
  strokeOpacity: 1,
  strokeWeight: 3
}

class DriverTracking extends Component<EnhancedProps> {
  locations = []
  lastLocation = null
  routePathPolylines = []
  lastTask = null
  car = null
  socket = null
  img = null
  canvas = null
  canvasContext = null
  markers = []

  readonly carMarkerIconOptions = {
    size: new google.maps.Size(CAR_MARK_ICON.SIZE, CAR_MARK_ICON.SIZE),
    scaledSize: new google.maps.Size(
      CAR_MARK_ICON.SCALE_SIZE,
      CAR_MARK_ICON.SCALE_SIZE
    ),
    anchor: new google.maps.Point(
      CAR_MARK_ICON.SCALE_SIZE / 2,
      CAR_MARK_ICON.SCALE_SIZE / 2
    )
  }

  constructor(props) {
    super(props)

    this.img = new Image(CAR_MARK_ICON.SIZE, CAR_MARK_ICON.SIZE)
    this.img.src = carIcon

    this.canvas = document.createElement('canvas')
    this.canvas.width = CAR_MARK_ICON.SIZE
    this.canvas.height = CAR_MARK_ICON.SIZE

    this.canvasContext = this.canvas.getContext('2d')
  }

  componentWillReceiveProps({
    selectedTask: nextSelectedTask
  }: Readonly<EnhancedProps>): void {
    const { selectedTask } = this.props

    if (nextSelectedTask) {
      if (
        selectedTask &&
        nextSelectedTask.get('id') === selectedTask.get('id')
      ) {
        return
      }

      this.connectSocketAndHandleResponse(nextSelectedTask)
    } else {
      this.resetTrackingInfo()
    }
  }

  private resetTrackingInfo() {
    this.markers.forEach((marker) => {
      marker.setMap(null)
    })
    this.markers = []

    if (this.socket) {
      this.socket.disconnect()
    }

    if (this.car) {
      this.car.setMap(null)
    }

    this.routePathPolylines.map((polyline) => {
      polyline.setMap(null)
    })
    this.routePathPolylines = []

    this.locations = []
    this.lastLocation = null
  }

  private connectSocketAndHandleResponse(selectedTask) {
    this.resetTrackingInfo()
    this.socket = socketio(`${config.socketioServer}/tasks`)

    const methodToSend = 'findTaskByIdAndSalt'

    this.socket.on('response', (response) => {
      const { method, type } = response

      if (method !== method || type !== 'data') {
        return
      }

      this.showMarkers(response)
    })

    this.socket.emit('request', {
      method: methodToSend,
      params: { ref: getTaskIdAndSalt(selectedTask) }
    })
  }

  showMarkers = ({ data }) => {
    const { externalRef, geolocation } = data

    if (externalRef) {
      this.lastTask = data
    } else if (geolocation) {
      this.addRoutePoint(data)
    }
  }

  refreshRoutePath = (locations) => {
    this.routePathPolylines.map((polyline) => {
      polyline.setMap(null)
    })
    this.routePathPolylines = []

    let lastLineIsPicked = false
    let { line, borderLine } = this.makeLine(lastLineIsPicked)

    for (let i = 1; i < locations.length; i++) {
      const location = locations[i]
      const lastLocation = locations[i - 1]

      const position = this.toGooglePoint(location.geolocation)
      const lastPosition = this.toGooglePoint(lastLocation.geolocation)

      const distance = google.maps.geometry.spherical.computeDistanceBetween(
        lastPosition,
        position
      )

      if (distance < 1500) {
        const picked = this.isPicked(location, this.lastTask)

        if (lastLineIsPicked !== picked) {
          lastLineIsPicked = picked

          const newLine = this.makeLine(lastLineIsPicked)
          line = newLine.line
          borderLine = newLine.borderLine

          line.getPath().push(lastPosition)
          borderLine.getPath().push(lastPosition)
        }

        if (line && borderLine) {
          line.getPath().push(position)
          borderLine.getPath().push(position)
        }
      } else {
        line = null
        borderLine = null
      }
    }
  }

  makeLine = (isPicked: boolean) => {
    const line = new google.maps.Polyline({
      ...POLYLINE_OPTIONS,
      strokeColor: isPicked ? PICKED_LINE_COLOR : LINE_COLOR,
      zIndex: 1
    })

    const borderLine = new google.maps.Polyline({
      ...POLYLINE_OPTIONS,
      strokeColor: 'white',
      strokeWeight: 6,
      zIndex: 0
    })

    borderLine.setMap(this.props.map)
    line.setMap(this.props.map)

    this.routePathPolylines.push(line)
    this.routePathPolylines.push(borderLine)

    return { line, borderLine }
  }

  refreshCarMarker = (locations) => {
    const { map } = this.props

    const location = locations[locations.length - 1]
    const lastLocation = locations[locations.length - 2]
    const position = this.toGooglePoint(location.geolocation)
    const lastPosition = this.toGooglePoint(lastLocation.geolocation)
    const heading = google.maps.geometry.spherical.computeHeading(
      lastPosition,
      position
    )

    const status = this.lastTask && this.lastTask.currentStatus
    if (
      !/Success|Failed|Cancelled/i.test(status) &&
      moment(location.timestamp).isAfter(moment().subtract(90, 'minutes'))
    ) {
      const carImgUrl = this.carMarkerRotate((heading + 90) % 360)
      const title = `${location.deviceId} - ${new Date(location.timestamp)} (${
        location.accuracy
      })`

      if (this.car) {
        this.car.setMap(map)
        this.car.setPosition(position)
        this.car.setTitle(title)
        this.car.setIcon({
          url: carImgUrl,
          ...this.carMarkerIconOptions
        })
      } else {
        this.car = new google.maps.Marker({
          draggable: false,
          zIndex: 1989,
          map,
          title,
          position,
          icon: {
            url: carImgUrl,
            ...this.carMarkerIconOptions
          }
        })
      }
    }
  }

  private isPicked = (data, task) => {
    const { actionLog = [] } = task
    const log = actionLog.find(
      (logItem) => logItem.type === TASK_STATUS.IN_TRANSIT
    )

    if (log) {
      return data.timestamp >= log.timestamp
    }

    return false
  }

  addRoutePoint = (data) => {
    /* Locations arrive from server async & unsorted,
     to display correctly buffer up, wait 0.5s, sort by timestamp, display markers & car */
    this.locations.push(data)

    if (!this.lastLocation || data.timestamp > this.lastLocation.timestamp) {
      this.lastLocation = data
    }

    this.locations.sort((a, b) => {
      return a.timestamp > b.timestamp
        ? 1
        : a.timestamp === b.timestamp
        ? 0
        : -1
    })

    if (this.locations.length > 1) {
      this.refreshRoutePath(this.locations)
      this.refreshCarMarker(this.locations)
    }
  }

  carMarkerRotate = (deg) => {
    this.canvasContext.clearRect(0, 0, CAR_MARK_ICON.SIZE, CAR_MARK_ICON.SIZE)
    this.canvasContext.save()

    this.canvasContext.translate(centerX, centerY)
    this.canvasContext.rotate((deg * Math.PI) / 180)
    this.canvasContext.translate(-centerX, -centerY)
    this.canvasContext.drawImage(this.img, 0, 0)
    this.canvasContext.restore()

    return this.canvas.toDataURL('image/png')
  }

  toGooglePoint = ({ coordinates: [longitude, latitude] }) => {
    return new google.maps.LatLng(latitude, longitude)
  }

  render() {
    return null
  }
}

const mapStateToProps = (state: any) => {
  const selectedTaskId = state.MapUiReducer.get('selectedTasks').first()

  if (selectedTaskId) {
    return {
      selectedTask: state.services.getIn([
        'tasks',
        'all',
        fromJS({}),
        'data',
        selectedTaskId
      ])
    }
  }

  return {
    selectedTask: null
  }
}

export default connect(mapStateToProps)(DriverTracking)
