import classnames from 'classnames'
import { List } from 'immutable'
import { debounce } from 'lodash'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { bindActionCreators } from 'redux'
import { Subscription } from 'rxjs'

import MapUiActions from '../../../../actions/MapUiActions'
import { TASK_SECTION, VIEW_TYPE } from '../../../../constants'
import chat from '../../../../services/chat/chat'
import { FilterMessages, SectionState, ViewState } from '../../../../types'
import sectionStateUtils from '../../../../util/sectionState'
import { getViewType } from '../../../../util/task'
import viewStateUtils from '../../../../util/viewState'
import Spinner from '../../../common/Spinner/Spinner'
import Driver from '../Driver/Driver'

import './TaskList.scss'
import TaskListSelector from './TaskList.selector'

interface Props extends RouteComponentProps {
  children: React.ReactNode
}

interface StateProps {
  sections: List<string>
  driversLoaded: boolean
  tasksLoaded: boolean
  viewType: VIEW_TYPE
  viewState: ViewState
  sectionState: SectionState
  MapUiActions: typeof MapUiActions
  filterMessages: FilterMessages
}

interface State {
  lastUpdateChat: string
}

type EnhanceProps = Props & StateProps

class TaskList extends React.Component<EnhanceProps, State> {
  subscription: Subscription = null

  constructor(props) {
    super(props)

    this.state = {
      lastUpdateChat: new Date().toISOString()
    }
  }

  componentDidMount(): void {
    const onStateDebounce = debounce(() => {
      this.setState({
        lastUpdateChat: new Date().toISOString()
      })
    }, 1000)

    this.subscription = chat.subject.subscribe(() => {
      if (this.props.filterMessages.active) {
        onStateDebounce()
      }
    })
  }

  componentWillUnmount(): void {
    this.subscription?.unsubscribe()
  }

  onGroupChange = ({ currentTarget: { id, checked } }) => {
    const {
      viewType,
      viewState: { checkedSeparatePendingAndOngoing },
      MapUiActions: { setViewState }
    } = this.props

    switch (id) {
      case 'groupByAll': {
        if (!checkedSeparatePendingAndOngoing) {
          return
        }

        setViewState(viewType, {
          checkedSeparatePendingAndOngoing: !checked
        })

        break
      }

      case 'splitPendingAndOnGoing': {
        if (checkedSeparatePendingAndOngoing) {
          return
        }

        setViewState(viewType, {
          checkedSeparatePendingAndOngoing: checked
        })

        break
      }

      default:
        break
    }
  }

  private onSectionCheckboxChange = ({ currentTarget: { id, checked } }) => {
    const { viewType, sectionState } = this.props

    switch (id) {
      case 'pending': {
        this.props.MapUiActions.setSectionState(
          viewType,
          TASK_SECTION.ON_GOING,
          {
            ...sectionState,
            checkedPending: checked
          }
        )

        break
      }

      case 'onGoing': {
        this.props.MapUiActions.setSectionState(
          viewType,
          TASK_SECTION.ON_GOING,
          {
            ...sectionState,
            checkedOnGoing: checked
          }
        )

        break
      }

      default:
        break
    }
  }

  private renderGroupBy() {
    const {
      sectionState,
      viewState: { checkedSeparatePendingAndOngoing }
    } = this.props

    return (
      <div className='groupByContainer'>
        <div className='checkboxesContainer'>
          <div
            className={classnames('row-align-center', {
              all: !checkedSeparatePendingAndOngoing
            })}
          >
            <div className='checkboxContainer pointer'>
              <input
                type='checkbox'
                id='groupByAll'
                checked={!checkedSeparatePendingAndOngoing}
                onChange={this.onGroupChange}
                className='pointer'
              />
              <label htmlFor='groupByAll' className='pointer'>
                Show all
              </label>
            </div>
            {!checkedSeparatePendingAndOngoing && (
              <div className='subCheckboxes row-align-center'>
                <span className='checkboxContainer pointer'>
                  <input
                    id='pending'
                    type='checkbox'
                    checked={sectionState.checkedPending}
                    onChange={this.onSectionCheckboxChange}
                    className='pointer'
                  />
                  <label htmlFor='pending' className='pointer'>
                    Pending
                  </label>
                </span>
                <span className='checkboxContainer pointer'>
                  <input
                    id='onGoing'
                    type='checkbox'
                    checked={sectionState.checkedOnGoing}
                    onChange={this.onSectionCheckboxChange}
                    className='pointer'
                  />
                  <label htmlFor='onGoing' className='pointer'>
                    On Going
                  </label>
                </span>
              </div>
            )}
          </div>

          <div className='checkboxContainer pointer'>
            <input
              type='checkbox'
              id='splitPendingAndOnGoing'
              checked={checkedSeparatePendingAndOngoing}
              onChange={this.onGroupChange}
              className='pointer'
            />
            <label htmlFor='splitPendingAndOnGoing' className='pointer'>
              Split by Pending / On Going
            </label>
          </div>
        </div>
      </div>
    )
  }

  renderChatTitle = () => {
    return (
      <div className='chatTitleHeader'>
        <FormattedMessage id='chat.viewingOngoing' />
      </div>
    )
  }

  render() {
    const { sections, viewType, filterMessages } = this.props
    const { lastUpdateChat } = this.state

    return (
      <div className='TaskList'>
        {filterMessages.active && this.renderChatTitle()}
        {!sections.includes(TASK_SECTION.SEARCH_RESULTS) &&
          viewType === VIEW_TYPE.TASKS &&
          this.renderGroupBy()}
        {!this.props.driversLoaded || !this.props.tasksLoaded ? (
          <h4 className='text-center'>
            <Spinner />
          </h4>
        ) : (
          <ul className='driverList'>
            {sections
              .map((sectionId) => {
                return (
                  <Driver
                    key={`${lastUpdateChat}_${sectionId}`}
                    sectionId={sectionId}
                    viewType={viewType}
                  />
                )
              })
              .toArray()}
          </ul>
        )}

        {this.props.children}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const viewType = getViewType(ownProps.location.pathname)

  return {
    ...TaskListSelector(state, viewType),
    viewType,
    viewState: viewStateUtils.getViewState(viewType, state),
    sectionState: sectionStateUtils.getSectionState(
      viewType,
      TASK_SECTION.ON_GOING,
      state
    ),
    filterMessages: state.MapUiReducer.get('filterMessages').toJS()
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    MapUiActions: bindActionCreators(MapUiActions, dispatch)
  }
}

export { mapStateToProps, mapDispatchToProps }
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(TaskList)
