import { fromJS } from 'immutable'

import {
  AUTHENTICATION_IN_PROGRESS,
  AUTHENTICATION_REJECTED,
  AUTHENTICATION_RESOLVED,
  LOGOUT
} from '../actions/AuthActions'
import chat from '../services/chat/chat'
import firebaseConfig from '../services/firebase/firebaseConfig'

const initialState = fromJS({
  inProgress: false,
  lastError: null,
  logoutReason: null,
  user: null,
  token: null
})

export default function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case AUTHENTICATION_IN_PROGRESS:
      return state.set('inProgress', true)

    case AUTHENTICATION_RESOLVED: {
      window.localStorage.setItem('user', JSON.stringify(action.user))
      window.localStorage.setItem('token', action.token)

      firebaseConfig.auth()

      return state.merge({
        inProgress: false,
        user: action.user,
        token: action.token,
        lastError: null,
        logoutReason: null
      })
    }

    case AUTHENTICATION_REJECTED:
      return state.merge({
        inProgress: false,
        user: null,
        token: null,
        lastError: action.error
      })

    case LOGOUT:
      chat.stopListen()

      window.localStorage.setItem('user', null)
      window.localStorage.setItem('token', null)

      firebaseConfig.signOut().finally(() => {
        window.location.reload()
      })

      return state.merge({
        inProgress: false,
        user: null,
        token: null,
        lastError: null,
        logoutReason: action.reason
      })

    default:
      return state
  }
}
