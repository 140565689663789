import { batchActions } from './batchActions'

const FLUSH_REQUEST = 'FLUSH_REQUEST'
const FLUSH_AWAIT = 'FLUSH_AWAIT'

function flushAsyncQueue(dispatch) {
  return dispatch({
    type: FLUSH_REQUEST
  })
}

function flushAwait(dispatch): Promise<void> {
  return dispatch({
    type: FLUSH_AWAIT
  })
}

const asyncActionsMiddleware = () => {
  const queue = []
  const resolvers = []

  return (next) => {
    return (action) => {
      if (action.type === FLUSH_AWAIT) {
        return new Promise((r) => resolvers.push(r))
      } else if (action.type === FLUSH_REQUEST) {
        const poppedQueue = queue.splice(0, queue.length)
        const poppedResolvers = resolvers.splice(0, resolvers.length)

        if (poppedQueue.length > 0 || poppedResolvers.length > 0) {
          // tslint:disable-next-line
          console.log(
            'Resolving queue',
            'length',
            poppedQueue.length,
            'promise resolvers',
            poppedResolvers.length
          )
        }

        try {
          if (poppedQueue.length > 0) {
            return next(batchActions(poppedQueue))
          }
        } finally {
          poppedResolvers.map((resolve) => resolve())
        }
      } else if (action?.meta?.async === true) {
        return new Promise((r) => {
          queue.push(action)
          resolvers.push(r)
        })
      } else {
        return next(action)
      }
    }
  }
}

export {
  flushAsyncQueue,
  flushAwait,
  asyncActionsMiddleware,
  FLUSH_AWAIT,
  FLUSH_REQUEST
}
