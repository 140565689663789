import { Map as ImmutableMap } from 'immutable'
import moment from 'moment'
import React from 'react'
import {
  Checkbox,
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Grid,
  InputGroup,
  ListGroup,
  ListGroupItem,
  Panel,
  Radio,
  Row
} from 'react-bootstrap'
import { FormattedMessage, injectIntl } from 'react-intl'
import ReactMarkdown from 'react-markdown'
import {
  generatePath,
  Link,
  RouteComponentProps,
  withRouter
} from 'react-router-dom'
import { compose } from 'recompose'

import { TASK_STATUS, VIEW_TYPE } from '../../../../../constants'
import { Routes } from '../../../../../navigation/Routes'
import { getFormControlPropsFromReduxForm } from '../../../../../util/form'
import DebouncedControl from '../../../../common/DebouncedControl/DebouncedControl'
import RFDateTimePicker from '../../../../common/RFDateTimePicker/RFDateTimePicker'
import TaskActionLog from '../../../../common/TaskActionLog/TaskActionLog'
import {
  PARCEL_SIZE_PRESETS,
  PARCEL_WEIGHT_PRESETS
} from '../../../../common/parcelDimensionPresets'
import QuotesContainer from '../../quotes/QuotesContainer/QuotesContainer'
import AddressAutocomplete from '../AddressAutocomplete/AddressAutocomplete'
import ContainedTasks from '../ContainedTasks/ContainedTasks'
import MerchantChoice from '../MerchantChoice/MerchantChoice'
import StatusSelect from '../StatusSelect/StatusSelect'

import './TaskFormFields.scss'

interface NoteProps {
  note: ImmutableMap<any, any>
}

function Note({ note }: NoteProps) {
  const date = moment(note.get('timestamp')).format('L LT')
  let element = <ReactMarkdown source={note.get('data')} />
  if (note.get('type') === 'image') {
    element = (
      <img
        style={{ maxWidth: '100%', maxHeight: '100%' }}
        src={note.get('data')}
      />
    )
  }
  return (
    <ListGroupItem key={note.get('timestamp')}>
      <p>
        <small>
          <b>
            {note.get('author')} &ndash; {date}
          </b>
        </small>
      </p>
      {element}
    </ListGroupItem>
  )
}

interface Props extends RouteComponentProps<{ viewType: VIEW_TYPE }> {
  merchants: ImmutableMap<any, any>
  task: ImmutableMap<any, any>
  TaskFormActions: any
  AddressActions: any
  fields: any
  allowEditing: any
  submitting: any
  intl: any
  sourceError: any
  merchantList: any
  destinationError: any
  user: any
}

class TaskFormFields extends React.PureComponent<Props> {
  state = {
    showCustomSizeInputs: false,
    showCustomWeightInput: false,
    sourceNameHeight: 0,
    sourceNameTop: 0,
    destinationNameHeight: 0,
    destinationNameTop: 0
  }

  customParcelLengthInput?: HTMLInputElement

  parcelValueInput?: HTMLInputElement

  selectSuggestedSourceAddress = (address) => {
    this.props.TaskFormActions.selectAddressSuggestion(address, 'source')
  }

  selectSuggestedDestinationAddress = (address) => {
    this.props.TaskFormActions.selectAddressSuggestion(address, 'destination')
  }

  handleMerchantIdChange = (event) => {
    const { fields } = this.props

    if (this.props.task != null) {
      // don't update anything if merchant is updated on an existing task
      return this.props.fields.merchantId.onChange(event)
    }

    const defaultMerchant = this.props.merchants.getIn([
      'data',
      event.target.value
    ])
    const lastSourcesString = window.localStorage.getItem('lastSources')
    const lastSources = lastSourcesString ? JSON.parse(lastSourcesString) : {}
    const loadPreviousSource =
      defaultMerchant && defaultMerchant.get('loadPreviousSource', true)
    const lastSource = loadPreviousSource
      ? lastSources[event.target.value]
      : null

    if (
      !fields.parcelLength.touched &&
      !fields.parcelWidth.touched &&
      !fields.parcelHeight.touched
    ) {
      this.props.TaskFormActions.fillInDefaultParcelSize(event.target.value)
    }

    if (!fields.parcelWeight.touched) {
      this.props.TaskFormActions.fillInDefaultParcelWeight(event.target.value)
    }

    if (!fields.parcelFragile.touched) {
      this.props.TaskFormActions.fillInDefaultParcelFragile(event.target.value)
    }
    if (!fields.parcelDoNotRotate.touched) {
      this.props.TaskFormActions.fillInDefaultParcelDoNotRotate(
        event.target.value
      )
    }
    if (!fields.parcelContainsLiquid.touched) {
      this.props.TaskFormActions.fillInDefaultParcelContainsLiquid(
        event.target.value
      )
    }
    if (!fields.parcelContainsHotFood.touched) {
      this.props.TaskFormActions.fillInDefaultParcelContainsHotFood(
        event.target.value
      )
    }

    this.props.TaskFormActions.fillInLastSource(
      lastSource || {
        city: 'London',
        country: 'United Kingdom'
      }
    )

    return this.props.fields.merchantId.onChange(event)
  }

  handleParcelSizePresetChange = (event) => {
    if (event.target.value !== 'custom') {
      const [
        parcelLength,
        parcelWidth,
        parcelHeight
      ] = event.target.value.split('x')
      this.props.fields.parcelLength.onChange(parseFloat(parcelLength))
      this.props.fields.parcelWidth.onChange(parseFloat(parcelWidth))
      this.props.fields.parcelHeight.onChange(parseFloat(parcelHeight))
    } else {
      this.setState({ showCustomSizeInputs: true })
    }
  }

  handleParcelWeightPresetChange = (event) => {
    if (event.target.value !== 'custom') {
      this.props.fields.parcelWeight.onChange(parseFloat(event.target.value))
    } else {
      this.setState({ showCustomWeightInput: true })
    }
  }

  handleParcelWeightPresetBlur = (event) => {
    if (event.target.value !== 'custom') {
      this.props.fields.parcelWeight.onBlur(parseFloat(event.target.value))
    }
  }

  customParcelLengthInputRef = (component: any) => {
    this.customParcelLengthInput = component
  }

  parcelValueInputRef = (component: any) => {
    this.parcelValueInput = component
  }

  private renderNotes = () => {
    const {
      intl,
      submitting,
      allowEditing,
      fields: { newNote },
      task
    } = this.props

    return (
      <Row>
        <NotesRow
          intl={intl}
          submitting={submitting}
          allowEditing={allowEditing}
          newNote={newNote}
          taskNotes={task && task.get('notes')}
        />
      </Row>
    )
  }

  render() {
    const {
      task,
      allowEditing,
      submitting,
      intl,
      sourceError,
      merchantList,
      user,
      match
    } = this.props

    const {
      type: { value: taskType },
      merchantId,
      externalRef,
      parcelLength,
      parcelWidth,
      parcelHeight,
      parcelWeight,
      parcelFragile,
      parcelDoNotRotate,
      parcelContainsLiquid,
      parcelContainsHotFood,
      parcelValue,
      currentStatus,
      schedule,
      pickupDateFrom,
      pickupDateTo,
      deliveryDateFrom,
      deliveryDateTo,
      sourceName,
      sourceAddress,
      sourceCity,
      sourcePostcode,
      sourceCountry,
      sourcePhone,
      sourceEmail,
      pickupInstructions,
      destinationName,
      destinationAddress,
      destinationCity,
      destinationPostcode,
      destinationCountry,
      destinationPhone,
      destinationEmail,
      deliveryInstructions,
      containedTasks,
      parentTask: { value: parentTask }
    } = this.props.fields
    const {
      moveUpContainedTask,
      moveDownContainedTask
    } = this.props.TaskFormActions

    const parcelSizeValues = `${parcelLength.value}x${parcelWidth.value}x${parcelHeight.value}`
    const parcelSizePresetsValue =
      PARCEL_SIZE_PRESETS.indexOf(parcelSizeValues) !== -1
        ? parcelSizeValues
        : 'custom'

    const parcelWeightPresetsValue =
      PARCEL_WEIGHT_PRESETS.indexOf(parcelWeight.value) !== -1
        ? parcelWeight.value
        : 'custom'

    const geomatrix =
      task && task.get('geomatrix') && task.get('geomatrix').toJS()

    const taskStatus = task && task.get('currentStatus')
    const isAdmin = user.get('administrator')

    const allowUpdateMerchant =
      taskStatus !== TASK_STATUS.SUCCESS &&
      taskStatus !== TASK_STATUS.CANCELLED &&
      (isAdmin || user.get('canUpdateTaskMerchant'))
    const etaPickup = task && task.getIn(['eta', 'pickup'])
    const etaDropoff = task && task.getIn(['eta', 'dropoff'])

    const isMultidropParcel = taskType === 'multidropParcel'
    const isMultidrop = taskType === 'multidrop'
    const actionLog = task && task.get('actionLog')

    const isNewTask = !task

    return (
      <Grid fluid className='TaskFormFields'>
        <HeaderFieldsGroup
          {...{
            merchantList,
            handleMerchantIdChange: this.handleMerchantIdChange,
            allowUpdateMerchant,
            submitting,
            allowEditing,
            currentStatus,
            merchantId,
            externalRef,
            isNewTask: !(task && task.get('id'))
          }}
        />

        {!isNewTask && this.renderNotes()}

        {task != null && task.get('onDemand') && (
          <QuotesContainer {...this.props} />
        )}

        {!taskType && geomatrix && <GeomatrixRow geomatrix={geomatrix} />}

        {isMultidropParcel ? (
          <Row>
            <Col sm={12}>
              <Panel className='schedulePanel customPanel'>
                <Panel.Heading>
                  <span className='iconContainer'>
                    <i className='fa fa-tasks fa-sm icon' />
                  </span>
                  Parent task
                </Panel.Heading>
                <Panel.Body>
                  This task is part of multidrop:{' '}
                  <Link
                    to={generatePath(Routes.Map.TaskDetail, {
                      taskId: parentTask.id,
                      viewType: match.params.viewType
                    })}
                  >
                    {parentTask.externalRef}
                  </Link>
                </Panel.Body>
              </Panel>
            </Col>
          </Row>
        ) : (
          <ScheduleRow
            {...{
              schedule,
              submitting,
              allowEditing,
              pickupDateFrom,
              pickupDateTo,
              deliveryDateFrom,
              deliveryDateTo,
              intl
            }}
          />
        )}

        {/* parcel properties */}
        {isMultidrop ? null : (
          <ParcelPropertiesRow
            {...{
              handleParcelSizePresetChange: this.handleParcelSizePresetChange,
              handleParcelWeightPresetChange: this
                .handleParcelWeightPresetChange,
              handleParcelWeightPresetBlur: this.handleParcelWeightPresetBlur,
              customParcelLengthInputRef: this.customParcelLengthInputRef,
              parcelValueInputRef: this.parcelValueInputRef,
              showCustomWeightInput: this.state.showCustomWeightInput,
              showCustomSizeInputs: this.state.showCustomSizeInputs,
              parcelSizePresetsValue,
              parcelWeightPresetsValue,
              submitting,
              allowEditing,
              parcelLength,
              parcelWidth,
              parcelHeight,
              parcelWeight,
              parcelValue,
              parcelFragile,
              parcelDoNotRotate,
              parcelContainsLiquid,
              parcelContainsHotFood,
              intl
            }}
          />
        )}

        <div className='taskFormPickupAndDropoffContainer'>
          {/* source */}
          {parentTask ? null : (
            <SourceCol
              {...{
                isMultidrop,
                etaPickup,
                currentStatus,
                sourceName,
                submitting,
                allowEditing,
                onAddressSuggestionSelect: this.selectSuggestedSourceAddress,
                merchantId,
                sourceError,
                sourceAddress,
                sourceCity,
                sourcePostcode,
                sourceCountry,
                sourcePhone,
                sourceEmail,
                pickupInstructions
              }}
            />
          )}

          {/* destination */}
          {isMultidrop ? null : (
            <DestinationCol
              {...{
                taskType,
                etaDropoff,
                destinationName,
                submitting,
                allowEditing,
                onAddressSuggestionSelect: this
                  .selectSuggestedDestinationAddress,
                merchantId,
                destinationError: this.props.destinationError,
                destinationAddress,
                destinationCity,
                destinationPostcode,
                destinationCountry,
                destinationPhone,
                destinationEmail,
                pickupInstructions,
                deliveryInstructions
              }}
            />
          )}
        </div>

        {containedTasks && containedTasks.value && (
          <ContainedTasks
            containedTasks={containedTasks.value}
            moveUp={moveUpContainedTask}
            moveDown={moveDownContainedTask}
          />
        )}

        {isNewTask && this.renderNotes()}

        {actionLog && actionLog.size > 0 && (
          <ActionLogRow actionLog={actionLog} />
        )}
      </Grid>
    )
  }
}

const HeaderFieldsGroup = ({
  merchantList,
  handleMerchantIdChange, // this.handleMerchantIdChange
  allowUpdateMerchant,
  submitting,
  allowEditing,
  currentStatus,
  merchantId,
  externalRef,
  isNewTask // !(task && task.get('id'))
}) => (
  <>
    <Row className='form-inline spaceBelow'>
      {/* merchantId */}
      <Col sm={6}>
        <MerchantChoice
          merchantList={merchantList}
          handleMerchantIdChange={handleMerchantIdChange}
          allowUpdateMerchant={allowUpdateMerchant}
          submitting={submitting}
          value={merchantId.value}
        />
      </Col>

      {/* externalRef */}
      <Col sm={6} className='text-right'>
        <CommonTextInput
          {...{
            controlId: 'externalRef',
            label: 'task.externalRef',
            hasError: externalRef.touched && externalRef.error,
            disabled: submitting,
            readOnly: !allowEditing,
            value: externalRef.value,
            onChange: externalRef.onChange
          }}
        />
      </Col>
    </Row>

    <CurrentStatusInput
      {...{
        hidden: isNewTask,
        disabled: submitting || !allowEditing,
        value: currentStatus.value,
        onChange: currentStatus.onChange
      }}
    />
  </>
)

const CurrentStatusInput = ({
  hidden, // isNewTask
  disabled, // submitting || !allowEditing
  value,
  onChange
}) => (
  <Row className='form-inline spaceBelow'>
    <Col sm={12}>
      <FormGroup
        controlId='currentStatus'
        className={'currentStatus' + (hidden ? ' hidden' : '')}
      >
        <ControlLabel className='alignedLabel'>
          <FormattedMessage id='task.currentStatus' />
        </ControlLabel>
        <div className='inputContainer'>
          <StatusSelect disabled={disabled} value={value} onChange={onChange} />
        </div>
      </FormGroup>
    </Col>
  </Row>
)

const GeomatrixRow = ({ geomatrix }) => (
  <Row className='form-inline spaceBelow'>
    <Col sm={12}>
      <Panel className='travelPanel customPanel'>
        <Panel.Heading>
          <span className='iconContainer'>
            <i className='fa fa-route fa-sm icon' />
          </span>
          <FormattedMessage id='task.travelPanelHeader' />
        </Panel.Heading>
        <Panel.Body>
          <Grid fluid>
            <Row>
              {geomatrix && geomatrix.walking && (
                <Col sm={4}>
                  <i className='fa fa-walking spaceAfter' />
                  <strong>Walking:</strong>{' '}
                  {formatDistanceAndDuration(geomatrix.walking)}
                </Col>
              )}
              {geomatrix && geomatrix.bicycling && (
                <Col sm={4}>
                  <i className='fa fa-bicycle spaceAfter' />
                  <strong>Cycling:</strong>{' '}
                  {formatDistanceAndDuration(geomatrix.bicycling)}
                </Col>
              )}
              {geomatrix && geomatrix.driving && (
                <Col sm={4}>
                  <i className='fa fa-car spaceAfter' />
                  <strong>Driving:</strong>{' '}
                  {formatDistanceAndDuration(geomatrix.driving)}
                </Col>
              )}
            </Row>
          </Grid>
        </Panel.Body>
      </Panel>
    </Col>
  </Row>
)

const ScheduleRow = ({
  schedule,
  submitting,
  allowEditing,
  pickupDateFrom,
  pickupDateTo,
  deliveryDateFrom,
  deliveryDateTo,
  intl
}) => (
  <Row>
    <Col sm={12}>
      <Panel className='schedulePanel customPanel'>
        <Panel.Heading>
          <span className='iconContainer'>
            <i className='fa fa-clock fa-sm icon' />
          </span>
          <FormattedMessage id='task.schedule' />
        </Panel.Heading>
        <Panel.Body>
          <Grid fluid>
            {/* urgent delivery */}
            <Row>
              <Col sm={12}>
                <FormGroup controlId='schedule-urgent'>
                  <Radio
                    data-testid='urgent-delivery-check-box'
                    inline
                    {...getFormControlPropsFromReduxForm(schedule)}
                    value='urgent'
                    checked={schedule.value === 'urgent'}
                    disabled={submitting || !allowEditing}
                  >
                    <FormattedMessage id='task.schedule.urgent' />
                  </Radio>
                </FormGroup>
              </Col>
            </Row>

            {/* asap delivery */}
            <Row>
              <Col sm={12}>
                <FormGroup controlId='schedule-asap'>
                  <Radio
                    data-testid='same-day-delivery-check-box'
                    inline
                    {...getFormControlPropsFromReduxForm(schedule)}
                    value='asap'
                    checked={schedule.value === 'asap'}
                    disabled={submitting || !allowEditing}
                  >
                    <FormattedMessage id='task.schedule.asap' />
                  </Radio>
                </FormGroup>
              </Col>
            </Row>

            {/* scheduled pickup & delivery */}
            <Row>
              <Col sm={12}>
                <FormGroup controlId='schedule-pickupAndDelivery'>
                  <Radio
                    data-testid='schedule-pickup-check-box'
                    inline
                    {...getFormControlPropsFromReduxForm(schedule)}
                    value='pickupAndDelivery'
                    checked={schedule.value === 'pickupAndDelivery'}
                    disabled={submitting || !allowEditing}
                  >
                    <FormattedMessage id='task.schedule.pickupAndDelivery' />
                  </Radio>
                </FormGroup>
              </Col>
            </Row>

            {schedule.value !== 'pickupAndDelivery' ? null : (
              <Row className='spaceBelowLarge'>
                <FormGroup
                  controlId='pickupDateFrom'
                  validationState={
                    pickupDateFrom.touched && pickupDateFrom.error
                      ? 'error'
                      : null
                  }
                  className='noBSMargin'
                >
                  <Col sm={2} className='alignedLabel timeLabel'>
                    <label htmlFor='pickupDateFrom_input'>
                      <FormattedMessage id='task.pickupDateFrom' />
                    </label>
                  </Col>
                  <Col sm={4}>
                    <RFDateTimePicker
                      {...getFormControlPropsFromReduxForm(pickupDateFrom)}
                      id='pickupDateFrom'
                      disabled={submitting}
                      readOnly={!allowEditing}
                      format={intl.formatMessage({
                        id: 'moment.shortDateTime'
                      })}
                      onBlur={null}
                    />
                  </Col>
                </FormGroup>
                <FormGroup
                  controlId='deliveryDateTo'
                  validationState={
                    deliveryDateTo.touched && deliveryDateTo.error
                      ? 'error'
                      : null
                  }
                  className='noBSMargin'
                >
                  <Col sm={2} className='alignedLabel timeLabel'>
                    <label htmlFor='deliveryDateTo_input'>
                      <FormattedMessage id='task.deliveryDateTo' />
                    </label>
                  </Col>
                  <Col sm={4}>
                    <RFDateTimePicker
                      {...getFormControlPropsFromReduxForm(deliveryDateTo)}
                      id='deliveryDateTo'
                      disabled={submitting}
                      readOnly={!allowEditing}
                      format={intl.formatMessage({
                        id: 'moment.shortDateTime'
                      })}
                      onBlur={null}
                    />
                  </Col>
                </FormGroup>
              </Row>
            )}

            <Row>
              <Col sm={12}>
                <FormGroup controlId='schedule-delivery'>
                  <Radio
                    data-testid='schedule-delivery-time-slot'
                    inline
                    {...getFormControlPropsFromReduxForm(schedule)}
                    value='delivery'
                    checked={schedule.value === 'delivery'}
                    disabled={submitting || !allowEditing}
                  >
                    <FormattedMessage id='task.schedule.delivery' />
                  </Radio>
                </FormGroup>
              </Col>
            </Row>

            {/* scheduled delivery */}
            {schedule.value !== 'delivery' ? null : (
              <Row className='spaceBelowLarge'>
                <FormGroup
                  controlId='deliveryDateFrom'
                  validationState={
                    deliveryDateFrom.touched && deliveryDateFrom.error
                      ? 'error'
                      : null
                  }
                  className='noBSMargin'
                >
                  <Col sm={2} className='alignedLabel timeLabel'>
                    <label htmlFor='deliveryDateFrom_input'>
                      <FormattedMessage id='task.deliveryDateFrom' />
                    </label>
                  </Col>
                  <Col sm={4}>
                    <RFDateTimePicker
                      {...getFormControlPropsFromReduxForm(deliveryDateFrom)}
                      id='deliveryDateFrom'
                      disabled={submitting}
                      readOnly={!allowEditing}
                      format={intl.formatMessage({
                        id: 'moment.shortDateTime'
                      })}
                      onBlur={null}
                    />
                  </Col>
                </FormGroup>
                <FormGroup
                  controlId='deliveryDateTo'
                  validationState={
                    deliveryDateTo.touched && deliveryDateTo.error
                      ? 'error'
                      : null
                  }
                  className='noBSMargin'
                >
                  <Col sm={2} className='alignedLabel timeLabel'>
                    <label htmlFor='deliveryDateTo_input'>
                      <FormattedMessage id='task.deliveryDateTo' />
                    </label>
                  </Col>
                  <Col sm={4}>
                    <RFDateTimePicker
                      {...getFormControlPropsFromReduxForm(deliveryDateTo)}
                      id='deliveryDateTo'
                      disabled={submitting}
                      readOnly={!allowEditing}
                      format={intl.formatMessage({
                        id: 'moment.shortDateTime'
                      })}
                      onBlur={null}
                    />
                  </Col>
                </FormGroup>
              </Row>
            )}

            <Row>
              <Col sm={12}>
                <FormGroup controlId='schedule-delivery' className='lastRadio'>
                  <Radio
                    inline
                    {...getFormControlPropsFromReduxForm(schedule)}
                    value='custom'
                    checked={schedule.value === 'custom'}
                    disabled={submitting || !allowEditing}
                  >
                    <FormattedMessage id='task.schedule.custom' />
                  </Radio>
                </FormGroup>
              </Col>
            </Row>

            {/* custom times */}
            {schedule.value !== 'custom' ? null : (
              <div>
                <Row className='lastInputRow'>
                  <FormGroup
                    controlId='pickupDateFrom'
                    validationState={
                      pickupDateFrom.touched && pickupDateFrom.error
                        ? 'error'
                        : null
                    }
                    className='noBSMargin'
                  >
                    <Col sm={2} className='alignedLabel timeLabel'>
                      <label htmlFor='pickupDateFrom_input'>
                        <FormattedMessage id='task.pickupDateFrom' />
                      </label>
                    </Col>
                    <Col sm={4}>
                      <RFDateTimePicker
                        {...getFormControlPropsFromReduxForm(pickupDateFrom)}
                        id='pickupDateFrom'
                        disabled={submitting}
                        readOnly={!allowEditing}
                        format={intl.formatMessage({
                          id: 'moment.shortDateTime'
                        })}
                        onBlur={null}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup
                    controlId='pickupDateTo'
                    validationState={
                      pickupDateTo.touched && pickupDateTo.error
                        ? 'error'
                        : null
                    }
                    className='noBSMargin'
                  >
                    <Col sm={2} className='alignedLabel timeLabel'>
                      <label htmlFor='pickupDateTo_input'>
                        <FormattedMessage id='task.pickupDateTo' />
                      </label>
                    </Col>
                    <Col sm={4}>
                      <RFDateTimePicker
                        {...getFormControlPropsFromReduxForm(pickupDateTo)}
                        id='pickupDateTo'
                        disabled={submitting}
                        readOnly={!allowEditing}
                        format={intl.formatMessage({
                          id: 'moment.shortDateTime'
                        })}
                        onBlur={null}
                      />
                    </Col>
                  </FormGroup>
                </Row>

                <Row className='lastInputRow'>
                  <FormGroup
                    controlId='deliveryDateFrom'
                    validationState={
                      deliveryDateFrom.touched && deliveryDateFrom.error
                        ? 'error'
                        : null
                    }
                    className='noBSMargin'
                  >
                    <Col sm={2} className='alignedLabel timeLabel'>
                      <label htmlFor='deliveryDateFrom_input'>
                        <FormattedMessage id='task.deliveryDateFrom' />
                      </label>
                    </Col>
                    <Col sm={4}>
                      <RFDateTimePicker
                        {...getFormControlPropsFromReduxForm(deliveryDateFrom)}
                        id='deliveryDateFrom'
                        disabled={submitting}
                        readOnly={!allowEditing}
                        format={intl.formatMessage({
                          id: 'moment.shortDateTime'
                        })}
                        onBlur={null}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup
                    controlId='deliveryDateTo'
                    validationState={
                      deliveryDateTo.touched && deliveryDateTo.error
                        ? 'error'
                        : null
                    }
                    className='noBSMargin'
                  >
                    <Col sm={2} className='alignedLabel timeLabel'>
                      <label htmlFor='deliveryDateTo_input'>
                        <FormattedMessage id='task.deliveryDateTo' />
                      </label>
                    </Col>
                    <Col sm={4}>
                      <RFDateTimePicker
                        {...getFormControlPropsFromReduxForm(deliveryDateTo)}
                        id='deliveryDateTo'
                        disabled={submitting}
                        readOnly={!allowEditing}
                        format={intl.formatMessage({
                          id: 'moment.shortDateTime'
                        })}
                        onBlur={null}
                      />
                    </Col>
                  </FormGroup>
                </Row>
              </div>
            )}
          </Grid>
        </Panel.Body>
      </Panel>
    </Col>
  </Row>
)

const SourceCol = ({
  isMultidrop,
  etaPickup,
  currentStatus,
  sourceName,
  submitting,
  allowEditing,
  onAddressSuggestionSelect,
  merchantId,
  sourceError,
  sourceAddress,
  sourceCity,
  sourcePostcode,
  sourceCountry,
  sourcePhone,
  sourceEmail,
  pickupInstructions
}) => {
  return (
    <Col>
      <Panel className='addressPanel customPanel'>
        <AddressPanelHeading
          iconClassName='fa-store-alt'
          title='map.tasks.modal.sourceTitle'
          eta={currentStatus === TASK_STATUS.DRIVER_ASSIGNED ? etaPickup : null}
        />

        <Panel.Body>
          <AddressNameInput
            {...{
              controlId: 'sourceName',
              merchantId, // merchantId.value
              disabled: submitting,
              readOnly: !allowEditing,
              controller: sourceName,
              onAddressSuggestionSelect
            }}
          />

          <AddressInput
            {...{
              controlId: 'sourceAddress',
              label: 'task.sourceAddress',
              hasError:
                (sourceError && sourceAddress.pristine) ||
                (sourceAddress.touched && sourceAddress.error),
              disabled: submitting,
              readOnly: allowEditing,
              value: sourceAddress.value,
              onChange: sourceAddress.onChange
            }}
          />

          <CommonTextInput
            {...{
              controlId: 'sourceCity',
              label: 'task.sourceCity',
              hasError:
                (sourceError && sourceCity.pristine) ||
                (sourceCity.touched && sourceCity.error),
              disabled: submitting,
              readOnly: !allowEditing,
              value: sourceCity.value,
              onChange: sourceCity.onChange,
              isRequired: true
            }}
          />

          <CommonTextInput
            controlId={'sourcePostcode'}
            label={'task.sourcePostcode'}
            hasError={
              (sourceError && sourcePostcode.pristine) ||
              (sourcePostcode.touched && sourcePostcode.error)
            }
            disabled={submitting}
            readOnly={!allowEditing}
            value={sourcePostcode.value}
            onChange={sourcePostcode.onChange}
            isRequired={true}
          />

          <CommonTextInput
            controlId={'sourceCountry'}
            label={'task.sourceCountry'}
            hasError={
              (sourceError && sourceCountry.pristine) ||
              (sourceCountry.touched && sourceCountry.error)
            }
            disabled={submitting}
            readOnly={!allowEditing}
            value={sourceCountry.value}
            onChange={sourceCountry.onChange}
            isRequired={true}
          />

          <CommonTextInput
            controlId={'sourcePhone'}
            label={'task.sourcePhone'}
            hasError={
              (sourceError && sourcePhone.pristine) ||
              (sourcePhone.touched && sourcePhone.error)
            }
            disabled={submitting}
            readOnly={!allowEditing}
            value={sourcePhone.value}
            onChange={sourcePhone.onChange}
            isRequired={true}
          />

          <CommonTextInput
            controlId={'sourceEmail'}
            label={'task.sourceEmail'}
            hasError={
              (sourceError && sourceEmail.pristine) ||
              (sourceEmail.touched && sourceEmail.error)
            }
            disabled={submitting}
            readOnly={!allowEditing}
            value={sourceEmail.value}
            onChange={sourceEmail.onChange}
            isRequired={true}
          />

          {/* Pickup instructions are for individual tasks only under mutlidrop: */}
          {!isMultidrop && (
            <InstructionsInput
              controlId={'pickupInstructions'}
              label={'task.pickupInstructions'}
              hasError={
                (sourceError && pickupInstructions.pristine) ||
                (pickupInstructions.touched && pickupInstructions.error)
              }
              disabled={submitting}
              readOnly={!allowEditing}
              value={pickupInstructions.value}
              onChange={pickupInstructions.onChange}
            />
          )}
        </Panel.Body>
      </Panel>
    </Col>
  )
}

const AddressPanelHeading = ({ title, eta, iconClassName }) => (
  <Panel.Heading>
    <span className='iconContainer'>
      <i className={`fa ${iconClassName} fa-sm icon`} />
    </span>
    <FormattedMessage id={title} />
    {eta ? (
      <span className='pull-right'>
        <i className='fa fa-time spaceAfter' />
        {moment(eta).fromNow()}
      </span>
    ) : null}
  </Panel.Heading>
)

const AddressNameInput = ({
  controlId,
  merchantId, // merchantId.value
  disabled, // submitting
  readOnly, // !allowEditing
  controller,
  onAddressSuggestionSelect
}) => (
  <FormGroup
    controlId={controlId}
    validationState={controller.touched && controller.error ? 'error' : null}
  >
    <ControlLabel>
      <FormattedMessage id={`task.${controlId}`} />
      <span className='required-field-mark'>*</span>
    </ControlLabel>
    <div className='AddressAutocompleteContainer'>
      <DebouncedControl
        value={controller.value}
        type='text'
        disabled={disabled}
        readOnly={readOnly}
        autoComplete='off'
        onChange={controller.onChange}
        onBlur={controller.onBlur}
        onFocus={controller.onFocus}
      />
      <AddressAutocomplete
        active={controller.active}
        left={0}
        onSelect={onAddressSuggestionSelect}
        merchantId={merchantId.value}
        query={controller.value}
      />
    </div>
  </FormGroup>
)

const AddressInput = ({
  controlId,
  label,
  hasError,
  disabled,
  readOnly,
  value,
  onChange
}) => (
  <FormGroup controlId={controlId} validationState={hasError ? 'error' : null}>
    <ControlLabel>
      <FormattedMessage id={label} />
      <span className='required-field-mark'>*</span>
    </ControlLabel>
    <DebouncedControl
      componentClass='textarea'
      rows={2}
      autoComplete='off'
      disabled={disabled}
      readOnly={!readOnly}
      value={value}
      onChange={onChange}
    />
  </FormGroup>
)

const CommonTextInput = ({
  controlId,
  label,
  hasError,
  disabled,
  readOnly,
  value,
  onChange,
  isRequired = false
}) => (
  <FormGroup controlId={controlId} validationState={hasError ? 'error' : null}>
    <ControlLabel>
      <FormattedMessage id={label} />
      {isRequired ? <span className='required-field-mark'>*</span> : null}
    </ControlLabel>
    <DebouncedControl
      type='text'
      autoComplete='off'
      {...{
        value,
        onChange,
        disabled,
        readOnly
      }}
    />
  </FormGroup>
)

const InstructionsInput = ({
  controlId,
  label,
  hasError,
  disabled,
  readOnly,
  value,
  onChange
}) => (
  <FormGroup controlId={controlId} validationState={hasError ? 'error' : null}>
    <ControlLabel>
      <FormattedMessage id={label} />
    </ControlLabel>
    <DebouncedControl
      componentClass='textarea'
      {...{
        value,
        onChange,
        disabled,
        readOnly
      }}
    />
    <div className='characterCounter'>{`(${value.length} characters)`}</div>
  </FormGroup>
)

const DestinationCol = ({
  taskType,
  etaDropoff,
  destinationName,
  submitting,
  allowEditing,
  onAddressSuggestionSelect,
  merchantId,
  destinationError,
  destinationAddress,
  destinationCity,
  destinationPostcode,
  destinationCountry,
  destinationPhone,
  destinationEmail,
  pickupInstructions,
  deliveryInstructions
}) => {
  return (
    <Col>
      <Panel className='addressPanel customPanel'>
        <AddressPanelHeading
          title='map.tasks.modal.destinationTitle'
          eta={etaDropoff}
          iconClassName='fa-map-marker-alt'
        />

        <Panel.Body>
          <AddressNameInput
            {...{
              controlId: 'destinationName',
              merchantId,
              disabled: submitting,
              readOnly: !allowEditing,
              controller: destinationName,
              onAddressSuggestionSelect
            }}
          />

          <AddressInput
            {...{
              controlId: 'destinationAddress',
              label: 'task.destinationAddress',
              hasError:
                (destinationError && destinationAddress.pristine) ||
                (destinationAddress.touched && destinationAddress.error),
              disabled: submitting,
              readOnly: allowEditing,
              value: destinationAddress.value,
              onChange: destinationAddress.onChange,
              isRequired: true
            }}
          />

          <CommonTextInput
            controlId='destinationCity'
            label='task.destinationCity'
            hasError={
              (destinationError && destinationCity.pristine) ||
              (destinationCity.touched && destinationCity.error)
            }
            disabled={submitting}
            readOnly={!allowEditing}
            value={destinationCity.value}
            onChange={destinationCity.onChange}
            isRequired={true}
          />

          <CommonTextInput
            controlId='destinationPostcode'
            label='task.destinationPostcode'
            hasError={
              (destinationError && destinationPostcode.pristine) ||
              (destinationPostcode.touched && destinationPostcode.error)
            }
            disabled={submitting}
            readOnly={!allowEditing}
            value={destinationPostcode.value}
            onChange={destinationPostcode.onChange}
            isRequired={true}
          />

          <CommonTextInput
            controlId='destinationCountry'
            label='task.destinationCountry'
            hasError={
              (destinationError && destinationCountry.pristine) ||
              (destinationCountry.touched && destinationCountry.error)
            }
            disabled={submitting}
            readOnly={!allowEditing}
            value={destinationCountry.value}
            onChange={destinationCountry.onChange}
          />

          <CommonTextInput
            controlId='destinationPhone'
            label='task.destinationPhone'
            hasError={
              (destinationError && destinationPhone.pristine) ||
              (destinationPhone.touched && destinationPhone.error)
            }
            disabled={submitting}
            readOnly={!allowEditing}
            value={destinationPhone.value}
            onChange={destinationPhone.onChange}
            isRequired={true}
          />

          <CommonTextInput
            controlId='destinationEmail'
            label='task.destinationEmail'
            hasError={
              (destinationError && destinationEmail.pristine) ||
              (destinationEmail.touched && destinationEmail.error)
            }
            disabled={submitting}
            readOnly={!allowEditing}
            value={destinationEmail.value}
            onChange={destinationEmail.onChange}
            isRequired={true}
          />

          {taskType === 'multidropParcel' && (
            <InstructionsInput
              controlId={'pickupInstructions'}
              label={'task.pickupInstructions'}
              hasError={pickupInstructions.error ? true : false}
              disabled={submitting}
              readOnly={!allowEditing}
              value={pickupInstructions.value}
              onChange={pickupInstructions.onChange}
            />
          )}

          <InstructionsInput
            controlId={'deliveryInstructions'}
            label={'task.deliveryInstructions'}
            hasError={false}
            disabled={submitting}
            readOnly={!allowEditing}
            value={deliveryInstructions.value}
            onChange={deliveryInstructions.onChange}
          />
        </Panel.Body>
      </Panel>
    </Col>
  )
}

const ParcelPropertiesRow = ({
  handleParcelSizePresetChange,
  handleParcelWeightPresetChange,
  handleParcelWeightPresetBlur,
  customParcelLengthInputRef,
  parcelValueInputRef,
  showCustomWeightInput,
  showCustomSizeInputs,
  parcelSizePresetsValue,
  parcelWeightPresetsValue,
  submitting,
  allowEditing,
  parcelLength,
  parcelWidth,
  parcelHeight,
  parcelWeight,
  parcelValue,
  parcelFragile,
  parcelDoNotRotate,
  parcelContainsLiquid,
  parcelContainsHotFood,
  intl
}) => (
  <Row>
    <Col sm={12}>
      <Panel className='customPanel'>
        <Panel.Heading>
          <span className='iconContainer'>
            <i className='fa fa-cube fa-sm icon' />
          </span>
          <FormattedMessage id='map.tasks.modal.parcelSpecifications' />
        </Panel.Heading>

        <Panel.Body>
          <Grid fluid className='form-horizontal'>
            {/* parcel size */}
            <ParcelSizeGroup
              {...{
                parcelSizePresetsValue,
                showCustomSizeInputs,
                submitting,
                allowEditing,
                handleParcelSizePresetChange,
                customParcelLengthInputRef,
                parcelLength,
                parcelWidth,
                parcelHeight,
                intl
              }}
            />

            {/* parcelWeight */}
            <ParcelWeightGroup
              {...{
                handleParcelWeightPresetChange,
                handleParcelWeightPresetBlur,
                showCustomWeightInput,
                parcelWeightPresetsValue,
                submitting,
                allowEditing,
                parcelWeight
              }}
            />

            {/* parcelValue */}
            <ParcelValueRow
              {...{
                submitting,
                allowEditing,
                parcelValue,
                parcelValueInputRef
              }}
            />

            {/* parcel handling instructions */}
            <ParcelHandlingInstructionsRow
              {...{
                submitting,
                allowEditing,
                parcelFragile,
                parcelDoNotRotate,
                parcelContainsLiquid,
                parcelContainsHotFood
              }}
            />
          </Grid>
        </Panel.Body>
      </Panel>
    </Col>
  </Row>
)

const ParcelSizeGroup = ({
  parcelSizePresetsValue,
  showCustomSizeInputs,
  submitting,
  allowEditing,
  handleParcelSizePresetChange,
  customParcelLengthInputRef,
  parcelLength,
  parcelWidth,
  parcelHeight,
  intl
}) =>
  parcelSizePresetsValue !== 'custom' && !showCustomSizeInputs ? (
    <FormGroup controlId='parcelSize'>
      <Col sm={4} componentClass={ControlLabel}>
        <FormattedMessage id='task.parcelSize' />
      </Col>
      <Col sm={8}>
        <FormControl
          componentClass='select'
          disabled={submitting || !allowEditing}
          onChange={handleParcelSizePresetChange}
          value={parcelSizePresetsValue}
        >
          {PARCEL_SIZE_PRESETS.map((size) => (
            <FormattedMessage id={`task.size.${size}`} key={size}>
              {renderLabel(size)}
            </FormattedMessage>
          ))}
          <FormattedMessage id='task.size.custom'>
            {renderCustomLabel()}
          </FormattedMessage>
        </FormControl>
      </Col>
    </FormGroup>
  ) : (
    <Row>
      <Col sm={4} className='alignedLabel'>
        <FormattedMessage id='task.parcelSize' />
      </Col>
      <Col sm={8}>
        <Grid fluid>
          <Row>
            <Col sm={4}>
              <FormGroup controlId='parcelLength'>
                <ControlLabel className='sr-only'>
                  <FormattedMessage id='task.parcelLength' />
                </ControlLabel>
                <InputGroup>
                  <FormControl
                    ref={customParcelLengthInputRef}
                    type='number'
                    disabled={submitting || !allowEditing}
                    placeholder={intl.formatMessage({
                      id: 'task.parcelLength'
                    })}
                    min={0}
                    max={133}
                    step='any'
                    {...getFormControlPropsFromReduxForm(parcelLength)}
                  />
                  <InputGroup.Addon>
                    <FormattedMessage id='task.parcelLengthUnit' />
                  </InputGroup.Addon>
                </InputGroup>
              </FormGroup>
            </Col>

            <Col sm={4}>
              <FormGroup controlId='parcelWidth'>
                <ControlLabel className='sr-only'>
                  <FormattedMessage id='task.parcelWidth' />
                </ControlLabel>
                <InputGroup>
                  <FormControl
                    type='number'
                    disabled={submitting || !allowEditing}
                    placeholder={intl.formatMessage({ id: 'task.parcelWidth' })}
                    min={0}
                    max={133}
                    step='any'
                    {...getFormControlPropsFromReduxForm(parcelWidth)}
                  />
                  <InputGroup.Addon>
                    <FormattedMessage id='task.parcelLengthUnit' />
                  </InputGroup.Addon>
                </InputGroup>
              </FormGroup>
            </Col>

            <Col sm={4}>
              <FormGroup controlId='parcelHeight'>
                <ControlLabel className='sr-only'>
                  <FormattedMessage id='task.parcelHeight' />
                </ControlLabel>
                <InputGroup>
                  <FormControl
                    type='number'
                    disabled={submitting || !allowEditing}
                    placeholder={intl.formatMessage({
                      id: 'task.parcelHeight'
                    })}
                    min={0}
                    max={133}
                    step='any'
                    {...getFormControlPropsFromReduxForm(parcelHeight)}
                  />
                  <InputGroup.Addon>
                    <FormattedMessage id='task.parcelLengthUnit' />
                  </InputGroup.Addon>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
        </Grid>
      </Col>
    </Row>
  )

const renderLabel = (value) => (label) => <option value={value}>{label}</option>

const renderCustomLabel = () => (label) => (
  <option value='custom'>{label}</option>
)

const ParcelWeightGroup = ({
  handleParcelWeightPresetChange,
  handleParcelWeightPresetBlur,
  parcelWeightPresetsValue,
  showCustomWeightInput,
  submitting,
  allowEditing,
  parcelWeight
}) => (
  <FormGroup controlId='parcelWeight'>
    <Col sm={4} componentClass={ControlLabel}>
      <FormattedMessage id='task.parcelWeight' />
    </Col>
    <Col sm={8}>
      {parcelWeightPresetsValue !== 'custom' && !showCustomWeightInput ? (
        <FormControl
          componentClass='select'
          disabled={submitting || !allowEditing}
          {...getFormControlPropsFromReduxForm(parcelWeight)}
          onChange={handleParcelWeightPresetChange}
          onBlur={handleParcelWeightPresetBlur}
          value={parcelWeightPresetsValue}
        >
          {PARCEL_WEIGHT_PRESETS.map((weight) => (
            <FormattedMessage id={`task.weight.${weight}`} key={weight}>
              {renderLabel(weight)}
            </FormattedMessage>
          ))}
          <FormattedMessage id={'task.weight.custom'}>
            {renderCustomLabel()}
          </FormattedMessage>
        </FormControl>
      ) : (
        <InputGroup>
          <FormControl
            type='number'
            disabled={submitting || !allowEditing}
            min={0}
            step='any'
            {...getFormControlPropsFromReduxForm(parcelWeight)}
          />
          <InputGroup.Addon>
            <FormattedMessage id='task.parcelWeightUnit' />
          </InputGroup.Addon>
        </InputGroup>
      )}
    </Col>
  </FormGroup>
)

const ParcelValueRow = ({
  submitting,
  allowEditing,
  parcelValue,
  parcelValueInputRef
}) => (
  <FormGroup controlId='parcelValue'>
    <Col sm={4} componentClass={ControlLabel}>
      <FormattedMessage id='task.parcelValue' />
    </Col>
    <Col sm={8}>
      <InputGroup>
        <DebouncedControl
          ref={parcelValueInputRef}
          type='number'
          disabled={submitting || !allowEditing}
          min={0}
          step='any'
          {...getFormControlPropsFromReduxForm(parcelValue)}
        />
        <InputGroup.Addon>
          <FormattedMessage id='task.parcelValueUnit' />
        </InputGroup.Addon>
      </InputGroup>
    </Col>
  </FormGroup>
)

const ParcelHandlingInstructionsRow = ({
  submitting,
  allowEditing,
  parcelFragile,
  parcelDoNotRotate,
  parcelContainsLiquid,
  parcelContainsHotFood
}) => (
  <Row>
    <Col sm={4} className='alignedLabel'>
      <FormattedMessage id='task.parcelHandling' />
    </Col>
    <Col sm={4} className='parcelPropsColumn'>
      <FormGroup controlId='parcelFragile'>
        <Checkbox
          disabled={submitting || !allowEditing}
          {...getFormControlPropsFromReduxForm(parcelFragile)}
        >
          <FormattedMessage id='task.parcelFragile' />
        </Checkbox>
      </FormGroup>

      <FormGroup controlId='parcelDoNotRotate'>
        <Checkbox
          disabled={submitting || !allowEditing}
          {...getFormControlPropsFromReduxForm(parcelDoNotRotate)}
        >
          <FormattedMessage id='task.parcelDoNotRotate' />
        </Checkbox>
      </FormGroup>
    </Col>
    <Col sm={4} className='parcelPropsColumn'>
      <FormGroup controlId='parcelContainsLiquid'>
        <Checkbox
          disabled={submitting || !allowEditing}
          {...getFormControlPropsFromReduxForm(parcelContainsLiquid)}
        >
          <FormattedMessage id='task.parcelContainsLiquid' />
        </Checkbox>
      </FormGroup>

      <FormGroup controlId='parcelContainsHotFood'>
        <Checkbox
          disabled={submitting || !allowEditing}
          {...getFormControlPropsFromReduxForm(parcelContainsHotFood)}
        >
          <FormattedMessage id='task.parcelContainsHotFood' />
        </Checkbox>
      </FormGroup>
    </Col>
  </Row>
)

const NotesRow = ({ intl, submitting, allowEditing, taskNotes, newNote }) => (
  <Col sm={12}>
    <Panel className='customPanel'>
      <Panel.Heading>
        <span className='iconContainer'>
          <i className='fa fa-sticky-note fa-xs icon' />
        </span>
        <FormattedMessage id='task.notes' />
      </Panel.Heading>
      <Panel.Body>
        <FormGroup controlId='newNote'>
          <ControlLabel className='sr-only'>
            <FormattedMessage id='map.tasks.modal.newNote' />
          </ControlLabel>
          <DebouncedControl
            componentClass='textarea'
            disabled={submitting}
            readOnly={!allowEditing}
            placeholder={intl.formatMessage({
              id: 'map.tasks.modal.newNote'
            })}
            {...getFormControlPropsFromReduxForm(newNote)}
          />
        </FormGroup>

        <ListGroup className='selectable'>
          {!taskNotes || taskNotes.size === 0 ? (
            <FormattedMessage id='map.tasks.modal.noNotes' />
          ) : (
            taskNotes
              .map((note, index) => <Note key={index} note={note} />)
              .toJS()
          )}
        </ListGroup>
      </Panel.Body>
    </Panel>
  </Col>
)

const ActionLogRow = ({ actionLog }) => (
  <Row>
    <Col sm={12}>
      <Panel className='customPanel'>
        <Panel.Heading>
          <span className='iconContainer'>
            <i className='fa fa-info fa-xs icon' />
          </span>
          <FormattedMessage id='task.actionLog' />
        </Panel.Heading>
        <Panel.Body>
          <TaskActionLog actionLog={actionLog} />
        </Panel.Body>
      </Panel>
    </Col>
  </Row>
)

function formatDistanceAndDuration({ distanceMeters, durationSeconds }) {
  return `${(distanceMeters / 1000).toFixed(1)} km / ${(
    durationSeconds / 60
  ).toFixed()} mins`
}

export {
  ActionLogRow,
  NotesRow,
  ParcelHandlingInstructionsRow,
  ParcelValueRow,
  ParcelWeightGroup,
  ParcelSizeGroup,
  ParcelPropertiesRow,
  DestinationCol,
  InstructionsInput,
  CommonTextInput,
  AddressInput,
  AddressNameInput,
  AddressPanelHeading,
  SourceCol,
  ScheduleRow,
  GeomatrixRow,
  CurrentStatusInput,
  HeaderFieldsGroup,
  Note,
  renderLabel,
  renderCustomLabel,
  formatDistanceAndDuration
}

export default compose(withRouter, injectIntl)(TaskFormFields)
