import { Map as ImmutableMap } from 'immutable'

import {
  SOCKET_CONNECT,
  SOCKET_CONNECT_ATTEMPT,
  SOCKET_DISCONNECT,
  SOCKET_ERROR,
  SOCKET_RECONNECT,
  SOCKET_RECONNECT_ATTEMPT,
  SOCKET_RECONNECT_ERROR,
  SOCKET_RECONNECT_FAILED,
  SOCKET_RECONNECTING
} from '../actions/SocketConnectionActions'

const initialState = ImmutableMap({
  connected: false,
  connectionInProgress: false,
  lastError: null
})

export default function SocketConnectionReducer(state = initialState, action) {
  switch (action.type) {
    case SOCKET_CONNECT:
    case SOCKET_RECONNECT:
      return state.merge({
        // socket: action.socket || state.get('socket'),
        connected: true,
        connectionInProgress: false,
        lastError: null
      })

    case SOCKET_ERROR:
    case SOCKET_DISCONNECT:
    case SOCKET_RECONNECT_FAILED:
      return state.merge({
        connected: false,
        connectionInProgress: false,
        lastError: action.error
      })

    case SOCKET_RECONNECT_ERROR:
      return state.merge({
        lastError: action.error
      })

    case SOCKET_CONNECT_ATTEMPT:
    case SOCKET_RECONNECT_ATTEMPT:
    case SOCKET_RECONNECTING:
      return state.merge({
        connected: false,
        connectionInProgress: true
      })

    default:
      return state
  }
}
