import { is, Map, OrderedSet, Set } from 'immutable'
import React from 'react'
import { generatePath, RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import { TASK_SECTION, VIEW_TYPE } from '../../../../constants'
import { Routes } from '../../../../navigation/Routes'
import { getViewType } from '../../../../util/task'
import PrintButton from '../Task/PrintButton/PrintButton'
import Task from '../Task/Task'

import './Tasks.scss'

interface InnerProps extends RouteComponentProps<{ viewType: VIEW_TYPE }> {}

interface OutterProps {
  tasks: {
    taskIds: Set<string>
    groupedTaskIds: {
      [key: string]: Set<string> | Map<string, Set<string>>
    }
  }
  sectionId: TASK_SECTION
}

type Props = InnerProps & OutterProps

class TasksList extends React.Component<
  {
    viewType: VIEW_TYPE
    taskIds: OrderedSet<any>
    sectionId: TASK_SECTION
    openDetail(taskId: string): void
    openChat(taskId: string): void
  },
  {}
> {
  shouldComponentUpdate(
    nextProps: Readonly<{
      viewType: VIEW_TYPE
      taskIds: OrderedSet<any>
      sectionId: TASK_SECTION
      openDetail(taskId: string): void
    }>,
    nextState: Readonly<{}>,
    nextContext: any
  ): boolean {
    return (
      !is(this.props.taskIds, nextProps.taskIds) ||
      this.props.viewType !== nextProps.viewType ||
      this.props.sectionId !== nextProps.sectionId ||
      this.props.openDetail !== nextProps.openDetail
    )
  }

  render() {
    const { viewType, sectionId, taskIds, openDetail, openChat } = this.props

    return taskIds.map((taskId) => {
      return (
        <Task
          key={taskId}
          taskId={taskId}
          viewType={viewType}
          sectionId={sectionId}
          openDetail={openDetail}
          openChat={openChat}
        />
      )
    })
  }
}

class Tasks extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Props): boolean {
    return (
      !is(this.props.tasks, nextProps.tasks) ||
      this.props.sectionId !== nextProps.sectionId ||
      this.props.location.pathname !== nextProps.location.pathname
    )
  }

  private openDetail = (taskId) => {
    this.props.history.push(
      generatePath(Routes.Map.TaskDetail, {
        taskId,
        viewType: this.props.match.params.viewType
      })
    )
  }

  private openChat = (taskId) => {
    this.props.history.push(
      generatePath(Routes.Map.TaskChat, {
        taskId,
        viewType: this.props.match.params.viewType
      })
    )
  }

  renderTasks = (taskIds, viewType: VIEW_TYPE, sectionId: TASK_SECTION) => {
    return (
      <TasksList
        taskIds={taskIds}
        viewType={viewType}
        sectionId={sectionId}
        openDetail={this.openDetail}
        openChat={this.openChat}
      />
    )
  }

  renderGroupTasks = (
    groupedTaskIds,
    viewType: VIEW_TYPE,
    sectionId: TASK_SECTION
  ) => {
    return groupedTaskIds
      .map((tasks, groupName) => {
        if (Map.isMap(tasks)) {
          return (
            <React.Fragment key={groupName}>
              <div className='groupName' data-testid='group-name'>
                {viewType === VIEW_TYPE.SENDERS && (
                  <PrintButton
                    merchantName={groupName}
                    tasksGroupedByDriver={tasks}
                  />
                )}
                {groupName}
              </div>
              {tasks
                .map((tasksId, driverName) => {
                  return (
                    <React.Fragment key={driverName}>
                      <div className='driverName' data-testid='driver-name'>
                        {viewType === VIEW_TYPE.SENDERS && (
                          <PrintButton
                            tasksGroupedByDriver={tasks}
                            driverName={driverName}
                            merchantName={groupName}
                          />
                        )}
                        {driverName}
                      </div>
                      {this.renderTasks(tasksId, viewType, sectionId)}
                    </React.Fragment>
                  )
                })
                .toArray()}
            </React.Fragment>
          )
        }

        return (
          <React.Fragment key={groupName}>
            <div className='groupName' data-testid='group-name'>
              {groupName}
            </div>

            {this.renderTasks(tasks, viewType, sectionId)}
          </React.Fragment>
        )
      })
      .toArray()
  }

  render() {
    const {
      tasks: { taskIds, groupedTaskIds },
      location: { pathname },
      sectionId
    } = this.props
    const viewType = getViewType(pathname)

    return (
      <div className='Tasks'>
        {groupedTaskIds &&
          this.renderGroupTasks(groupedTaskIds, viewType, sectionId)}
        {!groupedTaskIds && this.renderTasks(taskIds, viewType, sectionId)}
      </div>
    )
  }
}

const enhance = compose<InnerProps, OutterProps>(withRouter)
export { Tasks }
export default enhance(Tasks)
