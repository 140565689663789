import React from 'react'
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

const MerchantChoice = ({
  allowUpdateMerchant,
  handleMerchantIdChange,
  merchantList,
  submitting,
  value
}) => (
  <FormGroup
    controlId='merchantId'
    className={'merchantId' + (merchantList.size === 1 ? ' hidden' : '')}
  >
    <ControlLabel className='topLabel'>
      <FormattedMessage id='task.merchantId' />
    </ControlLabel>
    <FormControl
      value={value}
      componentClass='select'
      disabled={!allowUpdateMerchant || submitting}
      onChange={handleMerchantIdChange}
    >
      <MerchantOptions merchantList={merchantList} />
    </FormControl>
  </FormGroup>
)

const MerchantOptions = ({ merchantList }) => (
  <>
    {merchantList
      .sort()
      .map((merchantId) => {
        return (
          <option key={merchantId} value={merchantId}>
            {merchantId}
          </option>
        )
      })
      .toArray()}
  </>
)

export { MerchantOptions }
export default MerchantChoice
