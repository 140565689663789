import { Map as ImmutableMap, Set as ImmutableSet } from 'immutable'
import { difference } from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import MapUiActions from '../../../actions/MapUiActions'
import { VIEW_TYPE } from '../../../constants'
import GoogleMapStyleManager from '../../../util/googleMapStyleManager/googleMapStyleManager'
import DriverTracking from '../DriverTracking/DriverTracking'

import './GoogleMap.scss'
import MapSelector from './GoogleMap.selector'

const LONDON_COORDINATES = { lat: 51.507351, lng: -0.127758 }
const MAP_PADDING = {
  top: 15,
  right: 15,
  bottom: 15,
  left: 15
}

interface Props {
  viewType: VIEW_TYPE
  className?: string
}

interface StateProps {
  markers: ImmutableSet<any>
  infoWindows: ImmutableSet<any>
  polylines: ImmutableSet<any>
  bounds: google.maps.LatLngBounds
  selectedTasks: ImmutableSet<any>
}

interface DispatchProps {
  MapUiActions: typeof MapUiActions
}

class GoogleMap extends React.PureComponent<
  Props & StateProps & DispatchProps
> {
  markers: {
    [id: string]: {
      source: any
      googleMarker: google.maps.Marker
    }
  } = {}
  polylines = []
  infoWindows = {}
  openInfoWindows = []
  map = null
  mapContainer = null

  componentWillReceiveProps(
    nextProps: Readonly<Props & StateProps & DispatchProps>
  ): void {
    const { markers, infoWindows, polylines, selectedTasks } = this.props

    this.updateMarkers(markers, nextProps.markers)
    this.updateInfoWindows(infoWindows, nextProps.infoWindows)
    this.updatePolylines(polylines, nextProps.polylines)

    this.zoomToFit(selectedTasks, nextProps.selectedTasks, nextProps.markers)
  }

  private zoomToFit(selectedTasks, nextSelectedTasks, nextMarkers) {
    if (
      difference(nextSelectedTasks.toArray(), selectedTasks.toArray()).length >
      0
    ) {
      this.mapFit(nextMarkers)
    }
  }

  loadMap(container) {
    this.map = new google.maps.Map(container, {
      center: LONDON_COORDINATES,
      zoom: 13,
      streetViewControl: false,
      mapTypeControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      styles: GoogleMapStyleManager.getStylers(),
      zoomControl: false,
      fullscreenControl: false
    })

    this.map.addListener('click', this.handleMapClick)
  }

  handleMapClick = () => {
    this.closeAllInfoWindows()
    this.props.MapUiActions.deselectAll()
  }

  handleMarkerClick(id, marker, event) {
    this.openInfoWindow(id)
  }

  openInfoWindow(id) {
    // close all infoWindows before opening a new one (only one can be visible)
    this.closeAllInfoWindows()
    this.infoWindows[id].open(this.map)
    this.openInfoWindows.push(id)
  }

  closeAllInfoWindows() {
    while (this.openInfoWindows.length !== 0) {
      const infoWindow = this.infoWindows[this.openInfoWindows.pop()]

      if (infoWindow) {
        infoWindow.close()
      }
    }
  }

  private mapFit(markers) {
    if (markers.size === 0) {
      return
    }

    const bounds = new google.maps.LatLngBounds()

    markers.toJS().map((marker) => {
      bounds.extend(marker.position)
    })

    if (
      window.matchMedia(
        '(max-width: 768px), (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait)'
      ).matches
    ) {
      this.map.fitBounds(bounds, MAP_PADDING)
    } else {
      this.map.fitBounds(bounds, {
        ...MAP_PADDING,
        left: document.getElementById('side-bar').offsetWidth
      })
    }
    this.map.setZoom(Math.min(this.map.getZoom(), 14))
  }

  updateMarkers(
    prevMarkers: ImmutableSet<any>,
    nextMarkers: ImmutableSet<any>
  ) {
    if (prevMarkers === nextMarkers) {
      return
    }

    for (const id of Object.keys(this.markers)) {
      const { source, googleMarker } = this.markers[id]

      if (!nextMarkers.contains(source)) {
        googleMarker.setMap(null)
        delete this.markers[id]
      }
    }

    nextMarkers.forEach((marker: ImmutableMap<any, any>) => {
      const id = this.getId(marker)

      if (!this.markers[id]) {
        if (
          marker.getIn(['position', 'lat']) &&
          marker.getIn(['position', 'lng'])
        ) {
          const googleMarker = new google.maps.Marker(marker.toJS())
          googleMarker.addListener(
            'click',
            this.handleMarkerClick.bind(this, id, marker)
          )
          googleMarker.setMap(this.map)
          this.markers[id] = {
            source: marker,
            googleMarker
          }
        }
      }
    })
  }

  private getId = (infoWindow) =>
    infoWindow.get('_kind') + infoWindow.get('_id')

  updateInfoWindows(prevInfoWindows, nextInfoWindows) {
    if (prevInfoWindows === nextInfoWindows) {
      return
    }

    const removedInfoWindows = prevInfoWindows
      ? prevInfoWindows.subtract(nextInfoWindows)
      : ImmutableSet()
    const addedInfoWindows = nextInfoWindows.subtract(prevInfoWindows)

    removedInfoWindows.forEach((infoWindow) => {
      const id = this.getId(infoWindow)

      delete this.infoWindows[id]
    })

    addedInfoWindows.forEach((infoWindow) => {
      const id = this.getId(infoWindow)
      const iw = infoWindow.toJS()

      if (iw.position.lat && iw.position.lng) {
        this.infoWindows[id] = new google.maps.InfoWindow(iw)
      }
    })
  }

  updatePolylines(prevPolylines, nextPolylines) {
    if (prevPolylines !== nextPolylines) {
      this.polylines.forEach((polyline) => polyline.setMap(null))

      this.polylines = nextPolylines
        .map((polyline) => {
          const newLine = new google.maps.Polyline(polyline.toJS())
          newLine.setMap(this.map)

          return newLine
        })
        .toArray()
    }
  }

  setMapContainer = (elm) => {
    this.mapContainer = elm
    if (elm) {
      this.loadMap(elm)
    }
  }

  render() {
    const { className } = this.props
    return (
      <React.Fragment>
        <div
          id='mapCanvas'
          ref={this.setMapContainer}
          className={`map ${className}`}
        />
        <DriverTracking map={this.map} />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state: any, { viewType }: Props) => {
  const selectors = MapSelector(state, viewType)

  return {
    selectedTasks: state.MapUiReducer.get('selectedTasks'),
    markers: selectors.markers,
    infoWindows: selectors.infoWindows,
    polylines: selectors.polylines,
    bounds: selectors.bounds
  }
}

const mapDispatchToProps = (dispatch) => ({
  MapUiActions: bindActionCreators(MapUiActions, dispatch)
})

export { mapStateToProps, mapDispatchToProps }
export default connect<StateProps, DispatchProps, Props>(
  mapStateToProps,
  mapDispatchToProps
)(GoogleMap)
