import { fromJS } from 'immutable'
import { createSelector } from 'reselect'

import TaskAssignments from '../../../../../selectors/TaskAssignments/TaskAssignments'

import QuoteDialogSelector from './QuoteDialog.selector'

const isTaskNew = (state, props) => {
  const taskId = props.match?.params?.taskId || props.taskId

  return !taskId || taskId === 'new'
}

const isTaskAssigned = (state, props) => {
  const taskId = props.match?.params?.taskId || props.taskId
  const assignments = TaskAssignments(state, props)
  return assignments.get(taskId) != null
}

const getUser = (state) => {
  return state.AuthReducer.get('user')
}

const getMerchants = (state) => {
  return state.services.getIn(['merchants', 'all', fromJS({})])
}

const getOngoingTask = (state, props) => {
  const taskId = props.match?.params?.taskId || props.taskId

  return state.services.getIn(['tasks', 'all', fromJS({}), 'data', taskId])
}

const getTaskSearchResult = (state, props) => {
  const taskId = props.match?.params?.taskId || props.taskId

  return (
    state.services.getIn([
      'archivedTasks',
      'findById',
      state.MapUiReducer.get('lastQueryParams'),
      'data',
      taskId
    ]) ||
    state.services.getIn([
      'archivedTasks',
      'findById',
      fromJS({ keyword: taskId }),
      'data',
      taskId
    ])
  )
}

const combiner = (
  quotes,
  taskIsNew,
  taskIsAssigned,
  user,
  merchants,
  ongoingTask,
  taskSearchResult
) => {
  const task = ongoingTask || taskSearchResult
  const canUserEditTask = user.get('administrator') || user.get('canEditTask')
  return Object.assign({}, quotes, {
    user,
    merchants,
    merchantList: user.get('merchants'),
    task,
    taskIsAssigned,
    isTaskArchived: ongoingTask == null && taskSearchResult != null,
    allowRepeat: !taskIsNew,
    allowEditing:
      taskIsNew ||
      (ongoingTask != null && !ongoingTask.get('onDemand')) ||
      canUserEditTask
  })
}

export {
  isTaskNew,
  isTaskAssigned,
  getUser,
  getMerchants,
  getOngoingTask,
  getTaskSearchResult,
  combiner
}

export default createSelector(
  QuoteDialogSelector,
  isTaskNew,
  isTaskAssigned,
  getUser,
  getMerchants,
  getOngoingTask,
  getTaskSearchResult,
  combiner
)
