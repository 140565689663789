import { fromJS, List } from 'immutable'
import { createSelector } from 'reselect'

import { TASK_SECTION, VIEW_TYPE } from '../../../../constants'
import { ViewState } from '../../../../types'
import viewStateUtils from '../../../../util/viewState'

const getQueryParams = (state) => {
  return state.MapUiReducer.get('lastQueryParams')
}

const getIfDriversLoaded = (state) => {
  const query = getQueryParams(state)
  if (query == null) {
    return !!state.services.getIn(['drivers', 'all', fromJS({}), 'ready'])
  } else {
    return true
  }
}

const getIfTasksLoaded = (state) => {
  const query = getQueryParams(state)
  if (query == null) {
    return !!state.services.getIn(['tasks', 'all', fromJS({}), 'ready'])
  } else {
    return !!state.services.getIn(['archivedTasks', 'findById', query, 'ready'])
  }
}

const getViewState = (state, viewType) => {
  return viewStateUtils.getViewState(viewType, state)
}

const getViewType = (state, viewType) => {
  return viewType
}

const getSections = (viewType: VIEW_TYPE, viewState: ViewState) => {
  if (
    viewType === VIEW_TYPE.TASKS &&
    viewState.checkedSeparatePendingAndOngoing
  ) {
    return List([TASK_SECTION.PENDING, TASK_SECTION.ON_GOING])
  }

  return List([TASK_SECTION.ON_GOING])
}

const combiner = (
  queryParams,
  driversLoaded,
  tasksLoaded,
  viewState: ViewState,
  viewType: VIEW_TYPE
) => {
  if (queryParams == null) {
    return {
      sections: getSections(viewType, viewState),
      driversLoaded,
      tasksLoaded
    }
  } else {
    // If there are search terms entered in the search box, show the results
    return {
      sections: List([TASK_SECTION.SEARCH_RESULTS]),
      driversLoaded,
      tasksLoaded
    }
  }
}

export {
  getQueryParams,
  getIfDriversLoaded,
  getIfTasksLoaded,
  getViewState,
  getViewType,
  combiner
}

export default createSelector(
  getQueryParams,
  getIfDriversLoaded,
  getIfTasksLoaded,
  getViewState,
  getViewType,
  combiner
)
