import { Set as ImmutableSet } from 'immutable'
import React, { MouseEventHandler } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { connect } from 'react-redux'

import { batchUpdateTaskStatus } from '../../../../actions/MapUiActions'
import { i18n } from '../../../../util/i18n'
import StatusSelect from '../../task/edit/StatusSelect/StatusSelect'

interface Props {
  close: () => void | MouseEventHandler<Button>
  selectedTasks: ImmutableSet<any>
  batchUpdateTaskStatus: (selectedTasks, value) => void
}

class ChangeStatusDialog extends React.Component<Props> {
  private selectInputRef = React.createRef<HTMLSelectElement>()
  submit = () => {
    this.props.batchUpdateTaskStatus(
      this.props.selectedTasks,
      this.selectInputRef.current.value
    )
    this.props.close()
  }

  render() {
    return (
      <Modal
        animation={false}
        className='UploadDialog'
        show
        onHide={this.props.close}
      >
        <Modal.Header>
          <Modal.Title>{i18n`Change status`}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            <strong>{i18n`Selected ${this.props.selectedTasks.size} tasks.`}</strong>{' '}
            {i18n`Update status to:`}
          </p>
          <div className='inputContainer'>
            <StatusSelect inputRef={this.selectInputRef} />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={this.props.close}>
            <i className='fa fa-times-circle spaceAfter' />
            {i18n`Cancel`}
          </Button>
          <Button onClick={this.submit}>{i18n`Save`}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

const mapStateToProps = (state: any) => ({
  selectedTasks: state.MapUiReducer.get('selectedTasks')
})

const mapDispatchToProps = (dispatch) => ({
  batchUpdateTaskStatus(tasks, newStatus) {
    batchUpdateTaskStatus(tasks, newStatus)(dispatch)
  }
})

export { mapStateToProps, mapDispatchToProps }
export default connect(mapStateToProps, mapDispatchToProps)(ChangeStatusDialog)
