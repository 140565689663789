import { fromJS } from 'immutable'

import {
  REQUEST_SENT,
  RESPONSE,
  RESPONSE_BATCH
} from '../../actions/services/ServiceActions'
import log from '../../util/logger'

const initialState = fromJS({
  sent: false,
  responseReceived: false,
  generatedKeys: [],
  updated: 0,
  deleted: 0,
  lastError: null,
  useCache: false,
  resendOnReconnect: false
})

export default function WriteRequestReducer(
  state = initialState,
  action: any = {}
) {
  /* eslint no-unreachable: 0 */
  const { responseType, responseData } = action
  switch (action.type) {
    case REQUEST_SENT:
      return initialState.set('sent', true)

    case RESPONSE_BATCH:
      return action.batch.reduce(WriteRequestReducer, state)

    case RESPONSE:
      state = state.set('responseReceived', true)
      switch (responseType) {
        case 'state':
          return state.withMutations((aState) => {
            if (responseData.generatedKeys != null) {
              aState.merge({
                generatedKeys: responseData.generatedKeys,
                lastError: null
              })
            }
            if (responseData.inserted != null) {
              aState.merge({
                inserted: responseData.inserted,
                lastError: null
              })
            }
            if (responseData.updated != null) {
              aState.merge({
                updated: responseData.updated,
                lastError: null
              })
            }
            if (responseData.deleted != null) {
              aState.merge({
                deleted: responseData.deleted,
                lastError: null
              })
            }
          })

        case 'error':
          return state.set('lastError', responseData)

        default:
          log('Received unexpected response type: ', action)
          return state
      }

    default:
      return state
  }
}
