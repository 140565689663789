import React from 'react'

export default ({ quote }) => {
  const vendor = quote.get('vendor')
  const directText = quote.get('isDirect') ? ' (direct)' : ''
  const experimentalText = quote.get('isExperimental') ? ' [experimental!]' : ''
  return (
    <>
      {vendor}
      {directText}
      {experimentalText}
    </>
  )
}
