import React, { Component } from 'react'
import { Button, ListGroup, ListGroupItem } from 'react-bootstrap'
import { Subscription } from 'rxjs'

import cannedResponse from '../../../services/chat/cannedResponse'
import { CannedResponse } from '../../../services/chat/types'

import CannedResponseFormDialog from './CannedResponseFormDialog'
import './CannedResponses.scss'
import { CannedResponseSubmit, FormAction, State } from './types'

class CannedResponses extends Component<{}, State> {
  subscription: Subscription = null

  constructor(props) {
    super(props)

    this.state = {
      formAction: FormAction.NONE,
      cannedResponses: [],
      loading: true
    }
  }

  componentDidMount(): void {
    this.setState({ loading: true })

    this.subscription = cannedResponse.subject.subscribe((value) => {
      this.setState({
        cannedResponses: value.items,
        loading: !value.loaded
      })
    })
  }

  componentWillUnmount(): void {
    this.subscription?.unsubscribe()
  }

  onAddNew = () => {
    this.setState({
      formAction: FormAction.ADD
    })
  }

  onEdit = (response: CannedResponse) => () => {
    this.setState({
      editingResponse: response,
      formAction: FormAction.EDIT
    })
  }

  onDelete = (response: CannedResponse) => () => {
    if (
      !window.confirm(
        `Are you sure to delete canned response: "/${response.shortcut}"?`
      )
    ) {
      return
    }

    cannedResponse.remove(response.id)
  }

  _onSubmit = ({
    formAction,
    content,
    shortcut,
    editingId
  }: CannedResponseSubmit) => {
    switch (formAction) {
      case FormAction.ADD: {
        cannedResponse.add(shortcut, content)

        this.setState({ formAction: FormAction.NONE })
        break
      }

      case FormAction.EDIT: {
        cannedResponse.edit(editingId, shortcut, content)

        this.setState({
          editingResponse: undefined,
          formAction: FormAction.NONE
        })
        break
      }

      default:
        break
    }
  }

  _onHide = () => {
    this.setState({ formAction: FormAction.NONE, editingResponse: undefined })
  }

  render() {
    const { formAction, cannedResponses, loading, editingResponse } = this.state

    if (loading) {
      return <div className='manageCannedResponses'>Loading...</div>
    }

    return (
      <div className='manageCannedResponses'>
        <ListGroup className='list'>
          {cannedResponses.map((response) => {
            const { content, shortcut } = response

            return (
              <ListGroupItem key={shortcut} className='item'>
                <div className='shortcut'>/{shortcut}</div>
                <div className='content'>{content}</div>

                <div className='buttonContainer'>
                  <Button
                    bsStyle='primary'
                    bsSize='small'
                    className='spaceAfter'
                    onClick={this.onEdit(response)}
                  >
                    Edit
                  </Button>
                  <Button
                    bsStyle='danger'
                    bsSize='small'
                    onClick={this.onDelete(response)}
                  >
                    Delete
                  </Button>
                </div>
              </ListGroupItem>
            )
          })}
        </ListGroup>

        <Button bsStyle='primary' onClick={this.onAddNew}>
          <i className='fa fa-plus spaceAfter' /> Add new canned response
        </Button>

        <CannedResponseFormDialog
          formAction={formAction}
          onHide={this._onHide}
          onSubmitData={this._onSubmit}
          editingId={editingResponse?.id}
          initialValues={
            editingResponse
              ? {
                  content: editingResponse.content,
                  shortcut: editingResponse.shortcut
                }
              : {}
          }
        />
      </div>
    )
  }
}

export default CannedResponses
