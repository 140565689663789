import { fromJS, List } from 'immutable'
import { createSelector } from 'reselect'

import { TASK_SECTION } from '../../../../constants'
import TasksListByViewType from '../../../../selectors/map/tasks/TasksListByViewType/TasksListByViewType'

const haveTasksLoaded = (state) => {
  return !!state.services.getIn(['tasks', 'all', fromJS({}), 'ready'])
}

const getTasks = (state, props) => {
  const { viewType, sectionId, sectionState } = props

  if (props.sectionId === TASK_SECTION.SEARCH_RESULTS) {
    const tasks = state.services.getIn([
      'archivedTasks',
      'findById',
      state.MapUiReducer.get('lastQueryParams'),
      'data'
    ])

    const taskIds = (tasks && tasks.keySeq()) || fromJS([])

    return {
      numberOfPendingTasks: 0,
      numberOfOnGoingTasks: 0,
      taskIds
    }
  }

  return TasksListByViewType(state, { viewType, sectionId, sectionState })
}

const emptyData = { taskIds: List() }
const combiner = (tasksLoaded, tasks) => {
  return {
    tasks: !tasksLoaded ? emptyData : tasks
  }
}

export { haveTasksLoaded, getTasks, combiner }
export default createSelector(haveTasksLoaded, getTasks, combiner)
