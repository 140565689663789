import { PureComponent } from 'react'
import React from 'react'
import { Table } from 'react-bootstrap'

import { renderRow } from './utils'

class GlossarySenders extends PureComponent {
  statusAndColors = [
    {
      text: 'Added',
      paths: [require('./glossaryImages/senders/statusAdded.png')]
    },
    {
      text: 'Booked',
      paths: [require('./glossaryImages/senders/statusBooked.png')]
    },
    {
      text: 'Pickup at',
      paths: [require('./glossaryImages/senders/statusPickUpAt.png')]
    },
    {
      text: 'Pickup in',
      paths: [require('./glossaryImages/senders/statusPickUpIn.png')]
    },
    {
      text: 'At store',
      paths: [require('./glossaryImages/senders/statusAtStore.png')]
    },
    {
      text: 'Cancelled',
      paths: [require('./glossaryImages/senders/statusCancel.png')]
    }
  ]

  render() {
    return (
      <Table striped className='glossaryTable'>
        <thead>
          <tr>
            <th>Status</th>
            <th>Legend</th>
          </tr>
        </thead>
        <tbody>
          {this.statusAndColors.map(({ text, paths }, index) => (
            <tr key={`${text}_${index}`}>{renderRow(text, 30, paths)}</tr>
          ))}
        </tbody>
      </Table>
    )
  }
}

export default GlossarySenders
