import { pick } from 'lodash'

const getFormControlPropsFromReduxForm = (reduxForm) => {
  return pick(reduxForm, [
    'name',
    'checked',
    'onBlur',
    'onChange',
    'onDragStart',
    'onDrop',
    'onFocus',
    'value'
  ])
}

export { getFormControlPropsFromReduxForm }
