import React, { PureComponent } from 'react'
import { Button, Form, FormControl } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import MapUiActions from '../../../actions/MapUiActions'
import ArchivedTaskActions from '../../../actions/services/ArchivedTaskActions'
import ChatIconWithBadge from '../../common/ChatIconWithBadge/ChatIconWithBadge'
import FilterTasksByDay from '../tasks/FilterTasksByDay/FilterTasksByDay'

import './Sidebar.scss'

interface Props {
  children: React.ReactNode
  ArchivedTaskActions: typeof ArchivedTaskActions
  MapUiActions: typeof MapUiActions
}

interface State {
  searchText: string
}

class Sidebar extends PureComponent<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      searchText: ''
    }
  }

  handleClick(event) {
    event.stopPropagation()
  }

  onChangeSearchText = (e: React.FormEvent<any>) => {
    this.setState({ searchText: e.currentTarget.value })
  }

  getSearchText = () => {
    return this.state.searchText.trim()
  }

  handleSearchSubmit = (params) => {
    this.props.MapUiActions.changeLastTaskQuery(params)
    this.props.ArchivedTaskActions.findById(params)
  }

  onSearchClearClick = () => {
    this.setState({ searchText: '' })
    this.props.MapUiActions.changeLastTaskQuery(null)
  }

  onSubmit = (e: any) => {
    e.preventDefault()

    const searchText = this.getSearchText()

    if (searchText.length > 0) {
      this.handleSearchSubmit({ keyword: searchText })
    } else {
      this.onSearchClearClick()
    }
  }

  onFilterChat = (filterMessages) => {
    this.props.MapUiActions.setFilterMessages(filterMessages)
  }

  renderSearchBox = () => {
    const { searchText } = this.state
    const showClearButton = this.getSearchText().length > 0

    return (
      <div className='toolbar'>
        <Form onSubmit={this.onSubmit} className='search-box'>
          <FormControl
            type='text'
            size={12}
            placeholder='Order number'
            value={searchText}
            onChange={this.onChangeSearchText}
            id='keyword'
          />
          {showClearButton && (
            <Button onClick={this.onSearchClearClick}>
              <i className='fa fa-times-circle' />
            </Button>
          )}
        </Form>
        <FilterTasksByDay />
        <ChatIconWithBadge onFilterChat={this.onFilterChat} />
      </div>
    )
  }

  render() {
    return (
      <div className='Sidebar'>
        {this.renderSearchBox()}
        <div className='filters' onClick={this.handleClick.bind(this)}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ArchivedTaskActions: bindActionCreators(ArchivedTaskActions, dispatch),
    MapUiActions: bindActionCreators(MapUiActions, dispatch)
  }
}

export { mapDispatchToProps }
export default connect(null, mapDispatchToProps)(Sidebar)
