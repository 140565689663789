import { fromJS, Map as ImmutableMap } from 'immutable'
import { createSelector } from 'reselect'

import { TASK_STATUS } from '../../../../constants'

function getTasks(state) {
  return state.services.getIn(['tasks', 'all', fromJS({}), 'data'])
}

function getDismissedLatePickups(state) {
  return state.NotificationsUiReducer.get('dismissedLatePickups')
}

const combiner = (tasks, dismissedTasks) => {
  if (tasks == null) {
    return { lateTasks: ImmutableMap() }
  }

  return {
    lateTasks: tasks.filter((task) => {
      return (
        task.get('onDemand') &&
        (task.get('currentStatus') === TASK_STATUS.TASK_RECEIVED ||
          task.get('currentStatus') === TASK_STATUS.CANCELLED) &&
        task.getIn(['quotes', 'latePickup']) != null &&
        !dismissedTasks.has(task.get('id'))
      )
    })
  }
}

export { getTasks, getDismissedLatePickups, combiner }
export default createSelector(getTasks, getDismissedLatePickups, combiner)
