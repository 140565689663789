import { fromJS } from 'immutable'
import { createSelector } from 'reselect'

import { getChosenQuote } from '../../quotes/QuotesTable/QuotesTable.selector'

function getApproval(state, props) {
  const taskId = props.match?.params?.taskId || props.taskId

  return state.services.getIn([
    'tasks',
    'approveQuote',
    fromJS(state.QuotesUiReducer.getIn(['lastApproveQuoteQueries', taskId]))
  ])
}

function getCancellation(state, props) {
  const taskId = props.match?.params?.taskId || props.taskId

  return state.services.getIn([
    'tasks',
    'cancelQuote',
    fromJS(state.QuotesUiReducer.getIn(['lastCancelQuoteQueries', taskId]))
  ])
}

export default createSelector(
  getChosenQuote,
  getApproval,
  getCancellation,
  (chosenQuote, approval, cancellation) => {
    return {
      // expiry: Math.min.apply(
      //   Math,
      //   task.getIn(['quotes', 'results']).map((quote) => {
      //     return new Date(quote.get('expires'))
      //   }).toArray()
      // ),
      chosenQuote,
      approvalInProgress:
        approval != null &&
        approval.get('sent') &&
        !approval.get('responseReceived'),
      approvalError:
        approval != null &&
        approval.get('sent') &&
        approval.get('lastError') != null
          ? approval.get('lastError')
          : null,
      cancellationInProgress:
        cancellation != null &&
        cancellation.get('sent') &&
        !cancellation.get('responseReceived'),
      cancellationError:
        cancellation != null &&
        cancellation.get('sent') &&
        cancellation.get('lastError') != null
          ? cancellation.get('lastError')
          : null
    }
  }
)
