import React from 'react'

import { TRANSPORT_TYPE } from '../../../../constants'

import './VehicleIcon.scss'

const vehicleIconMap = {
  [TRANSPORT_TYPE.Bicycle]: 'fa fa-bicycle',
  [TRANSPORT_TYPE.CargoBicycle]: 'custom-icon-cargobicycle',
  [TRANSPORT_TYPE.Motorbike]: 'fa fa-motorcycle',
  [TRANSPORT_TYPE.Car]: 'fa fa-car',
  [TRANSPORT_TYPE.SmallVan]: 'fa fa-car',
  [TRANSPORT_TYPE.Van]: 'fa fa-car',
  [TRANSPORT_TYPE.Walk]: 'fa fa-walking'
}

const getVehicleClassName = (vehicleType) => {
  return vehicleIconMap[vehicleType] || `fa fa-bolt vehicle-${vehicleType}`
}

export { getVehicleClassName }
export default ({ vehicleType }) => {
  return <i className={getVehicleClassName(vehicleType)} />
}
