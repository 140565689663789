import classnames from 'classnames'
import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import chat from '../../../../services/chat/chat'
import { formatTime } from '../../../map/tasks/Task/statusUtils'
import { ResponseChatMessage } from '../types'

import './Message.scss'
import { Props } from './types'

const renderUsername = (message: ResponseChatMessage) => {
  if (message.sender.isAdministrator) {
    if (chat.isAdmin()) {
      return (
        <span>
          ORKESTRO <strong>[{message.sender.username}]</strong>
        </span>
      )
    }

    return <span>ORKESTRO</span>
  }

  return <span>{message.sender.username}</span>
}

const renderMessageHeader = (
  message: ResponseChatMessage,
  isTheSameSender: boolean
) => {
  return (
    message.timestamp &&
    !isTheSameSender && (
      <div className='name'>
        {renderUsername(message)} (
        <strong>{formatTime(message.timestamp.seconds * 1000)}</strong>)
      </div>
    )
  )
}

const renderReadIcon = (taskId, message) => {
  if (!chat.isAdmin()) {
    return null
  }

  const readUsers = chat.getReadUsers(taskId, message)
  if (readUsers.length === 0) {
    return null
  }

  const readPopover = (
    <Tooltip id='readMessages' placement='bottom' className='readTooltip'>
      <div>Seen by:</div>
      {readUsers.map((readUser, index) => {
        return (
          <div key={`${message.id}_${readUser}`}>
            {index + 1}. {readUser}
          </div>
        )
      })}
    </Tooltip>
  )

  return (
    <OverlayTrigger
      trigger={['click', 'hover']}
      placement='bottom'
      overlay={readPopover}
    >
      <div className='readIcon'>
        <i className='fa fa-check-double fa-xs' />
      </div>
    </OverlayTrigger>
  )
}

const isSender = (message: ResponseChatMessage) => {
  if (message.sender.isAdministrator) {
    return chat.isAdmin()
  }

  return chat.getCurrentUsername() === message.sender.username
}

const Message: React.FC<Props> = ({
  taskId,
  message,
  children,
  isTheSameSender
}) => {
  return (
    <div
      className={classnames('chatWindowMessage', {
        isSender: isSender(message),
        isTheSameSender
      })}
    >
      {renderMessageHeader(message, isTheSameSender)}
      <div
        className={classnames('senderMessage', {
          receiverMessage: !isSender(message)
        })}
      >
        {children}

        {renderReadIcon(taskId, message)}
      </div>
      {message.sending && <span className='sending'>sending...</span>}
    </div>
  )
}

export default Message
