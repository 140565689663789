import { fromJS } from 'immutable'
import { createSelector } from 'reselect'

import {
  getOngoingTask,
  getTaskSearchResult
} from '../task/edit/TaskForm/TaskForm.selector'

const getTask = (state, props) => {
  const taskId = props.match?.params?.taskId

  return state.services.getIn(['tasks', 'all', fromJS({}), 'data', taskId])
}

const combiner = (task, ongoingTask, taskSearchResult) => {
  return {
    task: task || taskSearchResult,
    isTaskArchived: ongoingTask == null && taskSearchResult != null
  }
}

export default createSelector(
  getTask,
  getOngoingTask,
  getTaskSearchResult,
  combiner
)
