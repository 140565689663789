import React from 'react'
import { Button } from 'react-bootstrap'
import { Subscription } from 'rxjs'

import { CHAT_ICON_TYPE } from '../../../constants'
import chat from '../../../services/chat/chat'

import IconInTaskList from './IconInTaskList/IconInTaskList'
import { Props, State } from './types'

class ChatIcon extends React.Component<Props, State> {
  subscription: Subscription = null

  constructor(props) {
    super(props)

    this.state = {
      resolved: false,
      hasMessages: false,
      hasUnreadMessages: false,
      lastMessageTimestamp: null
    }
  }

  componentDidMount(): void {
    this.subscription = chat.subject.subscribe((value) => {
      const { taskId } = this.props

      this.setState({
        resolved: chat.isResolved(taskId),
        hasMessages: chat.hasMessages(taskId),
        hasUnreadMessages: chat.hasUnreadMessages(taskId),
        lastMessageTimestamp: value.chats[taskId]?.lastMessage?.timestamp
      })
    })
  }

  componentWillUnmount(): void {
    this.subscription.unsubscribe()
  }

  onClick = (event: React.MouseEvent<Button | HTMLDivElement, MouseEvent>) => {
    const { onPress } = this.props

    event.stopPropagation()

    onPress()
  }

  renderChatIcon = () => {
    const { iconType } = this.props
    const {
      hasMessages,
      hasUnreadMessages,
      lastMessageTimestamp,
      resolved
    } = this.state

    switch (iconType) {
      case CHAT_ICON_TYPE.ORDER_DETAILS:
        return (
          <Button
            className='spaceAfter'
            bsStyle='success'
            onClick={this.onClick}
          >
            <i className='fa fa-comment-alt spaceAfter' />
            {hasUnreadMessages ? `New Messages (*)` : 'Start Chat'}
          </Button>
        )

      case CHAT_ICON_TYPE.ORDER_LIST:
        if (!hasMessages || resolved) {
          return null
        }

        return (
          <div className='pointer' onClick={this.onClick}>
            <IconInTaskList
              hasUnreadMessages={hasUnreadMessages}
              lastMessageTimestamp={lastMessageTimestamp}
            />
          </div>
        )

      default:
        return null
    }
  }

  render() {
    return this.renderChatIcon()
  }
}

export default ChatIcon
