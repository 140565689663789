import { fromJS } from 'immutable'
import { createSelector } from 'reselect'

import { TRANSPORT_TYPE } from '../../../../../constants'

const getQuotes = (state, props) => {
  return props.quotes
}

const getChosenQuote = (state, { taskId }) => {
  return state.QuotesUiReducer.getIn(['chosenQuote', taskId])
}

const getVendorTotalsByReference = (state, props) => {
  return state.services.getIn(
    [
      'tasks',
      'vendorTotalsByReferenceTask',
      fromJS({ taskId: props.taskId }),
      'data'
    ],
    fromJS({})
  )
}

const vehicleSortRate = {
  [TRANSPORT_TYPE.Walk]: 0,
  [TRANSPORT_TYPE.Bicycle]: 1,
  [TRANSPORT_TYPE.CargoBicycle]: 2,
  [TRANSPORT_TYPE.Motorbike]: 3,
  [TRANSPORT_TYPE.Car]: 4,
  [TRANSPORT_TYPE.SmallVan]: 5,
  [TRANSPORT_TYPE.Van]: 6,
  [TRANSPORT_TYPE.Unknown]: 7
}

// Sort: vehicle type then price
// rules are defined here: https://orkestro.atlassian.net/browse/ORK-335
const sortQuote = (quoteA, quoteB) => {
  const vehicleTypeA = quoteA.get('vehicleType')
  const priceA = parseFloat(quoteA.get('price'))

  const vehicleTypeB = quoteB.get('vehicleType')
  const priceB = parseFloat(quoteB.get('price'))
  if (vehicleTypeA === vehicleTypeB) {
    return priceA - priceB
  }

  return vehicleSortRate[vehicleTypeA] - vehicleSortRate[vehicleTypeB]
}

const combiner = (quotes, chosenQuote, vendorTotalsByReference) => {
  if (quotes == null) {
    return {
      chosenQuote,
      cheapest: -1,
      quickest: -1,
      vendorTotalsByReference
    }
  }

  // We need to store the original index of each quote in the array, because
  // it's used to select and approve a quote.
  quotes = quotes
    .map((quote, index) => quote.set('_originalIndex', index))
    .sort(sortQuote)

  const prices = quotes.map((quote) => parseFloat(quote.get('price')))
  const times = quotes.map((quote) => parseFloat(quote.get('timeToFullfill')))

  let cheapestIndex = -1
  let quickestIndex = -1
  let minPrice = Infinity
  let minTime = Infinity

  prices.forEach((price, index) => {
    if (price && price < minPrice) {
      minPrice = price
      cheapestIndex = index
    }
  })
  times.forEach((time, index) => {
    if (time < minTime) {
      minTime = time
      quickestIndex = index
    }
  })

  return {
    quotes,
    chosenQuote,
    cheapest: cheapestIndex,
    quickest: quickestIndex,
    vendorTotalsByReference
  }
}

export { getQuotes, getChosenQuote, combiner, getVendorTotalsByReference }
export default createSelector(
  getQuotes,
  getChosenQuote,
  getVendorTotalsByReference,
  combiner
)
