import React, { PureComponent } from 'react'
import { Col, Grid, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

import LocaleSelector from '../../common/LocaleSelector/LocaleSelector'
import '../Language/LanguageSettings.scss'

class LanguageSettings extends PureComponent {
  render() {
    return (
      <Grid fluid>
        <Row>
          <Col sm={12}>
            <h2>
              <FormattedMessage id='settings.language.title' />
            </h2>
            <LocaleSelector />
          </Col>
        </Row>
      </Grid>
    )
  }
}

export default LanguageSettings
