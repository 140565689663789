import { fromJS } from 'immutable'
import React from 'react'
import { connect } from 'react-redux'
import { generatePath, RouteComponentProps, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import TaskActions from '../../../../actions/services/TaskActions'
import { VIEW_TYPE } from '../../../../constants'
import { Routes } from '../../../../navigation/Routes'
import TaskForm from '../../task/edit/TaskForm/TaskForm'

interface Props extends RouteComponentProps<{ viewType: VIEW_TYPE }> {
  selectedTasks: any[]
  TaskActions: typeof TaskActions
  close: () => void
  className: string
}

class MergeTasksDialog extends React.PureComponent<Props> {
  handleSubmit(formData) {
    const { selectedTasks, match } = this.props
    const selectedExternalRefs = selectedTasks.map((task) =>
      task.get('externalRefs')
    )
    let data = fromJS(formData)

    // Remove the `currentStatus` and `newNote` fields, these will need to be
    // passed to their own service methods (not to `insert` or `update`).
    const newNote = data.get('newNote')
    data = data.delete('currentStatus')
    data = data.delete('newNote')
    data = data.set('mergedTaskIds', selectedExternalRefs)

    return new Promise((resolve, reject) => {
      // If this is a new task...
      // First insert the new task...
      this.props.TaskActions.geocodeAndInsert({ data })
        .catch((_error) => reject({ _error }))
        .then((response) => {
          // Then update the task with additional status changes and notes.
          const taskId = response.get('generatedKeys').get(0)
          const actionPromises = []

          // Reject the promise if there was an error while inserting the task.
          if (taskId == null || response.get('lastError') != null) {
            reject({ _error: response.get('lastError') })
          } else {
            actionPromises.push(
              this.props.TaskActions.addNote({
                taskId,
                type: 'text',
                author: null,
                data:
                  'Merged tasks: ' +
                  selectedTasks
                    .map(
                      (task) =>
                        `[${task.get(
                          'externalRef'
                        )}](https://d.orkestro.com/#/map/tasks/task/${task.get(
                          'id'
                        )})`
                    )
                    .join(', ')
              })
            )

            this.props.selectedTasks.map((task) => {
              actionPromises.push(
                this.props.TaskActions.addNote({
                  taskId: task.get('id'),
                  type: 'text',
                  author: null,
                  data:
                    'Merged to: ' +
                    `[${data.get(
                      'externalRef'
                    )}](https://d.orkestro.com/#/map/tasks/task/${taskId})`
                })
              )
              actionPromises.push(
                this.props.TaskActions.addStatus({
                  taskId: task.get('id'),
                  driverId: null,
                  author: null,
                  data: 'Cancelled'
                })
              )
            })

            if (newNote != null) {
              actionPromises.push(
                this.props.TaskActions.addNote({
                  taskId,
                  type: 'text',
                  author: null,
                  data: newNote
                })
              )
            }

            Promise.all(actionPromises)
              .catch((_error) => reject({ _error }))
              .then(() => {
                this.props.close()
                this.props.history.push(
                  generatePath(Routes.Map.TaskDetail, {
                    taskId,
                    viewType: match.params.viewType
                  })
                )
                resolve()
              })
          }
        })
    })
  }

  render() {
    const task = mergeTasks(this.props.selectedTasks)
    if (!task) {
      return null
    }
    return (
      <TaskForm
        className={this.props.className}
        taskId={undefined}
        sourceError={false}
        destinationError={false}
        onModalHide={this.props.close}
        onCloneClick={() => null}
        cloningInProgress={false}
        cloneError={null}
        onArchiveClick={() => null}
        archivingInProgress={false}
        archiveError={undefined}
        initialValues={task}
        onSubmit={this.handleSubmit.bind(this)}
      />
    )
  }
}

function mergeTasks(tasks) {
  const first = tasks.first()
  return {
    externalRef: mergeExternalRefs(
      tasks.map((task) => task.get('externalRef')).toJS()
    ),
    onDemand: false,
    merchantId: first.get('merchantId'),
    schedule: first.get('schedule'),
    parcelLength: first.get('parcelLength'),
    parcelWidth: first.get('parcelWidth'),
    parcelHeight: first.get('parcelHeight'),
    parcelWeight: first.get('parcelWeight'),
    parcelFragile: first.get('parcelFragile'),
    parcelDoNotRotate: first.get('parcelDoNotRotate'),
    parcelContainsLiquid: first.get('parcelContainsLiquid'),
    parcelContainsHotFood: first.get('parcelContainsHotFood'),
    currentStatus: first.get('currentStatus'),
    sourceName: first.get('sourceName'),
    sourceAddress: first.get('sourceAddress'),
    sourceCity: first.get('sourceCity'),
    sourcePostcode: first.get('sourcePostcode'),
    sourceCountry: first.get('sourceCountry'),
    sourcePhone: first.get('sourcePhone'),
    sourceEmail: first.get('sourceEmail'),
    destinationCity: first.get('destinationCity'),
    destinationCountry: first.get('destinationCountry'),
    destinationName: first.get('destinationName'),
    destinationAddress: first.get('destinationAddress'),
    destinationEmail: first.get('destinationEmail'),
    destinationPhone: first.get('destinationPhone'),
    destinationPostcode: first.get('destinationPostcode'),
    pickupDateFrom: first.get('pickupDateFrom'),
    pickupDateTo: first.get('pickupDateTo'),
    deliveryDateFrom: first.get('deliveryDateFrom'),
    deliveryDateTo: first.get('deliveryDateTo'),
    pickupInstructions:
      `${tasks.size} orders:\n${tasks
        .map((task) => task.get('externalRef'))
        .join(',\n ')}\n---\n` +
      tasks.map((task) => task.get('pickupInstructions')).join('\n---\n'),
    deliveryInstructions: tasks
      .map((task) => task.get('deliveryInstructions'))
      .join('\n---\n')
  }
}

const sortPartIndex = (partIndex) => (a, b) =>
  partIndex[a] > partIndex[b] ? 1 : partIndex[a] < partIndex[b] ? -1 : 0

function mergeExternalRefs(externalRefs) {
  const parts = []
  const partIndex = {}
  for (const ref of externalRefs) {
    ref.split(/[^A-Za-z0-9]+/).forEach((part, index) => {
      if (partIndex[part] === undefined) {
        partIndex[part] = index
        parts.push(part)
      }
    })
  }
  parts.sort(sortPartIndex(partIndex))
  return parts.join('_')
}

const mapStateToProps = (state: any) => ({
  selectedTasks: state.MapUiReducer.get('selectedTasks').map((taskId) =>
    state.services.getIn(['tasks', 'all', fromJS({}), 'data', taskId])
  )
})

const mapDispatchToProps = (dispatch) => ({
  TaskActions: bindActionCreators(TaskActions, dispatch)
})

export { mapStateToProps, mapDispatchToProps, sortPartIndex }
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MergeTasksDialog))
