import { fromJS, Set as ImmutableSet } from 'immutable'
import { cond, constant } from 'lodash'
import moment from 'moment'
import { createSelector } from 'reselect'

import { TASK_STATUS, VIEW_TYPE } from '../../../constants'
import TaskAssignments from '../../../selectors/TaskAssignments/TaskAssignments'
import FilteredTasks from '../../../selectors/map/tasks/FilteredTasks/FilteredTasks'
import { isMultidrop, isParentTask } from '../../../util/task'

const markerIcons = require('../../../images/map-markers-04.png')
const bicycleIcon = require('../../../images/bicycle.svg')

const pickupIcon = require('../../../images/map/shop.png')
const dropoffIcon = require('../../../images/map/flag.png')

const MARKER_ICON_SIZE = 96
const SPRITE_COLUMNS = 8
const SPRITE_ROWS = 3
const _10DaysInTimestamp = 1000 * 60 * 60 * 24 * 10
const markerScale = 0.5

const MARKER_OPTIONS = {
  url: markerIcons,
  scaledSize: {
    width: MARKER_ICON_SIZE * SPRITE_COLUMNS * markerScale,
    height: MARKER_ICON_SIZE * SPRITE_ROWS * markerScale
  },
  anchor: {
    x: (MARKER_ICON_SIZE * markerScale) / 2,
    y: (MARKER_ICON_SIZE * markerScale) / 2
  },
  size: {
    width: MARKER_ICON_SIZE * markerScale,
    height: MARKER_ICON_SIZE * markerScale
  },
  origin: {
    x: MARKER_ICON_SIZE * markerScale,
    y: 0
  }
}

const SELECTED_MARKER_ICON_SIZE = 128
const SELECTED_MARKER_SCALE = 0.35
const SELECTED_MARKER_OPTIONS = {
  scaledSize: {
    width: SELECTED_MARKER_ICON_SIZE * SELECTED_MARKER_SCALE,
    height: SELECTED_MARKER_ICON_SIZE * SELECTED_MARKER_SCALE
  },
  anchor: {
    x: (SELECTED_MARKER_ICON_SIZE * SELECTED_MARKER_SCALE) / 2,
    y: SELECTED_MARKER_ICON_SIZE * SELECTED_MARKER_SCALE
  },
  size: {
    width: SELECTED_MARKER_ICON_SIZE * SELECTED_MARKER_SCALE,
    height: SELECTED_MARKER_ICON_SIZE * SELECTED_MARKER_SCALE
  }
}

function getSelectedTasks(state) {
  return state.MapUiReducer.get('selectedTasks')
}

function getSelectedDrivers(state) {
  return state.MapUiReducer.get('selectedDrivers')
}

function getDrivers(state) {
  return state.services.getIn(['drivers', 'all', fromJS({}), 'data'])
}

function getTasks(state) {
  const mapUiQuery = state.MapUiReducer.get('lastQueryParams')

  if (mapUiQuery == null) {
    // get ongoing tasks displayed in the task list
    return FilteredTasks(state)
  } else {
    // get search results
    return state.services.getIn([
      'archivedTasks',
      'findById',
      mapUiQuery,
      'data'
    ])
  }
}

const normaliseStatus = (task, assignedDriverId) => {
  const currentStatus = task.get('currentStatus')

  if (currentStatus === TASK_STATUS.TASK_RECEIVED) {
    if (assignedDriverId == null && !task.get('isWalked')) {
      return 'unassigned'
    } else {
      return 'assigned'
    }
  }

  return currentStatus
}

const getMarkerSize = (normalisedStatus) => {
  switch (normalisedStatus) {
    case 'unassigned':
      return MARKER_ICON_SIZE * 2 * markerScale
    case 'assigned':
      return MARKER_ICON_SIZE * 3 * markerScale
    case TASK_STATUS.ARRIVED_AT_PICKUP:
      return MARKER_ICON_SIZE * 4 * markerScale
    case TASK_STATUS.IN_TRANSIT:
      return MARKER_ICON_SIZE * 4 * markerScale
    case TASK_STATUS.ARRIVED_AT_DROPOFF:
      return MARKER_ICON_SIZE * 4 * markerScale
    case TASK_STATUS.SUCCESS:
      return MARKER_ICON_SIZE * 5 * markerScale
    case TASK_STATUS.FAILED:
      return MARKER_ICON_SIZE * 6 * markerScale
    case TASK_STATUS.CANCELLED:
      return MARKER_ICON_SIZE * 7 * markerScale
    default:
      return MARKER_ICON_SIZE * 2 * markerScale
  }
}

const getViewType = (state, viewType: VIEW_TYPE) => viewType

const getSourceMarker = ({ task, isFirstTask, source, isHighlighted }) => {
  if (source.lat == null || source.lng == null || !isParentTask(task)) {
    return {}
  }

  const infoWindow = fromJS({
    // Create only one infoWindow/source
    _id: `${source.lat}X${source.lng}`,
    _kind: 'source',

    position: source,
    content: `<div class='info-window selectable'>
              <p>
                Merchant: <b>${task.get('merchantId')}</b>
              </p>
              <p>
                From:<br>
                <b>${task.get('sourceName')}<br>
                ${task.get('sourceAddress').replace(/\n/g, '<br>')}</b>
              </p>
            </div>`
  })

  const marker = fromJS({
    _id: `${source.lat}X${source.lng}`,
    _kind: 'source',

    position: source,
    optimized: false,
    title: `Source:\n${task.get('sourceAddress')}`,
    icon: cond([
      [
        (value) => value === true,
        constant({
          ...SELECTED_MARKER_OPTIONS,
          url: pickupIcon,
          anchor: {
            x: (MARKER_ICON_SIZE * SELECTED_MARKER_SCALE) / 2,
            y: (MARKER_ICON_SIZE * SELECTED_MARKER_SCALE) / 2
          }
        })
      ],
      [
        (value) => value === false,
        constant({
          ...MARKER_OPTIONS,
          origin: {
            ...MARKER_OPTIONS.origin,
            y: isHighlighted ? MARKER_ICON_SIZE * markerScale : 0
          }
        })
      ]
    ])(isFirstTask)
  })

  return { infoWindow, marker }
}

const getDestinationMarker = ({
  task,
  isFirstTask,
  destination,
  isHighlighted,
  driverId,
  driverName
}) => {
  if (
    destination.lat == null ||
    destination.lng == null ||
    (isMultidrop(task) && isParentTask(task))
  ) {
    return {}
  }

  const zIndex = isHighlighted ? 1000 : 0
  const opacity = 1
  const normalisedStatus = normaliseStatus(task, driverId)

  const infoWindow = fromJS({
    _id: task.get('id'),
    _kind: 'destination',

    position: destination,
    content: `<div class='info-window selectable'>
              <p>
                Merchant: <b>${task.get('merchantId')}</b><br>
                Status: <b>${task.get('currentStatus')}</b><br>
                Assigned to: <b>${driverName}</b>
              </p>
              <p>
                Source:<br>
                <b>${task.get('sourceName')}<br>
                ${task.get('sourceAddress').replace(/\n/g, '<br>')}</b>
              </p>
              <p>
                Destination:<br>
                <b>${task.get('destinationName')}<br>
                ${task.get('destinationAddress').replace(/\n/g, '<br>')}</b>
              </p>
            </div>`
  })

  const marker = fromJS({
    _id: task.get('id'),
    _kind: 'destination',

    position: destination,
    optimized: false,
    opacity,
    zIndex,
    icon: cond([
      [
        (value) => value === true,
        constant({
          ...SELECTED_MARKER_OPTIONS,
          url: dropoffIcon
        })
      ],
      [
        (value) => value === false,
        constant({
          ...MARKER_OPTIONS,
          anchor: {
            ...MARKER_OPTIONS.anchor,
            y: MARKER_ICON_SIZE * markerScale
          },
          origin: {
            x: getMarkerSize(normalisedStatus),
            y: isHighlighted ? MARKER_ICON_SIZE * markerScale : 0
          }
        })
      ]
    ])(isFirstTask)
  })

  return {
    infoWindow,
    marker
  }
}

const getMarker = ({
  selectedTasks,
  selectedDrivers,
  task,
  drivers,
  assignments,
  viewType,
  isFirstTask
}) => {
  const infoWindows = []
  const markers = []

  const assignedDriverId = assignments.get(task.get('id'))
  const assignedDriver = drivers ? drivers.get(assignedDriverId) : null
  let assignedDriverName = 'unassigned'
  if (assignedDriver != null) {
    assignedDriverName =
      assignedDriver.get('firstName') + ' ' + assignedDriver.get('lastName')
  }

  // Highlight if the task is selected
  const isHighlighted = selectedTasks.has(task.get('id'))

  let isShown
  if (
    assignedDriver == null ||
    [VIEW_TYPE.SENDERS, VIEW_TYPE.RECIPIENTS].includes(viewType)
  ) {
    isShown = false
  } else {
    // If this task has already been assigned to a driver, show this task
    // if at least one of the other tasks of this driver is selected, or
    // if the driver itself is selected.
    isShown =
      selectedTasks.intersect(assignedDriver.get('assignedTasks')).size > 0 ||
      selectedDrivers.has(assignedDriverId)
  }

  if (!isHighlighted && !isShown) {
    return {
      infoWindows: [],
      markers: []
    }
  }

  const sourceMarker = getSourceMarker({
    task,
    isFirstTask,
    isHighlighted,
    source: {
      lat: task.getIn(['sourceGeolocation', 'coordinates', 1]),
      lng: task.getIn(['sourceGeolocation', 'coordinates', 0])
    }
  })
  if (sourceMarker.infoWindow) {
    infoWindows.push(sourceMarker.infoWindow)
  }
  if (sourceMarker.marker) {
    markers.push(sourceMarker.marker)
  }

  const destinationMarker = getDestinationMarker({
    task,
    isFirstTask,
    isHighlighted,
    driverId: assignedDriverId,
    driverName: assignedDriverName,
    destination: {
      lat: task.getIn(['destinationGeolocation', 'coordinates', 1]),
      lng: task.getIn(['destinationGeolocation', 'coordinates', 0])
    }
  })
  if (destinationMarker.infoWindow) {
    infoWindows.push(destinationMarker.infoWindow)
  }
  if (destinationMarker.marker) {
    markers.push(destinationMarker.marker)
  }

  return {
    infoWindows,
    markers
  }
}

const combiner = (
  selectedTasks,
  selectedDrivers,
  drivers,
  tasks,
  assignments,
  viewType: VIEW_TYPE
) => {
  const markers = ImmutableSet().asMutable()
  const infoWindows = ImmutableSet().asMutable()

  // Driver's current location marker
  if (drivers != null) {
    drivers.forEach((driver) => {
      let lastKnownLocation = driver.get('lastKnownLocation')
      if (lastKnownLocation) {
        lastKnownLocation = lastKnownLocation.toJS()
      }

      if (
        lastKnownLocation == null ||
        Object.keys(lastKnownLocation).length === 0
      ) {
        return
      }

      // Don't show this driver if it hasn't been seen in the last 5 minutes
      // if ((new Date()) - new Date(lastKnownLocation.timestamp) > (5 * 60 * 1000)) {
      //   return
      // }
      // Show all drivers for last 10 days - for demo purposes
      if (Date.now() - lastKnownLocation.timestamp > _10DaysInTimestamp) {
        return
      }

      const name = driver.get('firstName') + ' ' + driver.get('lastName')
      const location = {
        lat: lastKnownLocation.geolocation.coordinates[1],
        lng: lastKnownLocation.geolocation.coordinates[0]
      }

      // Show all drivers for last 10 days - for demo purposes

      // Highlight if this driver is selected.
      const isHighlighted = selectedDrivers.has(driver.get('id'))
      // Show if this driver is assigned to one or more of the selected tasks.
      // const isShown = selectedTasks.intersect(driver.get('assignedTasks')).size > 0
      // Don't show this driver if it's not selected, and none its tasks are
      // selected.
      // if (!isHighlighted && !isShown) {
      //   return
      // }

      const zIndex = isHighlighted ? 1000 : 0
      const opacity = 1

      const timestamp = moment(
        driver.getIn(['lastKnownLocation', 'timestamp'])
      ).fromNow()
      const accuracy = parseInt(
        driver.getIn(['lastKnownLocation', 'accuracy']),
        10
      )

      infoWindows.add(
        fromJS({
          _id: driver.get('id'),
          _kind: 'driver',

          position: location,
          content: `<div class='info-window selectable'>
            <p>
              <b>${name}</b><br>
              has ${driver.get('assignedTasks').size} tasks
            </p>
            <p>
              Last seen ${timestamp}<br>
              Accuracy: ±${accuracy}m
            </p>
          </div>`
        })
      )

      markers.add(
        fromJS({
          _id: driver.get('id'),
          _kind: 'driver',

          position: location,
          title: `${driver.get('firstName')} ${driver.get('lastName')}\nhas ${
            driver.get('assignedTasks').size
          } tasks\nLast seen ${timestamp}`,
          zIndex,
          opacity,
          icon: {
            url: bicycleIcon,
            anchor: new google.maps.Point(25, 50),
            strokeWeight: 0,
            scale: 0.1
          }
        })
      )
    })
  }

  if (tasks != null) {
    const firstSelectedTaskId = selectedTasks.first()

    tasks.forEach((task) => {
      const isFirstTask = firstSelectedTaskId === task.get('id')
      const isMultidropTask = isMultidrop(task)

      if (isFirstTask && isMultidropTask) {
        const containedTasks = task.get('containedTasks')
        if (containedTasks) {
          containedTasks.forEach((containedTask) => {
            const id = containedTask.get('id')
            const subTask = tasks.get(id)
            if (!subTask) {
              return
            }

            const markerSubtaskInfo = getMarker({
              isFirstTask,
              selectedTasks: ImmutableSet([id]),
              selectedDrivers,
              task: subTask,
              drivers,
              assignments,
              viewType
            })
            markers.merge(markerSubtaskInfo.markers)
            infoWindows.merge(markerSubtaskInfo.infoWindows)
          })
        }
      }

      const markerInfo = getMarker({
        isFirstTask,
        selectedTasks,
        selectedDrivers,
        task,
        drivers,
        assignments,
        viewType
      })
      markers.merge(markerInfo.markers)
      infoWindows.merge(markerInfo.infoWindows)
    })
  }

  return {
    markers: markers.asImmutable(),
    infoWindows: infoWindows.asImmutable()
  }
}

export {
  getSelectedTasks,
  getSelectedDrivers,
  getDrivers,
  getTasks,
  combiner,
  getMarkerSize
}

export default createSelector(
  getSelectedTasks,
  getSelectedDrivers,
  getDrivers,
  getTasks,
  TaskAssignments,
  getViewType,
  combiner
)
