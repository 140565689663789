import React from 'react'
import { connect } from 'react-redux'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import { Routes } from '../../../../../navigation/Routes'
import { getTask } from '../../../../../selectors/map/tasks/TaskSelector/TaskSelector'

import './PrintButton.scss'
import { Props, StateProps } from './types'

class PrintButton extends React.Component<
  Props & StateProps & RouteComponentProps
> {
  private goToPrint = () => {
    const { tasksWithDriver, driverName, merchantName } = this.props

    window.localStorage.setItem(
      'printTasks',
      JSON.stringify({ driverName, tasksWithDriver, merchantName })
    )
  }

  render() {
    return (
      <Link to={Routes.PrintTasks} target='_blank' onClick={this.goToPrint}>
        <button
          className='printTasksButton spaceAfter'
          onClick={this.goToPrint}
        >
          <i className='fas fa-print' />
        </button>
      </Link>
    )
  }
}

const mapStateToProps = (state, { tasksGroupedByDriver }) => ({
  tasksWithDriver: tasksGroupedByDriver
    .map((tasksId) => {
      return tasksId.map((taskId) => getTask(state, { taskId })).toJS()
    })
    .toJS()
})

export default compose<Props, Props>(
  withRouter,
  connect<StateProps, {}, Props>(mapStateToProps)
)(PrintButton)
