const isProduction = () => process.env.DEPLOY_ENV === 'production'

const config = isProduction()
  ? {
      trackingServer: process.env.TRACKING_SERVER,
      socketioServer:
        process.env.SOCKETIO_SERVER || process.env.TRACKING_SERVER,
      stripePublishableKey: 'pk_live_CCcjd3Wa8qdS9P8dJJdPn4pC',
      firebaseConfig: {
        apiKey: 'AIzaSyDNzv78w0HktU5LMbSFaOidAd3ox4dqpwI',
        authDomain: 'orkestro-prod.firebaseapp.com',
        databaseURL: 'https://orkestro-prod.firebaseio.com',
        projectId: 'orkestro-prod',
        storageBucket: 'orkestro-prod.appspot.com',
        messagingSenderId: '626881410065',
        appId: '1:626881410065:web:03eaea44d49bca13e4edb0',
        measurementId: 'G-L765WZDEDH'
      }
    }
  : {
      trackingServer: process.env.TRACKING_SERVER,
      socketioServer:
        process.env.SOCKETIO_SERVER || process.env.TRACKING_SERVER,
      stripePublishableKey: 'pk_test_Xu8OTviojvm0IRpMKevpz85n',
      firebaseConfig: {
        apiKey: 'AIzaSyAa0_cvXUisqrpUCXGkYSVGZQV7_8613fg',
        authDomain: 'orkestro-dev.firebaseapp.com',
        databaseURL: 'https://orkestro-dev.firebaseio.com',
        projectId: 'orkestro-dev',
        storageBucket: 'orkestro-dev.appspot.com',
        messagingSenderId: '83029155341',
        appId: '1:83029155341:web:33e0ae1072481f0c17bf44',
        measurementId: 'G-BZDVN4XTRH'
      }
    }

export default config
