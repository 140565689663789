import { QUOTE_STATUS, SCHEDULE, TASK_STATUS } from '../../../../constants'
import {
  isWaitingForConfirmation,
  isWaitingToBeAssignedToDriver
} from '../../../../util/task'

const failedStatusKey = (task) =>
  task.get('currentStatus') === TASK_STATUS.FAILED ? 1 : 2

const onDemandStatusKey = (task) => {
  switch (task.get('currentStatus')) {
    case TASK_STATUS.FAILED:
      return 1

    case TASK_STATUS.SUCCESS:
      return 5

    case TASK_STATUS.CANCELLED:
      return 4

    case TASK_STATUS.TASK_RECEIVED:
    case TASK_STATUS.QUOTE_APPROVED:
      return 2

    default:
      return 3
  }
}

const waitingForQuoteApprovedKey = (task) =>
  task.getIn(['quotes', 'status']) === QUOTE_STATUS.QUOTED ? 1 : 2

const isWaitingForConfirmationKey = (task) =>
  isWaitingForConfirmation(task) ? 1 : 2

const isWaitingToBeAssignedToDriverKey = (task) =>
  isWaitingToBeAssignedToDriver(task) ? 2 : 1

const onDemandKey = (task) => (task.get('onDemand') === true ? 2 : 1)

const externalRefKey = (task) => task.get('externalRef')

const isUrgent = (task) =>
  [SCHEDULE.URGENT, SCHEDULE.ASAP].includes(task.get('schedule'))

const getCreatedTaskTime = (task) => {
  const createdTaskTime = task.getIn(
    ['actionLog', 0, 'timestamp'],
    new Date().toISOString()
  )

  return new Date(createdTaskTime).getTime() + 15 * 60e3
}

const getPickupTime = (task) => {
  const pickupTime = task.get('pickupDateFrom') || task.get('deliveryDateFrom')

  return new Date(pickupTime).getTime()
}

const deliveryDateKey = (task) =>
  isUrgent(task) ? getCreatedTaskTime(task) : getPickupTime(task)

const orderByKey = (keyFuncs) => (task1, task2) =>
  keyFuncs.reduce((order, keyFunc) => {
    if (order !== 0) {
      return order
    }

    const key1 = keyFunc(task1)
    const key2 = keyFunc(task2)

    if (key1 > key2) {
      return 1
    }

    if (key1 < key2) {
      return -1
    }

    return 0
  }, 0)

const unassignedTaskOrdering = orderByKey([
  failedStatusKey,
  waitingForQuoteApprovedKey,
  isWaitingForConfirmationKey,
  isWaitingToBeAssignedToDriverKey,
  onDemandKey,
  deliveryDateKey,
  externalRefKey
])

const onDemandTaskOrdering = orderByKey([
  onDemandStatusKey,
  waitingForQuoteApprovedKey,
  isWaitingForConfirmationKey,
  isWaitingToBeAssignedToDriverKey,
  onDemandKey,
  deliveryDateKey,
  externalRefKey
])

export { unassignedTaskOrdering, onDemandTaskOrdering }
