import classnames from 'classnames'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import './Spinner.scss'

class Spinner extends React.PureComponent<{
  className?: string
  showText?: boolean
}> {
  render() {
    const className = classnames('Spinner', this.props.className)

    return (
      <div className={className}>
        {this.props.showText ? (
          <p>
            <FormattedMessage id='loading' />
          </p>
        ) : null}
        <i className='fa fa-sync-alt Spinner' />
      </div>
    )
  }
}

export default Spinner
