import { CannedResponse } from '../../../services/chat/types'

enum FormAction {
  ADD,
  EDIT,
  NONE
}

interface State {
  editingResponse?: CannedResponse
  formAction: FormAction
  cannedResponses: CannedResponse[]
  loading: boolean
}

interface CannedResponseSubmit {
  editingId?: string
  formAction: FormAction
  shortcut: string
  content: string
}

export { State, FormAction, CannedResponseSubmit }
