import { fromJS } from 'immutable'
import { isNil } from 'lodash'
import { createStructuredSelector } from 'reselect'

import sectionStateUtils from '../../../../util/sectionState'
import TaskAssignments from '../../../TaskAssignments/TaskAssignments'

const getSectionState = (state, { viewType, sectionId }) => {
  return sectionStateUtils.getSectionState(viewType, sectionId, state)
}

function getUser(state) {
  return state.AuthReducer.get('user')
}

function getTask(state, props) {
  return (
    state.services.getIn(['tasks', 'all', fromJS({}), 'data', props.taskId]) ||
    state.services.getIn([
      'archivedTasks',
      'findById',
      state.MapUiReducer.get('lastQueryParams'),
      'data',
      props.taskId
    ])
  )
}

function getIsSelected(state, props) {
  return state.MapUiReducer.get('selectedTasks').includes(props.taskId)
}

function getAssignedDriver(state, props) {
  const task = getTask(state, props)
  let assignedDriverName = 'unassigned'
  if (task && task.get('onDemand')) {
    assignedDriverName = task.getIn(['quotes', 'chosenVendor']) || 'unassigned'
  } else {
    const assignedDriver = state.services.getIn([
      'drivers',
      'all',
      fromJS({}),
      'data',
      TaskAssignments(state).get(props.taskId)
    ])
    if (!isNil(assignedDriver)) {
      assignedDriverName =
        assignedDriver.get('firstName') + ' ' + assignedDriver.get('lastName')
    }
  }

  return assignedDriverName
}

function getTaskFilterDay(state) {
  return state.MapUiReducer.get('taskFilterDay')
}

export { getTask, getIsSelected, getAssignedDriver, getTaskFilterDay, getUser }
export default createStructuredSelector({
  task: getTask,
  isSelected: getIsSelected,
  assignedDriverName: getAssignedDriver,
  taskFilterDay: getTaskFilterDay,
  user: getUser,
  sectionState: getSectionState
})
