import { fromJS, Set as ImmutableSet } from 'immutable'
import moment from 'moment'

import {
  CHANGE_LAST_TASK_QUERY,
  CHANGE_TASK_FILTER_DAY,
  DESELECT_ALL,
  DESELECT_TASK,
  FILTER_MESSAGES,
  SELECT_ONE_TASK,
  SELECT_TASK,
  SELECT_TASK_RANGE,
  SET_SECTION_STATE,
  SET_VIEW_STATE
} from '../actions/MapUiActions'

const sectionsStateJSONString = window.localStorage.getItem('sectionsState')
const viewStateJSONString = window.localStorage.getItem('viewState')

const initialState = fromJS({
  selectedDrivers: ImmutableSet(),
  selectedTasks: ImmutableSet(),
  lastSelectedTask: null,
  taskFilterDay: moment().toISOString(),
  lastQueryParams: null,
  sectionsState: fromJS(JSON.parse(sectionsStateJSONString || '{}')),
  viewState: fromJS(JSON.parse(viewStateJSONString || '{}')),
  filterMessages: {
    active: false,
    read: true,
    unread: true
  }
})

export default function MapUiReducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_ONE_TASK:
      return state.merge({
        selectedTasks: ImmutableSet([action.taskId]),
        lastSelectedTask: action.taskId
      })

    case SELECT_TASK:
      return state.merge({
        selectedTasks: state.get('selectedTasks').add(action.taskId),
        lastSelectedTask: action.taskId
      })

    case SELECT_TASK_RANGE:
      return state.set(
        'selectedTasks',
        state.get('selectedTasks').union(action.taskIds)
      )

    case DESELECT_TASK:
      return state.set(
        'selectedTasks',
        state.get('selectedTasks').delete(action.taskId)
      )

    case DESELECT_ALL:
      return state.merge({
        selectedDrivers: ImmutableSet(),
        selectedTasks: ImmutableSet()
      })

    case CHANGE_TASK_FILTER_DAY:
      return state.set('taskFilterDay', action.taskFilterDay)

    case CHANGE_LAST_TASK_QUERY:
      return state.set('lastQueryParams', fromJS(action.params))

    case SET_SECTION_STATE:
      const sectionsState = state
        .get('sectionsState', fromJS({}))
        .merge(action.sectionsState)

      window.localStorage.setItem(
        'sectionsState',
        JSON.stringify(sectionsState.toJS())
      )

      return state.set('sectionsState', sectionsState)

    case SET_VIEW_STATE:
      const viewState = state
        .get('viewState', fromJS({}))
        .merge(action.viewState)

      window.localStorage.setItem('viewState', JSON.stringify(viewState.toJS()))

      return state.set('viewState', viewState)

    case FILTER_MESSAGES:
      return state.set('filterMessages', fromJS(action.filterMessages))

    default:
      return state
  }
}
