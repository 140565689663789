import { rpcSend } from './ServiceActions'

export default {
  // all: send.bind(null, 'tasks', 'all'),
  getById: rpcSend.bind(null, 'tasks', 'getById'),
  geocodeAndInsert: rpcSend.bind(null, 'tasks', 'geocodeAndInsert'),
  geocodeAndUpdate: rpcSend.bind(null, 'tasks', 'geocodeAndUpdate'),
  clone: rpcSend.bind(null, 'tasks', 'clone'),
  archive: rpcSend.bind(null, 'tasks', 'archive'),
  addNote: rpcSend.bind(null, 'tasks', 'addNote'),
  addStatus: rpcSend.bind(null, 'tasks', 'addStatus'),
  setQuoteStatus: rpcSend.bind(null, 'tasks', 'setQuoteStatus'),
  setOnDemand: rpcSend.bind(null, 'tasks', 'setOnDemand'),
  redoQuote: rpcSend.bind(null, 'tasks', 'redoQuote'),
  redoQuickQuote: rpcSend.bind(null, 'tasks', 'redoQuickQuote'),
  approveQuote: rpcSend.bind(null, 'tasks', 'approveQuote'),
  cancelQuote: rpcSend.bind(null, 'tasks', 'cancelQuote'),
  vendorTotalsByReferenceTask: rpcSend.bind(
    null,
    'tasks',
    'vendorTotalsByReferenceTask'
  )
}
