// polyfills
import * as Sentry from '@sentry/browser'
import 'core-js/fn/array/find'
import 'core-js/fn/array/find-index'
import areIntlLocalesSupported from 'intl-locales-supported'
import moment from 'moment'
import React, { PureComponent } from 'react'
import { DragDropContext } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import ReactDOM from 'react-dom'
import { IntlProvider } from 'react-intl'
import { connect, Provider as StoreProvider } from 'react-redux'
import RWMomentLocalizer from 'react-widgets/lib/localizers/moment'
import 'styles/Main.scss'
import 'styles/_react-widgets.scss'
import 'whatwg-fetch'

import AuthActions from './actions/AuthActions'
import { ChangfeedProvider } from './actions/ChangefeedProvider'
import { Router } from './navigation'
import configureStore from './stores'

const enGbMessages = require('./messages/en-GB.json')
const huHuMessages = require('./messages/hu-HU.json')
const itItMessages = require('./messages/it-IT.json')

// localisation

const SUPPORTED_LOCALES = ['en-GB', 'it-IT', 'hu-HU']
// @TODO double-check the below is already done in the library itself.
// Seems like this does it: `addLocaleData(allLocaleData);`.
// addLocaleData(enLocaleData)
// addLocaleData(itLocaleData)
// addLocaleData(huLocaleData)
const MESSAGES_MAP = {
  'en-GB': enGbMessages,
  'it-IT': itItMessages,
  'hu-HU': huHuMessages
}

const oldOnError = window.onerror
window.onerror = function(messageOrEvent, source, lineno, colno, error) {
  if (oldOnError) {
    oldOnError.apply(this, arguments)
  }
}

const store = (window.__orkestroStore =
  window.__orkestroStore || configureStore())
const dragAndDropProvider = (window.dragAndDropProvider =
  window.dragAndDropProvider || DragDropContext(HTML5Backend))

store.dispatch(AuthActions.loadLocalAuthentication())

class I18nProvider extends PureComponent<{ locale: string }> {
  changeLocale() {
    moment.locale('en') // @TODO fix Error if using en-GB (Cannot find)
    RWMomentLocalizer(moment)
  }
  componentWillMount() {
    this.changeLocale()
  }
  componentWillUpdate() {
    this.changeLocale()
  }
  render() {
    const messages = MESSAGES_MAP[this.props.locale]
    return (
      <IntlProvider locale={this.props.locale} messages={messages}>
        {Router}
      </IntlProvider>
    )
  }
}

const I18nRedux = connect((state: any) => ({
  locale: state.LocaleReducer.get('locale')
}))(I18nProvider)

const ChangefeedProviderConnected = connect(
  (state: any) => ({
    token: state.AuthReducer && state.AuthReducer.get('token')
  }),
  (dispatch) => ({
    dispatch
  })
)(ChangfeedProvider)

const AppRoot = dragAndDropProvider(
  class AppWrapper extends React.Component {
    render() {
      return (
        <StoreProvider store={store}>
          <ChangefeedProviderConnected>
            <I18nRedux />
          </ChangefeedProviderConnected>
        </StoreProvider>
      )
    }
  }
)

Sentry.init({ dsn: process.env.SENTRY_DSN })

function renderApp() {
  ReactDOM.unmountComponentAtNode(document.getElementById('app'))
  ReactDOM.render(<AppRoot />, document.getElementById('app'))
}

if (!areIntlLocalesSupported(SUPPORTED_LOCALES)) {
  // Load Intl polyfil in case Intl or some locales are not supported
  import('intl').then((intl) => {
    Intl.NumberFormat = intl.NumberFormat
    Intl.DateTimeFormat = intl.DateTimeFormat
    renderApp()
  })
} else {
  // Intl and all locales are supported, no need to load additional libraries
  renderApp()
}

export { store }
