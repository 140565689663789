import { List, Map as ImmutableMap } from 'immutable'
import { flow } from 'lodash'
import React from 'react'
import {
  Alert,
  Button,
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Grid,
  Modal
} from 'react-bootstrap'
import { FormattedMessage, injectIntl } from 'react-intl'
import { reduxForm } from 'redux-form'

import { getFormControlPropsFromReduxForm } from '../../../../util/form'
import RFSelectList from '../../../common/RFSelectList/RFSelectList'
import Spinner from '../../../common/Spinner/Spinner'

import './DriverForm.scss'

function ErrorMessage({ error }: { error: any }) {
  return (
    <Alert
      bsStyle='danger'
      className='pull-left'
      title={JSON.stringify(error, null, 2)}
    >
      {error === 'mobileNumberNotUnique' ? (
        <FormattedMessage id='management.drivers.modal.mobileNumberNotUnique' />
      ) : (
        <FormattedMessage id='management.drivers.modal.error' />
      )}
    </Alert>
  )
}

interface Props {
  // injected by parent component
  driver: ImmutableMap<any, any>
  merchants: List<any>
  onModalHide: React.MouseEventHandler<Button>
  sendOnboardingSms: React.MouseEventHandler<Button>
  sendOnboardingSmsSubmitting?: boolean
  // injected by react-intl
  intl: any
  // injected by redux-form
  fields: any
  handleSubmit: () => Promise<void>
  pristine: boolean
  submitting: boolean
  error: any
  archiveSubmitting: boolean
  archive: React.MouseEventHandler<Button>
}

class DriverForm extends React.PureComponent<Props> {
  render() {
    const { driver } = this.props
    const {
      companyName,
      firstName,
      lastName,
      mobileNumber,
      email,
      postcode,
      merchants,
      optinArrivedAtPickupAndArrivedAtDropoffStatus
    } = this.props.fields

    return (
      <Modal
        animation={false}
        className='DriverForm'
        show
        onHide={this.props.onModalHide}
      >
        <form onSubmit={this.props.handleSubmit}>
          <Modal.Header>
            <Modal.Title>
              <i className='fa fa-user spaceAfter' />
              {driver == null ? (
                <FormattedMessage id='management.drivers.modal.addTitle' />
              ) : (
                <FormattedMessage id='management.drivers.modal.editTitle' />
              )}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Grid fluid>
              <div className='form-horizontal'>
                <FormGroup controlId='companyName'>
                  <Col sm={4} componentClass={ControlLabel}>
                    <FormattedMessage id='driver.companyName' />
                  </Col>
                  <Col sm={8}>
                    <FormControl
                      type='text'
                      {...getFormControlPropsFromReduxForm(companyName)}
                    />
                  </Col>
                </FormGroup>

                <FormGroup controlId='firstName'>
                  <Col sm={4} componentClass={ControlLabel}>
                    <FormattedMessage id='driver.firstName' />
                  </Col>
                  <Col sm={8}>
                    <FormControl
                      type='text'
                      {...getFormControlPropsFromReduxForm(firstName)}
                    />
                  </Col>
                </FormGroup>

                <FormGroup controlId='lastName'>
                  <Col sm={4} componentClass={ControlLabel}>
                    <FormattedMessage id='driver.lastName' />
                  </Col>
                  <Col sm={8}>
                    <FormControl
                      type='text'
                      {...getFormControlPropsFromReduxForm(lastName)}
                    />
                  </Col>
                </FormGroup>

                <FormGroup controlId='mobileNumber'>
                  <Col sm={4} componentClass={ControlLabel}>
                    <FormattedMessage id='driver.mobileNumber' />
                  </Col>
                  <Col sm={8}>
                    <FormControl
                      type='text'
                      {...getFormControlPropsFromReduxForm(mobileNumber)}
                    />
                  </Col>
                </FormGroup>

                <FormGroup controlId='email'>
                  <Col sm={4} componentClass={ControlLabel}>
                    <FormattedMessage id='driver.email' />
                  </Col>
                  <Col sm={8}>
                    <FormControl
                      type='email'
                      {...getFormControlPropsFromReduxForm(email)}
                    />
                  </Col>
                </FormGroup>

                <FormGroup controlId='postcode'>
                  <Col sm={4} componentClass={ControlLabel}>
                    <FormattedMessage id='driver.postcode' />
                  </Col>
                  <Col sm={8}>
                    <FormControl
                      type='text'
                      {...getFormControlPropsFromReduxForm(postcode)}
                    />
                  </Col>
                </FormGroup>

                <FormGroup controlId='optinArrivedAtPickupAndArrivedAtDropoffStatus'>
                  <Col sm={4} componentClass={ControlLabel}>
                    <FormattedMessage id='driver.optinArrivedAtPickupAndArrivedAtDropoffStatus' />
                  </Col>
                  <Col sm={8}>
                    <FormControl
                      type='checkbox'
                      className='checkbox'
                      {...getFormControlPropsFromReduxForm(
                        optinArrivedAtPickupAndArrivedAtDropoffStatus
                      )}
                    />
                  </Col>
                </FormGroup>

                <FormGroup controlId='merchants'>
                  <Col sm={4} componentClass={ControlLabel}>
                    <FormattedMessage id='driver.merchants' />
                  </Col>
                  <Col sm={8}>
                    <RFSelectList
                      {...getFormControlPropsFromReduxForm(merchants)}
                      data={this.props.merchants.toJS()}
                      multiple
                      onBlur={null}
                    />
                  </Col>
                </FormGroup>
              </div>
            </Grid>
          </Modal.Body>

          <Modal.Footer>
            {this.props.error == null ? null : (
              <ErrorMessage error={this.props.error} />
            )}

            {this.props.driver ? (
              <Button
                onClick={this.props.archive}
                disabled={this.props.archiveSubmitting}
                data-testid='delete-driver-button'
              >
                <FormattedMessage id='management.drivers.modal.archive' />
              </Button>
            ) : null}

            {this.props.driver ? (
              <Button
                onClick={this.props.sendOnboardingSms}
                disabled={this.props.sendOnboardingSmsSubmitting}
              >
                <FormattedMessage id='management.drivers.modal.sendOnboardingSms' />
              </Button>
            ) : null}

            <Button
              onClick={this.props.onModalHide}
              data-testid='cancel-driver-button'
              disabled={this.props.submitting}
            >
              <i className='fa fa-minus spaceAfter' />
              <FormattedMessage id='management.drivers.modal.cancel' />
            </Button>
            <Button
              type='submit'
              className='btn-brand-secondary'
              data-testid='save-driver-button'
              disabled={this.props.pristine || this.props.submitting}
            >
              {this.props.submitting ? (
                <Spinner showText={false} className='spaceAfter' />
              ) : (
                <i className='fa fa-check spaceAfter' />
              )}

              <FormattedMessage id='management.drivers.modal.save' />
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

const reduxFormConfig = {
  form: 'driver',
  fields: [
    'companyName',
    'firstName',
    'lastName',
    'email',
    'mobileNumber',
    'postcode',
    'merchants',
    'password',
    'optinArrivedAtPickupAndArrivedAtDropoffStatus'
  ],
  validate() {
    return {}
  }
}

export { ErrorMessage, reduxFormConfig }
export default flow(reduxForm(reduxFormConfig))(injectIntl(DriverForm))
