import { fromJS, is } from 'immutable'

import {
  CHANGE_LAST_QUOTE_APPROVE_QUERY,
  CHANGE_LAST_QUOTE_CANCEL_QUERY,
  RESET_LAST_QUOTE_QUERIES,
  SELECT_QUOTE,
  TOGGLE_QUOTE
} from '../actions/QuotesUiActions'

const initialState = fromJS({
  selectedQuotes: {},
  lastApproveQuoteQueries: {},
  lastCancelQuoteQueries: {}
})

export default function QuotesUiReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_QUOTE:
      const key = ['chosenQuote', action.taskId]
      return state.setIn(
        key,
        is(state.getIn(key), action.chosenQuote) ? null : action.chosenQuote
      )

    case SELECT_QUOTE:
      return state.setIn(['chosenQuote', action.taskId], action.chosenQuote)

    case RESET_LAST_QUOTE_QUERIES:
      return state.merge({
        lastApproveQuoteQueries: {},
        lastCancelQuoteQueries: {}
      })

    case CHANGE_LAST_QUOTE_APPROVE_QUERY:
      return state.setIn(
        ['lastApproveQuoteQueries', action.taskId],
        fromJS(action.query)
      )

    case CHANGE_LAST_QUOTE_CANCEL_QUERY:
      return state.setIn(
        ['lastCancelQuoteQueries', action.taskId],
        fromJS(action.query)
      )

    default:
      return state
  }
}
