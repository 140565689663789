import { fromJS, Map as ImmutableMap } from 'immutable'
import { createSelector } from 'reselect'

function getDrivers(state) {
  return state.services.getIn(['drivers', 'all', fromJS({}), 'data'])
}

const combiner = (drivers) => {
  const assignments = ImmutableMap().asMutable()

  if (drivers != null) {
    drivers.forEach((driver) => {
      driver.get('assignedTasks').forEach((taskId) => {
        assignments.set(taskId, driver.get('id'))
      })
    })
  }

  return assignments.asImmutable()
}

export { getDrivers, combiner }
export default createSelector(getDrivers, combiner)
