import config from '../config'
import log from '../util/logger'

function requestAuthentication(credentials) {
  return (dispatch) => {
    dispatch(authenticationInProgress())

    window
      .fetch(config.trackingServer + '/users/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(credentials)
      })
      .then((response) => {
        if (!response.ok) {
          response
            .text()
            .then((responseText) => {
              dispatch(rejectAuthentication(responseText))
            })
            .catch((error) => {
              log('Authentication error', error)
              dispatch(rejectAuthentication(error))
            })
        } else {
          return response
            .json()
            .then((responseJSON) => {
              dispatch(resolveAuthentication(responseJSON))
            })
            .catch((error) => {
              log('Authentication error', error)
              dispatch(rejectAuthentication(error))
            })
        }
      })
      .catch((error) => {
        log('Authentication error', error)
        dispatch(rejectAuthentication(error))
      })
  }
}

function loadLocalAuthentication() {
  return (dispatch) => {
    dispatch(authenticationInProgress())

    try {
      const user = JSON.parse(window.localStorage.getItem('user'))
      const token = window.localStorage.getItem('token')

      if (user && token) {
        dispatch(resolveAuthentication({ user, token }))
      } else {
        dispatch(rejectAuthentication('noToken'))
      }
    } catch (e) {
      dispatch(rejectAuthentication('noToken'))
    }
  }
}

const LOGOUT = 'LOGOUT'
function logout(reason) {
  return { type: LOGOUT, reason }
}

const AUTHENTICATION_IN_PROGRESS = 'AUTHENTICATION_IN_PROGRESS'
function authenticationInProgress() {
  return { type: AUTHENTICATION_IN_PROGRESS }
}

const AUTHENTICATION_RESOLVED = 'AUTHENTICATION_RESOLVED'
function resolveAuthentication({ user, token }) {
  return { type: AUTHENTICATION_RESOLVED, user, token }
}

const AUTHENTICATION_REJECTED = 'AUTHENTICATION_REJECTED'
function rejectAuthentication(error) {
  return { type: AUTHENTICATION_REJECTED, error }
}

export {
  AUTHENTICATION_REJECTED,
  AUTHENTICATION_RESOLVED,
  AUTHENTICATION_IN_PROGRESS,
  LOGOUT
}
export default {
  requestAuthentication,
  logout,
  loadLocalAuthentication
}
