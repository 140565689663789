import React from 'react'
import { FormControl } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

import { TASK_STATUS } from '../../../../../constants'

interface Props {
  inputRef?: any
  disabled?: boolean
  value?: any
  onChange?: () => void
}

class StatusSelect extends React.PureComponent<Props> {
  private renderLabel = (value) => (label) => {
    return <option value={value}>{label}</option>
  }

  render() {
    return (
      <FormControl componentClass='select' {...this.props}>
        {[
          TASK_STATUS.TASK_RECEIVED,
          TASK_STATUS.DRIVER_ASSIGNED,
          TASK_STATUS.ARRIVED_AT_PICKUP,
          TASK_STATUS.IN_TRANSIT,
          TASK_STATUS.ARRIVED_AT_DROPOFF,
          TASK_STATUS.SUCCESS,
          TASK_STATUS.FAILED
        ].map((status) => (
          <FormattedMessage id={`task.status.${status}`} key={status}>
            {this.renderLabel(status)}
          </FormattedMessage>
        ))}
      </FormControl>
    )
  }
}

export default StatusSelect
