import config from './config'
import { parallelLimited } from './util/async'

const fetchLimited = parallelLimited(fetch, 5)

async function orkestroRpcCall(namespace, method, params) {
  const token = window.localStorage.getItem('token')

  try {
    const resp = await fetchLimited(
      `${config.trackingServer}/rpc/${namespace}/${method}`,
      {
        method: 'POST',
        headers: {
          authorization: token ? `Bearer ${token}` : undefined,
          'content-type': 'application/json'
        },
        body: JSON.stringify(params)
      }
    )

    return await resp.json()
  } catch (e) {
    // tslint:disable-next-line
    console.error('ResponseError', namespace, method, params, e)
    return {
      errors: [e.message]
    }
  }
}

export { orkestroRpcCall }
