import { debounce, noop } from 'lodash'
import React from 'react'
import { Button } from 'react-bootstrap'
import { Subscription } from 'rxjs'

import chat from '../../../services/chat/chat'
import { MessageType } from '../../../services/chat/types'
import { messagesQuery } from '../../../services/firebase/firestoreQuery'
import TaskCloseButton from '../../map/task/edit/TaskFormButtons/TaskCloseButton/TaskCloseButton'
import { formatTime } from '../../map/tasks/Task/statusUtils'

import './ChatWindow.scss'
import Composer from './Composer/Composer'
import Message from './Message/Message'
import NoMessage from './NoMessage/NoMessage'
import { ResolvedMessage } from './ResolvedMessage/ResolvedMessage'
import { Props, ResponseChatMessage, SendMessage, State } from './types'

const CHAT_WINDOW_BOTTOM_OFFSET = 150
const onScrollDebounce = debounce((taskId: string) => {
  chat.markAsRead(taskId)
}, 500)

class ChatWindow extends React.Component<Props, State> {
  ref = React.createRef<HTMLDivElement>()
  subscription: Subscription = null
  unsubscriber = noop

  constructor(props) {
    super(props)

    this.state = {
      messages: [],
      needScrollToBottom: true,
      loading: true,
      isAdministrator: false,
      resolved: false
    }
  }

  componentDidMount(): void {
    const { taskId } = this.props

    let force = true
    this.subscription = chat.subject.subscribe((value) => {
      this.setState({
        resolved: chat.isResolved(taskId),
        isAdministrator: value.isAdmin
      })
    })

    this.unsubscriber = messagesQuery(taskId).onSnapshot((snapshot) => {
      const { needScrollToBottom } = this.state
      const shouldScroll = needScrollToBottom || this.isScrolledAtBottom()

      this.setState(
        {
          needScrollToBottom: false,
          loading: false,
          messages: snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data()
          })) as ResponseChatMessage[]
        },
        () => {
          if (shouldScroll) {
            this.scrollToBottom(force)
            force = false

            if (chat.hasUnreadMessages(taskId)) {
              chat.markAsRead(taskId)
            }
          }
        }
      )
    })
  }

  componentWillUnmount(): void {
    this.subscription.unsubscribe()
    this.unsubscriber()
  }

  isScrolledAtBottom = () => {
    const scrollOffset =
      this.ref.current.scrollHeight -
      this.ref.current.scrollTop -
      this.ref.current.clientHeight

    return scrollOffset < CHAT_WINDOW_BOTTOM_OFFSET
  }

  scrollToBottom = (force: boolean) => {
    const shouldScroll = force || this.isScrolledAtBottom()

    if (shouldScroll) {
      this.ref.current.scroll({
        ...(!force && { behavior: 'smooth' }),
        top: this.ref.current.scrollHeight
      })
    }
  }

  onSend = ({ message, type = MessageType.TEXT, messageData }: SendMessage) => {
    const { messages } = this.state
    const { taskId } = this.props
    const username = chat.getCurrentUsername()

    this.setState({
      needScrollToBottom: true,
      messages: [
        ...messages,
        {
          sender: { username },
          message,
          messageData,
          type,
          sending: true
        }
      ]
    })

    chat.addMessage({
      taskId,
      username,
      message,
      messageData,
      type
    })
  }

  getUniqueString = (messageObject: ResponseChatMessage) => {
    return (
      messageObject &&
      `${messageObject.sender.username}_${messageObject.timestamp &&
        formatTime(messageObject.timestamp.seconds * 1000)}`
    )
  }

  onScroll = () => {
    const { taskId } = this.props

    if (chat.hasUnreadMessages(taskId) && this.isScrolledAtBottom()) {
      onScrollDebounce(taskId)
    }
  }

  private openImage = (imageData) => () => {
    const newTab = window.open()

    newTab.document.body.innerHTML = `<img src="${imageData}">`
  }

  renderMessages = () => {
    const { externalRef, taskId } = this.props
    const { messages, loading } = this.state

    if (loading) {
      return <div className='loadingContainer'>LOADING MESSAGES...</div>
    }

    if (messages.length === 0) {
      return <NoMessage externalRef={externalRef} />
    }

    return messages.map((responseMessage, index) => {
      const { message: messageText, messageData, type } = responseMessage
      const isTheSameSender =
        this.getUniqueString(messages[index - 1]) ===
        this.getUniqueString(responseMessage)

      const key = responseMessage?.id ?? `${index}_${messageText}`

      if (chat.getMessageType(responseMessage) === MessageType.RESOLVED) {
        if (!chat.isAdmin()) {
          return null
        }

        return <ResolvedMessage key={key} message={responseMessage} />
      }

      const messageProps = {
        key,
        taskId,
        message: responseMessage,
        isTheSameSender
      }

      if (type === MessageType.IMAGE) {
        return (
          <Message {...messageProps}>
            <div onClick={this.openImage(messageData)} className='pointer'>
              <img src={messageData} alt='chat image' height={160} />
            </div>
          </Message>
        )
      }

      return <Message {...messageProps}>{messageText}</Message>
    })
  }

  private onResolve = () => {
    const { taskId } = this.props

    chat.resolve(taskId)
  }

  private renderResolveButton = () => {
    const { taskId } = this.props
    const { isAdministrator, resolved } = this.state

    if (!isAdministrator || !chat.hasMessages(taskId)) {
      return null
    }

    const buttonProps = resolved
      ? { disabled: resolved, bsStyle: 'success' }
      : {}

    return (
      <Button onClick={this.onResolve} className='spaceAfter' {...buttonProps}>
        {resolved && <i className='fa fa-check spaceAfter' />}
        {resolved ? 'Resolved' : 'Resolve'}
      </Button>
    )
  }

  render() {
    const { externalRef, onBack, onClose, isTaskArchived } = this.props

    return (
      <div className='chatWindow'>
        <div className='header'>
          <div className='left row-align-center'>
            <i className='fa fa-comment-alt fa-lg' />
            <span className='title'>{externalRef}</span>
          </div>

          <div className='right row-align-center'>
            <Button onClick={onBack} className='spaceAfterLarge'>
              <i className='fa fa-arrow-left spaceAfter' />
              Back to Order
            </Button>
            {this.renderResolveButton()}
            <TaskCloseButton onClick={onClose} />
          </div>
        </div>
        <div className='content' ref={this.ref} onScroll={this.onScroll}>
          {this.renderMessages()}
        </div>

        <Composer onSend={this.onSend} disabled={isTaskArchived} />
      </div>
    )
  }
}

export default ChatWindow
