import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'

import reducers from '../reducers'
import {
  asyncActionsMiddleware,
  flushAsyncQueue
} from '../util/redux/asyncActions'
import { batchReducer } from '../util/redux/batchActions'

const reduxDevtoolsFunctionName = '__REDUX_DEVTOOLS_EXTENSION__'

const configureStore = () => {
  const store = createStore(
    batchReducer(reducers),
    {},
    compose(
      applyMiddleware(() => (next) => (action: any) => {
        const n = Date.now()
        const result = next(action)
        if (Date.now() - n > 500) {
          // tslint:disable-next-line
          console.warn(
            new Error(
              `slow action: ${Date.now() -
                n}ms ${typeof action} ${action.type ||
                action.funcName ||
                action.name}`
            )
          )
        }
        return result
      }),
      applyMiddleware(asyncActionsMiddleware),
      applyMiddleware(thunk),
      window[reduxDevtoolsFunctionName]
        ? window[reduxDevtoolsFunctionName]()
        : (x) => x
    )
  )
  setInterval(() => {
    flushAsyncQueue(store.dispatch)
  }, 100)
  return store
}

export default configureStore
