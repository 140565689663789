import { fromJS, Map as ImmutableMap } from 'immutable'
import { createSelector } from 'reselect'

function getDrivers(state) {
  return state.services.getIn(['drivers', 'all', fromJS({}), 'data'])
}

const combiner = (drivers) => {
  if (drivers != null) {
    return drivers.sortBy((driver) => {
      return driver.get('firstName') + ' ' + driver.get('lastName')
    })
  } else {
    return ImmutableMap()
  }
}

export { getDrivers, combiner }
export default createSelector(getDrivers, combiner)
