import React, { Component } from 'react'

import config from '../../../../config'
import UploadForm from '../UploadForm/UploadForm'
import UploadResponse from '../UploadResponse/UploadResponse'

import './UploadDialog.scss'

interface State {
  file: File
  submitting: boolean
  pristine: boolean
  error: any
  response: any
}

class UploadDialog extends Component<{}, State> {
  constructor(props) {
    super(props)
    this.state = {
      file: null,
      pristine: true,
      submitting: false,
      error: null,
      response: null
    }
  }

  handleSubmit(event) {
    event.preventDefault()

    this.setState({ submitting: true })

    return new Promise((resolve, reject) => {
      const data = new window.FormData()
      data.append('file', this.state.file)

      window
        .fetch(config.trackingServer + '/import', {
          method: 'POST',
          headers: {
            Accept: 'application/json'
          },
          body: data
        })
        .catch((error) => this.setState({ error, submitting: false }))
        .then((response: any) => {
          this.setState({ submitting: false })
          if (response == null) {
            return reject('timeout')
          } else if (!response.ok) {
            return reject(response.status)
          } else {
            return response.text()
          }
        })
        .catch((error) => this.setState({ error }))
        .then((json) => {
          this.setState({ response: json })
          resolve(json)
        })
    })
  }

  handleFileChange(event) {
    this.setState({
      pristine: false,
      file: event.target.files[0]
    })
  }

  render() {
    if (this.state.response == null) {
      return (
        <UploadForm
          onSubmit={this.handleSubmit.bind(this)}
          fileSelected={this.state.file}
          pristine={this.state.pristine}
          submitting={this.state.submitting}
          error={this.state.error}
          fields={{
            file: {
              onChange: this.handleFileChange.bind(this)
            }
          }}
        />
      )
    } else {
      return <UploadResponse response={this.state.response} />
    }
  }
}

export default UploadDialog
