import React, { PureComponent } from 'react'
import {
  Alert,
  Button,
  ButtonToolbar,
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Grid,
  HelpBlock,
  Row
} from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { compose } from 'recompose'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'

import AuthActions from '../../actions/AuthActions'
import { Routes } from '../../navigation/Routes'
import { getFormControlPropsFromReduxForm } from '../../util/form'
import Spinner from '../common/Spinner/Spinner'

interface Props extends RouteComponentProps {
  // injected by redux-form
  fields: any
  handleSubmit: (func) => (event) => Promise<void>
  // injected by react-redux
  AuthActions: typeof AuthActions
  AuthReducer: any
}

class Login extends PureComponent<Props> {
  handleSubmit(credentials) {
    this.props.AuthActions.requestAuthentication(credentials)
  }

  componentWillReceiveProps(nextProps) {
    // After successfully logging in, redirect the user to the next page.
    if (nextProps.AuthReducer.get('user') != null) {
      const { location } = this.props
      if (location.state && location.state.nextPathname) {
        this.props.history.replace(location.state.nextPathname)
      } else {
        this.props.history.replace(Routes.Root)
      }
    }
  }

  renderLogoutMessage() {
    if (this.props.AuthReducer.get('logoutReason') === 'user') {
      return (
        <Alert bsStyle='success'>
          <FormattedMessage id='login.logoutSuccess' />
        </Alert>
      )
    }
    return null
  }

  renderLoginError() {
    const lastError = this.props.AuthReducer.get('lastError')
    if (lastError && lastError !== 'noToken') {
      return <Alert bsStyle='danger'>{lastError}</Alert>
    }

    return null
  }

  render() {
    const { username, password } = this.props.fields

    let usernameValidationState
    if (username.touched && username.error) {
      usernameValidationState = 'error'
    }

    let passwordValidationState
    if (password.touched && password.error) {
      passwordValidationState = 'error'
    }

    return (
      <Grid className='Login'>
        <Row>
          <Col sm={8} smOffset={2} md={6} mdOffset={3} lg={4} lgOffset={4}>
            <h1>
              <FormattedMessage id='login.title' />
            </h1>

            {this.renderLogoutMessage()}
            {this.renderLoginError()}

            <form
              onSubmit={this.props.handleSubmit(this.handleSubmit.bind(this))}
            >
              <FormGroup
                controlId='username'
                validationState={usernameValidationState}
              >
                <ControlLabel>
                  <FormattedMessage id='login.username' />
                </ControlLabel>
                <FormControl
                  data-testid='login-email'
                  type='text'
                  bsSize='large'
                  {...getFormControlPropsFromReduxForm(username)}
                />
                <FormControl.Feedback />
                {usernameValidationState == null ? null : (
                  <HelpBlock>{username.error}</HelpBlock>
                )}
              </FormGroup>

              <FormGroup
                controlId='password'
                validationState={passwordValidationState}
              >
                <ControlLabel>
                  <FormattedMessage id='login.password' />
                </ControlLabel>
                <FormControl
                  data-testid='login-password'
                  type='password'
                  bsSize='large'
                  {...getFormControlPropsFromReduxForm(password)}
                />
                <FormControl.Feedback />
                {passwordValidationState == null ? null : (
                  <HelpBlock>{password.error}</HelpBlock>
                )}
              </FormGroup>

              <ButtonToolbar>
                <Button
                  data-testid='sign-in-button'
                  type='submit'
                  bsStyle='primary'
                  bsSize='large'
                  className='spaceAfter'
                  disabled={this.props.AuthReducer.get('inProgress')}
                >
                  <Spinner
                    showText={false}
                    className={
                      'spaceAfter ' +
                      (this.props.AuthReducer.get('inProgress')
                        ? ''
                        : 'invisible')
                    }
                  />
                  <FormattedMessage id='login.loginButton' />
                </Button>

                <a href='https://share.hsforms.com/1FRarqN3FTOSUC6ArTLht9w3cfig'>
                  <Button
                    bsSize='large'
                    className='btn-brand-secondary'
                    disabled={this.props.AuthReducer.get('inProgress')}
                  >
                    <FormattedMessage id='login.registerButton' />
                  </Button>
                </a>
              </ButtonToolbar>
            </form>
          </Col>
        </Row>
      </Grid>
    )
  }
}

const mapStateToProps = (state: any) => ({
  AuthReducer: state.AuthReducer
})
const mapDispatchToProps = (dispatch) => ({
  AuthActions: bindActionCreators(AuthActions, dispatch)
})

const reduxFormConfig = {
  form: 'login',
  fields: ['username', 'password'],
  validate(fields) {
    const errors: any = {}

    if (fields.username == null || fields.username.length === 0) {
      errors.username = 'You must enter a username.'
    }
    if (fields.password == null || fields.password.length === 0) {
      errors.password = 'You must enter a password.'
    }

    return errors
  }
}

export { mapStateToProps, mapDispatchToProps, reduxFormConfig }
export default compose(
  reduxForm(reduxFormConfig),
  connect(mapStateToProps, mapDispatchToProps)
)(Login)
