import { fromJS } from 'immutable'
import React from 'react'
import { Button } from 'react-bootstrap'
import { RouteComponentProps } from 'react-router-dom'

import { TASK_STATUS } from '../../../../constants'
import { getPickupAndDropoffTime } from '../Task/statusUtils'
import { getVehicleClassName } from '../VehicleIcon/VehicleIcon'

import './PrintTasks.scss'
import { State } from './types'

class PrintTasks extends React.Component<RouteComponentProps, State> {
  constructor(props) {
    super(props)

    const { tasksWithDriver, driverName, merchantName } = JSON.parse(
      window.localStorage.getItem('printTasks')
    )

    this.state = {
      tasksWithDriver,
      driverName,
      merchantName
    }
  }
  componentDidMount(): void {
    if (!this.state.tasksWithDriver) {
      return
    }

    window.print()
  }

  printTasksForDriver = (
    tasksWithDriver,
    merchantName: string,
    driverName?: string
  ) => {
    return (
      <div>
        <div className='row-align-center'>
          <h3 className='spaceAfter'>Merchant:</h3>
          <h3>{merchantName}</h3>
        </div>

        {Object.keys(tasksWithDriver).map((name: string) => {
          if (driverName && driverName !== name) {
            return []
          }

          return (
            <>
              <div className='row-align-center'>
                <h4 className='spaceAfter'>Driver:</h4>
                <h4>{name || '-'}</h4>
              </div>
              <table className='table'>
                <thead>
                  <tr>
                    <th className='printColumn25'>Order</th>
                    <th className='printColumn10'>Pick up time</th>
                    <th className='printColumn10'>Dropoff time</th>
                    <th className='printColumn5'>Vehicle</th>
                    <th className='printColumn30'>Dropoff</th>
                    <th className='printColumn20'>Notes</th>
                  </tr>
                </thead>
                <tbody>
                  {tasksWithDriver[name].map((task, index) => {
                    if (task.currentStatus === TASK_STATUS.CANCELLED) {
                      return null
                    }

                    const { pickup, dropoff } = getPickupAndDropoffTime(
                      fromJS(task),
                      null
                    )

                    return (
                      <tr>
                        <td className='printColumn25'>{task.externalRef}</td>
                        <td className='printColumn10'>{pickup || ''}</td>
                        <td className='printColumn10'>{dropoff || ''}</td>
                        <td className='printColumn5'>
                          {task.quotes && task.quotes.chosenQuote ? (
                            <i
                              className={`${getVehicleClassName(
                                task.quotes.chosenQuote.vehicleType
                              )}`}
                            />
                          ) : (
                            <span>-</span>
                          )}
                        </td>
                        <td className='printColumn30'>
                          {task.destinationAddress}
                        </td>
                        <td className='printColumn20' />
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </>
          )
        })}
      </div>
    )
  }

  render() {
    const { tasksWithDriver, driverName, merchantName } = this.state

    if (!tasksWithDriver) {
      return <div>No data</div>
    }

    return (
      <div className='printTasks'>
        <Button
          className='printButton'
          bsSize='large'
          onClick={() => window.print()}
        >
          Print
        </Button>
        {this.printTasksForDriver(tasksWithDriver, merchantName, driverName)}
      </div>
    )
  }
}

export default PrintTasks
