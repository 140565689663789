import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'

import AuthReducer from './AuthReducer'
import LocaleReducer from './LocaleReducer'
import MapUiReducer from './MapUiReducer'
import NotificationsUiReducer from './NotificationsUiReducer'
import QuotesUiReducer from './QuotesUiReducer'
import ReportsUiReducer from './ReportsUiReducer'
import SettingsUiReducer from './SettingsUiReducer'
import SocketNamespacesReducer from './SocketNamespacesReducer'
import services from './services'

export default combineReducers({
  form,
  SocketNamespacesReducer,
  LocaleReducer,
  AuthReducer,
  services,
  MapUiReducer,
  NotificationsUiReducer,
  QuotesUiReducer,
  ReportsUiReducer,
  SettingsUiReducer
})
