import React, { Component } from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'

import config from '../../config'
import { Routes } from '../../navigation/Routes'

interface State {
  stage: string
}

class ConfirmForm extends Component<
  RouteComponentProps<{ token: string }>,
  State
> {
  constructor(props) {
    super(props)

    this.state = {
      stage: 'submitting'
    }
  }

  componentDidMount() {
    // Process the token
    return window
      .fetch(
        `${config.trackingServer}/users/${this.props.match.params.token}/confirm`,
        {
          method: 'PUT'
        }
      )
      .then((response) => {
        return response.json()
      })
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            stage: 'success'
          })
        } else {
          this.setState({
            stage: JSON.stringify(response.error, null, 2)
          })
        }
      })
      .catch((error) => {
        this.setState({
          stage: JSON.stringify(error, null, 2)
        })
      })
  }

  render() {
    if (this.state.stage === 'submitting') {
      return <div>Submitting token, please wait...</div>
    } else if (this.state.stage === 'success') {
      return (
        <div>
          <div>Thank you. You are now confirmed</div>
          <Link to={Routes.Root}>Continue</Link>
        </div>
      )
    } else {
      return <div>Error: {this.state.stage}</div>
    }
  }
}
export default ConfirmForm
