import { fromJS } from 'immutable'
import React from 'react'
import { connect } from 'react-redux'
import { generatePath, RouteComponentProps, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import TaskActions from '../../../../actions/services/TaskActions'
import { VIEW_TYPE } from '../../../../constants'
import { Routes } from '../../../../navigation/Routes'
import TaskForm from '../../task/edit/TaskForm/TaskForm'

interface Props extends RouteComponentProps<{ viewType: VIEW_TYPE }> {
  selectedTasks: any[]
  TaskActions: typeof TaskActions
  className?: any
  close(): any
}

class MultidropDialog extends React.PureComponent<Props> {
  handleSubmit = (formData) => {
    const { selectedTasks } = this.props
    const selectedExternalRefs = selectedTasks.map((task) =>
      fromJS({
        id: task.get('id'),
        externalRef: task.get('externalRef')
      })
    )
    let data = fromJS(formData)

    // Remove the `currentStatus` and `newNote` fields, these will need to be
    // passed to their own service methods (not to `insert` or `update`).
    data = data.delete('currentStatus')
    data = data.delete('newNote')
    data = data.set('containedTaskIds', selectedExternalRefs)
    data = data.set('type', 'multidrop')

    return new Promise((resolve, reject) => {
      // If this is a new task...
      // First insert the new task...
      this.props.TaskActions.geocodeAndInsert({ data })
        .catch((_error) => reject({ _error }))
        .then((response) => {
          // Then update the task with additional status changes and notes.
          const taskId = response.get('generatedKeys').get(0)

          // Reject the promise if there was an error while inserting the task.
          if (!taskId) {
            alert(JSON.stringify(response.toJS()))
            reject({ _error: response.get('lastError') })
          } else {
            this.props.close()
            this.props.history.push(
              generatePath(Routes.Map.TaskDetail, {
                taskId,
                viewType: this.props.match.params.viewType
              })
            )
            resolve()
          }
        })
    })
  }

  render() {
    const task = createMultidrop(this.props.selectedTasks)
    if (!task) {
      return null
    }
    return (
      <TaskForm
        className={this.props.className}
        taskId={undefined}
        sourceError={false}
        destinationError={false}
        onModalHide={this.props.close}
        onCloneClick={() => null}
        cloningInProgress={false}
        cloneError={null}
        onArchiveClick={() => null}
        archivingInProgress={false}
        archiveError={undefined}
        initialValues={task}
        onSubmit={this.handleSubmit.bind(this)}
      />
    )
  }
}

function createMultidrop(tasks) {
  const first = tasks.first()
  return {
    type: 'multidrop',
    externalRef:
      'MD' +
      tasks.size +
      '_' +
      mergeExternalRefs(tasks.map((task) => task.get('externalRef')).toJS()),
    onDemand: false,
    merchantId: first.get('merchantId'),
    schedule: first.get('schedule'),
    parcelLength: first.get('parcelLength'),
    parcelWidth: first.get('parcelWidth'),
    parcelHeight: first.get('parcelHeight'),
    parcelWeight: first.get('parcelWeight'),
    parcelFragile: first.get('parcelFragile'),
    parcelDoNotRotate: first.get('parcelDoNotRotate'),
    parcelContainsLiquid: first.get('parcelContainsLiquid'),
    parcelContainsHotFood: first.get('parcelContainsHotFood'),
    currentStatus: first.get('currentStatus'),
    sourceName: first.get('sourceName'),
    sourceAddress: first.get('sourceAddress'),
    sourceCity: first.get('sourceCity'),
    sourcePostcode: first.get('sourcePostcode'),
    sourceCountry: first.get('sourceCountry'),
    sourcePhone: first.get('sourcePhone'),
    sourceEmail: first.get('sourceEmail'),
    destinationCity: first.get('sourceCity'),
    destinationCountry: first.get('sourceCountry'),
    destinationName: first.get('sourceName'),
    destinationAddress: first.get('sourceAddress'),
    destinationEmail: first.get('sourceEmail'),
    destinationPhone: first.get('sourcePhone'),
    destinationPostcode: first.get('sourcePostcode'),
    pickupDateFrom: first.get('pickupDateFrom'),
    pickupDateTo: first.get('pickupDateTo'),
    deliveryDateFrom: first.get('deliveryDateFrom'),
    deliveryDateTo: first.get('deliveryDateTo'),
    pickupInstructions:
      `${tasks.size} orders:\n${tasks
        .map((task) => task.get('externalRef'))
        .join(',\n ')}\n---\n` +
      tasks.map((task) => task.get('pickupInstructions')).join('\n---\n'),
    deliveryInstructions: `${tasks.size} different delivery locations`,
    containedTasks: tasks
      .map((task) => ({
        id: task.get('id'),
        externalRef: task.get('externalRef'),
        destinationCity: task.get('destinationCity'),
        destinationCountry: task.get('destinationCountry'),
        destinationName: task.get('destinationName'),
        destinationAddress: task.get('destinationAddress'),
        destinationEmail: task.get('destinationEmail'),
        destinationPhone: task.get('destinationPhone'),
        destinationPostcode: task.get('destinationPostcode')
      }))
      .toJS()
  }
}

const sortPartIndex = (partIndex) => (a, b) =>
  partIndex[a] > partIndex[b] ? 1 : partIndex[a] < partIndex[b] ? -1 : 0

function mergeExternalRefs(externalRefs) {
  const parts = []
  const partIndex = {}
  for (const ref of externalRefs) {
    ref.split(/[^A-Za-z0-9]+/).forEach((part, index) => {
      if (partIndex[part] === undefined) {
        partIndex[part] = index
        parts.push(part)
      }
    })
  }
  parts.sort(sortPartIndex(partIndex))
  return parts.join('_')
}

const mapStateToProps = (state: any) => ({
  selectedTasks: state.MapUiReducer.get('selectedTasks').map((taskId) =>
    state.services.getIn(['tasks', 'all', fromJS({}), 'data', taskId])
  )
})

const mapDispatchToProps = (dispatch) => ({
  TaskActions: bindActionCreators(TaskActions, dispatch)
})

export { mapStateToProps, mapDispatchToProps, MultidropDialog, sortPartIndex }
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MultidropDialog))
