import { Map as ImmutableMap } from 'immutable'

import {
  REQUEST_SENT,
  RESPONSE,
  RESPONSE_BATCH
} from '../../actions/services/ServiceActions'

import ReadRequestReducer from './ReadRequestReducer'
import RethinkReducer from './RethinkReducer'
import WriteRequestReducer from './WriteRequestReducer'

const initialState = RethinkReducer(undefined, {}).merge(
  ImmutableMap({
    all: ImmutableMap(),
    geocodeAndInsert: ImmutableMap(),
    geocodeAndUpdate: ImmutableMap(),
    clone: ImmutableMap(),
    archive: ImmutableMap(),
    addNote: ImmutableMap(),
    addStatus: ImmutableMap(),
    setQuoteStatus: ImmutableMap(),
    setOnDemand: ImmutableMap(),
    redoQuote: ImmutableMap(),
    redoQuickQuote: ImmutableMap(),
    approveQuote: ImmutableMap(),
    cancelQuote: ImmutableMap()
  })
)

export default function TasksReducer(state = initialState, action) {
  const { type, method, params } = action

  if (type === RESPONSE_BATCH) {
    return action.batch.reduce(TasksReducer, state)
  }

  if (type !== REQUEST_SENT && type !== RESPONSE) {
    return state
  }

  switch (method) {
    case 'all':
      return state.setIn(
        ['all', params],
        ReadRequestReducer(
          state.getIn(['all', params]) ||
            ReadRequestReducer().set('resendOnReconnect', true),
          action
        )
      )

    case 'geocodeAndInsert':
      return state.setIn(
        ['geocodeAndInsert', params],
        WriteRequestReducer(state.getIn(['geocodeAndInsert', params]), action)
      )

    case 'geocodeAndUpdate':
      return state.setIn(
        ['geocodeAndUpdate', params],
        WriteRequestReducer(state.getIn(['geocodeAndUpdate', params]), action)
      )

    case 'clone':
      return state.setIn(
        ['clone', params],
        WriteRequestReducer(state.getIn(['clone', params]), action)
      )

    case 'archive':
      return state.setIn(
        ['archive', params],
        WriteRequestReducer(state.getIn(['archive', params]), action)
      )

    case 'addNote':
      return state.setIn(
        ['addNote', params],
        WriteRequestReducer(state.getIn(['addNote', params]), action)
      )

    case 'addStatus':
      return state.setIn(
        ['addStatus', params],
        WriteRequestReducer(state.getIn(['addStatus', params]), action)
      )

    case 'setQuoteStatus':
      return state.setIn(
        ['setQuoteStatus', params],
        WriteRequestReducer(state.getIn(['setQuoteStatus', params]), action)
      )

    case 'setOnDemand':
      return state.setIn(
        ['setOnDemand', params],
        WriteRequestReducer(state.getIn(['setOnDemand', params]), action)
      )

    case 'redoQuote':
      return state.setIn(
        ['redoQuote', params],
        WriteRequestReducer(state.getIn(['redoQuote', params]), action)
      )

    case 'redoQuickQuote':
      return state.setIn(
        ['redoQuickQuote', params],
        WriteRequestReducer(state.getIn(['redoQuickQuote', params]), action)
      )

    case 'approveQuote':
      return state.setIn(
        ['approveQuote', params],
        WriteRequestReducer(state.getIn(['approveQuote', params]), action)
      )

    case 'cancelQuote':
      return state.setIn(
        ['cancelQuote', params],
        WriteRequestReducer(state.getIn(['cancelQuote', params]), action)
      )

    case 'vendorTotalsByReferenceTask':
      return state.setIn(
        [method, params],
        ReadRequestReducer(state.getIn([method, params]), {
          ...action,
          skipSetKeyPathWithId: true
        }).set('useCache', false)
      )

    default:
      return state.merge(RethinkReducer(state, action))
  }
}
