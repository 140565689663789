import { Map as ImmutableMap } from 'immutable'

import {
  REQUEST_SENT,
  RESPONSE,
  RESPONSE_BATCH
} from '../../actions/services/ServiceActions'
import log from '../../util/logger'

import ReadRequestReducer from './ReadRequestReducer'
import WriteRequestReducer from './WriteRequestReducer'

const initialState = ImmutableMap({
  all: ImmutableMap(),
  getById: ImmutableMap(),
  insert: ImmutableMap(),
  update: ImmutableMap(),
  delete: ImmutableMap()
})

export default function RethinkReducer(state = initialState, action) {
  const { type, method, params } = action

  if (type === RESPONSE_BATCH) {
    return action.batch.reduce(RethinkReducer, state)
  }

  if (type !== REQUEST_SENT && type !== RESPONSE) {
    return state
  }

  switch (method) {
    case 'all':
    case 'getById':
      return state.setIn(
        [method, params],
        ReadRequestReducer(state.getIn([method, params]), action)
      )
    case 'insert':
    case 'update':
    case 'delete':
      return state.setIn(
        [method, params],
        WriteRequestReducer(state.getIn([method, params]), action)
      )
    default:
      log('Received unknown method in response: ', action)
      return state
  }
}
