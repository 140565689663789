import classnames from 'classnames'
import React from 'react'

import { TASK_STATUS, VIEW_TYPE } from '../../../../../constants'
import Task from '../Task'

import { Props } from './types'

class MockTask extends React.PureComponent<Props> {
  render() {
    const {
      viewType,
      isChildTask,
      externalRef,
      containedTasks,
      sectionId
    } = this.props
    const isDriverView = viewType === VIEW_TYPE.TASKS

    return (
      <li>
        <div
          className={classnames('Task row-align-center', {
            taskForDriver: isDriverView,
            childTask: isChildTask
          })}
        >
          <div className='row-align-center'>
            <span
              className={classnames('statusAndTime', TASK_STATUS.CANCELLED, {
                statusAndTimeDriver: isDriverView
              })}
            >
              ...
            </span>

            <span className='externalRef'>{externalRef}</span>
          </div>
        </div>
        {containedTasks && (
          <ul className='containedTasks'>
            {containedTasks
              .map((childTask) => {
                const taskId = childTask.get('id')

                return (
                  <Task
                    key={taskId}
                    taskId={taskId}
                    viewType={viewType}
                    sectionId={sectionId}
                    isChildTask={true}
                  />
                )
              })
              .toArray()}
          </ul>
        )}
      </li>
    )
  }
}

export default MockTask
