import { Map as ImmutableMap } from 'immutable'

import {
  REQUEST_SENT,
  RESPONSE,
  RESPONSE_BATCH
} from '../../actions/services/ServiceActions'

import ReadRequestReducer from './ReadRequestReducer'
import RethinkReducer from './RethinkReducer'
import WriteRequestReducer from './WriteRequestReducer'

const initialState = RethinkReducer(undefined, {}).merge(
  ImmutableMap({
    insert: ImmutableMap()
  })
)

export default function DriversReducer(state = initialState, action) {
  const { type, method, params } = action

  if (type === RESPONSE_BATCH) {
    return action.batch.reduce(DriversReducer, state)
  }

  if (type !== REQUEST_SENT && type !== RESPONSE) {
    return state
  }

  switch (method) {
    case 'all':
      return state.setIn(
        ['all', params],
        ReadRequestReducer(
          state.getIn(['all', params]) ||
            ReadRequestReducer().set('resendOnReconnect', true),
          action
        )
      )

    case 'update':
      return state.setIn(
        ['update', params],
        WriteRequestReducer(state.getIn(['update', params]), action)
      )

    case 'create':
      return state.setIn(
        ['create', params],
        WriteRequestReducer(state.getIn(['create', params]), action)
      )

    case 'assignTasks':
      return state.setIn(
        ['assignTasks', params],
        WriteRequestReducer(state.getIn(['assignTasks', params]), action)
      )

    default:
      return state.merge(RethinkReducer(state, action))
  }
}
