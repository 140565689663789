import React from 'react'
import {
  Button,
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  InputGroup,
  Modal
} from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

import { getFormControlPropsFromReduxForm } from '../../../../util/form'
import Spinner from '../../../common/Spinner/Spinner'

interface ParcelWeightModal {
  children?: React.ReactNode
  onHide: () => void
  handleSubmit: () => Promise<void>
  // injected by redux-form
  fields: any
  submitting: boolean
  pristine: boolean
}

function ParcelWeightModal(props: ParcelWeightModal) {
  return (
    <Modal animation={false} show onHide={props.onHide} bsSize='sm'>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage id='settings.merchant.customiseParcelWeight' />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='form-horizontal'>
        <FormGroup controlId='parcelWeight'>
          <Col sm={6}>
            <ControlLabel>
              <FormattedMessage id='task.parcelWeight' />
            </ControlLabel>
          </Col>
          <Col sm={6}>
            <InputGroup>
              <FormControl
                type='number'
                disabled={props.submitting}
                min={0}
                step='any'
                {...getFormControlPropsFromReduxForm(props.fields.parcelWeight)}
              />
              <InputGroup.Addon>
                <FormattedMessage id='task.parcelWeightUnit' />
              </InputGroup.Addon>
            </InputGroup>
          </Col>
        </FormGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} disabled={props.submitting}>
          <i className='fa fa-times-circle spaceAfter' />
          <FormattedMessage id='settings.merchant.modal.cancel' />
        </Button>
        <Button
          type='submit'
          className='btn-brand-secondary'
          disabled={props.pristine || props.submitting}
          onClick={props.handleSubmit}
        >
          {props.submitting ? (
            <Spinner showText={false} className='spaceAfter' />
          ) : (
            <i className='fa fa-check spaceAfter' />
          )}
          <FormattedMessage id='settings.merchant.modal.save' />
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ParcelWeightModal
