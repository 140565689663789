import { fromJS, is, List, Map as ImmutableMap } from 'immutable'
import React, { PureComponent } from 'react'
import {
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Grid,
  Row,
  Well
} from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import SettingsUiActions from '../../../../actions/SettingsUiActions'
import MerchantActions from '../../../../actions/services/MerchantActions'
import Spinner from '../../../common/Spinner/Spinner'
import MerchantSettingsForm from '../MerchantSettingsForm/MerchantSettingsForm'
import StripeSources from '../StripeSources/StripeSources'

import './MerchantSettings.scss'

interface Props {
  // redux state
  userMerchants: List<any>
  merchants: ImmutableMap<any, any>
  SettingsUiReducer: ImmutableMap<any, any>
  // redux actions
  MerchantActions: typeof MerchantActions
  SettingsUiActions: typeof SettingsUiActions
}
class MerchantSettings extends PureComponent<Props> {
  componentWillMount() {
    this.props.SettingsUiActions.changeSelectedMerchant(
      this.getInitialMerchant()
    )
  }

  getInitialMerchant() {
    return this.props.userMerchants.first()
  }

  handleMerchantChange(event) {
    this.props.SettingsUiActions.changeSelectedMerchant(event.target.value)
  }

  handleMerchantSettingsSubmit(formData) {
    const selectedMerchant = this.props.merchants.getIn([
      'data',
      this.props.SettingsUiReducer.get('selectedMerchant')
    ])

    // only send the fields that have changed
    let data = fromJS(formData)
    data = data.filter((value, key) => {
      return !is(value, selectedMerchant.get(key))
    })

    return new Promise((resolve, reject) => {
      this.props.MerchantActions.update({
        id: selectedMerchant.get('id'),
        data
      })
        .catch((_error) => reject({ _error }))
        .then((response) => {
          if (response.get('lastError') != null) {
            reject({ _error: response.get('lastError') })
          } else {
            resolve()
          }
        })
    })
  }

  render() {
    const { merchants } = this.props
    const selectedMerchantId = this.props.SettingsUiReducer.get(
      'selectedMerchant'
    )

    if (merchants == null || !merchants.get('ready') || !selectedMerchantId) {
      return <Spinner />
    }

    return (
      <Grid fluid className='MerchantSettings'>
        <Row>
          <Col sm={12}>
            <h2>
              <FormattedMessage id='settings.merchant.title' />
            </h2>
            {/* // todo: wonder when size === 1? */}
            {this.props.merchants.size === 1 ? null : (
              <Well className='spaceBelow'>
                <Form horizontal className='merchantSelector'>
                  <FormGroup controlId='id'>
                    <Col sm={6} md={3}>
                      <ControlLabel>
                        <FormattedMessage id='settings.merchant.selectMerchant' />
                      </ControlLabel>
                    </Col>
                    <Col sm={6} md={3}>
                      <FormControl
                        componentClass='select'
                        value={selectedMerchantId}
                        onChange={this.handleMerchantChange.bind(this)}
                      >
                        {this.props.userMerchants
                          .sort()
                          .map((merchantId) => {
                            return (
                              <option key={merchantId} value={merchantId}>
                                {merchantId}
                              </option>
                            )
                          })
                          .toJS()}
                      </FormControl>
                    </Col>
                  </FormGroup>
                </Form>
              </Well>
            )}
          </Col>
        </Row>
        <MerchantSettingsForm
          onSubmit={this.handleMerchantSettingsSubmit.bind(this)}
          initialValues={merchants.getIn(['data', selectedMerchantId]).toJS()}
        />
        <StripeSources merchantId={selectedMerchantId} />
      </Grid>
    )
  }
}

const mapStateToProps = (state: any) => ({
  userMerchants: state.AuthReducer.getIn(['user', 'merchants']),
  merchants: state.services.getIn(['merchants', 'all', fromJS({})]),
  SettingsUiReducer: state.SettingsUiReducer
})

const mapDispatchToProps = (dispatch) => ({
  MerchantActions: bindActionCreators(MerchantActions, dispatch),
  SettingsUiActions: bindActionCreators(SettingsUiActions, dispatch)
})

export { mapStateToProps, mapDispatchToProps }
export default connect(mapStateToProps, mapDispatchToProps)(MerchantSettings)
