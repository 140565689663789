import React from 'react'

import { formatTime } from '../../../map/tasks/Task/statusUtils'

import './ResolvedMessage.scss'
import { Props } from './types'

const ResolvedMessage: React.FC<Props> = ({ message }) => {
  const renderTime = () => {
    return (
      message.timestamp && (
        <strong>{formatTime(message.timestamp.seconds * 1000)}</strong>
      )
    )
  }

  return (
    <div className='resolvedMessage'>
      <span>
        Resolved at {renderTime()} by <strong>{message.sender.username}</strong>
      </span>
    </div>
  )
}

export { ResolvedMessage }
