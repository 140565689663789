import { fromJS, is, Map as ImmutableMap } from 'immutable'
import React from 'react'
import { connect } from 'react-redux'
import { generatePath, RouteComponentProps } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import DriverActions from '../../../../actions/services/DriverActions'
import { Routes } from '../../../../navigation/Routes'
import DriverForm from '../form/DriverForm'

interface Props extends RouteComponentProps<{ id: string }> {
  // actions
  DriverActions: typeof DriverActions
  // reducer state
  driver: ImmutableMap<any, any>
  user: ImmutableMap<any, any>
}

class DriverDialog extends React.Component<Props> {
  handleSubmit(formData) {
    const { driver } = this.props

    // This is the object that will eventually get sent in an `update` or
    // `insert` request.
    let data = fromJS(formData)

    if (driver != null) {
      // If this is an existing driver, we'll only send the data that was
      // changed on the form.
      data = data.filter((value, key) => {
        return !is(value, driver.get(key))
      })
    }

    return new Promise((resolve, reject) => {
      // If this is a new driver...
      if (driver == null) {
        // First insert the new driver...
        this.props.DriverActions.create({ data })
          .catch((_error) => reject({ _error }))
          .then((response) => {
            const generatedId = response.get('generatedKeys').get(0)

            // Reject the promise if there was an error while inserting the driver.
            if (generatedId == null || response.get('lastError') != null) {
              reject({ _error: response.get('lastError') })
            } else {
              this.props.history.push(
                generatePath(Routes.Settings.Driver.Edit, {
                  id: generatedId
                })
              )
              resolve()
            }
          })
        // If this is an existing driver...
      } else {
        // // First update the driver with the form field changes...
        this.props.DriverActions.update({
          id: driver.get('id'),
          data
        })
          .catch((_error) => reject({ _error }))
          .then((response) => {
            // Reject the promise if there was an error while updating.
            if (
              response.get('updated') !== 1 ||
              response.get('lastError') != null
            ) {
              reject({ _error: response.get('lastError') })
            } else {
              resolve()
            }
          })
        // reject()
      }
    })
  }

  hideModal() {
    this.props.history.push(Routes.Settings.Driver.Root)
  }

  sendOnboardingSms() {
    this.props.DriverActions.sendOnboardingSms({
      id: this.props.driver.get('id')
    })
  }

  archive() {
    if (confirm('Delete this driver?')) {
      this.props.DriverActions.archive({
        id: this.props.driver.get('id')
      })
      this.props.history.push(Routes.Settings.Driver.Root)
    }
  }

  // FIXME: temporary fix for this component re-rendering with any store change
  shouldComponentUpdate(nextProps, nextState) {
    /* eslint no-unused-vars: 0 */
    for (const key in this.props) {
      if (this.props[key] !== nextProps[key]) {
        return true
      }
    }
    return false
  }

  render() {
    // `driver` will only be defined if this is an update dialog, otherwise
    // this dialog will be used to add a new driver.
    const { driver, user } = this.props
    const merchants = user.get('merchants')

    return (
      <DriverForm
        driver={driver}
        merchants={merchants}
        initialValues={
          driver != null
            ? driver.toJS()
            : // default field values for new drivers
              {
                optinArrivedAtPickupAndArrivedAtDropoffStatus: true,
                merchants: merchants.size === 1 ? merchants.toJS() : []
              }
        }
        onSubmit={this.handleSubmit.bind(this)}
        onModalHide={this.hideModal.bind(this)}
        sendOnboardingSms={this.sendOnboardingSms.bind(this)}
        archive={this.archive.bind(this)}
      />
    )
  }
}

const mapStateToProps = (state: any, props: any) => ({
  driver:
    props.match.params.id &&
    state.services.getIn([
      'drivers',
      'all',
      fromJS({}),
      'data',
      props.match.params.id
    ]),
  user: state.AuthReducer.get('user')
})
const mapDispatchToProps = (dispatch) => ({
  DriverActions: bindActionCreators(DriverActions, dispatch)
})

export { mapStateToProps, mapDispatchToProps }
export default connect(mapStateToProps, mapDispatchToProps)(DriverDialog)
