import { fromJS, Set as ImmutableSet } from 'immutable'
import React from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'

import { TASK_SECTION } from '../../../../constants'

interface Props {
  inputRef: (e: HTMLElement) => void
  merchantIds: ImmutableSet<string>
  drivers: ImmutableSet<any>
}

class DriverSelect extends React.Component<Props> {
  private sort = (a, b) => formatName(a).localeCompare(formatName(b))

  render() {
    let { drivers } = this.props
    const { inputRef, merchantIds } = this.props
    drivers = drivers.sortBy((driver) => driver.name) as ImmutableSet<any>
    drivers = drivers.filter((driver) => {
      // todo not sure about the logic here so let it be as it is, will need to re-check to remove unknown type
      return (merchantIds.intersect(driver) as unknown) as boolean
    }) as ImmutableSet<any>
    return (
      <select ref={inputRef}>
        <option value={TASK_SECTION.WALKED}>WALKED</option>
        {drivers
          .sort(this.sort)
          .toArray()
          .map((driver) => {
            const driverId = driver.get('id')
            return (
              <option key={driverId} value={driverId}>
                {formatName(driver)}
                {` (${driver.get('assignedTasks').size})`}
              </option>
            )
          })}
      </select>
    )
  }
}

function formatName(driver) {
  return `${driver.get('firstName')} ${driver.get('lastName')}`
}

const combiner = (drivers) => ({
  drivers
})

const getDrivers = (state) => {
  return state.services.getIn(['drivers', 'all', fromJS({}), 'data'])
}

const selector = createSelector(getDrivers, combiner)

export { selector, combiner, getDrivers }
export default connect(selector)(DriverSelect)
