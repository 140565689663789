import moment from 'moment'
import React from 'react'

import { ACTION_LOG, TASK_STATUS, VIEW_TYPE } from '../../../../constants'
import {
  getDropoffTime,
  getLogByType,
  getPickupTime,
  getTravelTimeInMinutes
} from '../../../../util/task'

const formatTime = (date, referenceDate = moment()) => {
  const m = moment(date)
  return m.isSame(referenceDate, 'day')
    ? m.format('HH:mm')
    : m.format('DD/MM HH:mm')
}

const getPickupAndDropoffTime = (task, referenceDate) => {
  const dates = [
    task.get('pickupDateFrom'),
    task.get('pickupDateTo'),
    task.get('deliveryDateFrom')
  ]
    .filter((val) => val)
    .sort()
  const dateFromString = dates[0] || getPickupTime(task)
  const dateToString = task.get('deliveryDateTo', getDropoffTime(task))

  const dateFrom = moment(dateFromString)
  const dateTo = moment(dateToString)

  referenceDate = referenceDate || moment()
  const dateFromTxt = formatTime(dateFrom, referenceDate)
  const dateToTxt = formatTime(dateTo, referenceDate)

  return {
    pickup: dateFromTxt,
    dropoff: dateToTxt
  }
}

const getTaskAcceptedQuoteText = (task, referenceDate, viewType: VIEW_TYPE) => {
  switch (viewType) {
    case VIEW_TYPE.SENDERS: {
      const pickupTime = getPickupTime(task)

      return `Booked for ${formatTime(pickupTime)}`
    }
    case VIEW_TYPE.RECIPIENTS: {
      const dropoffTime = getDropoffTime(task)

      return `Booked for ${formatTime(dropoffTime)}`
    }

    case VIEW_TYPE.TASKS: {
      const { pickup, dropoff } = getPickupAndDropoffTime(task, referenceDate)

      return (
        <div className='row-space-between'>
          <span>TT {getTravelTimeInMinutes(task)}'</span>
          <span>
            ({pickup}-{dropoff})
          </span>
        </div>
      )
    }
  }
}

const getTaskAddedText = (task, referenceDate, viewType: VIEW_TYPE) => {
  switch (viewType) {
    case VIEW_TYPE.SENDERS: {
      const pickupTime = getPickupTime(task)

      return `Added for ${formatTime(pickupTime)}`
    }
    case VIEW_TYPE.RECIPIENTS: {
      const dropoffTime = getDropoffTime(task)

      return `Added for ${formatTime(dropoffTime)}`
    }

    case VIEW_TYPE.TASKS: {
      const { pickup, dropoff } = getPickupAndDropoffTime(task, referenceDate)

      return (
        <div className='row-space-between'>
          <span>TT {getTravelTimeInMinutes(task)}'</span>
          <span>
            ({pickup}-{dropoff})
          </span>
        </div>
      )
    }
  }
}

const getFailText = (task, referenceDate, viewType: VIEW_TYPE) => {
  switch (viewType) {
    case VIEW_TYPE.SENDERS:
    case VIEW_TYPE.RECIPIENTS:
      return 'Failed'

    case VIEW_TYPE.TASKS:
      const { pickup, dropoff } = getPickupAndDropoffTime(task, referenceDate)
      const log = getLogByType(task, TASK_STATUS.FAILED)

      return (
        <div className='row-space-between'>
          {log && <span>AT {formatTime(log.get('timestamp'))}</span>}
          <span>
            ({pickup}-{dropoff})
          </span>
        </div>
      )
  }
}

const getDropInText = (task) => {
  const dropoffEta = task.getIn(['eta', 'dropoff']) || getDropoffTime(task)
  const diffMinutes = Math.round(
    (new Date(dropoffEta).getTime() - Date.now()) / 1000 / 60
  )

  if (diffMinutes === 0) {
    return 'Drop in now'
  }

  if (diffMinutes < 0) {
    return `Drop at ${moment(dropoffEta).format('HH:mm')}`
  }

  return `Drop in ${diffMinutes} min`
}

const getTaskDriverAssignedText = (
  task,
  referenceDate,
  viewType: VIEW_TYPE
) => {
  const pickupEta = task.getIn(['eta', 'pickup']) || getPickupTime(task)

  switch (viewType) {
    case VIEW_TYPE.SENDERS: {
      const diffMinutes = Math.round(
        (new Date(pickupEta).getTime() - Date.now()) / 1000 / 60
      )

      if (diffMinutes === 0) {
        return 'Pick in now'
      }

      if (diffMinutes < 0) {
        return `Pick at ${moment(pickupEta).format('HH:mm')}`
      }

      return `Pick in ${diffMinutes} min`
    }

    case VIEW_TYPE.RECIPIENTS: {
      return getDropInText(task)
    }

    case VIEW_TYPE.TASKS: {
      const { pickup, dropoff } = getPickupAndDropoffTime(task, referenceDate)

      return (
        <div className='row-space-between'>
          <span>PU {formatTime(pickupEta)}</span>
          <span>
            ({pickup}-{dropoff})
          </span>
        </div>
      )
    }
  }
}

const getArrivedAtPickupText = (task, referenceDate, viewType: VIEW_TYPE) => {
  const dropoffEta = task.getIn(['eta', 'dropoff']) || getDropoffTime(task)

  switch (viewType) {
    case VIEW_TYPE.SENDERS: {
      const log = getLogByType(task, TASK_STATUS.ARRIVED_AT_PICKUP)
      const diffMinutes = Math.round(
        (Date.now() - new Date(log.get('timestamp')).getTime()) / 1000 / 60
      )

      return `At store since ${diffMinutes} min (£££)`
    }

    case VIEW_TYPE.RECIPIENTS: {
      return getDropInText(task)
    }

    case VIEW_TYPE.TASKS: {
      const { pickup, dropoff } = getPickupAndDropoffTime(task, referenceDate)
      const travelTime = getTravelTimeInMinutes(task)

      return (
        <div className='row-space-between'>
          <span>
            DO {formatTime(dropoffEta)} {travelTime && `(+${travelTime}')`}
          </span>
          <span>
            ({pickup}-{dropoff})
          </span>
        </div>
      )
    }
  }
}

const getTaskInTransitText = (task, referenceDate, viewType: VIEW_TYPE) => {
  const dropoffEta = task.getIn(['eta', 'dropoff']) || getDropoffTime(task)

  switch (viewType) {
    case VIEW_TYPE.SENDERS: {
      const log = getLogByType(task, TASK_STATUS.IN_TRANSIT)

      return `Collected at ${formatTime(log.get('timestamp'))}`
    }

    case VIEW_TYPE.RECIPIENTS: {
      return getDropInText(task)
    }

    case VIEW_TYPE.TASKS: {
      const { pickup, dropoff } = getPickupAndDropoffTime(task, referenceDate)

      return (
        <div className='row-space-between'>
          <span>DO {formatTime(dropoffEta)}</span>
          <span>
            ({pickup}-{dropoff})
          </span>
        </div>
      )
    }
  }
}

const getTaskArrivedAtDropoffText = (
  task,
  referenceDate,
  viewType: VIEW_TYPE
) => {
  switch (viewType) {
    case VIEW_TYPE.SENDERS: {
      const log = getLogByType(task, TASK_STATUS.ARRIVED_AT_DROPOFF)

      return `Arrived at ${formatTime(log.get('timestamp'))}`
    }

    case VIEW_TYPE.RECIPIENTS: {
      const log = getLogByType(task, TASK_STATUS.ARRIVED_AT_DROPOFF)

      return `Arrived at ${formatTime(log.get('timestamp'))}`
    }

    case VIEW_TYPE.TASKS: {
      const { pickup, dropoff } = getPickupAndDropoffTime(task, referenceDate)
      const log = getLogByType(task, TASK_STATUS.ARRIVED_AT_DROPOFF)

      return (
        <div className='row-space-between'>
          <span>AT {formatTime(log.get('timestamp'))}</span>
          <span>
            ({pickup}-{dropoff})
          </span>
        </div>
      )
    }
  }
}

const getTaskSuccessText = (task, referenceDate, viewType: VIEW_TYPE) => {
  switch (viewType) {
    case VIEW_TYPE.SENDERS: {
      const log = getLogByType(task, TASK_STATUS.SUCCESS)

      return `Signed at ${formatTime(log.get('timestamp'))}`
    }

    case VIEW_TYPE.RECIPIENTS: {
      const log = getLogByType(task, TASK_STATUS.SUCCESS)

      return `Arrived at ${formatTime(log.get('timestamp'))}`
    }

    case VIEW_TYPE.TASKS: {
      const { pickup, dropoff } = getPickupAndDropoffTime(task, referenceDate)
      const log = getLogByType(task, TASK_STATUS.SUCCESS)

      return (
        <div className='row-space-between'>
          <span>AT {formatTime(log.get('timestamp'))}</span>
          <span>
            ({pickup}-{dropoff})
          </span>
        </div>
      )
    }
  }
}

const getTaskCancelledText = (task, referenceDate, viewType: VIEW_TYPE) => {
  switch (viewType) {
    case VIEW_TYPE.SENDERS:
    case VIEW_TYPE.RECIPIENTS:
      return 'Cancelled'

    case VIEW_TYPE.TASKS: {
      const { pickup, dropoff } = getPickupAndDropoffTime(task, referenceDate)
      const log =
        getLogByType(task, TASK_STATUS.CANCELLED) ||
        getLogByType(task, ACTION_LOG.JOB_CANCELLED)

      return (
        <div className='row-space-between'>
          {log && <span>AT {formatTime(log.get('timestamp'))}</span>}
          <span>
            ({pickup}-{dropoff})
          </span>
        </div>
      )
    }
  }
}

export {
  getTaskAddedText,
  getFailText,
  formatTime,
  getTaskAcceptedQuoteText,
  getTaskDriverAssignedText,
  getArrivedAtPickupText,
  getTaskInTransitText,
  getTaskArrivedAtDropoffText,
  getTaskSuccessText,
  getTaskCancelledText,
  getPickupAndDropoffTime
}
