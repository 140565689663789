import classnames from 'classnames'
import React from 'react'
import { Checkbox } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Subscription } from 'rxjs'

import chat from '../../../services/chat/chat'

import './ChatIconWithBadge.scss'
import { Props, State } from './types'

enum FilterType {
  Unread,
  Read
}

class ChatIconWithBadge extends React.Component<Props, State> {
  subscription: Subscription = null

  constructor(props) {
    super(props)

    this.state = {
      numberOfReadMessages: 0,
      numberOfUnreadMessages: 0
    }
  }

  componentDidMount(): void {
    this.countMessages()

    this.subscription = chat.subject.subscribe(() => {
      this.countMessages()
    })
  }

  private countMessages = () => {
    const { read, unread } = chat.countMessages()

    this.setState({
      numberOfUnreadMessages: unread,
      numberOfReadMessages: read
    })
  }

  componentWillUnmount(): void {
    this.subscription.unsubscribe()
  }

  onCheckboxChange = (filterType: FilterType) => () => {
    const { filterMessages, onFilterChat } = this.props

    switch (filterType) {
      case FilterType.Unread: {
        onFilterChat({ ...filterMessages, unread: !filterMessages.unread })

        break
      }

      case FilterType.Read: {
        onFilterChat({ ...filterMessages, read: !filterMessages.read })

        break
      }
    }
  }

  onChangeFilter = () => {
    const { onFilterChat, filterMessages } = this.props

    onFilterChat({ ...filterMessages, active: !filterMessages.active })
  }

  private renderCheckboxes = () => {
    const {
      filterMessages: { unread, read }
    } = this.props

    return (
      <>
        <div className='unread spaceAfter'>
          <Checkbox
            checked={unread}
            onChange={this.onCheckboxChange(FilterType.Unread)}
          >
            Unread
          </Checkbox>
        </div>
        <div className='read'>
          <Checkbox
            checked={read}
            onChange={this.onCheckboxChange(FilterType.Read)}
          >
            Read
          </Checkbox>
        </div>
      </>
    )
  }

  renderFilterHoverTitle = () => {
    const {
      filterMessages: { active }
    } = this.props

    return active
      ? 'Click here to Remove filter tasks with unread messages'
      : 'Click here to Filter tasks with unread messages'
  }

  render() {
    const {
      filterMessages: { active }
    } = this.props
    const { numberOfUnreadMessages } = this.state

    return (
      <div className='chatBadge'>
        <div
          className={classnames('badgeContainer pointer', { active })}
          title={this.renderFilterHoverTitle()}
          onClick={this.onChangeFilter}
        >
          <i className='fa fa-comment fa-2x' />
          {numberOfUnreadMessages > 0 && (
            <div className='badgeIcon'>{numberOfUnreadMessages}</div>
          )}
        </div>
        {active && this.renderCheckboxes()}
      </div>
    )
  }
}

const mapStateProps = (state) => {
  return {
    filterMessages: state.MapUiReducer.get('filterMessages').toJS()
  }
}

export default connect(mapStateProps)(ChatIconWithBadge)
