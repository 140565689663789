import React from 'react'

import './NoMessage.scss'
import { Props } from './types'

const NoMessage: React.FC<Props> = ({ externalRef }) => (
  <div className='noMessages'>
    <p>Ask a question about order number:&nbsp;</p>
    <p>
      <strong>{externalRef}</strong>
    </p>
  </div>
)

export default NoMessage
