import 'Drivers.scss'
import { Map as ImmutableMap } from 'immutable'
import React, { PureComponent } from 'react'
import {
  Button,
  ButtonToolbar,
  OverlayTrigger,
  Table,
  Tooltip
} from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { generatePath, Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import DriverActions from '../../../../actions/services/DriverActions'
import { Routes } from '../../../../navigation/Routes'
import DriversSelector from '../../../../selectors/DriversSelector/DriversSelector'
import Collapse from '../../../common/Collapse/Collapse'
import Spinner from '../../../common/Spinner/Spinner'

function TableRow({ driver }) {
  // const lastLocation = driver.get('lastKnownLocation')
  const merchants: string[] = driver
    .get('merchants')
    .join(',')
    .split(',')
    .filter((merchant: any) => merchant !== '')
  // let lastSeen = ''
  // if (lastLocation && Object.keys(lastLocation).length > 0) {
  //   const timestamp = lastLocation.get('timestamp')
  //   if (timestamp) {
  //     lastSeen = moment(timestamp).format('DD/MM HH:mm')
  //   }
  // }

  return (
    <tr key={driver.get('id')}>
      {/* <td>{lastSeen}</td> */}
      <td data-testid='drivers-list-item-full-name'>
        {driver.get('firstName')} {driver.get('lastName')}
      </td>
      <td>{driver.get('mobileNumber')}</td>
      <td>{driver.get('email')}</td>
      <td>{merchants.length > 0 ? <Collapse elements={merchants} /> : null}</td>
      <td>
        <Link
          data-testid='edit-driver-link'
          to={generatePath(Routes.Settings.Driver.Edit, {
            id: driver.get('id')
          })}
        >
          <i className='fa fa-pencil-alt editDriversIcon' />
          <span className='sr-only'>
            <FormattedMessage id='management.drivers.editDriver' />
          </span>
        </Link>
      </td>
    </tr>
  )
}

interface Props {
  // injected by react-router
  children: React.ReactNode
  // redux state
  drivers: ImmutableMap<any, any>
  // redux actions
  DriverActions: typeof DriverActions
}

class Drivers extends PureComponent<Props> {
  render() {
    return (
      <div className='Drivers'>
        <ButtonToolbar className='spaceBelow'>
          <Link to={Routes.Settings.Driver.New}>
            <Button
              bsStyle='primary'
              className='spaceAfter'
              data-testid='add-driver-button'
            >
              <i className='fa fa-plus spaceAfter' />
              <FormattedMessage id='management.drivers.toolbar.addDriver' />
            </Button>
          </Link>

          <OverlayTrigger
            placement='bottom'
            overlay={
              <Tooltip id='buzzhire promo'>
                <FormattedMessage id='management.buzzhire.tip' />
              </Tooltip>
            }
          >
            <a
              href='https://bit.ly/OrkestroBuzzHire'
              target='_blank'
              rel='noopener noreferrer'
            >
              <Button className='btn-brand-secondary spaceAfter'>
                <i className='fa fa-plus spaceAfter' />
                <FormattedMessage id='management.buzzhire.button' />
              </Button>
            </a>
          </OverlayTrigger>
        </ButtonToolbar>

        {this.props.drivers == null || this.props.drivers.size === 0 ? (
          <Spinner />
        ) : (
          <Table hover className='selectable driverTable'>
            <thead>
              <tr>
                {/* <th>{i18n`Last Seen`}</th> */}
                <th>
                  <FormattedMessage id='driver.name' />
                </th>
                <th>
                  <FormattedMessage id='driver.mobileNumber' />
                </th>
                <th>
                  <FormattedMessage id='driver.email' />
                </th>
                <th>
                  <FormattedMessage id='driver.merchants' />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {this.props.drivers
                .map((driver) => {
                  return <TableRow key={driver.get('id')} driver={driver} />
                })
                .toArray()}
            </tbody>
          </Table>
        )}

        {this.props.children}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  drivers: DriversSelector(state)
})

const mapDispatchToProps = (dispatch) => ({
  DriverActions: bindActionCreators(DriverActions, dispatch)
})

export { mapStateToProps, mapDispatchToProps, TableRow }
export default connect(mapStateToProps, mapDispatchToProps)(Drivers)
