import React, { Component } from 'react'
import {
  Button,
  Col,
  FormGroup,
  Modal,
  Panel,
  Row,
  Table
} from 'react-bootstrap'
import { FormattedMessage, injectIntl } from 'react-intl'

import RFDateTimePicker from '../../../common/RFDateTimePicker/RFDateTimePicker'
import Spinner from '../../../common/Spinner/Spinner'

import './OpeningTimesSettings.scss'

interface TimeInputRowProps {
  intl: any
  onChange: (params: object) => void
  value: any
  submitting: boolean
}

class TimeInputRow extends React.PureComponent<TimeInputRowProps> {
  private onOpenDateChange = (values) => {
    const {
      value: { weekDate, closes },
      onChange
    } = this.props
    onChange({ weekDate, opens: values, closes })
  }

  private onCloseDateChange = (values) => {
    const {
      value: { weekDate, opens },
      onChange
    } = this.props
    onChange({ weekDate, opens, closes: values })
  }

  private onSetToClosed = () => {
    const {
      value: { weekDate },
      onChange
    } = this.props
    onChange({ weekDate, opens: null, closes: null })
  }

  render() {
    const { intl, value, submitting } = this.props
    const { weekDate, opens, closes } = value

    return (
      <tr>
        <td>
          <label htmlFor={weekDate + 'opens'}>
            <FormattedMessage id={`dayOfWeek.${weekDate}`} />
          </label>
        </td>
        <td>
          <FormGroup controlId={weekDate + 'opens'}>
            <RFDateTimePicker
              id={weekDate + 'opens'}
              value={opens}
              onBlur={null}
              onChange={this.onOpenDateChange}
              calendar={false}
              disabled={submitting}
              format={'HH:mm'}
              placeholder={intl.formatMessage({
                id: 'settings.merchant.openingTimes.closed'
              })}
            />
          </FormGroup>
        </td>
        <td>
          <FormGroup controlId={weekDate + 'closes'}>
            <RFDateTimePicker
              id={weekDate + 'closes'}
              value={closes}
              onBlur={null}
              onChange={this.onCloseDateChange}
              calendar={false}
              disabled={submitting}
              format={'HH:mm'}
              placeholder={intl.formatMessage({
                id: 'settings.merchant.openingTimes.closed'
              })}
            />
          </FormGroup>
        </td>
        <td>
          <Button
            disabled={!opens && !closes}
            title={intl.formatMessage({
              id: 'settings.merchant.openingTimes.setToClosed'
            })}
            onClick={this.onSetToClosed}
          >
            <i className='fa fa-times-circle' />
            <span className='sr-only'>
              <FormattedMessage id='settings.merchant.openingTimes.setToClosed' />
            </span>
          </Button>
        </td>
      </tr>
    )
  }
}

interface OpeningTimesModalProps {
  // injected by parent prop
  onHide: () => void
  handleSubmit: () => Promise<void>
  // injected by react-intl
  intl: any
  // injected by redux-form
  fields: any
  submitting: boolean
  pristine: boolean
}

class OpeningTimesModal extends React.PureComponent<OpeningTimesModalProps> {
  private onChangeTimeInput = (rule) => (newRule) => {
    const { openingTimes } = this.props.fields
    const existingRuleIndex = openingTimes.value.indexOf(rule)
    if (existingRuleIndex === -1) {
      // There's no existing rule for this day of the week, so add
      // it to the openingTimes array.
      openingTimes.onChange(openingTimes.value.concat(newRule))
    } else {
      // There's already an existing rule for this day of the week,
      // so update it.
      const newValue = openingTimes.value.slice() // clone array
      newValue[existingRuleIndex] = newRule
      openingTimes.onChange(newValue)
    }
  }

  render() {
    const {
      onHide,
      intl,
      submitting,
      pristine,
      handleSubmit,
      fields: { openingTimes }
    } = this.props

    return (
      <Modal
        animation={false}
        show
        onHide={onHide}
        bsSize='sm'
        className='OpeningTimesModal'
      >
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id='settings.merchant.openingTimes.setOpeningTimes' />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <thead>
              <tr>
                <th />
                <th>
                  <FormattedMessage id='settings.merchant.openingTimes.opens' />
                </th>
                <th>
                  <FormattedMessage id='settings.merchant.openingTimes.closes' />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {// ISO week date numbers
              [1, 2, 3, 4, 5, 6, 7].map((weekDate) => {
                let rule = openingTimes.value.find(
                  (aRule) => aRule.weekDate === weekDate
                )
                rule = rule || { weekDate, opens: null, closes: null }

                return (
                  <TimeInputRow
                    key={weekDate}
                    intl={intl}
                    submitting={submitting}
                    value={rule}
                    onChange={this.onChangeTimeInput(rule)}
                  />
                )
              })}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide} disabled={submitting}>
            <i className='fa fa-times-circle spaceAfter' />
            <FormattedMessage id='settings.merchant.modal.cancel' />
          </Button>
          <Button
            type='submit'
            className='btn-brand-secondary'
            disabled={pristine || submitting}
            onClick={handleSubmit}
          >
            {submitting ? (
              <Spinner showText={false} className='spaceAfter' />
            ) : (
              <i className='fa fa-check spaceAfter' />
            )}
            <FormattedMessage id='settings.merchant.modal.save' />
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

interface TimeOutputRowProps {
  rule: any
}

function TimeOutputRow(props: TimeOutputRowProps) {
  const { weekDate, opens, closes } = props.rule

  return (
    <tr>
      <td>
        <FormattedMessage id={`dayOfWeek.${weekDate}`} />
      </td>
      {!opens || !closes ? (
        <td colSpan={2} className='text-muted'>
          <FormattedMessage id='settings.merchant.openingTimes.closedOnDay' />
        </td>
      ) : (
        [opens, closes].map((value, i) => <td key={i}>{value}</td>)
      )}
    </tr>
  )
}

interface Props {
  intl: any
  handleSubmit: () => Promise<void>
  resetForm: () => void
  fields: any
  submitting: boolean
  pristine: boolean
}

interface State {
  openingTimesModal
}

class OpeningTimesSettings extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      openingTimesModal: false
    }
  }

  openOpeningTimesModal() {
    this.setState({ openingTimesModal: true })
  }

  closeOpeningTimesModal() {
    this.setState({ openingTimesModal: false })
    this.props.resetForm()
  }

  handleOpeningTimesModalSubmit() {
    this.props.handleSubmit().then(this.closeOpeningTimesModal.bind(this))
  }

  render() {
    return (
      <Row className='OpeningTimesSettings'>
        <Col sm={12}>
          <h3>
            <FormattedMessage id='settings.merchant.openingTimes.title' />
          </h3>
        </Col>

        {/* parcel dimensions settings */}
        <Col sm={12} md={6}>
          <Panel>
            <Panel.Heading>
              <h3>
                <FormattedMessage id='dayOfWeek' />
              </h3>
            </Panel.Heading>

            <Panel.Body>
              <Table>
                <thead>
                  <tr>
                    <th />
                    <th>
                      <FormattedMessage id='settings.merchant.openingTimes.opens' />
                    </th>
                    <th>
                      <FormattedMessage id='settings.merchant.openingTimes.closes' />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {// ISO week date numbers
                  [1, 2, 3, 4, 5, 6, 7].map((weekDate) => {
                    let rule = this.props.fields.openingTimes.value.find(
                      (aRule) => {
                        return aRule.weekDate === weekDate
                      }
                    )
                    rule = rule || { weekDate, opens: null, closes: null }

                    return <TimeOutputRow key={weekDate} rule={rule} />
                  })}
                </tbody>
              </Table>

              <Button
                disabled={this.props.submitting}
                onClick={this.openOpeningTimesModal.bind(this)}
              >
                <FormattedMessage id='settings.merchant.openingTimes.setOpeningTimes' />
              </Button>
            </Panel.Body>
          </Panel>
        </Col>

        {this.state.openingTimesModal ? (
          <OpeningTimesModal
            {...this.props}
            onHide={this.closeOpeningTimesModal.bind(this)}
            handleSubmit={this.handleOpeningTimesModalSubmit.bind(this)}
          />
        ) : null}
      </Row>
    )
  }
}

export { TimeOutputRow, TimeInputRow, OpeningTimesModal }
export default injectIntl(OpeningTimesSettings)
