import React, { PureComponent } from 'react'
import { Alert, Button, Col, Grid, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import { Routes } from '../../../navigation/Routes'

class RegistrationConfirmation extends PureComponent {
  render() {
    return (
      <Grid>
        <Row>
          <Col sm={10} smOffset={1} md={6} mdOffset={3}>
            <h1>
              <FormattedMessage id='register.confirmation.title' />
            </h1>
            <p>
              <FormattedMessage id='register.confirmation.thanks' />
            </p>
            <Alert bsStyle='info'>
              <h4>
                <FormattedMessage id='register.confirmation.verificationTitle' />
              </h4>
              <p>
                <FormattedMessage id='register.confirmation.verificationInfo' />
              </p>
            </Alert>
            <Link to={Routes.Login}>
              <Button bsStyle='primary'>
                <FormattedMessage id='login.loginButton' />
              </Button>
            </Link>
          </Col>
        </Row>
      </Grid>
    )
  }
}

export default RegistrationConfirmation
