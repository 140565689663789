import socketio from 'socket.io-client'

import { resend } from '../actions/services/ServiceActions'
import config from '../config'

const sockets = {}

export function connect(namespace, options = null) {
  return (dispatch /*, getState*/) => {
    return new Promise((resolve, reject) => {
      if (namespace in sockets) {
        resolve(sockets[namespace])
        return
      }

      dispatch(connectAttempt(namespace))
      const socket = socketio(`${config.socketioServer}/${namespace}`, options)
      sockets[namespace] = socket

      socket.on('connect', () => {
        dispatch(connected(namespace, socket))
        resolve(socket)
      })
      socket.on('error', (error) => {
        // delete sockets[namespace]
        dispatch(connectError(namespace, error))
        reject(error)
      })
      socket.on('disconnect', () => {
        // delete sockets[namespace]
        dispatch(disconnected(namespace))
      })
      socket.on('reconnect', (tries) => {
        dispatch(reconnected(namespace, tries))
      })
      socket.on('reconnect_attempt', () => {
        dispatch(reconnectAttempt(namespace))
      })
      socket.on('reconnecting', (tries) => {
        dispatch(reconnecting(namespace, tries))
      })
      socket.on('reconnect_error', (error) => {
        dispatch(reconnectError(namespace, error))
      })
      socket.on('reconnect_failed', () => {
        // delete sockets[namespace]
        dispatch(reconnectFail(namespace))
      })
    })
  }
}

export const SOCKET_SUBSCRIBE = 'SOCKET_SUBSCRIBE'
export function subscribe(namespace, event, callback) {
  return (/* dispatch, getState */) => {
    sockets[namespace].on(event, callback)
    return { type: SOCKET_SUBSCRIBE, namespace, event }
  }
}

export const SOCKET_EMIT = 'SOCKET_EMIT'
export function emit(namespace, event, data) {
  return (/* dispatch, getState */) => {
    sockets[namespace].emit(event, data)
    return { type: SOCKET_EMIT, namespace, event, data }
  }
}

// For dispatching socket.io events on a Socket instance
// see: http://socket.io/docs/client-api/#socket

export const SOCKET_CONNECT = 'SOCKET_CONNECT'
export function connected(namespace, socket) {
  return { type: SOCKET_CONNECT, namespace, socket }
}

export const SOCKET_RECONNECT = 'SOCKET_RECONNECT'
export function reconnected(namespace, tries) {
  return (dispatch) => {
    dispatch(resend(namespace))
    dispatch({ type: SOCKET_RECONNECT, namespace, tries })
  }
}

export const SOCKET_ERROR = 'SOCKET_ERROR'
export function connectError(namespace, error) {
  return { type: SOCKET_ERROR, namespace, error }
}

export const SOCKET_DISCONNECT = 'SOCKET_DISCONNECT'
export function disconnected(namespace) {
  return { type: SOCKET_DISCONNECT, namespace }
}

export const SOCKET_RECONNECT_FAILED = 'SOCKET_RECONNECT_FAILED'
export function reconnectFail(namespace) {
  return { type: SOCKET_RECONNECT_FAILED, namespace }
}

export const SOCKET_RECONNECT_ERROR = 'SOCKET_RECONNECT_ERROR'
export function reconnectError(namespace, error) {
  return { type: SOCKET_RECONNECT_ERROR, namespace, error }
}

export const SOCKET_CONNECT_ATTEMPT = 'SOCKET_CONNECT_ATTEMPT'
function connectAttempt(namespace) {
  return { type: SOCKET_CONNECT_ATTEMPT, namespace }
}

export const SOCKET_RECONNECT_ATTEMPT = 'SOCKET_RECONNECT_ATTEMPT'
export function reconnectAttempt(namespace) {
  return { type: SOCKET_RECONNECT_ATTEMPT, namespace }
}

export const SOCKET_RECONNECTING = 'SOCKET_RECONNECTING'
export function reconnecting(namespace, tries) {
  return { type: SOCKET_RECONNECTING, namespace, tries }
}
