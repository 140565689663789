import React from 'react'
import { Button } from 'react-bootstrap'

import './TaskCloseButton.scss'

interface Props {
  onClick: React.MouseEventHandler<Button | HTMLDivElement>
}

class TaskCloseButton extends React.PureComponent<Props> {
  render() {
    return (
      <div className='pointer taskCloseButton' onClick={this.props.onClick}>
        <i className='far fa-times-circle fa-2x' />
      </div>
    )
  }
}

export default TaskCloseButton
