import moment from 'moment'
import React, { PureComponent } from 'react'
import { Well } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

interface Props {
  className?: string
  expiry: Date
}

class QuoteCountdown extends PureComponent<Props> {
  private interval = null
  componentWillMount() {
    this.interval = setInterval(this.forceUpdate.bind(this), 1e3)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    if (this.props.expiry == null) {
      return null
    }
    const remaining = this.props.expiry.getTime() - Date.now()
    if (remaining < 0) {
      return null
    }

    const className = 'QuoteCountdown ' + (this.props.className || '')

    return (
      <Well className={className}>
        <FormattedMessage id='map.tasks.quote.countdown' />
        <strong>{moment(remaining).format('m:ss')}</strong>
      </Well>
    )
  }
}

export default QuoteCountdown
