import { Map, Set } from 'immutable'
import React from 'react'
import { ListGroup, ListGroupItem } from 'react-bootstrap'
import { connect } from 'react-redux'

import { archiveSelected } from '../../../../actions/MapUiActions'
import DriverActions from '../../../../actions/services/DriverActions'
import TaskActions from '../../../../actions/services/TaskActions'
import { getTasks } from '../../../../selectors/map/tasks/FilteredTasks/FilteredTasks'
import AssignDriverDialog from '../AssignDriverDialog/AssignDriverDialog'
import ChangeStatusDialog from '../ChangeStatusDialog/ChangeStatusDialog'
import CreateMultidropDialog from '../CreateMultidropDialog/CreateMultidropDialog'
import MergeTasksDialog from '../MergeTasksDialog/MergeTasksDialog'

import './TaskContextMenu.scss'

const { Provider, Consumer } = React.createContext({
  handleContextMenu: (e) => alert('123')
})

interface StateProps {
  tasks: Map<any, any>
  selectedTasks: Set<string>
  enabled: boolean
}

interface DispatchProps {
  archiveSelected: () => void
  setOnDemand: (params) => void
  reassign: (driverId, targetTaskId?) => void
}

interface Props {
  children?: React.ReactElement
}

class TaskContextMenu extends React.Component<
  StateProps & DispatchProps & Props
> {
  static Context = Consumer
  state = { ActiveDialog: null, open: false, top: 0, left: 0 }

  showDialog = (ActiveDialog) => {
    this.setState({ ActiveDialog, open: false })
  }
  changeStatus = () => this.showDialog(ChangeStatusDialog)
  assignDriver = () => this.showDialog(AssignDriverDialog)
  merge = () => this.showDialog(MergeTasksDialog)
  multidrop = () => this.showDialog(CreateMultidropDialog)
  archive = () => {
    this.props.archiveSelected()
    this.close()
  }
  close = () => this.showDialog(null)

  requestQuotes = () => {
    this.props.selectedTasks.forEach((selectedTaskId) => {
      const task = this.props.tasks.get(selectedTaskId)
      if (task && task.get('isWalked')) {
        this.props.reassign(null, task.get('id'))
      }
      this.props.setOnDemand({
        taskId: selectedTaskId,
        data: true
      })
    })
    this.close()
  }

  getTopForMenu = (e) => {
    const menuHeight = 300
    const remainHeightForMenu = window.innerHeight - e.clientY
    if (remainHeightForMenu < menuHeight) {
      return e.clientY - (menuHeight - remainHeightForMenu)
    }
    return e.clientY
  }

  handleContextMenu = (e) => {
    e.preventDefault()
    if (this.props.enabled) {
      this.setState({
        open: true,
        top: this.getTopForMenu(e) + 'px',
        left: e.clientX + 'px'
      })
    }
  }

  handleOverlayClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.close()
  }

  providerValue = { handleContextMenu: this.handleContextMenu }

  render() {
    const { ActiveDialog, open, top, left } = this.state

    return (
      <Provider value={this.providerValue}>
        {this.props.children}
        {open && (
          <>
            <div
              className='TaskContextMenuOverlay'
              onTouchEnd={this.handleOverlayClick}
              onMouseDown={this.handleOverlayClick}
              onClick={this.handleOverlayClick}
              onContextMenu={this.handleOverlayClick}
            />
            <ListGroup className='TaskContextMenu' style={{ top, left }}>
              <ListGroupItem>
                <strong>{this.props.selectedTasks.size} tasks selected</strong>
              </ListGroupItem>
              <ListGroupItem onClick={this.changeStatus}>
                Change status
              </ListGroupItem>
              <ListGroupItem
                data-testid='task-context-menu-request-quotes'
                onClick={this.requestQuotes}
              >
                Request quotes
              </ListGroupItem>
              <ListGroupItem onClick={this.assignDriver}>
                Assign driver
              </ListGroupItem>
              <ListGroupItem onClick={this.merge}>Merge</ListGroupItem>
              <ListGroupItem onClick={this.multidrop}>Multidrop</ListGroupItem>
              <ListGroupItem onClick={this.archive}>Archive</ListGroupItem>
            </ListGroup>
          </>
        )}
        {ActiveDialog && <ActiveDialog close={this.close} />}
      </Provider>
    )
  }
}

const mapStateToProps = (state: any) => {
  const selectedTasks = state.MapUiReducer.get('selectedTasks')
  const user = state.AuthReducer.get('user')

  const canRightClick = user.get('administrator') || user.get('canRightClick')
  return {
    selectedTasks,
    enabled: selectedTasks.size > 0 && canRightClick,
    tasks: getTasks(state)
  }
}

const mapDispatchToProps = {
  archiveSelected,
  setOnDemand: TaskActions.setOnDemand,
  reassign: DriverActions.reassign
}

export { mapStateToProps, mapDispatchToProps }

export default connect<StateProps, DispatchProps, Props>(
  mapStateToProps,
  mapDispatchToProps
)(TaskContextMenu)
