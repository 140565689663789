import { rpcSend, send } from './ServiceActions'

const all = send.bind(null, 'drivers', 'all')
const update = rpcSend.bind(null, 'drivers', 'update')
const create = rpcSend.bind(null, 'drivers', 'create')
const assignTasks = rpcSend.bind(null, 'drivers', 'assignTasks')
const sendOnboardingSms = rpcSend.bind(null, 'drivers', 'sendOnboardingSms')
const archive = rpcSend.bind(null, 'drivers', 'archive')

export default {
  all,
  update,
  create,
  assignTasks,
  sendOnboardingSms,
  archive,

  /**
   * @param driverId
   * The driver ID of the person to assign the tasks to
   * @param targetTaskId
   * The assignedTasks array of the driver will be split at the index of the
   * drop target. The task IDs in `tasks` will be added at this index
   */
  reassign(driverId, targetTaskId?, tasks = []) {
    return (dispatch, getState) => {
      const state = getState()
      const selectedTasks = state.MapUiReducer.get('selectedTasks')

      dispatch(
        assignTasks({
          taskIds: tasks.length > 0 ? tasks : selectedTasks.toArray(),
          driverId,
          targetTaskId
        })
      )
    }
  }
}
