import React from 'react'
import { MenuItem, Navbar, NavDropdown } from 'react-bootstrap'
import { connect } from 'react-redux'
import { generatePath, Link, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import AuthActions from '../../../actions/AuthActions'
import { VIEW_TYPE } from '../../../constants'
import { Routes } from '../../../navigation/Routes'
import { getViewType } from '../../../util/task'
import NavItemLink from '../NavItemLink/NavItemLink'

import './Navbar.scss'

const Logo = React.memo(() => {
  return (
    <Navbar.Brand>
      <Link to={Routes.Root}>
        <img alt='ORKESTRO' className='xs-logo' />
        <span className='logo'>orkestro</span>
      </Link>
    </Navbar.Brand>
  )
})

const NavigationItems = withRouter(({ history }) => {
  const newOrderTitle = (
    <span>
      <i className='fa fa-plus' />{' '}
      <span className='navbarLabel' data-testid='new-order-menu'>
        NEW ORDER
      </span>
    </span>
  )

  const onOpenCreateNewOrder = () => {
    history.push(
      generatePath(Routes.Map.TaskDetail, {
        taskId: 'new',
        viewType: getViewType(window.location.hash)
      })
    )
  }

  const onOpenImportOrders = () =>
    history.push(
      generatePath(Routes.Map.TaskUpload, {
        viewType: getViewType(window.location.hash)
      })
    )

  return (
    <ul className='leftNav header-menu'>
      <NavDropdown
        title={newOrderTitle}
        id='navbarUserDropdown'
        data-testid='navbarUserDropdown'
      >
        <MenuItem onClick={onOpenCreateNewOrder} data-testid='create-new-order'>
          <i className='fa fa-plus spaceAfter' />
          Create new order
        </MenuItem>
        <MenuItem onClick={onOpenImportOrders} data-testid='import-orders'>
          <i className='fa fa-arrow-up spaceAfter' />
          Import orders
        </MenuItem>
      </NavDropdown>

      <NavItemLink
        to={generatePath(Routes.Map.Tasks, { viewType: VIEW_TYPE.SENDERS })}
      >
        <i className='fa fa-store-alt' />{' '}
        <span className='navbarLabel'>SENDERS</span>
      </NavItemLink>
      <NavItemLink
        to={generatePath(Routes.Map.Tasks, { viewType: VIEW_TYPE.TASKS })}
      >
        <i className='fa fa-motorcycle' />{' '}
        <span className='navbarLabel'>DRIVERS</span>
      </NavItemLink>
      <NavItemLink
        to={generatePath(Routes.Map.Tasks, { viewType: VIEW_TYPE.RECIPIENTS })}
      >
        <i className='fa fa-users' />{' '}
        <span className='navbarLabel'>RECIPIENTS</span>
      </NavItemLink>
      <NavItemLink to={Routes.Reports}>
        <i className='fa fa-list-alt' />{' '}
        <span className='navbarLabel'>REPORTS</span>
      </NavItemLink>
      <NavItemLink to={Routes.Settings.User}>
        <i className='fa fa-cog' />{' '}
        <span className='navbarLabel' data-testid='navItemSettings'>
          SETTINGS
        </span>
      </NavItemLink>
    </ul>
  )
})

interface Props {
  AuthActions: typeof AuthActions
  AuthReducer: any
}

class NavbarComponent extends React.PureComponent<Props> {
  constructor(props) {
    super(props)

    this.state = {
      info: false
    }
  }

  render() {
    const user = this.props.AuthReducer.get('user')
    return (
      <div className='Navbar'>
        <Logo />

        {user != null && <NavigationItems />}
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  AuthReducer: state.AuthReducer
})
const mapDispatchToProps = (dispatch) => ({
  AuthActions: bindActionCreators(AuthActions, dispatch)
})

export { mapStateToProps, mapDispatchToProps, Logo, NavigationItems }
export default connect(mapStateToProps, mapDispatchToProps)(NavbarComponent)
