import React from 'react'
import { RouteComponentProps } from 'react-router'

import { getViewType } from '../../../util/task'
import GoogleMap from '../GoogleMap/GoogleMap'
import Sidebar from '../SideBar/Sidebar'
import LateNotifications from '../tasks/LateNotifications/LateNotifications'
import TaskContextMenu from '../tasks/TaskContextMenu/TaskContextMenu'

import './MapContainer.scss'

interface Props extends RouteComponentProps {
  children: React.ReactNode
}

class MapContainer extends React.PureComponent<Props> {
  render() {
    const { children, location } = this.props
    const viewType = getViewType(location.pathname)

    return (
      <div className='mapAndTaskContainer'>
        <GoogleMap viewType={viewType} />

        <div className='MapContainer'>
          <TaskContextMenu>
            <div id='side-bar' className='sidebar'>
              <Sidebar children={children} />
            </div>
          </TaskContextMenu>

          <LateNotifications />
        </div>
      </div>
    )
  }
}

export default MapContainer
