const Routes = {
  Root: '/',
  Login: '/login',
  Register: '/register',
  Confirm: '/confirm/:token',
  Reports: '/reports',
  PrintTasks: '/printTasks',
  Map: {
    Root: '/map',
    Tasks: '/map/:viewType',
    TaskDetail: '/map/:viewType/task/:taskId',
    TaskChat: '/map/:viewType/task/:taskId/messages',
    TaskUpload: '/map/:viewType/upload'
  },
  Settings: {
    Root: '/settings',
    User: '/settings/user',
    Driver: {
      Root: '/settings/drivers',
      New: '/settings/drivers/new',
      Edit: '/settings/drivers/edit/:id'
    },
    Merchant: '/settings/merchant',
    Language: '/settings/language',
    Glossary: '/settings/glossary',
    CannedResponses: '/settings/canned-responses'
  }
}

export { Routes }
