import ColorHash from 'color-hash'
import { fromJS, is, Map, OrderedSet, Set as ImmutableSet } from 'immutable'
import { createSelector } from 'reselect'

import { TASK_STATUS, VIEW_TYPE } from '../../../constants'

import { getTasks } from './Marker.selector'

const polylineColorHash = new ColorHash({
  lightness: 0.5,
  saturation: 0.5
})

function getMapUi(state) {
  return state.MapUiReducer
}

function getDrivers(state) {
  return state.services.getIn(['drivers', 'all', fromJS({})])
}

const getViewType = (state, viewType: VIEW_TYPE) => viewType

const combiner = (ui, drivers, tasks, viewType: VIEW_TYPE) => {
  const polylines = ImmutableSet().asMutable()

  if (viewType !== VIEW_TYPE.TASKS) {
    return {
      polylines,
      bounds: getBounds(polylines)
    }
  }

  // Polylines for each driver
  if (drivers != null) {
    drivers.get('data').forEach((driver) => {
      const isDriverSelected =
        // Show polyline if this driver is selected, or
        ui.get('selectedDrivers').has(driver.get('id')) ||
        // ...if this driver is assigned to one or more of the selected tasks.
        ui.get('selectedTasks').intersect(driver.get('assignedTasks')).size > 0

      if (!isDriverSelected) {
        return
      }

      polylines.add(
        Map({
          _id: driver.get('id'),

          strokeColor: polylineColorHash.hex(driver.get('mobileNumber')),
          strokeWeight: 5,
          strokeOpacity: 1,

          path: OrderedSet()
            .withMutations((path) => {
              let previousTask

              driver.get('assignedTasks').forEach((taskId) => {
                const task = tasks.get(taskId)

                if (task == null) {
                  return
                }

                const source = task.get('sourceGeocoding')
                const destination = task.get('destinationGeocoding')
                const currentStatus = task.get('currentStatus')

                // Do not draw lines between points of successful, failed and
                // cancelled tasks.
                if (
                  currentStatus !== TASK_STATUS.SUCCESS &&
                  currentStatus !== TASK_STATUS.FAILED &&
                  currentStatus !== TASK_STATUS.CANCELLED
                ) {
                  if (previousTask) {
                    const previousSource = previousTask.get('sourceGeocoding')
                    const previousDestination = previousTask.get(
                      'destinationGeocoding'
                    )

                    if (path.size === 0 && previousDestination) {
                      // Start drawing the polyline from the last completed task's
                      // destination (if there were any tasks skipped due to the
                      // completion check above).
                      if (previousDestination) {
                        path.add(previousDestination)
                      }
                    } else if (!is(source, previousSource)) {
                      // If two subsequent tasks have the same source, do not draw a
                      // line between source and destination, only between the two
                      // destinations.
                      if (source) {
                        path.add(source)
                      }
                    }
                  } else {
                    if (source) {
                      path.add(source)
                    }
                  }

                  // Draw a line to the destination of the task.
                  if (destination) {
                    path.add(destination)
                  }
                }

                previousTask = task
              })
            })
            .asImmutable()
        })
      )
    })
  }

  return {
    polylines,
    bounds: getBounds(polylines)
  }
}

function getBounds(polylines) {
  return polylines.toArray().reduce((bounds, line) => {
    line.get('path').forEach((latLng) => {
      bounds.extend(
        new google.maps.LatLng(latLng.get('lat'), latLng.get('lng'))
      )
    })
    return bounds
  }, new google.maps.LatLngBounds())
}

export { combiner, getMapUi, getDrivers, getTasks }
export default createSelector(
  getMapUi,
  getDrivers,
  getTasks,
  getViewType,
  combiner
)
