import classnames from 'classnames'
import React from 'react'
import { ListGroup, ListGroupItem } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Subscription } from 'rxjs'

import { Routes } from '../../../../navigation/Routes'
import cannedResponse from '../../../../services/chat/cannedResponse'

import './CannedResponses.scss'
import { Props, State } from './types'

class CannedResponses extends React.Component<Props, State> {
  subscription: Subscription = null
  listGroupRefs = []

  constructor(props) {
    super(props)

    this.state = {
      cannedResponses: [],
      selectedIndex: 0
    }
  }

  componentDidMount(): void {
    this.subscription = cannedResponse.subject.subscribe((value) => {
      this.setState({
        cannedResponses: value.items
      })
    })
  }

  componentWillUnmount(): void {
    this.subscription?.unsubscribe()
  }

  public selectUp = () => {
    this.setState(
      {
        selectedIndex: this.getPreviousIndex()
      },
      this.scrollIntoView
    )
  }

  public selectDown = () => {
    this.setState(
      {
        selectedIndex: this.getNextIndex()
      },
      this.scrollIntoView
    )
  }

  private scrollIntoView = () => {
    const { selectedIndex } = this.state

    this.listGroupRefs[selectedIndex]?.scrollIntoView()
  }

  public select = () => {
    const { selectedIndex } = this.state
    const { onClick } = this.props

    onClick(this.getCannedResponsesByMessage()[selectedIndex])
  }

  private getPreviousIndex = () => {
    const { selectedIndex } = this.state

    return Math.max(selectedIndex - 1, 0)
  }

  private getNextIndex = () => {
    const { selectedIndex } = this.state

    return Math.min(
      selectedIndex + 1,
      this.getCannedResponsesByMessage().length - 1
    )
  }

  private getCannedResponsesByMessage = () => {
    const { cannedResponses } = this.state
    const { message } = this.props

    return cannedResponses.filter((response) =>
      response.shortcut
        .toLowerCase()
        .includes(message.toLowerCase().substring(1))
    )
  }

  render() {
    const { selectedIndex } = this.state
    const { onClick } = this.props

    return (
      <ListGroup className='cannedResponses'>
        {this.getCannedResponsesByMessage().map((response, index) => {
          const { content, shortcut } = response
          const selected = selectedIndex === index

          return (
            <div
              key={`${shortcut}_${index}`}
              ref={(ref) => {
                this.listGroupRefs[index] = ref
              }}
              onClick={() => onClick(response)}
            >
              <ListGroupItem className={classnames('item', { selected })}>
                <div className='shortcut'>/{shortcut}</div>
                <div className='content'>{content}</div>
              </ListGroupItem>
            </div>
          )
        })}
        <Link
          to={Routes.Settings.CannedResponses}
          target='_blank'
          className='manage'
        >
          <ListGroupItem onClick={() => {}}>
            <i className='fa fa-cog spaceAfter' />
            <span>Manage Canned responses</span>
          </ListGroupItem>
        </Link>
      </ListGroup>
    )
  }
}

export default CannedResponses
