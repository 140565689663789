import { fromJS, Map as ImmutableMap } from 'immutable'
import moment from 'moment'
import React, { PureComponent } from 'react'
import { Table } from 'react-bootstrap'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { connect } from 'react-redux'

import QuoteCourierLabel from '../QuoteCourierLabel/QuoteCourierLabel'

interface Props {
  quote: ImmutableMap<any, any>
  confirmation: ImmutableMap<any, any>
  driverName?: string
  driverPhone?: string
  showPickupTime?: boolean
  user: any
}

class SelectedQuoteTable extends PureComponent<Props> {
  static defaultProps = {
    showPickupTime: true
  }

  render() {
    const { quote, user } = this.props
    let { confirmation } = this.props
    const pricing = quote.get('pricing')

    confirmation =
      confirmation || fromJS({ vendorReference: '(confirmation not received)' })

    let price
    if (quote.get('price')) {
      price = (
        <FormattedNumber
          style='currency'
          currency='GBP'
          value={quote.get('price')}
        />
      )
    }

    let totalPrice
    if (!!pricing && pricing.get('totalPrice')) {
      totalPrice = (
        <FormattedNumber
          style='currency'
          currency='GBP'
          value={pricing.get('totalPrice')}
        />
      )
    }

    let basePrice
    let waitingTimeFee
    let cancellationFee
    let totalPriceWithVat
    let vatFee

    if (!!pricing) {
      const breakdown = pricing.get('breakdown')
      if (breakdown.get('basePrice')) {
        basePrice = (
          <FormattedNumber
            style='currency'
            currency='GBP'
            value={breakdown.get('basePrice')}
          />
        )
      }

      if (breakdown.get('waitingTimeFee')) {
        waitingTimeFee = (
          <FormattedNumber
            style='currency'
            currency='GBP'
            value={breakdown.get('waitingTimeFee')}
          />
        )
      }

      if (breakdown.get('cancellationFee')) {
        cancellationFee = (
          <FormattedNumber
            style='currency'
            currency='GBP'
            value={breakdown.get('cancellationFee')}
          />
        )
      }

      if (breakdown.get('totalPriceWithVat')) {
        totalPriceWithVat = (
          <FormattedNumber
            style='currency'
            currency='GBP'
            value={breakdown.get('totalPriceWithVat')}
          />
        )
      }

      if (breakdown.get('vatFee')) {
        vatFee = (
          <FormattedNumber
            style='currency'
            currency='GBP'
            value={breakdown.get('vatFee')}
          />
        )
      }
    }

    let timeToFullfill: number | string = Math.round(
      parseFloat(quote.get('timeToFullfill'))
    )
    if (isNaN(timeToFullfill)) {
      timeToFullfill = 'unknown'
    }

    return (
      <Table className='SelectedQuoteTable selectable'>
        <tbody>
          <tr>
            <th>
              <FormattedMessage id='quote.vendor' />
            </th>
            <td data-testid='request-quote-courier'>
              <QuoteCourierLabel quote={quote} />
            </td>
          </tr>
          <tr>
            <th>
              <FormattedMessage id='quote.vehicleType' />
            </th>
            <td>
              <FormattedMessage
                id={'quote.vehicleType.' + quote.get('vehicleType')}
              />
            </td>
          </tr>
          <tr>
            <th>
              <FormattedMessage id='quote.confirmation.vendorReference' />
            </th>
            <td>
              {confirmation.get('adminUrl') && user.get('administrator') ? (
                <a href={confirmation.get('adminUrl')} target='_blank'>
                  {confirmation.get('vendorReference')}
                </a>
              ) : (
                confirmation.get('vendorReference')
              )}
            </td>
          </tr>
          {this.props.driverName && (
            <tr>
              <th>
                <FormattedMessage id='quote.driverName' />
              </th>
              <td>{this.props.driverName}</td>
            </tr>
          )}
          {this.props.driverPhone && (
            <tr>
              <th>
                <FormattedMessage id='quote.driverPhone' />
              </th>
              <td>{this.props.driverPhone}</td>
            </tr>
          )}
          {this.props.showPickupTime && (
            <tr>
              <th>
                <FormattedMessage id='quote.estimatedPickup' />
              </th>
              <td>{moment(quote.get('estimatedPickup')).format('llll')}</td>
            </tr>
          )}
          <tr>
            <th>
              <FormattedMessage id='quote.timeToFullfill' />
            </th>
            <td>
              {timeToFullfill}
              <FormattedMessage id='map.tasks.quote.minutes' />
            </td>
          </tr>
          <tr>
            <th>
              <FormattedMessage id='quote.price' />{' '}
            </th>
            <td>{!price ? 'N/A' : price}</td>
          </tr>
          <tr>
            <th>
              <FormattedMessage id='quote.basePrice' />{' '}
            </th>
            <td>{!basePrice ? 'N/A' : basePrice}</td>
          </tr>
          <tr>
            <th>
              <FormattedMessage id='quote.waitingTimeFee' />{' '}
            </th>
            <td>{!waitingTimeFee ? 'N/A' : waitingTimeFee}</td>
          </tr>
          {
            <tr>
              <th>
                <FormattedMessage id='quote.cancellationFee' />{' '}
              </th>
              <td>{!cancellationFee ? 'N/A' : cancellationFee}</td>
            </tr>
          }
          <tr>
            <th>
              <FormattedMessage id='quote.totalPrice' />{' '}
            </th>
            <td>{!totalPrice ? 'N/A' : totalPrice}</td>
          </tr>
          <tr>
            <th>
              <FormattedMessage id='quote.vatFee' />{' '}
            </th>
            <td>{!vatFee ? 'N/A' : vatFee}</td>
          </tr>
          <tr>
            <th>
              <FormattedMessage id='quote.totalPriceWithVat' />{' '}
            </th>
            <td>{!totalPriceWithVat ? 'N/A' : totalPriceWithVat}</td>
          </tr>
          {user.get('administrator') &&
            (confirmation.get('notes') || []).map((note) => (
              <tr>
                <td colSpan={2} key={note.get('message')}>
                  {note.get('level') === 'warn' ? (
                    <i className='fa fa-exclamation-triangle text-danger ' />
                  ) : null}{' '}
                  {note.get('message')}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    )
  }
}

const SelectedQuoteTableSelector = (state) => ({
  user: state.AuthReducer.get('user')
})

export { SelectedQuoteTableSelector }
export default connect(SelectedQuoteTableSelector)(SelectedQuoteTable)
