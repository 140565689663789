import { fromJS, Map as ImmutableMap } from 'immutable'
import React from 'react'
import { ListGroup, ListGroupItem } from 'react-bootstrap'

import { orkestroRpcCall } from '../../../../../api'

import './AddressAutocomplete.scss'

interface SuggestionProps {
  suggestion: ImmutableMap<string, any>
  onSelect: (address) => void
}

class Suggestion extends React.Component<SuggestionProps> {
  private onClick = () => {
    this.props.onSelect(this.props.suggestion)
  }

  render() {
    const { suggestion } = this.props
    return (
      <ListGroupItem className='Suggestion' onClick={this.onClick}>
        <p>{suggestion.get('name')}</p>
        <p>
          {suggestion.get('address')}
          <br />
          {suggestion.get('city')}
          <br />
          {suggestion.get('postcode')}
          <br />
          {suggestion.get('country')}
        </p>
        <p>{suggestion.get('phone')}</p>
        <p>{suggestion.get('email')}</p>
      </ListGroupItem>
    )
  }
}

interface Props {
  active: boolean
  merchantId: string
  query: string
  left: any
  onSelect(address: any): void
}

interface State {
  results: any[]
}

class AddressAutocomplete extends React.Component<Props, State> {
  ref = React.createRef<any>()
  state = { results: [] }

  componentDidMount(): void {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount(): void {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = (e) => {
    if (!this.ref.current?.contains(e.target)) {
      this.setState({ results: [] })
    }
  }

  onSelect = (address) => {
    this.setState({ results: [] })
    this.props.onSelect(address)
  }

  fetchSuggestions = async (query: string) => {
    this.setState({ results: [] })

    const resp = await orkestroRpcCall('addresses', 'search', {
      merchantId: this.props.merchantId,
      query
    })

    const results = resp.results || []

    this.setState({
      results: results.map((row) => fromJS(row))
    })
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    const { active, query } = this.props

    if (!active || query.length <= 3) {
      return
    }

    if (query !== prevProps.query) {
      if (query.trim().length === 0) {
        this.setState({ results: [] })
        return
      }

      this.fetchSuggestions(query)
    } else {
      if (active !== prevProps.active) {
        this.fetchSuggestions(query)
      }
    }
  }

  render() {
    const { results } = this.state

    if (results.length === 0) {
      return null
    }

    return (
      <div ref={this.ref}>
        <ListGroup className='AddressAutocomplete'>
          {results.map((address) => (
            <Suggestion
              key={address.get('id')}
              suggestion={address}
              onSelect={this.onSelect}
            />
          ))}
        </ListGroup>
      </div>
    )
  }
}

export { Suggestion }
export default AddressAutocomplete
