import React, { FormEventHandler, PureComponent } from 'react'
import { Checkbox, Col, FormGroup, Panel, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

import { COURIER, TRANSPORT_TYPE } from '../../../../constants'

// Important: we must keep this in sync with the server-side code.
const VEHICLE_TYPES = [
  {
    vendor: COURIER.AddisonLee,
    vehicles: [
      TRANSPORT_TYPE.Motorbike,
      TRANSPORT_TYPE.SmallVan,
      TRANSPORT_TYPE.Van
    ]
  },
  {
    vendor: COURIER.Stuart,
    vehicles: [
      TRANSPORT_TYPE.Bicycle,
      TRANSPORT_TYPE.Motorbike,
      TRANSPORT_TYPE.SmallVan,
      TRANSPORT_TYPE.Van
    ]
  },
  {
    vendor: COURIER.Crawfords,
    vehicles: [
      TRANSPORT_TYPE.Bicycle,
      TRANSPORT_TYPE.Motorbike,
      TRANSPORT_TYPE.SmallVan,
      TRANSPORT_TYPE.Van
    ]
  },
  {
    vendor: COURIER.GreenCourier,
    vehicles: [
      TRANSPORT_TYPE.Bicycle,
      TRANSPORT_TYPE.Motorbike,
      TRANSPORT_TYPE.SmallVan,
      TRANSPORT_TYPE.Van
    ]
  },
  {
    vendor: COURIER.DeliveryMates,
    vehicles: [
      TRANSPORT_TYPE.Bicycle,
      TRANSPORT_TYPE.Motorbike,
      TRANSPORT_TYPE.SmallVan,
      TRANSPORT_TYPE.Van
    ]
  }
]

interface VehicleCheckboxProps {
  // injected by parent component
  vendor: string
  vehicleType: string
  checked: boolean
  onChange: FormEventHandler<any>
  submitting: boolean
}

function VehicleCheckbox(props: VehicleCheckboxProps) {
  const controlId = `${props.vendor}.${props.vehicleType}`
  return (
    <FormGroup controlId={controlId}>
      <Checkbox
        name={controlId}
        checked={props.checked}
        onChange={props.onChange}
        disabled={props.submitting}
      >
        <FormattedMessage id={`quote.vehicleType.${props.vehicleType}`} />
      </Checkbox>
    </FormGroup>
  )
}

interface Props {
  // injected by redux-form
  handleSubmit: () => Promise<void>
  resetForm: () => void
  fields: any
  submitting: boolean
  pristine: boolean
}

class VehicleTypeSettings extends PureComponent<Props> {
  handleCheckboxChange(event) {
    const { disallowedVehicles } = this.props.fields
    const { name, checked } = event.target

    const newValue = checked
      ? // Enable this type of vehicle by removing it from disallowed list
        disallowedVehicles.value.replace(name, '')
      : // Not checked means vehicle should be added to disallowed list
        disallowedVehicles.value.concat(name)

    disallowedVehicles.onChange(newValue)
    // We need to submit the form asynchronously for the checkbox change event
    // to propagatee through the redux store.
    setTimeout(this.props.handleSubmit, 50)
  }

  render() {
    return (
      <Row className='VehicleTypeSettings'>
        <Col sm={12}>
          <h3>
            <FormattedMessage id='settings.merchant.onDemand.title' />
          </h3>
        </Col>

        <Col sm={6}>
          <Panel>
            <Panel.Heading>
              <h3>
                <FormattedMessage id='settings.merchant.vehicleTypes.title' />
              </h3>
            </Panel.Heading>
            <Panel.Body>
              {VEHICLE_TYPES.map(({ vendor, vehicles }) => {
                return (
                  <div key={vendor}>
                    <h5>{vendor}</h5>
                    {vehicles.map((vehicleType) => {
                      return (
                        <VehicleCheckbox
                          key={vehicleType}
                          vendor={vendor}
                          vehicleType={vehicleType}
                          checked={
                            this.props.fields.disallowedVehicles.value.indexOf(
                              `${vendor}.${vehicleType}`
                            ) === -1
                          }
                          onChange={this.handleCheckboxChange.bind(this)}
                          submitting={this.props.submitting}
                        />
                      )
                    })}
                  </div>
                )
              })}
            </Panel.Body>
          </Panel>
        </Col>
      </Row>
    )
  }
}
export { VehicleCheckbox }
export default VehicleTypeSettings
