enum COURIER {
  AddisonLee = 'addison_lee',
  Crawfords = 'crawfords',
  GreenCourier = 'GREENCOURIER',
  DeliveryMates = 'delivery_mates',
  Stuart = 'stuart',
  Pedals = 'pedals',
  Urbit = 'urbit',
  Ecofleet = 'ECOFLEET'
}

enum TASK_STATUS {
  TASK_RECEIVED = 'TaskReceived',
  DRIVER_ASSIGNED = 'DriverAssigned',
  ARRIVED_AT_PICKUP = 'ArrivedAtPickup',
  IN_TRANSIT = 'InTransit',
  ARRIVED_AT_DROPOFF = 'ArrivedAtDropoff',
  SUCCESS = 'Success',
  FAILED = 'Failed',
  CANCELLED = 'Cancelled',
  QUOTE_APPROVED = 'quoteApproved'
}

enum ACTION_LOG {
  JOB_CANCELLED = 'jobCancelled'
}

enum TASK_SECTION {
  PENDING = 'ID_PENDING',
  ON_GOING = 'ID_ONGOING',
  WALKED = 'ID_WALKED',
  SEARCH_RESULTS = 'ID_RESULTS'
}

enum SORT_TYPE {
  CLOCK = 'CLOCK',
  FLEET = 'FLEET',
  MERCHANT = 'MERCHANT',
  DRIVER = 'DRIVER',
  LATE = 'LATE'
}

enum SCHEDULE {
  URGENT = 'urgent',
  ASAP = 'asap',
  PICKUP_AND_DELIVERY = 'pickupAndDelivery',
  CUSTOM = 'custom',
  DELIVERY = 'delivery'
}

enum QUOTE_STATUS {
  CANCELLED = 'CANCELLED',
  QUOTED = 'QUOTED',
  APPROVED = 'APPROVED',
  EXPIRED = 'EXPIRED'
}

enum VIEW_TYPE {
  SENDERS = 'senders',
  TASKS = 'tasks',
  RECIPIENTS = 'recipients'
}

enum GOOGLE_MAP_STYLE {
  LIGHT = 'light',
  DARK = 'dark'
}

const MAX_TASK_DISTANCE = 250000 // 250km

enum TRANSPORT_TYPE {
  Bicycle = 'bicycle',
  CargoBicycle = 'cargoBicycle',
  Motorbike = 'motorbike',
  SmallVan = 'smallVan',
  Van = 'van',
  Car = 'car',
  Unknown = 'unknown',
  Walk = 'walk'
}

enum DELIVERY_STATUS {
  TaskReceived = 'TaskReceived',
  QuoteAccepted = 'QuoteAccepted',
  DriverAssigned = 'DriverAssigned',
  ArrivedAtPickup = 'ArrivedAtPickup',
  InTransit = 'InTransit',
  ArrivedAtDropoff = 'ArrivedAtDropoff',
  DeliverySuccessful = 'Success',
  DeliveryCancelled = 'Cancelled',
  DeliveryVoided = 'Voided',
  DeliveryFailed = 'Failed',
  DeliveryExpired = 'Expired'
}

enum CHAT_ICON_TYPE {
  ORDER_LIST,
  ORDER_DETAILS
}

enum TOGGLE {
  ON = 'on',
  OFF = 'off'
}

export {
  TASK_STATUS,
  TASK_SECTION,
  SORT_TYPE,
  SCHEDULE,
  QUOTE_STATUS,
  TRANSPORT_TYPE,
  VIEW_TYPE,
  GOOGLE_MAP_STYLE,
  ACTION_LOG,
  MAX_TASK_DISTANCE,
  COURIER,
  DELIVERY_STATUS,
  CHAT_ICON_TYPE,
  TOGGLE
}
