import { Map } from 'immutable'
import React, { PureComponent } from 'react'
import { MenuItem, NavDropdown } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { changeLocale } from '../../../actions/LocaleActions'

const enFlag = require('../../../images/flags/en.svg')
const itFlag = require('../../../images/flags/it.svg')
const huFlag = require('../../../images/flags/hu.svg')

const FLAGS = { 'en-GB': enFlag, 'it-IT': itFlag, 'hu-HU': huFlag }
function LocaleIcon({ locale }) {
  return <img className='LocaleIcon' src={FLAGS[locale]} />
}

interface Props {
  locale: Map<any, any>
  actions: any
}

class LocaleSelector extends PureComponent<Props> {
  render() {
    const selectedLocale = this.props.locale.get('locale')
    const localeDropdownTitle = (
      <span>
        <LocaleIcon locale={selectedLocale} />{' '}
        <span className='navbarLabel'>
          <FormattedMessage id={`language.${selectedLocale}`} />
        </span>
      </span>
    )
    return (
      <NavDropdown title={localeDropdownTitle} id='navbarLocaleDropdown'>
        <MenuItem onClick={this.props.actions.changeLocale.bind(null, 'en-GB')}>
          <LocaleIcon locale='en-GB' /> <FormattedMessage id='language.en-GB' />
        </MenuItem>
        <MenuItem onClick={this.props.actions.changeLocale.bind(null, 'it-IT')}>
          <LocaleIcon locale='it-IT' /> <FormattedMessage id='language.it-IT' />
        </MenuItem>
        <MenuItem onClick={this.props.actions.changeLocale.bind(null, 'hu-HU')}>
          <LocaleIcon locale='hu-HU' /> <FormattedMessage id='language.hu-HU' />
        </MenuItem>
      </NavDropdown>
    )
  }
}

function mapStateToProps(state) {
  return {
    locale: state.LocaleReducer
  }
}
function mapDispatchToProps(dispatch) {
  const actions = { changeLocale }
  return { actions: bindActionCreators(actions, dispatch) }
}

export { mapStateToProps, mapDispatchToProps }
export default connect(mapStateToProps, mapDispatchToProps)(LocaleSelector)
