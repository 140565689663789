import { List } from 'immutable'
import { flow } from 'lodash'
import React from 'react'
import {
  Button,
  ControlLabel,
  Dropdown,
  Form,
  FormGroup,
  MenuItem
} from 'react-bootstrap'
import { FormattedMessage, injectIntl } from 'react-intl'
import { reduxForm } from 'redux-form'

import { getFormControlPropsFromReduxForm } from '../../../util/form'
import RFDateTimePicker from '../../common/RFDateTimePicker/RFDateTimePicker'
import RFSelectList from '../../common/RFSelectList/RFSelectList'
import Spinner from '../../common/Spinner/Spinner'

import './ReportsFilterForm.scss'

interface Props {
  // injected by parent component
  merchants: List<object>
  // injected by react-intl
  intl: any
  // injected by redux-form
  fields: any
  handleSubmit: React.FormEventHandler<any>
  pristine: boolean
  submitting: boolean
  error: any
}
class ReportsFilterForm extends React.PureComponent<Props> {
  handleSelectNoneClick() {
    this.props.fields.merchants.onChange([])
  }

  handleSelectAllClick() {
    this.props.fields.merchants.onChange(this.props.merchants.toArray())
  }

  render() {
    const { timeFrom, timeTill, merchants } = this.props.fields

    let selectedMerchants
    switch (merchants.value.length) {
      case 0:
        selectedMerchants = (
          <FormattedMessage id='reports.filterForm.noMerchantsSelected' />
        )
        break
      case this.props.merchants.size:
        selectedMerchants = (
          <FormattedMessage id='reports.filterForm.allMerchantsSelected' />
        )
        break
      default:
        selectedMerchants = merchants.value.join(', ')
        break
    }

    return (
      <Form
        inline
        className='ReportsFilterForm'
        onSubmit={this.props.handleSubmit}
      >
        <FormGroup controlId='timeFrom_input'>
          <ControlLabel className='sr-only'>
            <FormattedMessage id='reports.filterForm.dates' />
          </ControlLabel>{' '}
          <RFDateTimePicker
            {...getFormControlPropsFromReduxForm(timeFrom)}
            id='timeFrom'
            className='dtPicker form-control-custom'
            format={this.props.intl.formatMessage({
              id: 'moment.shortDateTime'
            })}
            onBlur={null}
          />{' '}
          <RFDateTimePicker
            {...getFormControlPropsFromReduxForm(timeTill)}
            id='timeTill'
            className='dtPicker form-control-custom'
            format={this.props.intl.formatMessage({
              id: 'moment.shortDateTime'
            })}
            onBlur={null}
          />
        </FormGroup>{' '}
        <FormGroup controlId='merchants'>
          <ControlLabel className='sr-only'>
            <FormattedMessage id='reports.filterForm.merchants' />
          </ControlLabel>{' '}
          <Dropdown
            id='merchants'
            className='form-control-custom'
            onSelect={(e) => e.stopPropagation()}
          >
            <Dropdown.Toggle>{selectedMerchants}</Dropdown.Toggle>
            <Dropdown.Menu>
              <Button onClick={this.handleSelectAllClick.bind(this)}>
                <FormattedMessage id='reports.filterForm.selectAll' />
              </Button>
              <Button onClick={this.handleSelectNoneClick.bind(this)}>
                <FormattedMessage id='reports.filterForm.selectNone' />
              </Button>
              <MenuItem divider />
              <RFSelectList
                {...getFormControlPropsFromReduxForm(merchants)}
                onBlur={null}
                onFocus={null}
                data={this.props.merchants.toJS()}
                multiple
              />
            </Dropdown.Menu>
          </Dropdown>
        </FormGroup>{' '}
        <Button
          data-testid='apply-filters'
          bsStyle='primary'
          disabled={this.props.submitting}
          onClick={this.props.handleSubmit}
        >
          {this.props.submitting ? (
            <Spinner showText={false} className='spaceAfter' />
          ) : (
            <i className='fa fa-filter spaceAfter' />
          )}
          <FormattedMessage id='reports.filterForm.apply' />
        </Button>{' '}
      </Form>
    )
  }
}

const reduxFormConfig = {
  form: 'reportsFilter',
  fields: ['timeFrom', 'timeTill', 'merchants'],
  validate() {
    return {}
  }
}

export { reduxFormConfig }
export default flow(reduxForm(reduxFormConfig))(injectIntl(ReportsFilterForm))
