import { PureComponent } from 'react'
import React from 'react'
import { Table } from 'react-bootstrap'

import '../Glossary/GlossaryDocument.scss'

import { renderRow } from './utils'

class GlossaryDrivers extends PureComponent {
  statusAndColors = [
    {
      text: 'Task received, Quote requested, Quote approved',
      paths: [require('./glossaryImages/drivers/statusTaskReceived.png')]
    },
    {
      text: 'Driver assigned',
      paths: [require('./glossaryImages/drivers/statusGreyBg.png')]
    },
    {
      text: 'In transit',
      paths: [require('./glossaryImages/drivers/statusInTransit.png')]
    },
    {
      text: 'Arrived at dropoff',
      paths: [require('./glossaryImages/drivers/statusArrivedAtDropoff.png')]
    },
    {
      text: 'Completed delivery',
      paths: [require('./glossaryImages/drivers/statusCompleted.png')]
    },
    {
      text: 'Failed',
      paths: [require('./glossaryImages/drivers/statusFailed.png')]
    },
    {
      text: 'Cancelled',
      paths: [require('./glossaryImages/drivers/statusCancel.png')]
    }
  ]

  render() {
    return (
      <Table striped className='glossaryTable'>
        <thead>
          <tr>
            <th>Status</th>
            <th>Legend</th>
          </tr>
        </thead>
        <tbody>
          {this.statusAndColors.map(({ text, paths }, index) => (
            <tr key={`${text}_${index}`}>{renderRow(text, 25, paths)}</tr>
          ))}
        </tbody>
      </Table>
    )
  }
}

export default GlossaryDrivers
