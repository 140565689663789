import React, { PureComponent } from 'react'
import { Col, Grid, Nav, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { Routes } from '../../navigation/Routes'
import NavItemLink from '../common/NavItemLink/NavItemLink'

import './Settings.scss'
interface Props {
  children: React.ReactNode
  isAdmin: boolean
}

class Settings extends PureComponent<Props> {
  render() {
    const { isAdmin } = this.props

    return (
      <Grid className='Settings'>
        <Row>
          <Col sm={12}>
            <h1>
              <FormattedMessage id='settings.title' />
            </h1>
          </Col>
        </Row>
        <Row>
          <Col sm={2}>
            <Nav stacked bsStyle='pills' className='settingsMenu'>
              <NavItemLink to={Routes.Settings.User}>
                <FormattedMessage id='settings.menu.user' />
              </NavItemLink>
              <NavItemLink to={Routes.Settings.Driver.Root}>
                <FormattedMessage id='settings.menu.drivers' />
              </NavItemLink>
              <NavItemLink to={Routes.Settings.Merchant}>
                <FormattedMessage id='settings.menu.merchant' />
              </NavItemLink>
              <NavItemLink to={Routes.Settings.Language}>
                <FormattedMessage id='settings.menu.language' />
              </NavItemLink>
              <NavItemLink to={Routes.Settings.Glossary}>
                <FormattedMessage id='settings.menu.glossary' />
              </NavItemLink>
              {isAdmin && (
                <NavItemLink to={Routes.Settings.CannedResponses}>
                  <FormattedMessage id='settings.menu.cannedResponses' />
                </NavItemLink>
              )}
            </Nav>
          </Col>
          <Col sm={9}>{this.props.children}</Col>
        </Row>
      </Grid>
    )
  }
}

const mapStateToProps = (state) => ({
  isAdmin: state.AuthReducer.getIn(['user', 'administrator'])
})

export default connect(mapStateToProps)(Settings)
