import React from 'react'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { generatePath } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import ArchivedTaskActions from '../../../actions/services/ArchivedTaskActions'
import { Routes } from '../../../navigation/Routes'
import chat from '../../../services/chat/chat'
import ChatWindow from '../../common/ChatWindow/ChatWindow'
import LoadingModal from '../../common/LoadingModal/LoadingModal'

import TaskChatSelector from './TaskChat.selector'
import { DispatchProps, Props } from './types'

class TaskChat extends React.Component<Props & DispatchProps, {}> {
  private onClose = () => {
    this.props.history.push(
      generatePath(Routes.Map.Tasks, {
        viewType: this.props.match.params.viewType
      })
    )
  }

  private goBack = () => {
    const { taskId, viewType } = this.props.match.params

    this.props.history.push(
      generatePath(Routes.Map.TaskDetail, {
        taskId,
        viewType
      })
    )
  }

  componentDidUpdate() {
    this.findArchivedTask()
  }

  componentDidMount() {
    this.findArchivedTask()

    const { taskId } = this.props.match.params
    chat.setActive(taskId)
  }

  componentWillUnmount(): void {
    chat.setActive(null)
  }

  // look up when task is archived
  findArchivedTask = () => {
    if (this.props.task) {
      return
    }

    this.props.ArchivedTaskActions.findById({
      keyword: this.props.match.params.taskId
    })
  }

  render() {
    const {
      match: {
        params: { taskId }
      },
      task,
      isTaskArchived
    } = this.props

    if (!task) {
      return <LoadingModal onHide={this.onClose} />
    }

    return (
      <Modal animation={false} show onHide={this.onClose}>
        <ChatWindow
          externalRef={task.get('externalRef')}
          taskId={taskId}
          onBack={this.goBack}
          onClose={this.onClose}
          isTaskArchived={isTaskArchived}
        />
      </Modal>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  ArchivedTaskActions: bindActionCreators(ArchivedTaskActions, dispatch)
})

export default connect(TaskChatSelector, mapDispatchToProps)(TaskChat)
