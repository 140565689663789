import { fromJS } from 'immutable'

import { TASK_SECTION, TASK_STATUS, VIEW_TYPE } from '../constants'
import TasksListByViewType from '../selectors/map/tasks/TasksListByViewType/TasksListByViewType'
import { FilterMessages, SectionState, ViewState } from '../types'
import sectionStateUtils from '../util/sectionState'

import TaskActions from './services/TaskActions'

const SELECT_ONE_TASK = 'SELECT_ONE_TASK'
function selectOneTask(taskId) {
  return { type: SELECT_ONE_TASK, taskId }
}

const SELECT_TASK = 'SELECT_TASK'
function selectTask(taskId) {
  return { type: SELECT_TASK, taskId }
}

// Find which list the previous and newly selected tasks are in.
const getTaskList = ({ taskIds, taskId, lastSelectedTask }) => {
  if (taskIds.includes(lastSelectedTask) && taskIds.includes(taskId)) {
    return taskIds
  }

  return null
}

const SELECT_TASK_RANGE = 'SELECT_TASK_RANGE'
function selectTaskRange(taskId, viewType: VIEW_TYPE, sectionId: TASK_SECTION) {
  return (dispatch, getState) => {
    const state = getState()
    const { taskIds, groupedTaskIds } = TasksListByViewType(state, {
      viewType,
      sectionId,
      sectionState: {
        ...sectionStateUtils.getSectionState(viewType, sectionId, state)
      }
    })

    const onDemandTasks = groupedTaskIds
      ? groupedTaskIds
          .valueSeq()
          .flatten()
          .toJS()
      : taskIds.toJS()

    // Get the previous task selected from the list.
    const lastSelectedTask = state.MapUiReducer.get('lastSelectedTask')
    if (lastSelectedTask == null) {
      // Select this task if there haven't been other tasks selected before.
      return dispatch(selectTask(taskId))
    }

    const taskList = getTaskList({
      taskIds: onDemandTasks,
      taskId,
      lastSelectedTask
    })

    if (!taskList) {
      // Just select this task if the previously selected task is in a
      // different list
      return dispatch(selectTask(taskId))
    }

    const lastSelectedTaskIndex = taskList.findIndex((task) => {
      return task === lastSelectedTask
    })
    const newlySelectedTaskIndex = taskList.findIndex((task) => {
      return task === taskId
    })
    const filteredTaskIds = taskList.filter((task, index) => {
      const fromTop = lastSelectedTaskIndex < newlySelectedTaskIndex

      return (
        (fromTop &&
          index > lastSelectedTaskIndex &&
          index <= newlySelectedTaskIndex) ||
        (!fromTop &&
          index < lastSelectedTaskIndex &&
          index >= newlySelectedTaskIndex)
      )
    })

    return dispatch({ type: SELECT_TASK_RANGE, taskIds: filteredTaskIds })
  }
}

const DESELECT_TASK = 'DESELECT_TASK'
function deselectTask(taskId) {
  return { type: DESELECT_TASK, taskId }
}

const DESELECT_ALL = 'DESELECT_ALL'
function deselectAll() {
  return { type: DESELECT_ALL }
}

const CHANGE_TASK_FILTER_DAY = 'CHANGE_TASK_FILTER_DAY'
function changeTaskFilterDay(taskFilterDay) {
  return { type: CHANGE_TASK_FILTER_DAY, taskFilterDay }
}

const CHANGE_LAST_TASK_QUERY = 'CHANGE_LAST_TASK_QUERY'
function changeLastTaskQuery(params) {
  return { type: CHANGE_LAST_TASK_QUERY, params }
}

const SET_SECTION_STATE = 'SET_SECTION_STATE'
const setSectionState = (
  viewType: VIEW_TYPE,
  sectionId: TASK_SECTION,
  sectionState: SectionState
) => {
  return {
    type: SET_SECTION_STATE,
    sectionsState: {
      [sectionStateUtils.getId(viewType, sectionId)]: sectionState
    }
  }
}

const SET_VIEW_STATE = 'SET_VIEW_STATE'
const setViewState = (viewType: VIEW_TYPE, viewState: ViewState) => {
  return {
    type: SET_VIEW_STATE,
    viewState: {
      [viewType]: viewState
    }
  }
}

const FILTER_MESSAGES = 'FILTER_MESSAGES'
const setFilterMessages = (filterMessages: FilterMessages) => {
  return {
    type: FILTER_MESSAGES,
    filterMessages
  }
}

function batchUpdateTaskStatus(tasks, newStatus) {
  return (dispatch) => {
    tasks.map((taskId) => {
      dispatch(
        TaskActions.addStatus({
          taskId,
          driverId: null,
          author: null, // defaults to session user
          data: newStatus
        })
      )
    })
  }
}

function archiveSelected() {
  return (dispatch, getState) => {
    const state = getState()
    const tasks = state.MapUiReducer.get('selectedTasks')
    // tslint:disable-next-line
    if (confirm(`Archive ${tasks.size} selected tasks?`)) {
      return tasks.map(async (taskId) => {
        const status = state.services.getIn([
          'tasks',
          'all',
          fromJS({}),
          'data',
          taskId,
          'currentStatus'
        ])
        if (
          status !== TASK_STATUS.CANCELLED &&
          status !== TASK_STATUS.SUCCESS
        ) {
          const ref = state.services.getIn([
            'tasks',
            'all',
            fromJS({}),
            'data',
            taskId,
            'externalRef'
          ])
          alert(
            `${ref} not archived: must be completed or cancelled (status: ${status})`
          )
        } else {
          const response = await dispatch(TaskActions.archive({ taskId }))
          if (response.get('deleted') === 1) {
            dispatch(deselectTask(taskId))
          }
        }
      })
    }
  }
}

function toggleTask(taskId, viewType, sectionId, multiSelect?, rangeSelect?) {
  return (dispatch, getState) => {
    const state = getState()
    const tasks = state.MapUiReducer.get('selectedTasks')
    const selected = tasks.includes(taskId)
    if (multiSelect) {
      if (selected) {
        dispatch(deselectTask(taskId))
      } else {
        dispatch(selectTask(taskId))
      }
    } else if (rangeSelect) {
      dispatch(selectTaskRange(taskId, viewType, sectionId))
    } else if (tasks.size > 1) {
      dispatch(selectOneTask(taskId))
    } else {
      if (selected) {
        dispatch(deselectAll())
      } else {
        dispatch(selectOneTask(taskId))
      }
    }
  }
}

export {
  SELECT_ONE_TASK,
  SELECT_TASK,
  SELECT_TASK_RANGE,
  DESELECT_TASK,
  DESELECT_ALL,
  CHANGE_TASK_FILTER_DAY,
  CHANGE_LAST_TASK_QUERY,
  SET_SECTION_STATE,
  SET_VIEW_STATE,
  FILTER_MESSAGES,
  batchUpdateTaskStatus,
  archiveSelected
}

export default {
  changeLastTaskQuery,
  changeTaskFilterDay,
  deselectAll,
  deselectTask,
  selectTask,
  toggleTask,
  setSectionState,
  setViewState,
  setFilterMessages
}
