import React from 'react'
import { FormControl } from 'react-bootstrap'

interface Props {
  onChange: (event) => void
  onBlur: (event) => void
  value: any
}

class DebouncedControl extends React.Component<Props & any> {
  lastEvent: any = null
  scheduledTimeout: any = null

  onChange = (event) => {
    clearTimeout(this.scheduledTimeout)
    this.scheduledTimeout = setTimeout(this.dispatchLastEvent, 1000)
    event.persist()
    this.lastEvent = event
    this.forceUpdate()
  }

  onBlur = (event) => {
    this.dispatchLastEvent()
    if (this.props.onBlur) {
      this.props.onBlur(event)
    }
  }

  dispatchLastEvent = () => {
    const { lastEvent } = this
    this.lastEvent = null
    if (lastEvent) {
      this.props.onChange(lastEvent)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.scheduledTimeout)
    this.dispatchLastEvent()
  }

  keyDown = (e) => {
    if (e.keyCode === 13) {
      // enter key
      this.dispatchLastEvent()
    }
  }

  render() {
    return (
      <FormControl
        {...{
          ...this.props,
          onBlur: this.onBlur,
          onKeyDown: this.keyDown,
          value:
            this.lastEvent && this.lastEvent.target
              ? this.lastEvent.target.value
              : this.props.value,
          onChange: this.onChange
        }}
      />
    )
  }
}

export default DebouncedControl
