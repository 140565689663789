import { fromJS } from 'immutable'

import { orkestroRpcCall } from '../../api'
import AuthActions from '../AuthActions'
import { connect } from '../SocketConnectionActions'

const REQUEST_SENT = 'REQUEST_SENT'
const RESPONSE = 'RESPONSE'
const RESPONSE_BATCH = 'RESPONSE_BATCH'

function send(namespace, method, params, reconnecting = false) {
  params = fromJS(params)

  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(connect(namespace))
        .then((socket) => {
          const id = Math.random().toString()
          const responseInStore = getState().services.getIn([
            namespace,
            method,
            params
          ])
          const token = getState().AuthReducer.get('token')

          if (
            (!reconnecting &&
              responseInStore != null &&
              responseInStore.get('useCache')) ||
            (reconnecting && !responseInStore.get('resendOnReconnect'))
          ) {
            resolve(responseInStore)
          } else {
            let responseBatch = []
            let dispatchTimeout

            socket.on('response', (response) => {
              if (response.extras.id === id) {
                if (
                  response.type === 'error' &&
                  response.data === 'invalidToken'
                ) {
                  dispatch(AuthActions.logout('invalidToken'))
                  return
                }

                // hack to reduce performance hit on frontend by frequent gps updates:
                const { data } = response
                const gps = (data.quotes && data.quotes.gps) || []
                if (gps.length > 0) {
                  const lastTs = new Date(gps[gps.length - 1].timestamp)
                  // we care if gps don't update for 20min+
                  lastTs.setMilliseconds(0)
                  lastTs.setSeconds(0)
                  lastTs.setMinutes(Math.ceil(lastTs.getMinutes() / 5) * 5)
                  response.data.lastGpsUpdateTimestamp = lastTs.toISOString()
                  response.data.quotes.gps = undefined
                }

                responseBatch.push({
                  type: RESPONSE,
                  namespace,
                  method,
                  params,
                  responseType: response.type,
                  responseData: response.data
                })

                if (dispatchTimeout) {
                  clearTimeout(dispatchTimeout)
                }
                dispatchTimeout = setTimeout(() => {
                  const batch = responseBatch
                  responseBatch = []
                  dispatch({
                    type: RESPONSE_BATCH,
                    batch
                  })
                  resolve(
                    getState().services.getIn([namespace, method, params])
                  )
                }, 250)
              }
            })

            socket.emit('request', { method, params, token, extras: { id } })

            dispatch({ type: REQUEST_SENT, namespace, method, params })
            resolve()
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

function resend(namespace) {
  return (dispatch, getState) => {
    const methods = getState().services.get(namespace)

    if (methods != null) {
      methods.forEach((method, methodName) => {
        method.forEach((response, params) => {
          dispatch(send(namespace, methodName, params, true))
        })
      })
    }
  }
}

function rpcSend(namespace, method, paramsRaw) {
  return async (dispatch, getState) => {
    const params = fromJS(paramsRaw)
    const state = getState()
    const cachedResult = state.services.getIn([namespace, method, params])

    if (cachedResult && cachedResult.get('useCache')) {
      return cachedResult
    }

    dispatch({
      type: REQUEST_SENT,
      namespace,
      method,
      params,
      meta: { async: true }
    })

    try {
      const data = await orkestroRpcCall(namespace, method, paramsRaw)

      const batch = []

      if (data.results) {
        batch.push({
          type: RESPONSE,
          namespace,
          method,
          params,
          responseType: 'state',
          responseData: 'initializing'
        })
        batch.push(
          ...data.results.map((rowData) => ({
            type: RESPONSE,
            namespace,
            method,
            params,
            responseType: 'data',
            responseData: rowData
          }))
        )
        batch.push({
          type: RESPONSE,
          namespace,
          method,
          params,
          responseType: 'state',
          responseData: 'ready'
        })
      }

      if (data.error) {
        batch.push({
          type: RESPONSE,
          namespace,
          method,
          params,
          responseType: 'error',
          responseData: (data.error && data.error.message) || 'Unknown error'
        })
      }

      if (data.meta && Object.keys(data.meta).length > 0) {
        batch.push({
          type: RESPONSE,
          namespace,
          method,
          params,
          responseType: 'state',
          responseData: data.meta
        })
      }

      await dispatch({
        type: RESPONSE_BATCH,
        batch,
        meta: {
          async: true
        }
      })

      return getState().services.getIn([namespace, method, params])
    } catch (e) {
      // tslint:disable-next-line
      console.error(e)
    }
  }
}

export { send, resend, rpcSend, REQUEST_SENT, RESPONSE, RESPONSE_BATCH }
