import React from 'react'
import { Form } from 'react-bootstrap'
import { reduxForm } from 'redux-form'

import DefaultParcelSettings from '../DefaultParcelSettings/DefaultParcelSettings'
import NewTaskSettings from '../NewTaskSettings/NewTaskSettings'
import NotificationSettings from '../NotificationSettings/NotificationSettings'
import OpeningTimesSettings from '../OpeningTimesSettings/OpeningTimesSettings'
import VehicleTypeSettings from '../VehicleTypeSettings/VehicleTypeSettings'

import './MerchantSettingsForm.scss'

interface Props {
  handleSubmit: () => Promise<void>
  resetForm: () => void
  fields: any
  submitting: boolean
  pristine: boolean
}

class MerchantSettingsForm extends React.PureComponent<Props> {
  render() {
    return (
      <Form className='MerchantSettingsForm'>
        <NotificationSettings {...this.props} />
        <NewTaskSettings {...this.props} />
        <DefaultParcelSettings {...this.props} />
        <VehicleTypeSettings {...this.props} />
        <OpeningTimesSettings {...this.props} />
      </Form>
    )
  }
}

const reduxFormConfig = {
  form: 'merchantSettings',
  fields: [
    'inTransitMailMessage',
    'inTransitTextMessage',
    'nearbyMailMessage',
    'nearbyTextMessage',
    'sendMailWhenInTransit',
    'sendMailWhenNearby',
    'loadPreviousSource',
    'sendTextWhenInTransit',
    'sendTextWhenNearby',
    'parcelLength',
    'parcelWidth',
    'parcelHeight',
    'parcelWeight',
    'parcelFragile',
    'parcelDoNotRotate',
    'parcelContainsLiquid',
    'parcelContainsHotFood',
    'disallowedVehicles',
    'openingTimes'
  ],
  validate(values) {
    // TODO: localise validation error messages
    const errors: any = {}

    if (values.openingTimes) {
      values.openingTimes.forEach((times) => {
        if (
          (times.opens == null && times.closes != null) ||
          (times.opens != null && times.closes == null)
        ) {
          errors.openingTimes =
            'You must specify both an opening and closing time for days when you are not closed.'
        }
      })
    }

    return errors
  }
}
export { reduxFormConfig }
export default reduxForm(reduxFormConfig)(MerchantSettingsForm)
