import React, { PureComponent } from 'react'
import { Checkbox, Col, FormGroup, Panel, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

import { getFormControlPropsFromReduxForm } from '../../../../util/form'

interface Props {
  handleSubmit: () => Promise<void>
  resetForm: () => void
  fields: any
  submitting: boolean
  pristine: boolean
}

class NewTaskSettings extends PureComponent<Props> {
  handleCheckboxChange(event) {
    this.props.fields[event.target.name].onChange(event)
    // We need to submit the form asynchronously for the checkbox change event
    // to propagatee through the redux store.
    setTimeout(this.props.handleSubmit, 50)
  }

  render() {
    const { loadPreviousSource } = this.props.fields

    return (
      <Row className='NewTaskSettings'>
        <Col sm={12}>
          <h3>
            <FormattedMessage id='settings.merchant.NewTaskSettings.title' />
          </h3>
        </Col>

        {/* sms notification settings */}
        <Col sm={6}>
          <Panel>
            <Panel.Heading>
              <h3>
                <FormattedMessage id='settings.merchant.NewTaskSettings.heading' />
              </h3>
            </Panel.Heading>
            <Panel.Body>
              <FormGroup controlId='loadPreviousSource'>
                <Checkbox
                  disabled={this.props.submitting}
                  {...getFormControlPropsFromReduxForm(loadPreviousSource)}
                  onChange={this.handleCheckboxChange.bind(this)}
                >
                  <FormattedMessage id='merchant.loadPreviousSource' />
                </Checkbox>
              </FormGroup>
            </Panel.Body>
          </Panel>
        </Col>
      </Row>
    )
  }
}

export default NewTaskSettings
