import { fromJS, Map as ImmutableMap } from 'immutable'
import React from 'react'
import { Col, Panel, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import MerchantActions from '../../../../actions/services/MerchantActions'
import Spinner from '../../../common/Spinner/Spinner'

interface BankCardProps {
  card: ImmutableMap<string, any>
}

const BankCard = (props: BankCardProps) => {
  const { card } = props

  return (
    <Panel className='BankCard'>
      <Panel.Body>
        {card.get('cvc_check') === 'pass' &&
        card.get('address_zip_check') === 'pass' &&
        card.get('address_line1_check') === 'pass' ? (
          <p className='pull-right text-success'>
            <i className='fa fa-check spaceAfter' />
            Verified
          </p>
        ) : (
          <p className='pull-right text-danger'>
            <i className='fa fa-times-circle spaceAfter' />
            Unverified
          </p>
        )}
        <h5>
          {card.get('brand')} ending with {card.get('last4')}
        </h5>
        <p>
          Expires: {card.get('exp_month')}/{card.get('exp_year')}
          <br />
        </p>
        <h5>Billing address</h5>
        <p className='selectable'>
          {card.get('address_line1')}
          <br />
          {card.get('address_line2')}
          <br />
          {card.get('address_city')}
          <br />
          {card.get('address_zip')}
          <br />
          {card.get('address_state')}
          <br />
          {card.get('address_country')}
        </p>
      </Panel.Body>
    </Panel>
  )
}

interface StateProps {
  stripeSources: ImmutableMap<any, any>
}

interface DispatchProps {
  MerchantActions: typeof MerchantActions
}

interface Props {
  merchantId: string
}

class StripeSources extends React.PureComponent<
  StateProps & DispatchProps & Props
> {
  componentWillMount() {
    this.getStripeSources()
  }

  componentWillUpdate() {
    this.getStripeSources()
  }

  getStripeSources() {
    this.props.MerchantActions.getStripeSources({ id: this.props.merchantId })
  }

  render() {
    const { stripeSources } = this.props

    return (
      <Row>
        <Col sm={12} md={6}>
          <h2>
            <FormattedMessage id='settings.merchant.billing.title' />
          </h2>
          <Panel>
            <Panel.Heading>
              <h4>
                <FormattedMessage id='settings.merchant.stripeSources.title' />
              </h4>
            </Panel.Heading>
            <Panel.Body>
              {stripeSources == null || !stripeSources.get('ready') ? (
                <Spinner />
              ) : stripeSources.get('data') == null ||
                stripeSources.get('data').size === 0 ? (
                <FormattedMessage id='settings.merchant.stripeSources.noSources' />
              ) : (
                stripeSources
                  .get('data')
                  .map((card, id) => {
                    return <BankCard key={id} card={card} />
                  })
                  .toArray()
              )}
            </Panel.Body>
          </Panel>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = (state: any, props: Props) => {
  return {
    stripeSources: state.services.getIn([
      'merchants',
      'getStripeSources',
      fromJS({ id: props.merchantId })
    ])
  }
}
const mapDispatchToProps = (dispatch) => ({
  MerchantActions: bindActionCreators(MerchantActions, dispatch)
})

export { mapStateToProps, mapDispatchToProps, BankCard }
export default connect<StateProps, DispatchProps, Props>(
  mapStateToProps,
  mapDispatchToProps
)(StripeSources)
