import { Map } from 'immutable'

import { VIEW_TYPE } from '../constants'

const getViewState = (viewType: VIEW_TYPE, state: any) => {
  return state.MapUiReducer.get('viewState')
    .get(
      viewType,
      Map({
        checkedSeparatePendingAndOngoing: true
      })
    )
    .toJS()
}

export default {
  getViewState
}
