import { fromJS } from 'immutable'

import { CHANGE_LAST_REPORT_QUERY } from '../actions/ReportsUiActions'

const initialState = fromJS({
  lastQuery: null,
  params: null
})

export default function ReportsUiReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_LAST_REPORT_QUERY:
      return state.merge({
        lastQuery: action.query,
        params: action.params
      })

    default:
      return state
  }
}
