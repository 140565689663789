const BATCH_ACTION = 'BATCH_ACTION'

function batchActions(actions) {
  return { type: BATCH_ACTION, payload: actions }
}

function batchReducer(reducer) {
  return (state, action) => {
    if (action.type === BATCH_ACTION) {
      return action.payload.reduce(reducer, state)
    } else {
      return reducer(state, action)
    }
  }
}

export { batchActions, batchReducer, BATCH_ACTION }
