/**
 * Chat services
 * docs: https://orkestro.atlassian.net/wiki/spaces/ORK2/pages/299794433/CHAT
 */

import { auth, firestore } from 'firebase/app'
import { isNil } from 'lodash'
import { BehaviorSubject } from 'rxjs'

import {
  RequestChatMessage,
  ResponseChatInfo,
  ResponseChatMessage
} from '../../components/common/ChatWindow/types'
import { TASK_SECTION, TOGGLE } from '../../constants'
import { chatsQuery, chatsQueryForLast } from '../firebase/firestoreQuery'

import cannedResponse from './cannedResponse'
import { ChatBehaviorSubject, MessageType } from './types'

const subject = new BehaviorSubject<ChatBehaviorSubject>({
  isAdmin: false,
  username: null,
  activeTaskId: null,
  chats: {},
  taskIds: {}
})

let chatQueryUnsubscriber: () => void = null

const isSameLastMessage = (
  newChatInfo: ResponseChatInfo,
  chatInfo: ResponseChatInfo
) => {
  return newChatInfo?.lastMessage?.id === chatInfo?.lastMessage?.id
}

const listen = async (username: string) => {
  stopListen()

  const idTokenResult = await auth().currentUser.getIdTokenResult()
  const isAdministrator = idTokenResult.claims.administrator === true

  subject.next({
    ...subject.getValue(),
    username,
    isAdmin: isAdministrator
  })

  if (isAdministrator) {
    cannedResponse.listen()
  }

  let initializedData = false

  chatQueryUnsubscriber = chatsQueryForLast(2, 'days').onSnapshot(
    (snapshot) => {
      const value = { ...subject.getValue() }

      snapshot.docChanges().forEach((docChange) => {
        switch (docChange.type) {
          case 'added':
          case 'modified':
            const taskId = docChange.doc.id
            const newChatInfo = docChange.doc.data() as ResponseChatInfo
            const prevChatInfo = value.chats[taskId]

            value.chats[taskId] = newChatInfo

            if (getSoundSetting() === TOGGLE.OFF) {
              return
            }

            // don't play sound when not finish initializing yet
            if (!initializedData) {
              return
            }

            // if the chat is opening or receive the change (lastRead, resolved...) with the same message id will not play sound
            if (
              value.activeTaskId === taskId ||
              isSameLastMessage(newChatInfo, prevChatInfo)
            ) {
              return
            }

            if (!hasUnreadMessages(taskId)) {
              return
            }

            new Audio(require('./assets/new_messages.mp3')).play()
            break

          case 'removed':
            delete value.chats[docChange.doc.id]
            break
        }
      })

      subject.next(value)

      if (!initializedData) {
        initializedData = true
      }
    }
  )
}

const stopListen = () => {
  if (chatQueryUnsubscriber) {
    chatQueryUnsubscriber()
  }

  cannedResponse.stopListen()
}

const extractAndFlattenTaskIds = (chatInfo: ChatBehaviorSubject): string[] => {
  return Object.keys(chatInfo.taskIds).reduce(
    (acc, sectionId: TASK_SECTION) => [
      ...acc,
      ...(chatInfo.taskIds[sectionId] || [])
    ],
    []
  )
}

const countMessages = () => {
  const value = subject.getValue()
  const taskIds = extractAndFlattenTaskIds(value)

  return Object.keys(value.chats).reduce(
    (acc, taskId) => {
      if (!taskIds.includes(taskId) || isResolved(taskId)) {
        return acc
      }

      if (hasUnreadMessages(taskId)) {
        return {
          ...acc,
          unread: acc.unread + 1
        }
      }

      if (hasMessages(taskId)) {
        return {
          ...acc,
          read: acc.read + 1
        }
      }

      return acc
    },
    {
      read: 0,
      unread: 0
    }
  )
}

const hasUnreadMessages = (taskId: string) => {
  const value = subject.getValue()
  const taskIds = extractAndFlattenTaskIds(value)

  if (!taskIds.includes(taskId)) {
    return false
  }

  const { chats, username } = value

  const chatInfo = chats[taskId]
  const lastMessageTimestamp = chatInfo?.lastMessage?.timestamp

  if (lastMessageTimestamp) {
    const myLastReadTimestamp = chatInfo?.lastRead?.[username]

    if (myLastReadTimestamp) {
      return lastMessageTimestamp.toMillis() > myLastReadTimestamp.toMillis()
    }

    return true
  }

  return false
}

const addMessage = ({
  taskId,
  message,
  username,
  type = MessageType.TEXT,
  messageData = '',
  resolved = false
}: RequestChatMessage) => {
  const taskIdDoc = chatsQuery.doc(taskId)
  const messageObj = {
    sender: { username, isAdministrator: isAdmin() },
    timestamp: firestore.FieldValue.serverTimestamp(),
    message,
    messageData,
    type
  }
  const addMessageDoc = taskIdDoc.collection('messages').doc()
  const addMessagePromise = addMessageDoc.set(messageObj)

  const taskDoc = {
    lastRead: {
      [username]: firestore.FieldValue.serverTimestamp()
    },
    resolved,
    ...(resolved
      ? {}
      : {
          lastMessage: {
            ...messageObj,
            id: addMessageDoc.id
          }
        })
  }

  const taskPromise = taskIdDoc.set(taskDoc, { merge: true })

  return Promise.all([addMessagePromise, taskPromise])
}

const markAsRead = (taskId: string) => {
  return chatsQuery.doc(taskId).set(
    {
      lastRead: {
        [getCurrentUsername()]: firestore.FieldValue.serverTimestamp()
      }
    },
    { merge: true }
  )
}

const hasMessages = (taskId: string) => {
  return !isNil(subject.getValue().chats[taskId])
}

const isRead = (taskId) => hasMessages(taskId) && !hasUnreadMessages(taskId)

const setActive = (activeTaskId: string) => {
  subject.next({ ...subject.getValue(), activeTaskId })
}

const setTaskIds = (sectionId, taskIds: string[]) => {
  const prevValue = subject.getValue()

  subject.next({
    ...prevValue,
    taskIds: {
      ...prevValue.taskIds,
      [sectionId]: taskIds
    }
  })
}

const resolve = (taskId: string) => {
  return addMessage({
    taskId,
    type: MessageType.RESOLVED,
    message: '',
    username: getCurrentUsername(),
    resolved: true
  })
}

const isResolved = (taskId: string) => {
  const value = subject.getValue()

  return value.isAdmin && value.chats[taskId]?.resolved === true
}

const setSoundSetting = (toggle: TOGGLE) => {
  window.localStorage.setItem('chat_sound', toggle)
}

const getSoundSetting = () =>
  (window.localStorage.getItem('chat_sound') as TOGGLE) || TOGGLE.ON

const getReadUsers = (
  taskId: string,
  message: ResponseChatMessage
): string[] => {
  const { chats, username } = subject.getValue()
  const lastRead = chats[taskId]?.lastRead ?? {}
  const excludeUsers = [username, message.sender.username]

  return Object.keys(lastRead).reduce((acc, readUsername) => {
    if (excludeUsers.includes(readUsername)) {
      return acc
    }

    const readTimestamp = lastRead[readUsername]
    if (!readTimestamp || !message.timestamp) {
      return acc
    }

    if (readTimestamp.toMillis() >= message.timestamp.toMillis()) {
      return [...acc, readUsername]
    }

    return acc
  }, [])
}

const getCurrentUsername = () => subject.getValue().username

const getMessageType = (message: ResponseChatMessage) => {
  return message.type || MessageType.TEXT
}

const isAdmin = () => subject.getValue().isAdmin

export default {
  addMessage,
  hasUnreadMessages,
  countMessages,
  markAsRead,
  subject,
  listen,
  stopListen,
  hasMessages,
  setActive,
  setTaskIds,
  isRead,
  resolve,
  isResolved,
  setSoundSetting,
  getSoundSetting,
  getReadUsers,
  getCurrentUsername,
  getMessageType,
  isAdmin
}
