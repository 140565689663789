import { Map } from 'immutable'

import { SORT_TYPE, TASK_SECTION, VIEW_TYPE } from '../constants'

const getId = (viewType: VIEW_TYPE, sectionId: TASK_SECTION) =>
  [viewType, sectionId].join('_')

const getSectionState = (
  viewType: VIEW_TYPE,
  sectionId: TASK_SECTION,
  state: any
) => {
  const id = getId(viewType, sectionId)

  return state.MapUiReducer.get('sectionsState')
    .get(
      id,
      Map({
        sortType: SORT_TYPE.CLOCK,
        checkedPending: true,
        checkedOnGoing: true
      })
    )
    .toJS()
}

export default {
  getId,
  getSectionState
}
