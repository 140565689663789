import { is } from 'immutable'
import { isEmpty } from 'lodash'
import React from 'react'

import { MAX_TASK_DISTANCE } from '../../../../../constants'

import { Props } from './types'

class WarningIcon extends React.Component<Props, {}> {
  shouldComponentUpdate(
    nextProps: Readonly<Props>,
    nextState: Readonly<{}>,
    nextContext: any
  ): boolean {
    return (
      !is(this.props.task, nextProps.task) ||
      !is(this.props.user, nextProps.user)
    )
  }

  getWarnings() {
    const { task, user } = this.props
    let tooltip = ''

    if (!task.get('sourceGeolocation') || !task.get('destinationGeolocation')) {
      tooltip += '\nAddress has not been geolocated.'
    }

    if (
      task.getIn(['geomatrix', 'walking', 'distanceMeters']) > MAX_TASK_DISTANCE
    ) {
      tooltip += '\nDistance too large (wrong address or wrongly geolocated)'
    }

    if (user.get('administrator')) {
      for (const note of task.getIn(['quotes', 'confirmation', 'notes']) ||
        []) {
        if (note.get('level') === 'warn') {
          tooltip += '\n' + note.get('message')
        }
      }
    }

    if (task.getIn(['quotes', 'confirmation'])) {
      const actionLog = task.get('actionLog')
      const quoteApproved = actionLog
        .filter((log) => log.get('type') === 'quoteApproved')
        .get(-1)
      if (quoteApproved && quoteApproved.get('level') === 'warn') {
        const notes = quoteApproved.get('notes')
        if (notes) {
          tooltip += '\n' + notes.toJS().join('\n')
        }
      }
    }

    return tooltip.trim()
  }

  render() {
    const warnings = this.getWarnings()

    if (isEmpty(warnings)) {
      return null
    }

    return (
      <span className='warningIconContainer'>
        <span className='warningIcon' title={warnings}>
          <i className='fa fa-exclamation-triangle' />
        </span>
      </span>
    )
  }
}

export default WarningIcon
