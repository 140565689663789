import { createSelector } from 'reselect'

function getResponse(state) {
  if (state.ReportsUiReducer.get('lastQuery') === 'filter') {
    return state.services.getIn([
      'archivedTasks',
      'find',
      state.ReportsUiReducer.get('params'),
      'data'
    ])
  } else if (
    state.ReportsUiReducer.get('lastQuery') === 'search' &&
    state.form.reportsSearch != null
  ) {
    return state.services.getIn([
      'archivedTasks',
      'findById',
      state.ReportsUiReducer.get('params'),
      'data'
    ])
  } else {
    return null
  }
}

const combiner = (response) => {
  return response
}

export { getResponse, combiner }
export default createSelector(getResponse, combiner)
