import { Map as ImmutableMap } from 'immutable'
import React from 'react'
import { Alert } from 'react-bootstrap'
import { connect } from 'react-redux'
import {
  generatePath,
  Link,
  RouteComponentProps,
  withRouter
} from 'react-router-dom'
import { bindActionCreators } from 'redux'

import NotificationsUiActions from '../../../../actions/NotificationsUiActions'
import { VIEW_TYPE } from '../../../../constants'
import { Routes } from '../../../../navigation/Routes'
import LateNotificationsSelector from '../../../../selectors/map/tasks/LateNotificationsSelector/LateNotificationsSelector'

import './LateNotifications.scss'

interface NotificationProps {
  task: ImmutableMap<any, any>
  onDismissClick: () => void
  viewType: VIEW_TYPE
}

class Notification extends React.PureComponent<NotificationProps> {
  render() {
    const { task, viewType } = this.props
    const taskId = task.get('id')
    return (
      <Alert
        className='Notification'
        bsStyle='warning'
        onDismiss={this.props.onDismissClick}
      >
        <Link to={generatePath(Routes.Map.TaskDetail, { taskId, viewType })}>
          Task {task.get('externalRef')} needs to be picked up soon, but no
          driver has been assigned to the task.
        </Link>
      </Alert>
    )
  }
}

interface Props extends RouteComponentProps<{ viewType: VIEW_TYPE }> {
  lateTasks: ImmutableMap<any, any>
  NotificationsUiActions: typeof NotificationsUiActions
}
class LateNotifications extends React.PureComponent<Props> {
  handleDismissClick(taskId) {
    this.props.NotificationsUiActions.dismissLatePickupNotification(taskId)
  }

  render() {
    return (
      <div className='LateNotifications'>
        {this.props.lateTasks
          .map((task) => {
            const id = task.get('id')
            return (
              <Notification
                key={id}
                task={task}
                onDismissClick={this.handleDismissClick.bind(this, id)}
                viewType={this.props.match.params.viewType}
              />
            )
          })
          .toArray()}
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  NotificationsUiActions: bindActionCreators(NotificationsUiActions, dispatch)
})

export { Notification, mapDispatchToProps }
export default connect(
  LateNotificationsSelector,
  mapDispatchToProps
)(withRouter(LateNotifications))
