import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router'

import config from '../../../config'
import RegistrationConfirmation from '../RegistrationConfirmation/RegistrationConfirmation'
import RegistrationForm from '../RegistrationForm/RegistrationForm'

interface State {
  response: object
  error: object
}

class RegistrationFormContainer extends Component<RouteComponentProps, State> {
  constructor(props) {
    super(props)

    this.state = {
      response: null,
      error: null
    }
  }

  handleSubmit(formData) {
    // To avoid PCI compliance, we never want to see the card details at the
    // server end. Even if we don't store these details, we don't want anything
    // in our log files or whatever. Stripe gives us a way of avoiding this:
    // the client app sends the card details to Stripe and gets back a token.
    // This token can then be sent to our server.
    return new Promise((resolve, reject) => {
      window.Stripe.card.createToken(
        {
          number: formData.cardNumber,
          cvc: formData.cvc,
          exp_month: formData.expiryMonth,
          exp_year: formData.expiryYear,
          name: formData.cardholderName,
          address_line1: formData.billingAddress1,
          address_line2: formData.billingAddress2,
          address_city: formData.billingCity,
          address_zip: formData.billingPostcode,
          address_country: formData.billingCountry
        },
        (status, response) => {
          if (status !== 200 || response.ok === false) {
            reject(response)
          } else {
            resolve(response)
          }
        }
      )
    })
      .then((stripeClientResponse: any) => {
        return window.fetch(`${config.trackingServer}/users/register`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            stripeToken: stripeClientResponse.id,
            companyName: formData.companyName,
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            password: formData.password1,
            tnc: formData.tnc
          })
        })
      })
      .then((response) => {
        return response.json()
      })
      .then((response) => {
        if (response.error == null) {
          this.setState({ response })
        } else {
          throw response
        }
      })
      .catch((error) => {
        /* eslint no-throw-literal:0 */
        // Make sure we reject the original Promise, and convert whatever error
        // we get into an error object compatible with redux-form.
        this.setState({ error })
        throw { error }
      })
  }

  render() {
    if (!this.getCompanyName() && !this.state.response) {
      // redirect to hsforms page, if company name is not set in url
      return (
        <div
          ref={() =>
            window.location.assign(
              'https://share.hsforms.com/1FRarqN3FTOSUC6ArTLht9w3cfig'
            )
          }
        >
          <div
            className='loadingspinner'
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              margin: '-75px 0 0 -75px',
              width: '150px',
              height: '150px'
            }}
          ></div>
        </div>
      )
    } else {
      return this.state.response == null ? (
        <RegistrationForm
          serverError={this.state.error}
          onSubmit={this.handleSubmit.bind(this)}
          merchantNameReadOnly={this.getCompanyName() ? true : false}
          initialValues={{
            billingCountry: 'United Kingdom',
            companyName: this.getCompanyName()
          }}
        />
      ) : (
        <RegistrationConfirmation />
      )
    }
  }

  getCompanyName(): string | undefined {
    const { location: { search = '' } = {} } = this.props
    const match = search.match(/merchant=([^&]+)/)
    if (match && match[1]) {
      return decodeURIComponent(match[1]).trim()
    }
  }
}

export default RegistrationFormContainer
