import React, { PureComponent } from 'react'
import 'styles/App.scss'

import ConnectionError from './common/ConnectionError/ConnectionError'
import Navbar from './common/Navbar/Navbar'

class App extends PureComponent {
  render() {
    return (
      <div className='App'>
        <ConnectionError />
        <Navbar />
        <div className='main'>{this.props.children}</div>
      </div>
    )
  }
}

export default App
