import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

import config from '../../config'
import chat from '../chat/chat'

if (firebase.apps.length === 0) {
  firebase.initializeApp(config.firebaseConfig)
}

const auth = async () => {
  firebase.auth().onAuthStateChanged(async (user) => {
    const token = window.localStorage.getItem('token')
    if (!token) {
      return
    }

    if (user) {
      chat.listen(user.uid)

      return
    }

    const response = await fetch(
      `${config.trackingServer}/users/getFirebaseCustomTokens`,
      {
        method: 'GET',
        headers: {
          authorization: `Bearer ${token}`,
          'content-type': 'application/json'
        }
      }
    )

    const { token: customToken } = await response.json()
    const {
      user: { uid }
    } = await firebase.auth().signInWithCustomToken(customToken)

    chat.listen(uid)
  })
}

const signOut = () => firebase.auth().signOut()

export default { auth, signOut }
