import { getChosenQuote } from '../components/map/task/quotes/QuotesTable/QuotesTable.selector'

import TaskActions from './services/TaskActions'

function redoQuotes(taskId) {
  return (dispatch) => {
    dispatch(selectQuote(null, null))
    return dispatch(TaskActions.redoQuote({ taskId }))
  }
}

function approveQuote({ taskId }) {
  return (dispatch, getState) => {
    const quote = getChosenQuote(getState(), { taskId })
    if (quote) {
      const query = {
        taskId,
        chosenQuote: getState()
          .QuotesUiReducer.getIn(['chosenQuote', taskId])
          .toJS()
      }
      dispatch(TaskActions.approveQuote(query))
      return dispatch(changeLastQuoteApproveQuery(taskId, query))
    } else {
      // tslint:disable-next-line
      console.error(
        `Asked to approve quote but no quote is selected for task ${taskId}`
      )
    }
  }
}

function cancelQuote(task) {
  const taskId = task.get('id')

  return (dispatch) => {
    const query = {
      taskId,
      timestamp: task.getIn(['quotes', 'timestamp'])
    }

    dispatch(TaskActions.cancelQuote(query))
    return dispatch(changeLastQuoteCancelQuery(taskId, query))
  }
}

const SELECT_QUOTE = 'SELECT_QUOTE'
export function selectQuote(taskId, chosenQuote) {
  return { type: SELECT_QUOTE, taskId, chosenQuote }
}

const TOGGLE_QUOTE = 'TOGGLE_QUOTE'
function toggleQuote(taskId, chosenQuote) {
  return { type: TOGGLE_QUOTE, taskId, chosenQuote }
}

const RESET_LAST_QUOTE_QUERIES = 'RESET_LAST_QUOTE_QUERIES'
function resetLastQuoteQueries() {
  return { type: RESET_LAST_QUOTE_QUERIES }
}

const CHANGE_LAST_QUOTE_APPROVE_QUERY = 'CHANGE_LAST_QUOTE_APPROVE_QUERY'
export function changeLastQuoteApproveQuery(taskId, query) {
  return { type: CHANGE_LAST_QUOTE_APPROVE_QUERY, taskId, query }
}

const CHANGE_LAST_QUOTE_CANCEL_QUERY = 'CHANGE_LAST_QUOTE_CANCEL_QUERY'
export function changeLastQuoteCancelQuery(taskId, query) {
  return { type: CHANGE_LAST_QUOTE_CANCEL_QUERY, taskId, query }
}

export {
  SELECT_QUOTE,
  TOGGLE_QUOTE,
  RESET_LAST_QUOTE_QUERIES,
  CHANGE_LAST_QUOTE_APPROVE_QUERY,
  CHANGE_LAST_QUOTE_CANCEL_QUERY
}

export default {
  resetLastQuoteQueries,
  redoQuotes,
  approveQuote,
  cancelQuote,
  toggleQuote
}
