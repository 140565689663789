import { Map as ImmutableMap } from 'immutable'
import moment from 'moment'
import { matchPath } from 'react-router'

import {
  QUOTE_STATUS,
  SCHEDULE,
  TASK_STATUS,
  TRANSPORT_TYPE,
  VIEW_TYPE
} from '../constants'
import { Routes } from '../navigation/Routes'

const getViewType = (pathname: string) => {
  const match = matchPath<{ viewType: VIEW_TYPE }>(pathname.replace(/#/, ''), {
    path: Routes.Map.Tasks
  })

  if (match) {
    return match.params.viewType
  }

  return VIEW_TYPE.TASKS
}

const getTaskIdAndSalt = (task: ImmutableMap<string, any>) =>
  task.get('salt') + task.get('id')

const getPickupTime = (task) => {
  const schedule = task.get('schedule')
  if (
    schedule === SCHEDULE.PICKUP_AND_DELIVERY ||
    schedule === SCHEDULE.CUSTOM
  ) {
    return moment(task.get('pickupDateFrom'))
  } else if (schedule === SCHEDULE.DELIVERY) {
    return moment(task.get('deliveryDateTo')).subtract(60, 'minutes')
  }

  return moment(task.getIn(['quotes', 'timestamp'])).add(15, 'minutes')
}

const getDropoffTime = (task) => {
  const schedule = task.get('schedule')
  if (
    schedule === SCHEDULE.PICKUP_AND_DELIVERY ||
    schedule === SCHEDULE.CUSTOM ||
    schedule === SCHEDULE.DELIVERY
  ) {
    return moment(task.get('deliveryDateTo'))
  } else {
    return getPickupTime(task).add(60, 'minutes')
  }
}

const getDriver = (task, driver) => {
  if (driver) {
    return {
      id: driver.get('id'),
      name: (driver.get('firstName') + ' ' + driver.get('lastName')).trim(),
      phone: driver.get('mobileNumber')
    }
  } else if (task.getIn(['quotes', 'confirmation'])) {
    const vehicle = task.getIn(['quotes', 'chosenQuote', 'vehicleType']) || ''

    return {
      id: null,
      name: task.getIn(['driver', 'name']),
      phone: task.getIn(['driver', 'phone']),
      vehicle
    }
  } else {
    return null
  }
}

/**
 * Internal drivers have driver info and do not have quotes.confirmation
 * @param task
 */
const isInternalDriver = (task) => {
  const confirmation = task.getIn(['quotes', 'confirmation'])
  const driverId = task.getIn(['driver', 'id'])

  return !confirmation && driverId
}

/**
 * Third party drivers will have driver.id = null
 * @param task
 */
const is3rdPartyDriver = (task) => {
  const driverId = task.getIn(['driver', 'id'])
  return driverId == null
}

const getLogByType = (task, type) => {
  return task.get('actionLog', []).find((log) => log.get('type') === type)
}

const getDropoffDelay = (task) => {
  const plannedDropoffTime = getDropoffTime(task)
  if (!plannedDropoffTime) {
    return null
  }
  const arrivedAtDropoffInfo = getLogByType(task, TASK_STATUS.SUCCESS)
  const arrivedAtDropoffTime =
    arrivedAtDropoffInfo && arrivedAtDropoffInfo.get('timestamp')
  const arrivedTimeOrETA =
    arrivedAtDropoffTime || task.getIn(['eta', 'dropoff'])
  const diffMinutes = moment(arrivedTimeOrETA).diff(
    plannedDropoffTime,
    'minutes',
    true
  )
  if (diffMinutes > 0) {
    return Math.ceil(diffMinutes)
  }
  return null
}

const getDropoffWait = (task) => {
  const arrivedAtDropoffInfo = getLogByType(
    task,
    TASK_STATUS.ARRIVED_AT_DROPOFF
  )
  if (arrivedAtDropoffInfo) {
    // if ARRIVED_AT_DROPOFF status does not exist, use Now
    const diffMinutes = moment().diff(
      arrivedAtDropoffInfo.get('timestamp'),
      'minutes',
      true
    )
    return Math.ceil(diffMinutes)
  }
  return null
}

const getPickupDelay = (task) => {
  const plannedPickupTime = getPickupTime(task)
  if (!plannedPickupTime) {
    return null
  }

  const arrivedAtPickupLog =
    getLogByType(task, TASK_STATUS.ARRIVED_AT_PICKUP) ||
    getLogByType(task, TASK_STATUS.IN_TRANSIT)
  const arrivedAtPickupTime =
    arrivedAtPickupLog && arrivedAtPickupLog.get('timestamp')
  const arrivedTimeOrETA = arrivedAtPickupTime || task.getIn(['eta', 'pickup'])
  const diffMinutes = moment(arrivedTimeOrETA).diff(
    plannedPickupTime,
    'minutes',
    true
  )
  if (diffMinutes > 0) {
    return Math.ceil(diffMinutes)
  }
  return null
}

const getPickupTimeForWaitingTime = (task: ImmutableMap<any, any>) => {
  if ([SCHEDULE.ASAP || SCHEDULE.URGENT].includes(task.get('schedule'))) {
    return new Date().toISOString()
  }

  return task.get('pickupDateFrom')
}

const getPickupWait = (task: ImmutableMap<any, any>) => {
  const arrivedAtPickupInfo = getLogByType(task, TASK_STATUS.ARRIVED_AT_PICKUP)

  if (arrivedAtPickupInfo) {
    if (
      arrivedAtPickupInfo.get('timestamp') <= task.get('pickupDateFrom') &&
      new Date().toISOString() <= task.get('pickupDateFrom')
    ) {
      return null
    }
    if (arrivedAtPickupInfo.get('timestamp') > task.get('pickupDateFrom')) {
      return Math.ceil(
        moment().diff(arrivedAtPickupInfo.get('timestamp'), 'minutes', true)
      )
    }
    const pickupDateFrom = getPickupTimeForWaitingTime(task)

    return Math.ceil(moment().diff(pickupDateFrom, 'minutes', true))
  }

  return null
}

const getGeomatrixTransportType = (task) => {
  if (isInternalDriver(task)) {
    return 'bicycling'
  }

  const vehicleType = task.getIn(['quotes', 'chosenQuote', 'vehicleType'])
  switch (vehicleType) {
    case TRANSPORT_TYPE.Walk:
      return 'walking'

    case TRANSPORT_TYPE.Bicycle:
    case TRANSPORT_TYPE.CargoBicycle:
      return 'bicycling'

    case TRANSPORT_TYPE.Motorbike:
    case TRANSPORT_TYPE.Car:
    case TRANSPORT_TYPE.SmallVan:
    case TRANSPORT_TYPE.Van:
      return 'driving'

    default:
      break
  }

  return task.get('isWalked') ? 'walking' : 'driving'
}

const getTravelTimeInMinutes = (task) => {
  const transportType = getGeomatrixTransportType(task)

  if (transportType) {
    const durationSeconds = task.getIn([
      'geomatrix',
      transportType,
      'durationSeconds'
    ])

    if (durationSeconds != null && durationSeconds > 0) {
      return Math.ceil(durationSeconds / 60)
    }
  }

  return null
}

const isWaitingForConfirmation = (task) => {
  return (
    task.getIn(['quotes', 'confirmation', 'vendorReference']) ===
    '(waiting for confirmation)'
  )
}

const isWaitingToBeAssignedToDriver = (
  task: ImmutableMap<string, any>
): boolean => {
  const taskCurrentStatus = task.get('currentStatus')

  return (
    taskCurrentStatus === TASK_STATUS.QUOTE_APPROVED ||
    (task.getIn(['quotes', 'status']) === QUOTE_STATUS.APPROVED &&
      taskCurrentStatus === TASK_STATUS.TASK_RECEIVED)
  )
}

const isParentTask = (task) => !task.get('parentTask')

const isMultidrop = (task) => task.get('type') === 'multidrop'

export {
  getViewType,
  getTaskIdAndSalt,
  is3rdPartyDriver,
  isInternalDriver,
  getDriver,
  getPickupTime,
  getDropoffTime,
  getLogByType,
  getDropoffDelay,
  getDropoffWait,
  getPickupDelay,
  getPickupWait,
  getTravelTimeInMinutes,
  isWaitingForConfirmation,
  isWaitingToBeAssignedToDriver,
  isParentTask,
  isMultidrop
}
