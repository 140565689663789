import { isEqual, isObject, transform } from 'lodash'

const NOOP = () => {}

const difference = (object, base) => {
  return transform(object, (result, value, key) => {
    if (!isEqual(value, base[key])) {
      result[key] =
        isObject(value) && isObject(base[key])
          ? difference(value, base[key])
          : value
    }
  })
}

export { NOOP, difference }
