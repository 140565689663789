import classnames from 'classnames'
import React from 'react'

import { formatTime } from '../../../map/tasks/Task/statusUtils'

import './IconInTaskList.scss'
import { Props } from './types'

const IconInTaskList: React.FC<Props> = ({
  hasUnreadMessages,
  lastMessageTimestamp
}) => (
  <div className={classnames('iconInTaskList', { hasUnreadMessages })}>
    <i className='fa fa-comment-alt' />
    {lastMessageTimestamp && (
      <span className='time'>
        ({formatTime(lastMessageTimestamp.toDate())})
      </span>
    )}
  </div>
)

export default IconInTaskList
