// import WriteRequestReducer from './WriteRequestReducer'
import { Map as ImmutableMap } from 'immutable'

import {
  REQUEST_SENT,
  RESPONSE,
  RESPONSE_BATCH
} from '../../actions/services/ServiceActions'

import ReadRequestReducer from './ReadRequestReducer'
import RethinkReducer from './RethinkReducer'

const initialState = RethinkReducer(undefined, {}).merge(
  ImmutableMap({
    find: ImmutableMap(),
    findById: ImmutableMap()
  })
)

export default function ArchivedTasksReducer(state = initialState, action) {
  const { type, method, params } = action

  if (type === RESPONSE_BATCH) {
    return action.batch.reduce(ArchivedTasksReducer, state)
  }

  if (type !== REQUEST_SENT && type !== RESPONSE) {
    return state
  }

  switch (method) {
    case 'find':
      return state.setIn(
        ['find', params],
        ReadRequestReducer(state.getIn(['find', params]), action)
      )

    case 'findById':
      return state.setIn(
        ['findById', params],
        ReadRequestReducer(state.getIn(['findById', params]), action).set(
          'useCache',
          false
        )
      )

    default:
      return state.merge(RethinkReducer(state, action))
  }
}
