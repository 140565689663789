import classnames from 'classnames'
import { Map as ImmutableMap } from 'immutable'
import React from 'react'
import {
  generatePath,
  Link,
  RouteComponentProps,
  withRouter
} from 'react-router-dom'

import { QUOTE_STATUS, TASK_STATUS, VIEW_TYPE } from '../../../../constants'
import { Routes } from '../../../../navigation/Routes'
import { isInternalDriver } from '../../../../util/task'
import { isTaskPending } from '../../../../util/taskState'
import { getVehicleClassName } from '../VehicleIcon/VehicleIcon'

interface Props extends RouteComponentProps<{ viewType: VIEW_TYPE }> {
  task: ImmutableMap<string, any>
}

const _15MinsInTimestamp = 15 * 60 * 1000

export function getTaskIconContainerClassName(task) {
  if (task.get('onDemand') !== true) {
    return 'editIcon'
  }

  const classNames = ['onDemandIcon']

  switch (task.getIn(['quotes', 'status'])) {
    case undefined:
    case QUOTE_STATUS.CANCELLED: {
      classNames.push('notQuoted')

      break
    }

    case QUOTE_STATUS.QUOTED: {
      if (
        new Date(task.getIn(['quotes', 'timestamp'])).valueOf() +
          _15MinsInTimestamp >
        Date.now()
      ) {
        classNames.push('quoted')

        if (task.getIn(['quotes', 'allDone'])) {
          classNames.push('quoted-complete')
        }
      } else {
        classNames.push('expired')
      }

      break
    }
    case QUOTE_STATUS.APPROVED: {
      if (task.get('currentStatus') === TASK_STATUS.TASK_RECEIVED) {
        if (isInternalDriver(task)) {
          classNames.push('jobAccepted')
        } else {
          classNames.push('approved')
        }
      } else {
        classNames.push('jobAccepted')
      }
      break
    }
  }

  return classnames(classNames)
}

export function getTaskIconClassName(task) {
  if (task.get('isWalked')) {
    return 'fa fa-walking'
  }

  const vehicleType = task.getIn(['quotes', 'chosenQuote', 'vehicleType'])
  if (vehicleType) {
    return getVehicleClassName(vehicleType)
  }

  const driverName = task.getIn(['driver', 'name'])

  if (driverName && driverName.match(/^SUPPER/i)) {
    return getVehicleClassName('motorbike')
  }

  if (isTaskPending(task)) {
    return 'fa fa-chevron-right pending'
  }

  return 'fa fa-chevron-right'
}

class TaskIcon extends React.PureComponent<Props> {
  render() {
    const { task, match } = this.props

    const className = getTaskIconContainerClassName(task)
    const iconClassName = getTaskIconClassName(task)
    const taskId = task.get('id')
    return (
      <Link
        to={generatePath(Routes.Map.TaskDetail, {
          taskId,
          viewType: match.params.viewType
        })}
        className='taskIconContainer'
      >
        <span className={className}>
          <i className={iconClassName} />
        </span>
      </Link>
    )
  }
}

export default withRouter(TaskIcon)
