import { is, List, Map as ImmutableMap } from 'immutable'
import moment from 'moment'
import React, { PureComponent } from 'react'
import { Table } from 'react-bootstrap'
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import QuotesUiActions from '../../../../../actions/QuotesUiActions'
import TaskActions from '../../../../../actions/services/TaskActions'
import Interval from '../../../../common/Interval/Interval'
import Spinner from '../../../../common/Spinner/Spinner'
import QuoteCourierLabel from '../QuoteCourierLabel/QuoteCourierLabel'

import './QuotesTable.scss'
import QuotesTableSelector from './QuotesTable.selector'

interface StarRatingProps {
  rating: number
}

function StarRating({ rating }: StarRatingProps) {
  if (rating == null) {
    return null
  }

  const wholeRating = Math.round(rating)
  const stars = []

  for (let i = 0; i < wholeRating; i++) {
    stars[i] = <i key={i} className='fa fa-star active' />
  }
  for (let i = wholeRating; i < 5; i++) {
    stars[i] = <i key={i} className='fa fa-star inactive' />
  }
  stars[stars.length] = (
    <span key={stars.length} className='displayRating'>
      <FormattedMessage
        id='map.tasks.quote.rating'
        values={{
          rating: (
            <FormattedNumber
              value={rating}
              minimumFractionDigits={1}
              maximumFractionDigits={1}
            />
          )
        }}
      />
    </span>
  )
  return <span className='StarRating'>{stars}</span>
}

interface QuoteRowProps {
  formatMessage: (message) => string
  quote: ImmutableMap<string, any>
  showPickupTime: boolean
  onClick: () => void
  selected: boolean
  cheapest: boolean
  quickest: boolean
  noOfBookings
}

function QuoteRow({
  formatMessage,
  quote,
  showPickupTime,
  onClick,
  selected,
  cheapest,
  quickest,
  noOfBookings
}: QuoteRowProps) {
  const error = quote.get('error')
  const canSelect = !error
  const className =
    'QuoteRow' +
    (error ? ' text-muted' : '') +
    (canSelect ? ' canSelect' : '') +
    (selected ? ' selected' : '')
  // HACK: remove fake ratings once we have real ratings
  const fakeRatings = {
    crawfords: 4.9,
    addison_lee: 4.2,
    stuart: 4.7,
    deliverymates: 4.7,
    greencourier: 4.8,
    pedals: 4.8
  }

  function getRating(vendor) {
    return fakeRatings[vendor.toLowerCase().replace(/_/g, '')] || 4.6
  }

  // work around https://github.com/facebook/react/issues/7424
  let timeToFullfill: number | string = Math.round(
    parseFloat(quote.get('timeToFullfill'))
  )
  if (isNaN(timeToFullfill)) {
    timeToFullfill = 'unknown'
  }

  return (
    <tr className={className} onClick={canSelect ? onClick : null}>
      <td>
        <QuoteCourierLabel quote={quote} />
      </td>
      <td>
        <FormattedMessage
          id={`quote.vehicleType.${quote.get('vehicleType')}`}
        />
      </td>
      <td>{typeof noOfBookings === 'number' && noOfBookings}</td>
      {quote.get('loading') ? (
        <>
          <td colSpan={showPickupTime ? 3 : 2} className='text-center'>
            <Interval
              time={3000}
              render={() =>
                quote.get('canAcceptAfterTimeout') && loadingTimeout(quote) ? (
                  'N/A'
                ) : (
                  <Spinner />
                )
              }
            />
          </td>
          <td key='vendorRating'>
            <StarRating rating={getRating(quote.get('vendor', ''))} />
          </td>
        </>
      ) : error ? (
        <td
          colSpan={showPickupTime ? 5 : 4}
          title={JSON.stringify(error)}
          className='selectable'
        >
          {quote.get('errorMessage') || quote.get('error') || (
            // todo: never come here because quote.get('error') never nil
            <FormattedMessage id='map.tasks.quote.error' />
          )}
        </td>
      ) : (
        <>
          {showPickupTime ? (
            <td key='estimatedPickup'>
              {moment(quote.get('estimatedPickup')).format('L LT')}
            </td>
          ) : null}
          <td key='timeToFullfill' className={quickest ? 'highlight' : ''}>
            <strong>
              {timeToFullfill +
                formatMessage({ id: 'map.tasks.quote.minutes' })}
            </strong>
          </td>
          <td key='price' className={cheapest ? 'highlight' : ''}>
            {!quote.get('price') ? (
              'N/A'
            ) : (
              <>
                <strong>
                  <FormattedNumber
                    style='currency'
                    currency='GBP'
                    value={quote.get('price')}
                  />{' '}
                </strong>
                <FormattedMessage id='map.tasks.quote.vat' />
              </>
            )}
          </td>

          <td key='vendorRating'>
            <StarRating rating={getRating(quote.get('vendor'))} />
          </td>
        </>
      )}
    </tr>
  )
}

interface Props {
  // injected by parent component
  taskId: string
  showPickupTime?: boolean
  disabled?: boolean
  className?: string
  // redux selector
  quotes?: List<any>
  chosenQuote?: ImmutableMap<any, any>
  cheapest?: number
  quickest?: number
  vendorTotalsByReference: ImmutableMap<any, any>
  // redux actions
  QuotesUiActions: typeof QuotesUiActions
  TaskActions: typeof TaskActions
  // injected by react-intl
  intl: any
}

class QuotesTable extends PureComponent<Props> {
  static defaultProps = {
    showPickupTime: true
  }

  componentDidMount(): void {
    this.props.TaskActions.vendorTotalsByReferenceTask({
      taskId: this.props.taskId
    })
  }

  toggleQuote = (chosenQuote) => () => {
    this.props.QuotesUiActions.toggleQuote(this.props.taskId, chosenQuote)
  }

  getNoOfBookings = (quote) => {
    const { vendorTotalsByReference } = this.props
    if (vendorTotalsByReference && quote.has('vendor')) {
      const foundItem = vendorTotalsByReference.valueSeq().find((item) => {
        return (
          quote.get('vendor') === item.get('vendor') &&
          quote.get('vehicleType') === item.get('vehicleType') &&
          quote.get('isDirect') === item.get('isDirect')
        )
      })
      return foundItem && foundItem.get('count')
    }
  }

  render() {
    if (this.props.quotes == null) {
      return null
    }
    const className =
      'QuotesTable' +
      (this.props.disabled ? ' disabled' : '') +
      (this.props.className ? ' ' + this.props.className : '')

    return (
      <Table responsive className={className}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id='quote.vendor' />
            </th>
            <th>
              <FormattedMessage id='quote.vehicleType' />
            </th>
            <th>
              <FormattedMessage id='quote.noOfBookings' />
            </th>
            {this.props.showPickupTime && (
              <th>
                <FormattedMessage id='quote.estimatedPickup' />
              </th>
            )}
            <th className='timeToFullfillColumn'>
              <FormattedMessage id='quote.timeToFullfill' />
            </th>
            <th className='priceColumn'>
              <FormattedMessage id='quote.price' />
            </th>
            <th>
              <FormattedMessage id='map.tasks.quote.vendorRating' />
            </th>
          </tr>
        </thead>
        <tbody>
          {this.props.quotes
            .map((quote, index) => {
              const originalIndex = quote.get('_originalIndex')
              return (
                <QuoteRow
                  key={originalIndex}
                  formatMessage={this.props.intl.formatMessage}
                  quote={quote}
                  showPickupTime={this.props.showPickupTime}
                  onClick={this.toggleQuote(quote)}
                  selected={is(this.props.chosenQuote, quote)}
                  cheapest={this.props.cheapest === index}
                  quickest={this.props.quickest === index}
                  noOfBookings={this.getNoOfBookings(quote)}
                />
              )
            })
            .toArray()}
        </tbody>
      </Table>
    )
  }
}

function loadingTimeout(quote) {
  return Date.now() - new Date(quote.get('created')).getTime() > 20000
}

const mapDispatchToProps = (dispatch) => ({
  QuotesUiActions: bindActionCreators(QuotesUiActions, dispatch),
  TaskActions: bindActionCreators(TaskActions, dispatch)
})

export { loadingTimeout, mapDispatchToProps, StarRating, QuoteRow }
export default connect(
  QuotesTableSelector,
  mapDispatchToProps
)(injectIntl(QuotesTable))
