import React, { useState } from 'react'

import './Collapse.scss'

interface CollapseProps {
  elements: string[]
}

const Collapse: React.FC<CollapseProps> = ({ elements }) => {
  const [opened, setOpened] = useState(false)
  const onCLick = (e: React.MouseEvent) => {
    setOpened(!opened)
  }
  return (
    <div className='collapseTable'>
      <input
        id='collapsible'
        className={
          !opened ? 'toggle checkboxCollapse' : 'toggle-opened checkboxCollapse'
        }
        type='checkbox'
      />
      <label
        htmlFor='collapsible'
        className={!opened ? 'lbl-toggle' : 'lbl-toggle lbl-toggle-opened'}
        onClick={onCLick}
      >
        {elements[0] + (elements.length > 1 ? ', ' : '')}
      </label>
      <div
        className={
          !opened ? 'collapsible-content' : 'collapsible-content-opened'
        }
      >
        <div className='content-inner'>
          <p>{elements.join(', ')}</p>
        </div>
      </div>
    </div>
  )
}

export default Collapse
