import { fromJS, Map as ImmutableMap } from 'immutable'

import {
  REQUEST_SENT,
  RESPONSE,
  RESPONSE_BATCH
} from '../../actions/services/ServiceActions'
import log from '../../util/logger'

const initialState = fromJS({
  sent: false,
  initializing: false,
  ready: false,
  data: ImmutableMap(),
  lastError: null,
  useCache: true,
  resendOnReconnect: false
})

export default function ReadRequestReducer(
  state = initialState,
  action: any = {}
) {
  /* eslint no-unreachable: 0 */
  const { responseType, responseData, skipSetKeyPathWithId = false } = action
  switch (action.type) {
    case REQUEST_SENT:
      return state.merge({
        sent: true,
        initializing: false,
        ready: false,
        data: ImmutableMap()
      })

    case RESPONSE_BATCH:
      return action.batch.reduce(ReadRequestReducer, state)

    case RESPONSE:
      switch (responseType) {
        case 'state':
          switch (responseData) {
            case 'initializing':
              return state.merge({
                initializing: true,
                ready: false,
                lastError: null,
                data: ImmutableMap()
              })
            case 'ready':
              return state.merge({
                initializing: false,
                ready: true,
                lastError: null
              })
            default:
              log('Received unexpected state in response: ', action)
              return state
          }

        case 'data':
          return state.withMutations((aState) => {
            if (Array.isArray(responseData)) {
              if (skipSetKeyPathWithId) {
                aState.setIn(['data'], fromJS(responseData).toMap())
              } else {
                for (const item of responseData) {
                  aState.setIn(['data', item.id], fromJS(item))
                }
              }
            } else {
              aState.setIn(['data', responseData.id], fromJS(responseData))
            }
            aState.set('lastError', null)
          })

        case 'dataDeleted':
          return state.withMutations((aState) => {
            aState.deleteIn(['data', responseData])
            aState.set('lastError', null)
          })

        case 'error':
          return state.set('lastError', responseData)

        default:
          log('Received unexpected response type: ', action)
          return state
      }

    default:
      return state
  }
}
