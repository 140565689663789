import {
  fromJS,
  Map as ImmutableMap,
  OrderedMap,
  OrderedSet,
  Seq
} from 'immutable'
import { createSelector } from 'reselect'

import {
  SORT_TYPE,
  TASK_SECTION,
  TASK_STATUS,
  VIEW_TYPE
} from '../../../../constants'
import chat from '../../../../services/chat/chat'
import { FilterMessages, SectionState, ViewState } from '../../../../types'
import { getDropoffDelay } from '../../../../util/task'
import { isTaskPending } from '../../../../util/taskState'
import viewStateUtils from '../../../../util/viewState'
import TaskAssignments from '../../../TaskAssignments/TaskAssignments'
import FilteredTasks from '../FilteredTasks/FilteredTasks'
import {
  onDemandTaskOrdering,
  unassignedTaskOrdering
} from '../Ordering/ordering'
import { getUser } from '../TaskSelector/TaskSelector'

const STATUSES_TO_HIDE_FOR_SENDERS = [
  TASK_STATUS.IN_TRANSIT,
  TASK_STATUS.ARRIVED_AT_DROPOFF,
  TASK_STATUS.SUCCESS
]

const STATUSES_TO_HIDE_FOR_SORT_BY_LATE = [
  TASK_STATUS.CANCELLED,
  TASK_STATUS.SUCCESS
]

const checkIfSortByLate = (task, sortType) => {
  switch (sortType) {
    case SORT_TYPE.LATE: {
      const currentStatus = task.get('currentStatus')

      return (
        getDropoffDelay(task) > 0 &&
        !STATUSES_TO_HIDE_FOR_SORT_BY_LATE.includes(currentStatus)
      )
    }
    default: {
      return true
    }
  }
}

const NO_DRIVER_TEXT = 'No driver'

const getMerchant = (task) => {
  const merchantId = task.get('merchantId')
  if (merchantId) {
    return merchantId.trim().toUpperCase()
  }
  return ''
}

const getFleetName = (task) => {
  const fleetName =
    task.getIn(['driver', 'companyName']) ||
    task.getIn(['quotes', 'chosenQuote', 'vendor'])

  if (fleetName) {
    return fleetName
      .replace(/[\s_]+/g, ' ')
      .toUpperCase()
      .trim()
  } else if (task.get('isWalked')) {
    return 'Walked'
  } else {
    return 'Unknown'
  }
}

const filterBy = (
  viewType: VIEW_TYPE,
  sortType: SORT_TYPE,
  { checkedPending, checkedOnGoing }: SectionState,
  viewState: ViewState,
  sectionId: TASK_SECTION
) => (task) => {
  switch (viewType) {
    case VIEW_TYPE.SENDERS: {
      const currentStatus = task.get('currentStatus')

      return !STATUSES_TO_HIDE_FOR_SENDERS.includes(currentStatus)
    }

    case VIEW_TYPE.RECIPIENTS: {
      return checkIfSortByLate(task, sortType)
    }

    case VIEW_TYPE.TASKS: {
      if (viewState.checkedSeparatePendingAndOngoing) {
        switch (sectionId) {
          case TASK_SECTION.PENDING:
            return isTaskPending(task) && checkIfSortByLate(task, sortType)

          default:
            return !isTaskPending(task) && checkIfSortByLate(task, sortType)
        }
      }

      if (checkedPending && checkedOnGoing) {
        return checkIfSortByLate(task, sortType)
      }

      const isPending = isTaskPending(task)

      if (checkedPending && isPending) {
        return checkIfSortByLate(task, sortType)
      }

      if (checkedOnGoing && !isPending) {
        return checkIfSortByLate(task, sortType)
      }

      return false
    }
  }
}

const getSortType = (
  state,
  props: {
    sortType: SORT_TYPE
    viewType: VIEW_TYPE
    sectionId: TASK_SECTION
    sectionState: SectionState
  }
) => {
  switch (props.viewType) {
    case VIEW_TYPE.SENDERS:
      return SORT_TYPE.MERCHANT

    case VIEW_TYPE.RECIPIENTS:
    case VIEW_TYPE.TASKS: {
      return props.sectionState.sortType
    }
  }
}

const getSectionState = (state, props: { sectionState: SectionState }) => {
  return props.sectionState
}

const getViewType = (state, props: { viewType: VIEW_TYPE }) => {
  return props.viewType
}

const getViewState = (state, props: { viewType: VIEW_TYPE }) => {
  return viewStateUtils.getViewState(props.viewType, state)
}

const sortBy = (
  viewType: VIEW_TYPE,
  { checkedOnGoing, checkedPending }: SectionState,
  viewState: ViewState,
  sectionId: TASK_SECTION
) => (taskA, taskB) => {
  if (viewType === VIEW_TYPE.TASKS) {
    if (viewState.checkedSeparatePendingAndOngoing) {
      if (sectionId === TASK_SECTION.PENDING) {
        return unassignedTaskOrdering(taskA, taskB)
      } else if (sectionId === TASK_SECTION.ON_GOING) {
        return onDemandTaskOrdering(taskA, taskB)
      }
    } else if (checkedPending && !checkedOnGoing) {
      return unassignedTaskOrdering(taskA, taskB)
    }
    return onDemandTaskOrdering(taskA, taskB)
  } else {
    return onDemandTaskOrdering(taskA, taskB)
  }
}

const getAssignments = (state, props) => TaskAssignments(state, props)

const getDrivers = (state) =>
  state.services.getIn(['drivers', 'all', fromJS({}), 'data'])

const groupByDriverInsideGroup = (tasks, grouped, drivers, assignments) => {
  return grouped.map((taskIds) => {
    return taskIds.reduce((acc, taskId) => {
      const foundTask = tasks.find((task) => task.get('id') === taskId)
      if (!foundTask) {
        return acc
      }

      const driverName = getDriverName(foundTask)

      const taskIdsByDriver = acc.get(driverName, OrderedSet([])).add(taskId)

      return acc.set(driverName, taskIdsByDriver)
    }, OrderedMap({}))
  })
}

// sort by drivers needs to keep the order of assigned tasks
const sortTasksForDriver = (taskIds, drivers, assignments) => {
  if (taskIds.size > 0) {
    const driver =
      drivers && assignments && drivers.get(assignments.get(taskIds.first()))

    const assignedTasks = driver && driver.get('assignedTasks')

    return assignedTasks
      ? assignedTasks.withMutations((listOfTasks) => {
          return listOfTasks.toOrderedSet().intersect(taskIds)
        })
      : taskIds
  }

  return taskIds
}

const getGroupedTaskIds = (
  filteredAndSortedTasks,
  sortType,
  drivers,
  assignments
) => {
  switch (sortType) {
    case SORT_TYPE.LATE:
    case SORT_TYPE.CLOCK:
      return null

    case SORT_TYPE.DRIVER: {
      return groupBy(filteredAndSortedTasks, sortType).map((taskIds) =>
        sortTasksForDriver(taskIds, drivers, assignments)
      )
    }

    case SORT_TYPE.MERCHANT: {
      const grouped = groupBy(filteredAndSortedTasks, sortType)

      return groupByDriverInsideGroup(
        filteredAndSortedTasks,
        grouped,
        drivers,
        assignments
      )
    }

    default:
      return groupBy(filteredAndSortedTasks, sortType)
  }
}

const getDriverName = (task) => task.getIn(['driver', 'name']) || NO_DRIVER_TEXT

const groupBy = (tasks, sortType: SORT_TYPE) => {
  return tasks.toList().reduce((acc, task) => {
    const groupName = getGroupName(task, sortType)
    const taskId = task.get('id')

    if (acc.has(groupName)) {
      return acc.set(groupName, acc.get(groupName).add(taskId))
    } else {
      return acc.set(groupName, OrderedSet([taskId]))
    }
  }, OrderedMap({}))
}

const getGroupName = (task, sortType) => {
  switch (sortType) {
    case SORT_TYPE.FLEET:
      return getFleetName(task)
    case SORT_TYPE.MERCHANT:
      return getMerchant(task)
    case SORT_TYPE.DRIVER:
      return getDriverName(task)
    default:
      return ''
  }
}

const getSectionId = (state, props) => props.sectionId

const getFilterMessages = (state) =>
  state.MapUiReducer.get('filterMessages').toJS()

const filterByMessages = ({ active, read, unread }: FilterMessages) => (
  task
) => {
  if (!active) {
    return true
  }

  const taskId = task.get('id')

  if (chat.isResolved(taskId)) {
    return false
  }

  if (unread && read) {
    return chat.hasUnreadMessages(taskId) || chat.isRead(taskId)
  }

  if (unread) {
    return chat.hasUnreadMessages(taskId)
  }

  if (read) {
    return chat.isRead(taskId)
  }

  return true
}

const removeChildTask = (task) => !task.get('parentTask')

const moveWalkedTasksToBottom = (tasks) => {
  const walkingTasks = tasks.filter((task) => task.get('isWalked'))
  const notWalkingTasks = tasks.filter((task) => !task.get('isWalked'))

  return notWalkingTasks.concat(walkingTasks)
}

const combiner = (
  user: ImmutableMap<string, any>,
  filterMessages: FilterMessages,
  tasks,
  sortType: SORT_TYPE,
  drivers,
  assignments,
  sectionState: SectionState,
  viewType: VIEW_TYPE,
  viewState: ViewState,
  sectionId: TASK_SECTION
) => {
  if (tasks == null) {
    return { taskIds: Seq() }
  }

  // [multidrop] remove children tasks
  const parentTasks = tasks.filter(removeChildTask)

  let filteredAndSortedTasks = parentTasks
    .filter(filterBy(viewType, sortType, sectionState, viewState, sectionId))
    .filter(filterByMessages(filterMessages))
    .sort(sortBy(viewType, sectionState, viewState, sectionId))

  if (sortType === SORT_TYPE.FLEET || sortType === SORT_TYPE.DRIVER) {
    filteredAndSortedTasks = moveWalkedTasksToBottom(filteredAndSortedTasks)
  }

  chat.setTaskIds(sectionId, parentTasks.keySeq().toJS())

  return {
    numberOfPendingTasks: parentTasks.filter(isTaskPending).size,
    numberOfOnGoingTasks: parentTasks.filter((task) => !isTaskPending(task))
      .size,
    taskIds: filteredAndSortedTasks.keySeq(),
    groupedTaskIds: getGroupedTaskIds(
      filteredAndSortedTasks,
      sortType,
      drivers,
      assignments
    )
  }
}

const TasksListByViewType = createSelector(
  getUser,
  getFilterMessages,
  FilteredTasks,
  getSortType,
  getDrivers,
  getAssignments,
  getSectionState,
  getViewType,
  getViewState,
  getSectionId,
  combiner
)

export { combiner, getSortType, moveWalkedTasksToBottom }
export default TasksListByViewType
