import React from 'react'
import {
  Button,
  Checkbox,
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Panel,
  Row
} from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

import { getFormControlPropsFromReduxForm } from '../../../../util/form'
import {
  PARCEL_SIZE_PRESETS,
  PARCEL_WEIGHT_PRESETS
} from '../../../common/parcelDimensionPresets'
import ParcelSizeModal from '../ParcelSizeModal/ParcelSizeModal'
import ParcelWeightModal from '../ParcelWeightModal/ParcelWeightModal'

interface Props {
  handleSubmit: () => Promise<void>
  resetForm: () => void
  fields: any
  submitting: boolean
  pristine: boolean
}
interface State {
  customSizeModal: boolean
  customWeightModal: boolean
}

class DefaultParcelSettings extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      customSizeModal: false,
      customWeightModal: false
    }
  }

  handleCheckboxChange(event) {
    this.props.fields[event.target.name].onChange(event)
    // We need to submit the form asynchronously for the checkbox change event
    // to propagatee through the redux store.
    setTimeout(this.props.handleSubmit, 50)
  }

  openCustomSizeModal() {
    this.setState({ customSizeModal: true })
  }
  closeCustomSizeModal() {
    this.setState({ customSizeModal: false })
    this.props.resetForm()
  }
  openCustomWeightModal() {
    this.setState({ customWeightModal: true })
  }
  closeCustomWeightModal() {
    this.setState({ customWeightModal: false })
    this.props.resetForm()
  }

  handleParcelSizePresetChange(event) {
    if (event.target.value !== 'custom') {
      const [
        parcelLength,
        parcelWidth,
        parcelHeight
      ] = event.target.value.split('x')
      this.props.fields.parcelLength.onChange(parseFloat(parcelLength))
      this.props.fields.parcelWidth.onChange(parseFloat(parcelWidth))
      this.props.fields.parcelHeight.onChange(parseFloat(parcelHeight))
      setTimeout(this.props.handleSubmit, 50)
    } else {
      this.openCustomSizeModal()
    }
  }

  handleParcelWeightPresetChange(event) {
    if (event.target.value !== 'custom') {
      this.props.fields.parcelWeight.onChange(parseFloat(event.target.value))
      setTimeout(this.props.handleSubmit, 50)
    } else {
      this.openCustomWeightModal()
    }
  }
  handleParcelWeightPresetBlur(event) {
    if (event.target.value !== 'custom') {
      this.props.fields.parcelWeight.onBlur(parseFloat(event.target.value))
    } else {
      this.openCustomWeightModal()
    }
  }

  handleCustomSizeModalSubmit() {
    this.props.handleSubmit().then(this.closeCustomSizeModal.bind(this))
  }

  handleCustomWeightModalSubmit() {
    this.props.handleSubmit().then(this.closeCustomWeightModal.bind(this))
  }

  private renderOption = (size) => (label) => {
    return <option value={size}>{label}</option>
  }

  private renderCustomOption = (label) => {
    return <option value='custom'>{label}</option>
  }

  render() {
    const {
      parcelLength,
      parcelWidth,
      parcelHeight,
      parcelWeight,
      parcelFragile,
      parcelDoNotRotate,
      parcelContainsLiquid,
      parcelContainsHotFood
    } = this.props.fields

    const parcelSizeValues = `${parcelLength.value}x${parcelWidth.value}x${parcelHeight.value}`
    const parcelSizePresetsValue =
      PARCEL_SIZE_PRESETS.indexOf(parcelSizeValues) !== -1
        ? parcelSizeValues
        : 'custom'

    const parcelWeightPresetsValue =
      PARCEL_WEIGHT_PRESETS.indexOf(parcelWeight.value) !== -1
        ? parcelWeight.value
        : 'custom'

    return (
      <Row className='DefaultParcelSettings'>
        <Col sm={12}>
          <h3>
            <FormattedMessage id='settings.merchant.parcelProperties.title' />
          </h3>
        </Col>

        {/* parcel dimensions settings */}
        <Col sm={6}>
          <Panel>
            <Panel.Heading>
              <h3>
                <FormattedMessage id='settings.merchant.parcelProperties.dimensions.title' />
              </h3>
            </Panel.Heading>

            <Panel.Body>
              <FormGroup controlId='parcelSizePreset' className='spaceBelow'>
                <ControlLabel>
                  <FormattedMessage id='task.parcelSize' />
                </ControlLabel>
                <FormControl
                  componentClass='select'
                  disabled={this.props.submitting}
                  onChange={this.handleParcelSizePresetChange.bind(this)}
                  value={parcelSizePresetsValue}
                >
                  {PARCEL_SIZE_PRESETS.map((size, i) => (
                    <FormattedMessage id={`task.size.${size}`} key={i}>
                      {this.renderOption(size)}
                    </FormattedMessage>
                  ))}
                  <FormattedMessage id='task.size.custom'>
                    {this.renderCustomOption}
                  </FormattedMessage>
                </FormControl>
                <Button
                  onClick={this.openCustomSizeModal.bind(this)}
                  disabled={this.props.submitting}
                >
                  <FormattedMessage id='settings.merchant.customiseParcelSize' />
                </Button>
              </FormGroup>

              <FormGroup controlId='parcelWeight' className='spaceBelow'>
                <ControlLabel>
                  <FormattedMessage id='task.parcelWeight' />
                </ControlLabel>
                <FormControl
                  componentClass='select'
                  disabled={this.props.submitting}
                  {...getFormControlPropsFromReduxForm(parcelWeight)}
                  onChange={this.handleParcelWeightPresetChange.bind(this)}
                  onBlur={this.handleParcelWeightPresetBlur.bind(this)}
                  value={parcelWeightPresetsValue}
                >
                  {PARCEL_WEIGHT_PRESETS.map((weight, i) => (
                    <FormattedMessage id={`task.weight.${weight}`} key={i}>
                      {this.renderOption(weight)}
                    </FormattedMessage>
                  ))}
                  <FormattedMessage id={'task.weight.custom'}>
                    {this.renderCustomOption}
                  </FormattedMessage>
                </FormControl>
                <Button
                  onClick={this.openCustomWeightModal.bind(this)}
                  disabled={this.props.submitting}
                >
                  <FormattedMessage id='settings.merchant.customiseParcelWeight' />
                </Button>
              </FormGroup>
            </Panel.Body>
          </Panel>
        </Col>

        {/* parcel handling settings */}
        <Col sm={6}>
          <Panel>
            <Panel.Heading>
              <h3>
                <FormattedMessage id='settings.merchant.parcelProperties.handling.title' />
              </h3>
            </Panel.Heading>

            <Panel.Body>
              <FormGroup controlId='parcelFragile'>
                <Checkbox
                  disabled={this.props.submitting}
                  {...getFormControlPropsFromReduxForm(parcelFragile)}
                  onChange={this.handleCheckboxChange.bind(this)}
                >
                  <FormattedMessage id='task.parcelFragile' />
                </Checkbox>
              </FormGroup>

              <FormGroup controlId='parcelDoNotRotate'>
                <Checkbox
                  disabled={this.props.submitting}
                  {...getFormControlPropsFromReduxForm(parcelDoNotRotate)}
                  onChange={this.handleCheckboxChange.bind(this)}
                >
                  <FormattedMessage id='task.parcelDoNotRotate' />
                </Checkbox>
              </FormGroup>

              <FormGroup controlId='parcelContainsLiquid'>
                <Checkbox
                  disabled={this.props.submitting}
                  {...getFormControlPropsFromReduxForm(parcelContainsLiquid)}
                  onChange={this.handleCheckboxChange.bind(this)}
                >
                  <FormattedMessage id='task.parcelContainsLiquid' />
                </Checkbox>
              </FormGroup>

              <FormGroup controlId='parcelContainsHotFood'>
                <Checkbox
                  disabled={this.props.submitting}
                  {...getFormControlPropsFromReduxForm(parcelContainsHotFood)}
                  onChange={this.handleCheckboxChange.bind(this)}
                >
                  <FormattedMessage id='task.parcelContainsHotFood' />
                </Checkbox>
              </FormGroup>
            </Panel.Body>
          </Panel>
        </Col>

        {this.state.customSizeModal && (
          <ParcelSizeModal
            {...this.props}
            onHide={this.closeCustomSizeModal.bind(this)}
            handleSubmit={this.handleCustomSizeModalSubmit.bind(this)}
          />
        )}

        {this.state.customWeightModal && (
          <ParcelWeightModal
            {...this.props}
            onHide={this.closeCustomWeightModal.bind(this)}
            handleSubmit={this.handleCustomWeightModalSubmit.bind(this)}
          />
        )}
      </Row>
    )
  }
}

export default DefaultParcelSettings
