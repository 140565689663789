import { fromJS, Map as ImmutableMap } from 'immutable'
import moment from 'moment'
import { createSelector } from 'reselect'

import { SCHEDULE } from '../../../../constants'

function getTasks(state) {
  return state.services.getIn(['tasks', 'all', fromJS({}), 'data'])
}

function getDateFilter(state) {
  return state.MapUiReducer.get('taskFilterDay')
}

const combiner = (tasks, dateFilter) => {
  // just a simple constant for now, can be made into reducer state later
  const showTasksWithoutDates = true

  if (tasks == null) {
    return ImmutableMap()
  }
  if (dateFilter == null) {
    return tasks
  }

  return tasks.filter((task) => {
    const taskSchedule = task.get('schedule')
    const schedulesToDisplayForToday = [SCHEDULE.URGENT, SCHEDULE.ASAP]

    if (
      schedulesToDisplayForToday.includes(taskSchedule) &&
      moment(dateFilter).isSame(moment(), 'day')
    ) {
      return showTasksWithoutDates
    }

    return moment(task.get('deliveryDateTo')).isSame(dateFilter, 'day')
  })
}

export { getTasks, getDateFilter, combiner }
export default createSelector(getTasks, getDateFilter, combiner)
