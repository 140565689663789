import { fromJS } from 'immutable'

import { CHANGE_LOCALE } from '../actions/LocaleActions'

const initialState = fromJS({
  locale: 'en-GB'
})

export default function(state = initialState, action) {
  switch (action.type) {
    case CHANGE_LOCALE:
      return state.set('locale', action.locale)
    default:
      return state
  }
}
