export const PARCEL_SIZE_PRESETS = [
  '25x17x0.5',
  '35x25x2.5',
  '25x20x7',
  '30x25x15',
  '40x30x30',
  '60x50x50',
  '133x133x133',
  '90x20x20'
]

export const PARCEL_WEIGHT_PRESETS = [
  0.1,
  0.75,
  1.5,
  3,
  5,
  12,
  25,
  75,
  100,
  150
]
