import { createSelector } from 'reselect'

function getSockets(state) {
  return state.SocketNamespacesReducer
}

const combiner = (sockets) => {
  const errors = sockets.reduce((accErrors, connection) => {
    return accErrors + (connection.get('lastError') == null ? 0 : 1)
  }, 0)

  return { errors }
}

export { getSockets, combiner }
export default createSelector(getSockets, combiner)
