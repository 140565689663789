import { is } from 'immutable'
import React from 'react'

import { TASK_STATUS } from '../../../../../constants'
import {
  getDropoffDelay,
  getDropoffWait,
  getPickupWait
} from '../../../../../util/task'

import { Props } from './types'

class TaskFlags extends React.Component<Props, {}> {
  interval = null

  shouldComponentUpdate(
    nextProps: Readonly<Props>,
    nextState: Readonly<{}>,
    nextContext: any
  ): boolean {
    return !is(this.props.task, nextProps.task)
  }

  componentDidMount() {
    this.interval = setInterval(this.forceUpdate.bind(this), 60000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  private getParcelValue = (task) => {
    const parcelValue = parseFloat(task.get('parcelValue'))
    if (isNaN(parcelValue) || typeof parcelValue !== 'number') {
      return ''
    }

    return `£${Math.ceil(parcelValue)}`
  }

  private getWaitingTime = (task) => {
    switch (task.get('currentStatus')) {
      case TASK_STATUS.ARRIVED_AT_PICKUP:
        return getPickupWait(task)

      case TASK_STATUS.ARRIVED_AT_DROPOFF:
        return getDropoffWait(task)

      default:
        return null
    }
  }

  render() {
    const { task } = this.props
    const waitingTime = this.getWaitingTime(task)
    const dropoffDelay = getDropoffDelay(task)
    const parcelValue = this.getParcelValue(task)
    const discardedStatuses = [
      TASK_STATUS.CANCELLED,
      TASK_STATUS.SUCCESS,
      TASK_STATUS.FAILED,
      TASK_STATUS.TASK_RECEIVED
    ]
    const isInTheDiscardedStatuses = discardedStatuses.includes(
      task.get('currentStatus')
    )

    const isDelayed = dropoffDelay && !isInTheDiscardedStatuses

    return (
      <>
        {waitingTime && (
          <span className='flag waitingTime'>
            <i className='fa fa-hourglass-start spaceAfter' />
            <span className='label'>Waiting time:&nbsp;</span>
            {waitingTime}
            <span className='postfix'>&nbsp;min</span>
          </span>
        )}
        {isDelayed && (
          <span className='flag delayTime'>
            <i className='fa fa-clock spaceAfter' />
            <span className='label'>Delay time:&nbsp;</span>
            {dropoffDelay}
            <span className='postfix'>&nbsp;min</span>
          </span>
        )}
        {parcelValue && (
          <span id='parcel-value'>
            <i className='fa fa-box-open fa-sm spaceAfter' />
            <span className='label'>Parcel value:&nbsp;</span>
            {parcelValue}
          </span>
        )}
      </>
    )
  }
}

export default TaskFlags
