import React from 'react'
import {
  Alert,
  Button,
  ControlLabel,
  FormControl,
  FormGroup,
  Modal
} from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { generatePath, RouteComponentProps, withRouter } from 'react-router-dom'

import { VIEW_TYPE } from '../../../../constants'
import { Routes } from '../../../../navigation/Routes'
import Spinner from '../../../common/Spinner/Spinner'

interface Props extends RouteComponentProps<{ viewType: VIEW_TYPE }> {
  onSubmit: React.FormEventHandler<any>
  fileSelected: any
  fields: any
  pristine: boolean
  submitting: boolean
  error: any
}

class UploadForm extends React.PureComponent<Props> {
  handleModalHide() {
    this.props.history.push(
      generatePath(Routes.Map.Tasks, {
        viewType: this.props.match.params.viewType
      })
    )
  }

  render() {
    const { file } = this.props.fields

    return (
      <Modal
        animation={false}
        className='UploadDialog'
        show
        onHide={this.handleModalHide.bind(this)}
      >
        <form onSubmit={this.props.onSubmit}>
          <Modal.Header>
            <Modal.Title>
              <i className='fa fa-upload' />{' '}
              <FormattedMessage id='map.tasks.upload.title' />
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>
              <FormattedMessage id='map.tasks.upload.description' />
            </p>

            <FormGroup controlId='file'>
              <ControlLabel
                className={
                  this.props.fileSelected
                    ? 'btn btn-default'
                    : 'btn btn-brand-secondary'
                }
              >
                <FormattedMessage id='map.tasks.upload.file' />
              </ControlLabel>
              <FormControl
                type='file'
                accept='text/csv'
                {...file}
                className='file-upload'
              />
              <span>
                {this.props.fileSelected != null
                  ? this.props.fileSelected.name
                  : null}
              </span>
            </FormGroup>
          </Modal.Body>

          <Modal.Footer>
            {this.props.error == null ? null : (
              <Alert
                bsStyle='danger'
                title={JSON.stringify(this.props.error, null, 2)}
                className='pull-left'
              >
                <FormattedMessage id='map.tasks.upload.error' />
                <pre>{this.props.error}</pre>
              </Alert>
            )}

            <Button
              onClick={this.handleModalHide.bind(this)}
              disabled={this.props.submitting}
            >
              <i className='fa fa-times-circle spaceAfter' />
              <FormattedMessage id='map.tasks.modal.cancel' />
            </Button>
            <Button
              type='submit'
              className={
                this.props.fileSelected
                  ? 'btn-brand-secondary'
                  : 'btn btn-default'
              }
              disabled={
                this.props.pristine ||
                this.props.submitting ||
                this.props.fileSelected == null
              }
            >
              {this.props.submitting ? (
                <Spinner showText={false} className='spaceAfter' />
              ) : (
                <i className='fa fa-check spaceAfter' />
              )}

              <FormattedMessage id='map.tasks.upload.upload' />
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default withRouter(UploadForm)
