const cancelTask = async (taskId: string) => {
  try {
    const response = await fetch(
      `${process.env.TRACKING_SERVER}/tasks/${taskId}/cancel`,
      {
        method: 'PUT',
        headers: {
          authorization: `Bearer ${window.localStorage.getItem('token')}`,
          'content-type': 'application/json'
        }
      }
    )
    const message = await response.text()

    if (response.status >= 400) {
      throw new Error(response.status + ' ' + message)
    } else {
      return message
    }
  } catch (error) {
    // tslint:disable-next-line
    console.log(error)
    alert(`Failed to cancel task ${taskId}`)
  }
}

export { cancelTask }
