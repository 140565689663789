import React from 'react'

const renderRow = (text: string, height: number, paths: any[]) => {
  return (
    <React.Fragment key={text}>
      <td>
        {paths.map((image, index) => (
          <img key={`${text}_img_${index}`} src={image} height={height} />
        ))}
      </td>
      <td>{text}</td>
    </React.Fragment>
  )
}

export { renderRow }
