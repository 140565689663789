import classnames from 'classnames'
import { is, Map as ImmutableMap } from 'immutable'
import { flow, noop } from 'lodash'
import moment from 'moment'
import React from 'react'
import { DragSource, DropTarget } from 'react-dnd'
import { connect } from 'react-redux'
import VisibilitySensor from 'react-visibility-sensor'
import { bindActionCreators } from 'redux'

import MapUiActions from '../../../../actions/MapUiActions'
import DriverActions from '../../../../actions/services/DriverActions'
import {
  CHAT_ICON_TYPE,
  SORT_TYPE,
  TASK_SECTION,
  TASK_STATUS,
  VIEW_TYPE
} from '../../../../constants'
import TaskSelector from '../../../../selectors/map/tasks/TaskSelector/TaskSelector'
import { SectionState } from '../../../../types'
import gpsPointsSubscriptionManager from '../../../../util/gpsPointsSubscriptionManager'
import { isParentTask, isWaitingForConfirmation } from '../../../../util/task'
import { getIsMissingGpsTracking } from '../../../../util/taskState'
import ChatIcon from '../../../common/ChatIcon/ChatIcon'

import MockTask from './MockTask/MockTask'
import StatusAndTime from './StatusAndTime/StatusAndTime'
import './Task.scss'
import TaskFlags from './TaskFlags/TaskFlags'
import VehicleIcon from './VehicleIcon/VehicleIcon'
import WarningIcon from './WarningIcon/WarningIcon'
import { formatTime } from './statusUtils'

const DND_TASK = 'DND_TASK'

interface StateProps {
  isSelected: boolean
  user: ImmutableMap<string, any>
  task: ImmutableMap<string, any>
  assignedDriverName: string
  taskFilterDay: string
  sectionState: SectionState
}

interface DispatchProps {
  MapUiActions: typeof MapUiActions
  DriverActions: typeof DriverActions
}

interface Props {
  taskId: string
  driverId: string
  openDetail: (taskId: string) => void
  openChat: (taskId: string) => void
  // DnD injected
  connectDragSource: (task) => React.ReactNode
  connectDropTarget: (task) => React.ReactNode
  isOver: boolean
  viewType: VIEW_TYPE
  sectionId: TASK_SECTION
  isChildTask: boolean
}

interface State {
  lastGpsUpdateTimestamp: number
}

type EnhancedProps = Props & StateProps & DispatchProps

const VisibilitySensorOffset = { top: -800, bottom: -800 }

class TaskComponent extends React.Component<EnhancedProps, State> {
  private singleClick = false
  private doubleClick = false

  constructor(props) {
    super(props)
    this.state = {
      lastGpsUpdateTimestamp: gpsPointsSubscriptionManager.getLastValue(
        props.taskId
      )
    }
  }

  shouldComponentUpdate(
    nextProps: Readonly<EnhancedProps>,
    nextState: Readonly<State>,
    nextContext: any
  ): boolean {
    return (
      this.props.taskId !== nextProps.taskId ||
      !is(this.props.task, nextProps.task) ||
      !is(this.props.user, nextProps.user) ||
      this.props.viewType !== nextProps.viewType ||
      this.props.sectionId !== nextProps.sectionId ||
      this.props.isSelected !== nextProps.isSelected ||
      this.props.sectionState.sortType !== nextProps.sectionState.sortType ||
      this.props.assignedDriverName !== nextProps.assignedDriverName ||
      this.props.taskFilterDay !== nextProps.taskFilterDay ||
      this.props.isOver !== nextProps.isOver ||
      this.state.lastGpsUpdateTimestamp !== nextState.lastGpsUpdateTimestamp
    )
  }

  handleClick = (event) => {
    const { isChildTask = false } = this.props
    if (isChildTask) {
      return
    }

    event.stopPropagation()
    const multiSelect = event.ctrlKey || event.metaKey
    const rangeSelect = event.shiftKey

    if (this.singleClick) {
      this.doubleClick = true
    }
    this.singleClick = true

    if (!this.doubleClick) {
      this.props.MapUiActions.toggleTask(
        this.props.taskId,
        this.props.viewType,
        this.props.sectionId,
        multiSelect,
        rangeSelect
      )
    }

    setTimeout(this.handleDoubleClick, 300)
  }

  handleDoubleClick = () => {
    this.singleClick = false
    if (this.doubleClick) {
      this.doubleClick = false
      this.openDetail()
    }
  }

  openDetail = (event?: any) => {
    if (event) {
      event.stopPropagation()
    }

    const { openDetail = noop, taskId } = this.props

    openDetail(taskId)
  }

  onUpdateLastGpsUpdateTimestamp = (lastGpsUpdateTimestamp) => {
    if (this.state.lastGpsUpdateTimestamp === lastGpsUpdateTimestamp) {
      return
    }

    this.setState({ lastGpsUpdateTimestamp })
  }

  componentDidMount() {
    gpsPointsSubscriptionManager.subscribe(
      this.props.taskId,
      this.onUpdateLastGpsUpdateTimestamp
    )
  }

  componentWillUnmount(): void {
    gpsPointsSubscriptionManager.unsubscribe(
      this.props.taskId,
      this.onUpdateLastGpsUpdateTimestamp
    )
  }

  getClassNameAndTitle = () => {
    const { task, assignedDriverName, isSelected, user, isOver } = this.props
    const status = task.get('currentStatus')
    const courierReference = task.getIn(
      ['quotes', 'confirmation', 'vendorReference'],
      ''
    )
    const canSeeAlerts = user.get('administrator')
    const canSeeEta = user.get('canSeeEta') || user.get('administrator')
    const etaPickup =
      canSeeEta &&
      (status === TASK_STATUS.TASK_RECEIVED ||
        status === TASK_STATUS.DRIVER_ASSIGNED) &&
      task.getIn(['eta', 'pickup'])
    const etaDropoff = canSeeEta && task.getIn(['eta', 'dropoff'])

    const tooltip =
      `${task.get('externalRef')}\n\n` +
      `Status: ${status}\n` +
      `\nAssigned to: ${assignedDriverName}\n` +
      `  Courier Reference: ${courierReference}\n` +
      (etaPickup ? `\nPickup ETA: ${formatTime(etaPickup)}` : '') +
      (etaDropoff ? `\nDropoff ETA: ${formatTime(etaDropoff)}` : '') +
      `\nMerchant: ${task.get('merchantId')}\n` +
      `   Order number: ${task.get('externalRef') || 'unknown'}\n` +
      `   Deliver by: ${
        task.get('deliveryDateTo')
          ? moment(task.get('deliveryDateTo')).calendar(null, {
              sameElse: 'LLLL'
            })
          : 'unknown'
      }\n` +
      `\nTo:\n` +
      `   ${task.get('destinationName')}\n` +
      `   ${task.get('destinationAddress')}` +
      `   ${task.get('destinationCity')} ${task.get('destinationPostcode')}` +
      `   ${task.get('destinationCountry')}`

    const className = ['TaskContainer']
    if (task.getIn(['quotes', 'latePickup'])) {
      className.push('notification')
    }
    if (isOver) {
      className.push('dndOver')
    }
    if (isSelected) {
      className.push('selected')
    }
    if (this.getChildrenTasksInfo(task).existed) {
      className.push('MultiTaskBlock')
    }

    // display red-color when no gps tracking
    if (
      canSeeAlerts &&
      task.getIn(['quotes', 'confirmation']) &&
      getIsMissingGpsTracking({
        currentStatus: task.get('currentStatus'),
        lastGpsUpdateTimestamp: this.state.lastGpsUpdateTimestamp
      })
    ) {
      className.push('text-danger')
    }

    return {
      tooltip,
      className: classnames(className)
    }
  }

  private getChildrenTasksInfo(task) {
    const containedTasks = task.get('containedTasks')
    const existed =
      task.get('type') === 'multidrop' &&
      !!containedTasks &&
      containedTasks.size > 0

    return {
      existed,
      numberOfTasks: existed ? containedTasks.size : 0
    }
  }

  private openChat = () => {
    const { openChat = noop, taskId } = this.props

    openChat(taskId)
  }

  renderDom(task) {
    const { viewType, isChildTask, sectionId, taskFilterDay, user } = this.props
    const container = this.getClassNameAndTitle()
    const isDriverView = viewType === VIEW_TYPE.TASKS
    const { existed, numberOfTasks } = this.getChildrenTasksInfo(task)

    return (
      <li
        className={container.className}
        title={container.tooltip}
        onClick={this.handleClick}
        data-testid='task-list-item'
      >
        <div
          className={classnames('Task row-align-center', {
            taskForDriver: isDriverView,
            childTask: isChildTask
          })}
        >
          <div className='row-align-center'>
            <StatusAndTime
              task={task}
              viewType={viewType}
              taskFilterDay={taskFilterDay}
            />

            <span className='externalRef' data-testid='order-number'>
              {existed ? `x ${numberOfTasks}` : task.get('externalRef')}
            </span>
          </div>

          <div className='row-align-center right-icons'>
            <TaskFlags task={task} />
            <VehicleIcon
              task={task}
              isChildTask={isChildTask}
              isMultidrop={existed}
              openDetail={this.openDetail}
            />
            <WarningIcon task={task} user={user} />

            {isWaitingForConfirmation(task) && (
              <span className='waitingForConfirmation'>
                <span className='Spinner' title='Waiting for confirmation'>
                  <i className='fa fa-sync-alt fa-sm' />
                </span>
              </span>
            )}

            {isParentTask(task) && (
              <ChatIcon
                taskId={task.get('id')}
                iconType={CHAT_ICON_TYPE.ORDER_LIST}
                onPress={this.openChat}
              />
            )}
          </div>
        </div>

        {existed && (
          <ul className='containedTasks'>
            {task
              .get('containedTasks')
              .map((childTask) => {
                const taskId = childTask.get('id')

                return (
                  <Task
                    key={taskId}
                    taskId={taskId}
                    viewType={viewType}
                    sectionId={sectionId}
                    isChildTask={true}
                  />
                )
              })
              .toArray()}
          </ul>
        )}
      </li>
    )
  }

  renderMockDom = (task) => {
    const { isChildTask, viewType, sectionId } = this.props

    return (
      <MockTask
        viewType={viewType}
        isChildTask={isChildTask}
        externalRef={task.get('externalRef')}
        containedTasks={task.get('containedTasks')}
        sectionId={sectionId}
      />
    )
  }

  renderTask = ({ isVisible }) => {
    const { task } = this.props

    if (isVisible) {
      const { connectDragSource, connectDropTarget } = this.props

      return connectDragSource(connectDropTarget(this.renderDom(task)))
    }

    return this.renderMockDom(task)
  }

  render() {
    const { task } = this.props
    if (!task) {
      return null
    }

    return (
      <VisibilitySensor offset={VisibilitySensorOffset}>
        {this.renderTask}
      </VisibilitySensor>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  MapUiActions: bindActionCreators(MapUiActions, dispatch),
  DriverActions: bindActionCreators(DriverActions, dispatch)
})

const DragSourceSpec = {
  canDrag(props: EnhancedProps) {
    const hasDriverId = props.task.hasIn(['driver', 'id'])

    if (!props.isSelected) {
      props.MapUiActions.deselectAll()
      props.MapUiActions.selectTask(props.taskId)
    }

    return hasDriverId && props.sectionState.sortType === SORT_TYPE.DRIVER
  },
  beginDrag(props) {
    return {
      taskId: props.taskId,
      driverId: props.task.getIn(['driver', 'id'])
    }
  }
}

const DragSourceCollect = (dragSourceConnect) => {
  return {
    connectDragSource: dragSourceConnect.dragSource()
  }
}

const DropTargetSpec = {
  canDrop(props) {
    if (!props.task) {
      return false
    }

    const hasDriverId = props.task.hasIn(['driver', 'id'])

    return hasDriverId && props.sectionState.sortType === SORT_TYPE.DRIVER
  },
  drop(props, monitor) {
    const driverId = props.task.getIn(['driver', 'id'])

    if (driverId) {
      const item = monitor.getItem()

      if (item && item.driverId === driverId) {
        props.DriverActions.reassign(driverId, props.taskId)
      }
    }
  }
}

const DropTargetCollect = (dragTargetConnect, monitor) => {
  return {
    connectDropTarget: dragTargetConnect.dropTarget(),
    isOver: monitor.canDrop() && monitor.isOver()
  }
}

export { mapDispatchToProps }

const Task = flow(
  DragSource(DND_TASK, DragSourceSpec, DragSourceCollect),
  DropTarget(DND_TASK, DropTargetSpec, DropTargetCollect),
  connect<StateProps, DispatchProps, any, State>(
    TaskSelector,
    mapDispatchToProps
  )
)(TaskComponent)

export default Task
