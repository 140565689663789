import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import SocketConnectionsSelector from '../../../selectors/SocketConnectionsSelector/SocketConnectionsSelector'
import { NOOP } from '../../../util'

import './ConnectionError.scss'

interface Props {
  errors: number
}

class ConnectionError extends React.PureComponent<Props> {
  handleReloadClick = () => {
    window.location.reload()
  }

  render() {
    return (
      <Modal
        animation={false}
        className='ConnectionError'
        show={this.props.errors > 0}
        bsSize='sm'
        onHide={NOOP}
      >
        <Modal.Header>
          <Modal.Title>
            <i className='fa fa-exclamation-triangle spaceAfter' />
            <FormattedMessage id='connection.error.title' />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            <FormattedMessage id='connection.error.body' />
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={this.handleReloadClick}>
            <i className='fa fa-sync-alt spaceAfter Spinner' />
            <FormattedMessage id='connection.error.reload' />
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => SocketConnectionsSelector(state)

export { mapStateToProps }
export default connect(mapStateToProps)(ConnectionError)
