import EXIF from 'exif-js'

const MAX_WIDTH = 1000
const MAX_HEIGHT = 1000

const getScaledSize = (width, height) => {
  if (width > height) {
    if (width > MAX_WIDTH) {
      return [MAX_WIDTH, Math.round((height *= MAX_WIDTH / width))]
    }
  } else {
    if (height > MAX_HEIGHT) {
      return [Math.round((width *= MAX_HEIGHT / height)), MAX_HEIGHT]
    }
  }

  return [width, height]
}

const rotateImage = (img, width, height, exifData): HTMLCanvasElement => {
  const canvas = document.createElement('canvas')

  canvas.width = width
  canvas.height = height

  const context = canvas.getContext('2d')

  const orientation = exifData.Orientation
  if (orientation) {
    if (orientation > 4) {
      canvas.width = height
      canvas.height = width
    }

    switch (orientation) {
      case 2:
        context.translate(width, 0)
        context.scale(-1, 1)

        break

      case 3:
        context.translate(width, height)
        context.rotate(Math.PI)

        break

      case 4:
        context.translate(0, height)
        context.scale(1, -1)

        break

      case 5:
        context.rotate(0.5 * Math.PI)
        context.scale(1, -1)

        break

      case 6:
        context.rotate(0.5 * Math.PI)
        context.translate(0, -height)

        break

      case 7:
        context.rotate(0.5 * Math.PI)
        context.translate(width, -height)
        context.scale(-1, 1)

        break

      case 8:
        context.rotate(-0.5 * Math.PI)
        context.translate(-width, 0)

        break

      default:
        break
    }
  }

  context.drawImage(img, 0, 0, width, height)

  return canvas
}

const resizeImage = (img, exifData: { Orientation?: number } = {}) => {
  const [width, height] = getScaledSize(img.width, img.height)

  const canvas = rotateImage(img, width, height, exifData)

  return canvas.toDataURL('image/jpeg', 0.7)
}

const convertImageFileToBase64 = (imageFile: File): Promise<string> => {
  if (!/image/i.test(imageFile.type)) {
    return Promise.reject('File ' + imageFile.name + ' is not an image.')
  }

  return new Promise(async (resolve, reject) => {
    EXIF.getData(imageFile as any, function() {
      const fileReader = new FileReader()
      fileReader.onload = (event) => {
        const { result } = event.target
        const blob = new Blob([result]) // create blob...
        window.URL = window.URL || window.webkitURL
        const blobURL = window.URL.createObjectURL(blob) // and get it's URL

        const image = new Image()
        image.src = blobURL
        image.onload = () => {
          resolve(resizeImage(image, this.exifdata))
        }
        image.onerror = () => {
          reject('Can not load file')
        }
      }
      fileReader.readAsArrayBuffer(imageFile)
    })
  })
}

export { convertImageFileToBase64 }
