import { GOOGLE_MAP_STYLE } from '../../constants'

const KEY = 'MAP_STYLERS_TYPE'

const lightStylers = require('./light-stylers.json')
const nightStylers = require('./night-stylers.json')

const getStylers = () => {
  return getStyle() === GOOGLE_MAP_STYLE.DARK ? nightStylers : lightStylers
}
const getStyle = () => {
  return (
    (window.localStorage.getItem(KEY) as GOOGLE_MAP_STYLE) ||
    GOOGLE_MAP_STYLE.DARK
  )
}

const setStyle = (style: GOOGLE_MAP_STYLE) => {
  window.localStorage.setItem(KEY, style)
}

export default {
  setStyle,
  getStyle,
  getStylers
}
