import { fromJS } from 'immutable'
import { change, getValues } from 'redux-form'

// TODO: The following are horrible redux-form action dispatchers; update to
// redux-form 6 or newer and use the built-in action creators instead.

export function fillInDefaultParcelSize(merchantId) {
  return (dispatch, getState) => {
    const merchant = getState().services.getIn([
      'merchants',
      'all',
      fromJS({}),
      'data',
      merchantId
    ])

    dispatch({
      type: 'redux-form/CHANGE',
      field: 'parcelLength',
      value: merchant.get('parcelLength'),
      touch: false,
      form: 'task'
    })
    dispatch({
      type: 'redux-form/CHANGE',
      field: 'parcelWidth',
      value: merchant.get('parcelWidth'),
      touch: false,
      form: 'task'
    })
    dispatch({
      type: 'redux-form/CHANGE',
      field: 'parcelHeight',
      value: merchant.get('parcelHeight'),
      touch: false,
      form: 'task'
    })
  }
}

export function fillInDefaultParcelWeight(merchantId) {
  return (dispatch, getState) => {
    const merchant = getState().services.getIn([
      'merchants',
      'all',
      fromJS({}),
      'data',
      merchantId
    ])

    dispatch({
      type: 'redux-form/CHANGE',
      field: 'parcelWeight',
      value: merchant.get('parcelWeight'),
      touch: false,
      form: 'task'
    })
  }
}

export function fillInDefaultParcelFragile(merchantId) {
  return (dispatch, getState) => {
    const merchant = getState().services.getIn([
      'merchants',
      'all',
      fromJS({}),
      'data',
      merchantId
    ])

    dispatch({
      type: 'redux-form/CHANGE',
      field: 'parcelFragile',
      value: merchant.get('parcelFragile'),
      touch: false,
      form: 'task'
    })
  }
}

export function fillInDefaultParcelDoNotRotate(merchantId) {
  return (dispatch, getState) => {
    const merchant = getState().services.getIn([
      'merchants',
      'all',
      fromJS({}),
      'data',
      merchantId
    ])

    dispatch({
      type: 'redux-form/CHANGE',
      field: 'parcelDoNotRotate',
      value: merchant.get('parcelDoNotRotate'),
      touch: false,
      form: 'task'
    })
  }
}

export function fillInDefaultParcelContainsLiquid(merchantId) {
  return (dispatch, getState) => {
    const merchant = getState().services.getIn([
      'merchants',
      'all',
      fromJS({}),
      'data',
      merchantId
    ])

    dispatch({
      type: 'redux-form/CHANGE',
      field: 'parcelContainsLiquid',
      value: merchant.get('parcelContainsLiquid'),
      touch: false,
      form: 'task'
    })
  }
}

export function fillInDefaultParcelContainsHotFood(merchantId) {
  return (dispatch, getState) => {
    const merchant = getState().services.getIn([
      'merchants',
      'all',
      fromJS({}),
      'data',
      merchantId
    ])

    dispatch({
      type: 'redux-form/CHANGE',
      field: 'parcelContainsHotFood',
      value: merchant.get('parcelContainsHotFood'),
      touch: false,
      form: 'task'
    })
  }
}

export function selectAddressSuggestion(address, type) {
  return (dispatch) => {
    switch (type) {
      case 'source':
        dispatch({
          type: 'redux-form/CHANGE',
          field: 'sourceName',
          value: address.get('name'),
          touch: false,
          form: 'task'
        })
        dispatch({
          type: 'redux-form/CHANGE',
          field: 'sourceAddress',
          value: address.get('address'),
          touch: false,
          form: 'task'
        })
        dispatch({
          type: 'redux-form/CHANGE',
          field: 'sourceCity',
          value: address.get('city'),
          touch: false,
          form: 'task'
        })
        dispatch({
          type: 'redux-form/CHANGE',
          field: 'sourcePostcode',
          value: address.get('postcode'),
          touch: false,
          form: 'task'
        })
        dispatch({
          type: 'redux-form/CHANGE',
          field: 'sourceCountry',
          value: address.get('country'),
          touch: false,
          form: 'task'
        })
        dispatch({
          type: 'redux-form/CHANGE',
          field: 'sourcePhone',
          value: address.get('phone'),
          touch: false,
          form: 'task'
        })
        dispatch({
          type: 'redux-form/CHANGE',
          field: 'sourceEmail',
          value: address.get('email'),
          touch: false,
          form: 'task'
        })
        break
      case 'destination':
        dispatch({
          type: 'redux-form/CHANGE',
          field: 'destinationName',
          value: address.get('name'),
          touch: false,
          form: 'task'
        })
        dispatch({
          type: 'redux-form/CHANGE',
          field: 'destinationAddress',
          value: address.get('address'),
          touch: false,
          form: 'task'
        })
        dispatch({
          type: 'redux-form/CHANGE',
          field: 'destinationCity',
          value: address.get('city'),
          touch: false,
          form: 'task'
        })
        dispatch({
          type: 'redux-form/CHANGE',
          field: 'destinationPostcode',
          value: address.get('postcode'),
          touch: false,
          form: 'task'
        })
        dispatch({
          type: 'redux-form/CHANGE',
          field: 'destinationCountry',
          value: address.get('country'),
          touch: false,
          form: 'task'
        })
        dispatch({
          type: 'redux-form/CHANGE',
          field: 'destinationPhone',
          value: address.get('phone'),
          touch: false,
          form: 'task'
        })
        dispatch({
          type: 'redux-form/CHANGE',
          field: 'destinationEmail',
          value: address.get('email'),
          touch: false,
          form: 'task'
        })
        break
    }
  }
}

export function fillInLastSource(address) {
  return (dispatch) => {
    dispatch({
      type: 'redux-form/CHANGE',
      field: 'sourceName',
      value: address.name,
      touch: false,
      form: 'task'
    })
    dispatch({
      type: 'redux-form/CHANGE',
      field: 'sourceAddress',
      value: address.address,
      touch: false,
      form: 'task'
    })
    dispatch({
      type: 'redux-form/CHANGE',
      field: 'sourceCity',
      value: address.city,
      touch: false,
      form: 'task'
    })
    dispatch({
      type: 'redux-form/CHANGE',
      field: 'sourcePostcode',
      value: address.postcode,
      touch: false,
      form: 'task'
    })
    dispatch({
      type: 'redux-form/CHANGE',
      field: 'sourceCountry',
      value: address.country,
      touch: false,
      form: 'task'
    })
    dispatch({
      type: 'redux-form/CHANGE',
      field: 'sourcePhone',
      value: address.phone,
      touch: false,
      form: 'task'
    })
    dispatch({
      type: 'redux-form/CHANGE',
      field: 'sourceEmail',
      value: address.email,
      touch: false,
      form: 'task'
    })
  }
}

export function moveUpContainedTask(taskId) {
  return _moveTask(taskId, -1)
}

export function moveDownContainedTask(taskId) {
  return _moveTask(taskId, +1)
}

function _moveTask(taskId, direction) {
  return (dispatch, getState) => {
    const { containedTasks: tasks } = getValues(getState().form.task)

    const index = tasks.findIndex((t) => t.id === taskId)
    const indexOther = index + direction
    const chosenTask = tasks[index]
    const otherTask = tasks[indexOther]

    if (otherTask) {
      const newTasks = [...tasks]
      newTasks[index] = otherTask
      newTasks[indexOther] = chosenTask

      dispatch(change('task', 'containedTasks', newTasks))
    }
  }
}
