import React from 'react'

interface Props {
  time: number
  render?: () => any
  children?: () => any
}

class Interval extends React.PureComponent<Props> {
  _interval = null

  private runForceUpdate = () => {
    this.forceUpdate()
  }

  componentDidMount() {
    this._interval = setInterval(this.runForceUpdate, this.props.time || 1000)
  }

  componentWillUnmount() {
    clearInterval(this._interval)
  }

  render() {
    return (this.props.render || this.props.children)()
  }
}

export default Interval
