import { fromJS } from 'immutable'

import { CHANGE_SELECTED_MERCHANT_SETTINGS } from '../actions/SettingsUiActions'

const initialState = fromJS({
  selectedMerchant: null
})

export default function SettingsUiReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_SELECTED_MERCHANT_SETTINGS:
      return state.set('selectedMerchant', action.merchantId)

    default:
      return state
  }
}
