import classnames from 'classnames'
import { is, Map, Set } from 'immutable'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import MapUiActions from '../../../../actions/MapUiActions'
import { SORT_TYPE, TASK_SECTION, VIEW_TYPE } from '../../../../constants'
import { FilterMessages, SectionState, ViewState } from '../../../../types'
import sectionStateUtils from '../../../../util/sectionState'
import viewStateUtils from '../../../../util/viewState'
import TaskContextMenu from '../TaskContextMenu/TaskContextMenu'
import Tasks from '../Tasks/Tasks'

import './Driver.scss'
import DriverSelectorFactory from './Driver.selector'

interface Props {
  sectionId: TASK_SECTION
  tasks: {
    taskIds: Set<string>
    groupedTaskIds: {
      [key: string]: Set<string> | Map<string, Set<string>>
    }
    numberOfPendingTasks: number
    numberOfOnGoingTasks: number
  }
  MapUiActions: typeof MapUiActions
  sectionState: SectionState
  viewState: ViewState
  viewType: VIEW_TYPE
  filterMessages: FilterMessages
}

class Driver extends React.Component<Props, {}> {
  private renderTasksContainer = ({ handleContextMenu }) => {
    const { tasks, sectionId, filterMessages } = this.props

    return (
      <TasksContainer
        sectionId={sectionId}
        tasks={tasks}
        handleContextMenu={handleContextMenu}
        filterMessages={filterMessages}
      />
    )
  }

  onSortChange = (event) => {
    const { viewType, sectionId, sectionState } = this.props

    this.props.MapUiActions.setSectionState(viewType, sectionId, {
      ...sectionState,
      sortType: event.currentTarget.value
    })
  }

  private getListOfSortBy() {
    const { viewType } = this.props

    if (viewType === VIEW_TYPE.RECIPIENTS) {
      return [SORT_TYPE.CLOCK, SORT_TYPE.MERCHANT, SORT_TYPE.LATE]
    }

    return [
      SORT_TYPE.CLOCK,
      SORT_TYPE.FLEET,
      SORT_TYPE.MERCHANT,
      SORT_TYPE.DRIVER,
      SORT_TYPE.LATE
    ]
  }

  renderSortByDropdown = () => {
    return (
      <div className='sortByContainer'>
        <span>Sort by:&nbsp;</span>
        <select
          data-testid='sort-by-select'
          onChange={this.onSortChange}
          value={this.props.sectionState.sortType}
        >
          {this.getListOfSortBy().map((sortBy) => {
            return (
              <option key={sortBy} value={sortBy}>
                {sortBy}
              </option>
            )
          })}
        </select>
      </div>
    )
  }

  private renderSectionTitle(title: string) {
    return (
      <span className='title' data-testid='title'>
        {title}
      </span>
    )
  }

  private renderSectionHeader() {
    const { sectionId, tasks, viewType, viewState } = this.props

    switch (sectionId) {
      case TASK_SECTION.PENDING:
        return this.renderSectionTitle(`Pending (${tasks.taskIds.size})`)

      case TASK_SECTION.ON_GOING: {
        if (viewType === VIEW_TYPE.TASKS) {
          return (
            <div className='sectionContainer'>
              {!viewState.checkedSeparatePendingAndOngoing && (
                <span className='sectionTitle'>
                  {this.renderSectionTitle(
                    `Pending (${tasks.numberOfPendingTasks})`
                  )}
                  <span>&nbsp;|</span>
                  {this.renderSectionTitle(
                    `On Going (${tasks.numberOfOnGoingTasks})`
                  )}
                </span>
              )}
              {viewState.checkedSeparatePendingAndOngoing &&
                this.renderSectionTitle(`On Going (${tasks.taskIds.size})`)}
            </div>
          )
        }

        return this.renderSectionTitle(`On Going (${tasks.taskIds.size})`)
      }

      case TASK_SECTION.SEARCH_RESULTS:
        return this.renderSectionTitle(`Search results (${tasks.taskIds.size})`)
    }
  }

  renderSectionInfo = () => {
    const { viewType } = this.props
    switch (viewType) {
      case VIEW_TYPE.RECIPIENTS:
      case VIEW_TYPE.TASKS:
        return (
          <div tabIndex={0} className='nameContainer'>
            <div className='DriverName'>
              {this.renderSectionHeader()}
              {this.renderSortByDropdown()}
            </div>
          </div>
        )

      default:
        return null
    }
  }

  render() {
    const { viewType } = this.props

    return (
      <li
        className={classnames('Driver', {
          driverView: viewType === VIEW_TYPE.TASKS
        })}
      >
        {this.renderSectionInfo()}
        <TaskContextMenu.Context>
          {this.renderTasksContainer}
        </TaskContextMenu.Context>
      </li>
    )
  }
}

interface TasksContainerProps {
  tasks: {
    taskIds: Set<string>
    groupedTaskIds: {
      [key: string]: Set<string> | Map<string, Set<string>>
    }
  }
  sectionId: TASK_SECTION
  handleContextMenu: (event) => void
  filterMessages: FilterMessages
}

class TasksContainer extends React.Component<TasksContainerProps> {
  shouldComponentUpdate(nextProps): boolean {
    return (
      !is(this.props.tasks, nextProps.tasks) ||
      this.props.handleContextMenu !== nextProps.handleContextMenu ||
      this.props.sectionId !== nextProps.sectionId
    )
  }

  render() {
    const { handleContextMenu, tasks, sectionId, filterMessages } = this.props

    if (tasks.taskIds.size === 0 && filterMessages.active) {
      return (
        <div className='noOngoingChats'>
          <FormattedMessage id='chat.noOngoing' />
        </div>
      )
    }

    return (
      <ul className='taskList' onContextMenu={handleContextMenu}>
        <Tasks tasks={tasks} sectionId={sectionId} />
      </ul>
    )
  }
}

const mapStateToProps = (state, props) => {
  const sectionState = sectionStateUtils.getSectionState(
    props.viewType,
    props.sectionId,
    state
  )
  const viewState = viewStateUtils.getViewState(props.viewType, state)

  return {
    ...DriverSelectorFactory(state, { ...props, sectionState, viewState }),
    sectionState,
    viewState,
    filterMessages: state.MapUiReducer.get('filterMessages').toJS()
  }
}

const mapDispatchToProps = (dispatch) => ({
  MapUiActions: bindActionCreators(MapUiActions, dispatch)
})

export { Driver, TasksContainer, mapStateToProps, mapDispatchToProps }
export default connect(mapStateToProps, mapDispatchToProps)(Driver)
