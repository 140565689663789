import { fromJS, Set as ImmutableSet } from 'immutable'

import { DISMISS_LATE_PICKUP_NOTIFICATION } from '../actions/NotificationsUiActions'

const initialState = fromJS({
  dismissedLatePickups: ImmutableSet()
})

export default function NotificationsUiReducer(state = initialState, action) {
  switch (action.type) {
    case DISMISS_LATE_PICKUP_NOTIFICATION:
      return state.set(
        'dismissedLatePickups',
        state.get('dismissedLatePickups').add(action.taskId)
      )

    default:
      return state
  }
}
