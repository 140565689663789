import { createSelector } from 'reselect'

import MarkerSelector from './Marker.selector'
import PolylineSelector from './Polyline.selector'

const LONDON = { lat: 51.507351, lng: -0.127758 }

const combiner = (markers: any, polylines) => {
  /* global google */

  const bounds = new google.maps.LatLngBounds()
  if (markers.bounds != null) {
    bounds.union(markers.bounds)
  }
  if (polylines.bounds != null) {
    bounds.union(polylines.bounds)
  }

  // if there's nothing to show on the map, zoom in on London
  if (markers.bounds == null && polylines.bounds == null) {
    bounds.extend(new google.maps.LatLng(LONDON.lat - 0.1, LONDON.lat - 0.1))
    bounds.extend(new google.maps.LatLng(LONDON.lat + 0.1, LONDON.lat + 0.1))
  }

  return {
    markers: markers.markers,
    infoWindows: markers.infoWindows,
    polylines: polylines.polylines,
    bounds
  }
}

export { combiner, LONDON }
export default createSelector(MarkerSelector, PolylineSelector, combiner)
