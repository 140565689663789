/* eslint no-console: 0*/
'use strict'

export default function log(...args: any[]) {
  const ts = new Date()
    .toISOString()
    .replace('T', ' ')
    .replace('Z', '')

  // tslint:disable-next-line
  console.log(ts, ...args)
}
