import classnames from 'classnames'
import React, { PureComponent } from 'react'
import { Button, Checkbox, Col, Grid, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import AuthActions from '../../../actions/AuthActions'
import { GOOGLE_MAP_STYLE, TOGGLE } from '../../../constants'
import chat from '../../../services/chat/chat'
import googleMapStyleManager from '../../../util/googleMapStyleManager/googleMapStyleManager'

import './UserSettings.scss'

const gmapDark = require('./images/dark.png')
const gmapLight = require('./images/light.png')

interface Props {
  AuthActions: typeof AuthActions
  AuthReducer: any
}

interface State {
  selectedStyle: GOOGLE_MAP_STYLE
  soundSetting: TOGGLE
}

class UserSettings extends PureComponent<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      selectedStyle: googleMapStyleManager.getStyle(),
      soundSetting: chat.getSoundSetting()
    }
  }

  onLogout = () => {
    this.props.AuthActions.logout('user')
  }

  setGoogleMapStyle = (style: GOOGLE_MAP_STYLE) => () => {
    googleMapStyleManager.setStyle(style)
    this.setState({ selectedStyle: style })
  }

  private turnOffChatSound = () => {
    const toggle =
      chat.getSoundSetting() === TOGGLE.OFF ? TOGGLE.ON : TOGGLE.OFF

    chat.setSoundSetting(toggle)

    this.setState({
      soundSetting: toggle
    })
  }

  render() {
    const { selectedStyle, soundSetting } = this.state
    const user = this.props.AuthReducer.get('user')

    if (!user) {
      return null
    }

    return (
      <Grid fluid>
        <Row>
          <Col sm={12}>
            <h2>
              <FormattedMessage id='settings.user.title' />
            </h2>
          </Col>
        </Row>

        <h4>Chat:</h4>
        <p className='chatSoundSettings'>
          <Checkbox
            id='chatSoundTurnOff'
            checked={soundSetting === TOGGLE.OFF}
            onChange={this.turnOffChatSound}
          >
            <label htmlFor='chatSoundTurnOff' className='chatSoundTurnOff'>
              <i className='fa fa-bell-slash spaceAfter' />
              Turn <strong>OFF</strong> the chat sound
            </label>
          </Checkbox>
        </p>

        <br />

        <h4>Map color:</h4>

        <div className='visualPicker'>
          {[GOOGLE_MAP_STYLE.LIGHT, GOOGLE_MAP_STYLE.DARK].map((style) => {
            const selected = selectedStyle === style
            const selectedProps = selected
              ? {}
              : { onClick: this.setGoogleMapStyle(style) }

            return (
              <div
                key={style}
                className={classnames('item pointer', { selected })}
                {...selectedProps}
              >
                <img
                  src={style === GOOGLE_MAP_STYLE.LIGHT ? gmapLight : gmapDark}
                  width={80}
                  height={80}
                />
                <span>{style.toUpperCase()}</span>
              </div>
            )
          })}
        </div>

        <br />

        <h4>User info:</h4>

        <p>
          You are logged in with email: <b>{user.get('username')}</b>
        </p>

        <Button
          bsStyle='primary'
          className='spaceAfter'
          onClick={this.onLogout}
          data-testid='logoutButton'
        >
          Logout
        </Button>
      </Grid>
    )
  }
}

const mapStateToProps = (state: any) => ({
  AuthReducer: state.AuthReducer
})

const mapDispatchToProps = (dispatch) => ({
  AuthActions: bindActionCreators(AuthActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings)
