import React, { PureComponent } from 'react'
import { Col, Grid, Row, Tab, Tabs } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

import '../../map/tasks/Task/Task.scss'
import '../Glossary/GlossaryDocument.scss'

import GlossaryCommonDefinitions from './GlossaryCommonDefinitions'
import GlossaryDrivers from './GlossaryDrivers'
import GlossaryIcons from './GlossaryIcons'
import GlossaryRecipients from './GlossaryRecipients'
import GlossarySenders from './GlossarySenders'

class GlossaryDocument extends PureComponent {
  render() {
    return (
      <Grid fluid>
        <Row>
          <Col sm={12}>
            <h2>
              <FormattedMessage id='settings.glossary.title' />
            </h2>

            <h4>
              The dashboard contains colors and icons that help you monitor the
              status of the orders. Here's what they mean.
            </h4>
            <GlossaryCommonDefinitions />

            <Tabs
              defaultActiveKey={1}
              animation={false}
              id='noanim-tab-example'
            >
              <Tab
                eventKey={1}
                title={
                  <>
                    <i className='fa fa-store-alt' /> <span>SENDERS</span>
                  </>
                }
              >
                <GlossarySenders />
              </Tab>
              <Tab
                eventKey={2}
                title={
                  <>
                    <i className='fa fa-motorcycle' /> <span>DRIVERS</span>
                  </>
                }
              >
                <GlossaryDrivers />
              </Tab>
              <Tab
                eventKey={3}
                title={
                  <>
                    <i className='fa fa-users' /> <span>RECIPIENTS</span>
                  </>
                }
              >
                <GlossaryRecipients />
              </Tab>
            </Tabs>
            <GlossaryIcons />
          </Col>
        </Row>
      </Grid>
    )
  }
}

export default GlossaryDocument
