import moment from 'moment'
import React, { MouseEventHandler } from 'react'
import { Alert, Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import QuotesUiActions from '../../../../../actions/QuotesUiActions'
import DriverActions from '../../../../../actions/services/DriverActions'
import TaskActions from '../../../../../actions/services/TaskActions'
import { cancelTask } from '../../../../../api/tasks'
import { QUOTE_STATUS, TASK_STATUS } from '../../../../../constants'
import { isTaskPending } from '../../../../../util/taskState'
import Spinner from '../../../../common/Spinner/Spinner'

import './TaskFormButtons.scss'

interface DispatchProps {
  QuotesUiActions: typeof QuotesUiActions
  DriverActions: typeof DriverActions
}

interface Props {
  // injected by parent component
  onModalHide: MouseEventHandler<Button>
  onCloneClick: MouseEventHandler<Button>
  onMissingItemButtonClick?: MouseEventHandler<Button>
  onRepeatBookingButtonClick?: MouseEventHandler<Button>
  onArchiveClick: MouseEventHandler<Button>
  // redux selector
  allowEditing: boolean
  allowRepeat: boolean
  // injected by redux-form
  pristine: boolean
  submitting: boolean
  error: any
  handleSubmit: () => Promise<void>
  QuotesUiActions: typeof QuotesUiActions
  TaskActions: typeof TaskActions
  task: any
  user: any
  chosenQuote: any
  taskId: string
  approvalInProgress: boolean
  cancellationInProgress: boolean
  taskIsAssigned: boolean
  isTaskArchived: boolean
  cloningInProgress: boolean
  archivingInProgress: boolean
}

interface State {
  cancellingOrder: boolean
}

class TaskFormButtons extends React.PureComponent<
  DispatchProps & Props,
  State
> {
  constructor(props) {
    super(props)

    this.state = {
      cancellingOrder: false
    }
  }

  setOnDemand() {
    this.props.TaskActions.setOnDemand({
      taskId: this.props.task.get('id'),
      data: true
    })
  }

  unassignWalkedTask = (task) => {
    if (task && task.get('isWalked')) {
      this.props.DriverActions.reassign(null, task.get('id'), [task.get('id')])
    }
  }

  handleSetOnDemandClick = (task) => () => {
    this.unassignWalkedTask(task)
    if (this.props.pristine) {
      this.setOnDemand()
    } else {
      this.props.handleSubmit().then(this.setOnDemand.bind(this))
    }
  }

  redoQuotes() {
    this.props.QuotesUiActions.redoQuotes(this.props.task.get('id'))
  }

  handleRedoClick = (task) => () => {
    this.unassignWalkedTask(task)
    if (this.props.pristine) {
      this.redoQuotes()
    } else {
      this.props.handleSubmit().then(this.redoQuotes.bind(this))
    }
  }

  handleApproveClick() {
    this.props.QuotesUiActions.approveQuote({
      taskId: this.props.task.get('id')
    })
  }

  handleCancelClick() {
    const confirmationMessage = isTaskPending(this.props.task)
      ? 'This cancellation has no fee.'
      : 'You might be charged a fee.'

    if (
      !confirm(
        `Are you sure that you want to cancel this job?\n${confirmationMessage}`
      )
    ) {
      return
    }

    this.props.QuotesUiActions.cancelQuote(this.props.task)
  }

  handleCancelOrder = async () => {
    if (!confirm('Are you sure that you want to cancel this order?')) {
      return
    }

    const { taskId } = this.props

    this.setState({ cancellingOrder: true })

    try {
      await cancelTask(taskId)
    } catch (error) {
      alert(error.message)
    } finally {
      this.setState({ cancellingOrder: false })
    }
  }

  renderMissingItemButton = () => {
    const { onMissingItemButtonClick, cloningInProgress } = this.props
    return (
      <Button onClick={onMissingItemButtonClick} disabled={cloningInProgress}>
        {cloningInProgress ? (
          <Spinner className='spaceAfter' />
        ) : (
          <i className='fa fa-box-open spaceAfter' />
        )}
        <FormattedMessage id='map.tasks.modal.missingItem' />
      </Button>
    )
  }

  renderRepeatBookingButton = () => {
    const { onRepeatBookingButtonClick } = this.props
    return (
      <Button onClick={onRepeatBookingButtonClick}>
        <i className='fa fa-redo spaceAfter' />
        <FormattedMessage id='map.tasks.modal.repeatBooking' />
      </Button>
    )
  }

  render() {
    const {
      taskId,
      task,
      pristine,
      submitting,
      allowEditing,
      taskIsAssigned,
      allowRepeat
    } = this.props
    const requestInProgress =
      this.props.approvalInProgress || this.props.cancellationInProgress

    const { cancellingOrder } = this.state

    let expiry
    let expired

    // todo: task.getIn with string is not valid, always undefined, bug?
    if (task != null && task.getIn('onDemand')) {
      expiry = moment(task.getIn(['quotes', 'timestamp'])).add(15, 'minutes')
      expired = expiry.isBefore(moment())
    }

    const isAdmin = this.props.user.get('administrator')
    const canCancel = isAdmin || this.props.user.get('canCancelTask')
    const canClone = isAdmin || this.props.user.get('canCloneTask')
    const isMultidropParcel = task && task.get('type') === 'multidropParcel'
    const isMultidrop = task && task.get('type') === 'multidrop'

    const taskStatus = task && task.get('currentStatus')
    const quoteStatus = task && task.getIn(['quotes', 'status'])
    const taskFinished =
      taskStatus === TASK_STATUS.SUCCESS || taskStatus === TASK_STATUS.CANCELLED

    const onDemand = task && task.get('onDemand')
    const showArchiveButton =
      task && isAdmin && taskStatus === TASK_STATUS.CANCELLED
    const showRedoQuoteButton =
      task &&
      !isMultidropParcel &&
      onDemand &&
      quoteStatus !== QUOTE_STATUS.APPROVED &&
      !taskFinished &&
      !taskIsAssigned
    const showApproveButton =
      showRedoQuoteButton && quoteStatus === QUOTE_STATUS.QUOTED
    const showCancelJobButton =
      canCancel &&
      quoteStatus === QUOTE_STATUS.APPROVED &&
      !taskFinished &&
      !isMultidropParcel
    const showCloneButton = task && canClone && !isMultidrop
    const showRepeatButton = allowRepeat && !isMultidrop

    const showCancelOrderButton =
      task && isTaskPending(task) && quoteStatus !== QUOTE_STATUS.APPROVED

    return (
      <div>
        {/* submit error message */}
        {this.props.error && (
          <Alert
            bsStyle='danger'
            title={JSON.stringify(this.props.error, null, 2)}
            className='pull-left'
          >
            <FormattedMessage id='map.tasks.modal.error' />
          </Alert>
        )}

        <div>
          {/* set to on-demand button */}
          {task && !onDemand && !isMultidropParcel && (
            <Button
              bsStyle='primary'
              className='pull-left'
              data-testid='quotes-message-button'
              onClick={this.handleSetOnDemandClick(task)}
              disabled={
                submitting ||
                !allowEditing ||
                this.props.isTaskArchived ||
                this.props.taskIsAssigned ||
                (task != null && task.get('schedule') == null)
              }
            >
              <i className='fa fa-redo-alt spaceAfter' />
              <FormattedMessage id='map.tasks.modal.setOnDemand' />
            </Button>
          )}

          {/* archive button */}
          {showArchiveButton && (
            <Button
              onClick={this.props.onArchiveClick}
              disabled={!allowEditing || this.props.archivingInProgress}
            >
              {this.props.archivingInProgress ? (
                <Spinner className='spaceAfter' />
              ) : (
                <i className='fa fa-trash spaceAfter' />
              )}
              <FormattedMessage id='map.tasks.modal.archive' />
            </Button>
          )}

          {/* close button */}
          {!this.props.pristine && (
            <Button
              onClick={this.props.onModalHide}
              disabled={submitting}
              data-testid='close-button-modal-task'
            >
              <i className='fa fa-times-circle spaceAfter' />
              {<FormattedMessage id='map.tasks.modal.cancel' />}
            </Button>
          )}

          {/* save button */}
          {(!taskId || !pristine) && (
            <Button
              type='submit'
              className='btn-brand-secondary'
              disabled={submitting}
              data-testid='save-task-button'
            >
              {submitting ? (
                <Spinner showText={false} className='spaceAfter' />
              ) : (
                <i className='fa fa-check spaceAfter' />
              )}

              <FormattedMessage id='map.tasks.modal.save' />
            </Button>
          )}

          {/* QUOTE BUTTONS */}
          {/* redo quotes button */}
          {showRedoQuoteButton && (
            <Button
              bsStyle='primary'
              className='pull-left'
              onClick={this.handleRedoClick(task)}
              disabled={
                requestInProgress ||
                this.props.isTaskArchived ||
                quoteStatus === QUOTE_STATUS.APPROVED
              }
            >
              {task.get('quotes') != null ? (
                <i className='fa fa-redo-alt spaceAfter' />
              ) : (
                <Spinner className='spaceAfter' />
              )}
              <FormattedMessage id='map.tasks.quote.new' />
            </Button>
          )}

          {/* cancel order button */}
          {showCancelOrderButton && (
            <Button
              bsStyle='warning'
              onClick={this.handleCancelOrder}
              disabled={cancellingOrder}
            >
              {cancellingOrder ? (
                <Spinner className='spaceAfter' />
              ) : (
                <i className='fa fa-ban-circle spaceAfter' />
              )}
              <FormattedMessage id='map.tasks.cancelOrder' />
            </Button>
          )}

          {/* cancel job button */}
          {showCancelJobButton && (
            <Button
              bsStyle='warning'
              onClick={this.handleCancelClick.bind(this)}
              disabled={requestInProgress}
            >
              {this.props.cancellationInProgress ? (
                <Spinner className='spaceAfter' />
              ) : (
                <i className='fa fa-ban-circle spaceAfter' />
              )}
              <FormattedMessage id='map.tasks.quote.cancel' />
            </Button>
          )}

          {/* approve quote button */}
          {showApproveButton && (
            <Button
              bsStyle='success'
              onClick={this.handleApproveClick.bind(this)}
              disabled={
                requestInProgress || expired || this.props.chosenQuote == null
              }
              data-testid='request-quotes-button'
            >
              {this.props.approvalInProgress ? (
                <Spinner className='spaceAfter' />
              ) : (
                <i className='fa fa-check spaceAfter' />
              )}
              <FormattedMessage id='map.tasks.quote.approve' />
            </Button>
          )}
        </div>

        <div className='taskFormUtilitiesButtons'>
          {showRepeatButton &&
            this.props.onRepeatBookingButtonClick &&
            this.renderRepeatBookingButton()}

          {showCloneButton &&
            this.props.onMissingItemButtonClick &&
            this.renderMissingItemButton()}

          {/* clone button */}
          {showCloneButton && (
            <Button
              onClick={this.props.onCloneClick}
              disabled={this.props.cloningInProgress}
              data-testid='book-another-driver-button'
            >
              {this.props.cloningInProgress ? (
                <Spinner className='spaceAfter' />
              ) : (
                <i className='fa fa-copy spaceAfter' />
              )}
              <FormattedMessage id='map.tasks.modal.clone' />
            </Button>
          )}
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  QuotesUiActions: bindActionCreators(QuotesUiActions, dispatch),
  DriverActions: bindActionCreators(DriverActions, dispatch)
})

export { mapDispatchToProps }
export default connect<{}, DispatchProps, any>(
  null,
  mapDispatchToProps
)(TaskFormButtons)
