import { isEmpty } from 'lodash'
import { BehaviorSubject } from 'rxjs'

import { cannedResponsesDoc } from '../firebase/firestoreQuery'

import { CannedBehaviorSubject, CannedResponses } from './types'

const subject = new BehaviorSubject<CannedBehaviorSubject>({
  items: [],
  loaded: false
})

const add = (shortcut: string, content: string) => {
  return cannedResponsesDoc.set({
    items: [
      ...subject.getValue().items,
      {
        id: new Date().getTime().toString(),
        shortcut,
        content
      }
    ]
  })
}

const edit = (id: string, shortcut: string, content: string) => {
  const { items } = subject.getValue()
  const foundIndex = items.findIndex((message) => message.id === id)
  if (foundIndex < 0) {
    return alert('Invalid id')
  }

  const newMessages = [...items]
  newMessages[foundIndex] = {
    id,
    shortcut,
    content
  }

  return cannedResponsesDoc.set({
    items: newMessages
  })
}

const remove = (id: string) => {
  return cannedResponsesDoc.set({
    items: subject.getValue().items.filter((message) => message.id !== id)
  })
}

let cannedResponseQueryUnsubscriber: () => void = null

const listen = () => {
  cannedResponseQueryUnsubscriber = cannedResponsesDoc.onSnapshot(
    (snapshot) => {
      const cannedResponses = !isEmpty(snapshot.data())
        ? (snapshot.data() as CannedResponses)
        : { items: [] }

      subject.next({
        ...cannedResponses,
        loaded: true
      })
    }
  )
}

const stopListen = () => {
  if (cannedResponseQueryUnsubscriber) {
    cannedResponseQueryUnsubscriber()
  }
}

export default { subject, add, edit, remove, listen, stopListen }
