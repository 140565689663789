import download from 'downloadjs'
import { List, Map as ImmutableMap } from 'immutable'
import moment from 'moment'
import React from 'react'
import { Button, Table, Well } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { generatePath, Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import ReportsUiActions from '../../../actions/ReportsUiActions'
import ArchivedTaskActions from '../../../actions/services/ArchivedTaskActions'
import TaskActions from '../../../actions/services/TaskActions'
import { COURIER, VIEW_TYPE } from '../../../constants'
import { Routes } from '../../../navigation/Routes'
import ReportsSelector from '../../../selectors/ReportsSelector/ReportsSelector'
import TrackerLink from '../../common/TrackerLink/TrackerLink'
import SelectedQuoteTable from '../../map/task/quotes/SelectedQuoteTable/SelectedQuoteTable'
import ReportsFilterForm from '../ReportsFilterForm/ReportsFilterForm'
import ReportsSearchForm from '../ReportsSearchForm/ReportsSearchForm'
import { exportTasksToCsvString } from '../TasksCsvExport/TasksCsvExport'

import './Reports.scss'

function buildActionLog(task) {
  const actionLog = task.get('actionLog')
  if (actionLog) {
    return actionLog.map((action, index) => {
      const timestamp = action.get('timestamp')

      let author = <span>{action.get('author')}</span>
      if (
        action.get('author') === COURIER.AddisonLee &&
        action.get('driverId')
      ) {
        author = (
          <span>
            {action.get('author')}
            <br />
            {action.get('driverId')}
          </span>
        )
      }

      return (
        <tr key={index}>
          <td>{moment(timestamp).format('DD/MM HH:mm')}</td>
          <td>{author}</td>
          <td>{action.get('type')}</td>
        </tr>
      )
    })
  } else {
    return <tr />
  }
}

function buildNotes(task) {
  try {
    const notes = task.get('notes')
    if (notes) {
      return notes.map((action, index) => {
        if (typeof action.get !== 'function') {
          return null
        }

        const timestamp = action.get('timestamp')

        return (
          <tr key={index}>
            <td>{moment(timestamp).format('DD/MM HH:mm')}</td>
            <td>{action.get('author')}</td>
            <td>
              {action.get('type') === 'image' ? (
                <img src={action.get('data')} className='imageNote' />
              ) : (
                action.get('data')
              )}
            </td>
          </tr>
        )
      })
    } else {
      return <tr />
    }
  } catch (err) {
    return (
      <tr>
        <td>{'Error for task ' + task.get('id')}</td>
      </tr>
    )
  }
}

function TableRow({ task, clone, canClone }) {
  const chosenQuote = task.getIn(['quotes', 'chosenQuote'])
  const confirmation =
    task.getIn(['quotes', 'confirmation']) ||
    task.getIn(['quotes', 'previousConfirmations', -1])
  return (
    <tr data-testid='report-row'>
      <td>{task.get('merchantId')}</td>
      <td data-testid='order-number'>
        {task.get('externalRef')}
        {task.get('externalRef') && canClone ? (
          <a onClick={clone}>
            <i className='fa fa-copy' />
          </a>
        ) : null}
      </td>
      <td className='text-nowrap'>
        {moment(task.getIn(['actionLog', 0, 'timestamp'])).format(
          'YYYY-MM-DD HH:mm'
        )}
      </td>
      <td>
        <FormattedMessage id={`task.schedule.${task.get('schedule')}`} />
      </td>
      <td>
        <span className='text-nowrap'>
          {moment(task.get('pickupDateFrom')).format('YYYY-MM-DD HH:mm')}
        </span>{' '}
        &ndash;{' '}
        <span className='text-nowrap'>
          {moment(task.get('pickupDateTo')).format('YYYY-MM-DD HH:mm')}
        </span>
      </td>
      <td>
        <span className='text-nowrap'>
          {moment(task.get('deliveryDateFrom')).format('YYYY-MM-DD HH:mm')}
        </span>{' '}
        &ndash;{' '}
        <span className='text-nowrap'>
          {moment(task.get('deliveryDateTo')).format('YYYY-MM-DD HH:mm')}
        </span>
      </td>
      <td>
        <p>
          <FormattedMessage id='task.parcelSize' />: {task.get('parcelLength')}x
          {task.get('parcelWidth')}x{task.get('parcelHeight')}{' '}
          <FormattedMessage id='task.parcelLengthUnit' />
        </p>
        <p>
          <FormattedMessage id='task.parcelWeight' />: {task.parcelWeight}{' '}
          <FormattedMessage id='task.parcelWeightUnit' />
        </p>
        <p>
          <FormattedMessage id='task.parcelHandling' />:{' '}
        </p>
        <ul>
          {task.get('parcelFragile') ? (
            <li>
              <FormattedMessage id='task.parcelFragile' />
            </li>
          ) : null}
          {task.get('parcelDoNotRotate') ? (
            <li>
              <FormattedMessage id='task.parcelDoNotRotate' />
            </li>
          ) : null}
          {task.get('parcelContainsLiquid') ? (
            <li>
              <FormattedMessage id='task.parcelContainsLiquid' />
            </li>
          ) : null}
          {task.get('parcelContainsHotFood') ? (
            <li>
              <FormattedMessage id='task.parcelContainsHotFood' />
            </li>
          ) : null}
        </ul>
      </td>
      <td>
        <p>{task.get('sourceName')}</p>
        <p>
          {task.get('sourceAddress')}
          <br />
          {task.get('sourceCity')}
          <br />
          {task.get('sourcePostcode')}
          <br />
          {task.get('sourceCountry')}
        </p>
        <p>{task.get('sourcePhone')}</p>
        <p>{task.get('sourceEmail')}</p>
      </td>
      <td>{task.get('pickupInstructions')}</td>
      <td>
        <p>{task.get('destinationName')}</p>
        <p>
          {task.get('destinationAddress')}
          <br />
          {task.get('destinationCity')}
          <br />
          {task.get('destinationPostcode')}
          <br />
          {task.get('destinationCountry')}
        </p>
        <p>{task.get('destinationPhone')}</p>
        <p>{task.get('destinationEmail')}</p>
      </td>
      <td>{task.get('deliveryInstructions')}</td>
      <td>
        {confirmation && chosenQuote ? (
          <SelectedQuoteTable quote={chosenQuote} confirmation={confirmation} />
        ) : (
          <FormattedMessage id='reports.table.noQuoteChosen' />
        )}
      </td>
      <td>
        {task.get('timeCompleted') != null ? (
          <span className='text-nowrap'>
            {moment(task.get('timeCompleted')).format('YYYY-MM-DD HH:mm')}
          </span>
        ) : (
          <Link
            to={generatePath(Routes.Map.TaskDetail, {
              taskId: task.get('id'),
              viewType: VIEW_TYPE.TASKS
            })}
          >
            <Button>
              <FormattedMessage id='reports.table.linkToInProgress' />
            </Button>
          </Link>
        )}
      </td>
      <td>
        <Table condensed>
          <thead>
            <tr>
              <th>
                <FormattedMessage id='task.actionLog.timestamp' />
              </th>
              <th>
                <FormattedMessage id='task.actionLog.author' />
              </th>
              <th>
                <FormattedMessage id='task.actionLog.type' />
              </th>
            </tr>
          </thead>
          <tbody>{buildActionLog(task)}</tbody>
        </Table>
      </td>
      <td>
        <TrackerLink task={task} />
        <p>
          {`${task.get('driverName', '') ||
            task.getIn(['driver', 'name'], '')} ${task.get('driverPhone', '') ||
            task.getIn(['driver', 'phone'], '')}`}
        </p>
      </td>
      <td>
        <Table condensed>
          <thead>
            <tr>
              <th>
                <FormattedMessage id='task.notes.timestamp' />
              </th>
              <th>
                <FormattedMessage id='task.notes.author' />
              </th>
              <th>
                <FormattedMessage id='task.notes.data' />
              </th>
            </tr>
          </thead>
          <tbody>{buildNotes(task)}</tbody>
        </Table>
      </td>
      <td>
        <Table condensed>
          <thead>
            <tr>
              <th>
                <FormattedMessage id='task.sms.timestamp' />
              </th>
              <th>
                <FormattedMessage id='task.sms.phone' />
              </th>
              <th>
                <FormattedMessage id='task.sms.message' />
              </th>
            </tr>
          </thead>
          <tbody>
            {(task.get('smsLog') || []).map((sms, index) => {
              return (
                <tr key={index}>
                  <td>
                    {moment(sms.get('dateCreated')).format('DD/MM HH:mm')}
                  </td>
                  <td>{sms.get('to')}</td>
                  <td>{sms.get('message')}</td>
                </tr>
              )
            })}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={3}>
                Total: {task.get('smsLog') ? task.get('smsLog').size : 0}
              </td>
            </tr>
          </tfoot>
        </Table>
      </td>
    </tr>
  )
}

interface Props {
  ArchivedTaskActions: typeof ArchivedTaskActions
  ReportsUiActions: typeof ReportsUiActions
  TaskActions: typeof TaskActions
  merchants: List<any>
  user: ImmutableMap<string, any>
  results: ImmutableMap<string, any>
}

class Reports extends React.PureComponent<Props> {
  MAX_RESULTS_PERMITTED = 2000

  componentWillMount() {
    const params = this.getInitialFilterValues()
    this.props.ArchivedTaskActions.find(params)
    this.props.ReportsUiActions.changeLastReportQuery('filter', params)
  }

  getInitialFilterValues() {
    return {
      timeFrom: moment()
        .subtract(1, 'day')
        .startOf('day')
        .toISOString(),
      timeTill: moment()
        .subtract(1, 'day')
        .endOf('day')
        .toISOString(),
      merchants: this.props.merchants.toJS()
    }
  }

  handleFilterSubmit(params) {
    this.props.ArchivedTaskActions.find(params)
    this.props.ReportsUiActions.changeLastReportQuery('filter', params)
  }

  handleExportClick() {
    if (this.props.results != null) {
      // tslint:disable-next-line
      console.log(
        'tasks',
        this.props.results
          .map((t) => t.toJS())
          .toArray()
          .slice(0, 10)
      )
      const csvString = exportTasksToCsvString(
        this.props.results.map((task) => task.toJS()).toArray()
      )
      download(csvString, 'orkestro_report.csv')
    }
  }

  handleSearchSubmit(params) {
    this.props.ArchivedTaskActions.findById(params)
    this.props.ReportsUiActions.changeLastReportQuery('search', params)
  }

  createCopy(task) {
    // tslint:disable-next-line
    confirm(`Create a copy ${task.get('externalRef')}?`) &&
      this.props.TaskActions.clone({ taskId: task.get('id') })
  }

  renderTableHeader() {
    return (
      <thead>
        <tr>
          <th>
            <FormattedMessage id='task.merchantId' />
          </th>
          <th>
            <FormattedMessage id='task.externalRef' />
          </th>
          <th>
            <FormattedMessage id='task.createdAt' />
          </th>
          <th>
            <FormattedMessage id='task.schedule' />
          </th>
          <th>
            <FormattedMessage id='reports.table.pickupDate' />
          </th>
          <th>
            <FormattedMessage id='reports.table.deliveryDate' />
          </th>
          <th>
            <FormattedMessage id='reports.table.parcelSpecifications' />
          </th>
          <th>
            <FormattedMessage id='reports.table.source' />
          </th>
          <th>
            <FormattedMessage id='task.pickupInstructions' />
          </th>
          <th>
            <FormattedMessage id='reports.table.destination' />
          </th>
          <th>
            <FormattedMessage id='task.deliveryInstructions' />
          </th>
          <th>
            <FormattedMessage id='task.quotes.chosen' />
          </th>
          <th>
            <FormattedMessage id='task.timeCompleted' />
          </th>
          <th>
            <FormattedMessage id='task.actionLog' />
          </th>
          <th>
            <FormattedMessage id='task.trackingUrl' />
          </th>
          <th>
            <FormattedMessage id='task.notes' />
          </th>
          <th>
            <FormattedMessage id='task.smsLog' />
          </th>
        </tr>
      </thead>
    )
  }

  handleResults(results) {
    const filteredResults = results.filter(
      (result) => result.get('id') !== undefined
    )
    const maxLengthOfResults = filteredResults.slice(
      0,
      this.MAX_RESULTS_PERMITTED
    )

    return filteredResults.size > this.MAX_RESULTS_PERMITTED
      ? maxLengthOfResults
      : filteredResults
  }

  render() {
    return (
      <div className='Reports'>
        <div className='toolbar'>
          <Button onClick={this.handleExportClick.bind(this)}>
            <FormattedMessage id='reports.filterForm.exportCsv' />
          </Button>{' '}
          <Well>
            <ReportsFilterForm
              merchants={this.props.merchants.sort()}
              onExportClick={this.handleExportClick.bind(this)}
              onSubmit={this.handleFilterSubmit.bind(this)}
              initialValues={this.getInitialFilterValues()}
            />
          </Well>{' '}
          <Well>
            <ReportsSearchForm
              onSubmit={this.handleSearchSubmit.bind(this)}
              initialValues={{
                keyword: ''
              }}
            />
          </Well>
        </div>
        {this.props.results?.size > this.MAX_RESULTS_PERMITTED ? (
          <div className='alert alert-danger'>
            <FormattedMessage id='reports.searchResults.maxNumberResults' />
          </div>
        ) : null}
        <Table responsive bordered striped className='selectable reportsTable'>
          {this.renderTableHeader()}
          <tbody>
            {!this.props.results
              ? null
              : this.handleResults(this.props.results)
                  .map((task) => (
                    <TableRow
                      key={task.get('id')}
                      task={task}
                      canClone={this.props.user.get('administrator')}
                      clone={this.createCopy.bind(this, task)}
                    />
                  ))
                  .toArray()}
          </tbody>
        </Table>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  merchants: state.AuthReducer.get('user').get('merchants'),
  user: state.AuthReducer.get('user'),
  results: ReportsSelector(state)
})

const mapDispatchToProps = (dispatch) => ({
  TaskActions: bindActionCreators(TaskActions, dispatch),
  ArchivedTaskActions: bindActionCreators(ArchivedTaskActions, dispatch),
  ReportsUiActions: bindActionCreators(ReportsUiActions, dispatch)
})

export { mapStateToProps, mapDispatchToProps, TableRow }
export default connect(mapStateToProps, mapDispatchToProps)(Reports)
